/**
 * Helper
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { useEditorJs } from '@/hooks/core';

import { ContentElementType } from '@/codegen/graphql';

import { ContentElementItem } from './interfaces';
import { useContentElementContext } from './contexts/ContentElementContext';


/**
 * Get CE context value (merged with common)
 * @param {} type
 */
export const getContentElementContextValue = (type: ContentElementType) => {
    const {
        edit,
        show,
        edit: { common: editCommon = {} } = {},
        show: { common: showCommon = {} } = {},
    } = useContentElementContext();

    return {
        edit: _.assign(editCommon, edit?.[type]),
        show: _.assign(showCommon, show?.[type]),
    };
};

/**
 * Check content element is filled
 * @param {ContentElementItem} contentElement
 * @param {ReturnType<typeof useEditorJs>} editorJsHook
 * @returns {boolean}
 */
export const checkContentElementIsFilled = (
    contentElement: ContentElementItem,
    editorJsHook: ReturnType<typeof useEditorJs>,
): boolean => {
    const { isNotEmpty, isValidOutputData } = editorJsHook;

    switch (contentElement.type) {
        case ContentElementType.Checklist:
            return !!contentElement.content.items.length;

        case ContentElementType.Text:
        case ContentElementType.EditorJsBlock:
            return (
                !!contentElement.title
                || (
                    !!isValidOutputData(contentElement.content)
                    && isNotEmpty(contentElement.content)
                )
            );

        case ContentElementType.Video:
            return !!contentElement.content.location;

        case ContentElementType.Audio:
            return !!contentElement.content.location;

        default:
            return true;
    }
};
