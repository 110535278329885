/**
 * AudioRecorder
 *
 * @author: exode <hello@exode.ru>
 */

import { Canceler } from 'axios';

import React, { ComponentProps, useRef } from 'react';

import { AudioRecorder as ReactAudioVoiceRecorder } from 'react-audio-voice-recorder';

import { Notify } from '@/cutils';
import { uploadFile, useI18n } from '@/hooks/core';

import { StorageFileEntity, StorageSpace } from '@/codegen/graphql';

import { Container } from './AudioRecorder.styled';


interface Props extends ComponentProps<typeof ReactAudioVoiceRecorder> {
    space: StorageSpace;
    uploadId: string;
    onUploaded: (file: Partial<StorageFileEntity>) => void;
}


const AudioRecorder = (props: Props) => {

    const { space, uploadId, onUploaded } = props;

    const cancelFileUploadRef = useRef<Canceler | null>(null);

    const { t } = useI18n('components.Atoms.AudioRecorder');

    return (
        <Container>
            <ReactAudioVoiceRecorder showVisualizer={true}
                                     downloadOnSavePress={false}
                                     downloadFileExtension="webm"
                                     onNotAllowedOrFound={(err) => console.table(err)}
                                     mediaRecorderOptions={{ audioBitsPerSecond: 128000 }}
                                     audioTrackConstraints={{
                                         noiseSuppression: true,
                                         echoCancellation: true,
                                     }}
                                     onRecordingComplete={async (blob) => {
                                         const {
                                             success,
                                             exception,
                                             payload: [ file ] = [],
                                         } = await uploadFile({
                                             space,
                                             cancelFileUploadRef,
                                             id: uploadId,
                                             files: [
                                                 new File(
                                                     [ blob ],
                                                     'record.mp3',
                                                     { type: blob.type },
                                                 ),
                                             ],
                                         });

                                         if (exception || !success) {
                                             Notify.vkui({
                                                 appearance: 'error',
                                                 message: t('loadFileFailed'),
                                             });

                                             return;
                                         }

                                         onUploaded(file);
                                     }} {...props}/>
        </Container>
    );
};


export { AudioRecorder };
