/**
 * ImageOptionAtom
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If } from '@/cutils';

import { Caption } from '@exode.ru/vkui';

import { useTaskBuilderContext } from '@/components/Task/Builder';

import { CheckboxOptionAtomProps } from './CheckboxOptionAtom';


interface Props extends CheckboxOptionAtomProps {

}


const ImageOptionAtom = (props: Props) => {

    const { variant, checkbox } = props;

    const { show: { subMode } } = useTaskBuilderContext();

    const isChecked = checkbox.getIsChecked(variant);

    const { location } = variant.attachments?.main || {};

    const [ comment, fill ] = subMode === 'result'
        ? checkbox.getCheckboxCommentAndFill(variant)
        : [ null, null ];

    return (
        <div onClick={() => checkbox.handleChange(variant, !isChecked)}
             style={{ borderColor: fill ? `${fill} !important` : undefined }}
             className={[
                 'relative vk-rounded overflow-hidden border-[3px]',
                 subMode !== 'result' ? 'cursor-pointer' : '',
                 (isChecked || fill) ? 'p-2 border-solid border-[var(--accent)]' : '',
             ].join(' ')}>
            <div className="vk-rounded">
                <div className={[
                    'absolute',
                    (isChecked || fill) ? 'top-[18px] left-[18px]' : 'top-[10px] left-[10px]',
                ].join(' ')}>
                    <div style={{ color: fill ? `${fill} !important` : undefined }} className={[
                        'text-accent bg-white',
                        checkbox.type === 'checkbox' ? 'rounded-[6px]' : 'rounded-full',
                    ].join(' ')}>
                        {isChecked ? checkbox.onIcon : checkbox.offIcon}
                    </div>
                </div>

                <img alt="img" width="100%" src={location} className={[
                    'block object-cover vk-rounded aspect-[1/1]',
                    (isChecked || fill) && (!!variant.text || !!comment)
                        ? 'mb-[calc(0.5rem_+_3px)]'
                        : '',
                ].join(' ')}/>

                <If is={!!variant.text || !!comment}>
                    <Caption className="text-center text-subhead pt-2">
                        {[ comment, variant.text ].filter((e) => e).join(' • ')}
                    </Caption>
                </If>
            </div>
        </div>
    );
};


export { ImageOptionAtom };
