/**
 * TaskResultWidget
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement, useState } from 'react';

import { If } from '@/cutils';
import { Page } from '@/pages/Core';

import { TaskViewMode } from '@/types/task';
import { TaskResultFragmentFragment } from '@/codegen/graphql';

import { SimpleCell } from '@exode.ru/vkui';
import { IconButton } from '@mui/material';
import { Icon20ArrowUturnLeftOutline } from '@vkontakte/icons';

import { Accordion } from '@/components/Atoms/Accordion';
import { TaskBuilder } from '@/components/Task/Builder';
import { TagInlineText } from '@/components/Atoms/TagInlineText';


interface Props {
    icon: ReactElement;
    title: string | undefined;
    subtitle: string;
    answers: TaskResultFragmentFragment[];
    handleReply: () => void;
    points?: number;
    maxPoints?: number;
}


const TaskResultWidget = (props: Props) => {

    const {
        icon,
        title,
        subtitle,
        answers,
        handleReply,
        points = 0,
        maxPoints = 0,
    } = props;

    const [ open, setOpen ] = useState(false);

    return (
        <Page.Row separator="hide" className="w-full" innerNoVerticalPadding={0}>
            <div className="d-thin-border-bottom bg-hover">
                <SimpleCell before={icon}
                            hasHover={false}
                            hasActive={false}
                            className="cursor-pointer"
                            onClick={(e) => {
                                e.stopPropagation();

                                setOpen((prev) => !prev);
                            }}
                            after={(
                                <IconButton onClick={(e) => {
                                    e.stopPropagation();

                                    handleReply();
                                }}>
                                    <Icon20ArrowUturnLeftOutline fill="var(--icon_secondary)"/>
                                </IconButton>
                            )}
                            subtitle={(
                                <>
                                    <TagInlineText size="m"
                                                   content={`${points} / ${maxPoints}`}
                                                   gradient={points === maxPoints ? 'green' : 'blue'}/>

                                    <If is={!!subtitle}>
                                        <>
                                            {' • '}
                                            {subtitle}
                                        </>
                                    </If>
                                </>
                            )}>
                    {title}
                </SimpleCell>
            </div>

            <div className={[
                open ? '' : 'hidden',
                'flex flex-col justify-center',
            ].join(' ')}>
                {answers.map(({ task, point, maxPoint, answer }, index) => (
                    <Accordion stopPropagation
                               key={task.uuid}
                               borderRadius="0px"
                               horizontalPadding="0"
                               tappableClassName="!rounded-none"
                               header={(
                                   <div className="flex w-full items-center justify-around gap-2 mr-3">
                                       <span className="font-regular fs-16 flex flex-1 line-clamp-1">
                                           <span className="font-medium">
                                               {index + 1}
                                           </span>

                                           {' • '}

                                           {task.title}
                                       </span>

                                       <TagInlineText size="m"
                                                      content={`${point} / ${maxPoint}`}
                                                      gradient={point === maxPoint ? 'green' : 'red'}/>
                                   </div>
                               )}>
                        <div className="px-4">
                            <TaskBuilder mode={TaskViewMode.Show} task={task} show={{
                                subMode: 'result',
                                answer: { userAnswer: answer },
                            }}/>
                        </div>
                    </Accordion>
                ))}
            </div>
        </Page.Row>
    );
};


export { TaskResultWidget };
