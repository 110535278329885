/**
 * BlockTitle
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactNode } from 'react';

import { ConfigStore, observer } from '@/store/core/config';

import { Title } from '@exode.ru/vkui';


interface Props {
    title: ReactNode;
    className?: string;
}


const BlockTitle = observer((props: Props) => {

    const { title, className } = props;

    return (
        <Title weight="3"
               data-component="BlockTitle"
               style={{ lineHeight: '130%' }}
               level={ConfigStore.isDesktop ? '2' : '3'}
               className={[ 'p-0', className ].join(' ')}>
            {title}
        </Title>
    );
});


export { BlockTitle };
