/**
 * CourseRoutes
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PageSpace } from '@/shared/types';

import { RouterItemsType } from '@/types/router';
import { lazyWithRetry } from '@/helpers/react';

import { EducationTabHeaderPart } from '@/components/Panel/Header';


const CourseEnrolledListPage = lazyWithRetry(() => import('@/pages/Course/EnrolledList'));
const CourseStudyPage = lazyWithRetry(() => import('@/pages/Course/Study'));
const CourseInformationPage = lazyWithRetry(() => import('@/pages/Course/Information'));
const EducationTab = lazyWithRetry(() => import('@/tabs/Education'));


const CourseRoutes: RouterItemsType = {
    '/education/:page([0-9]+)': {
        view: <EducationTab/>,
        header: <EducationTabHeaderPart/>,
        type: 'tab',
        tab: 'education',
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/courses/enrolled': {
        view: <CourseEnrolledListPage/>,
        type: 'tab',
        tab: 'education',
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/course/:courseId([0-9_A-Za-z]+)': {
        view: <CourseInformationPage/>,
        type: 'tab',
        tab: 'catalog',
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/courses/:page([0-9]+)/:courseId([0-9]+)': {
        view: <CourseInformationPage/>,
        type: 'tab',
        tab: 'catalog',
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/courses/:page([0-9]+)/:courseId([0-9]+)/study/empty': {
        view: <CourseInformationPage/>,
        type: 'tab',
        tab: 'catalog',
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/courses/:page([0-9]+)/:courseId([0-9]+)/study/:lessonId([0-9]+)': {
        view: <CourseStudyPage/>,
        type: 'fullscreen',
        tab: 'education',
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
};


export { CourseRoutes };
