/**
 * ChatNotOpenedView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { Icon56MessagesOutline } from '@vkontakte/icons';

import { Placeholder } from '@/components/Atoms/Placeholder';


const ChatNotOpenedView = () => {

    const { t } = useI18n('pages.Chat.Dialog');

    return (
        <div className="flex flex-col w-full relative">
            <Placeholder iconSize={96}
                         className="m-auto"
                         header={t('startChatting')}
                         content={t('selectChatOrCreateOne')}
                         icon={<Icon56MessagesOutline fill="var(--accent)"/>}/>
        </div>
    );
};


export { ChatNotOpenedView };
