/**
 * ManageSellerManagersPage store
 *
 * @author: exode <hello@exode.ru>
 */

import { makeObservable } from 'mobx';
import { observer, PageStore } from '@/pages/Core';

import { defaultFilter, dtoFilterTransformer } from './filter';


class ManageSellerManagersPage extends PageStore {

    constructor() {
        super();
        makeObservable(this);
    }

    state = {
        list: {
            managers: {
                skip: 0,
                take: 15,
            },
            productManagers: {
                skip: 0,
                take: 15,
            },
        },
        filter: {
            managers: dtoFilterTransformer(defaultFilter),
            productManagers: {},
        },
        sort: {},
    };

}

const ManageSellerManagersPageStore = new ManageSellerManagersPage();


export { observer, ManageSellerManagersPageStore };
