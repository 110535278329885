/**
 * Message styled item
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const MessageContainer = styled.div`
    position: relative;

    .message-text {
        a {
            display: contents;
        }
    }
`;

export const ActionButtonsContainer = styled.div`
    display: flex;
    margin-bottom: 2px;
    justify-content: center;
    gap: 0.75rem;

    .vkuiButton {
        min-width: auto;
        min-height: 20px;

        &__content {
            padding: 0 2px 0 6px !important;
            font-size: 10px;
        }

        &__after {
            padding-right: 4px !important;
            margin: 0;
        }
    }

    #delete {
        .vkuiButton__content {
            color: var(--dynamic_red)
        }
    }

    #repeat {
        .vkuiButton__content {
            color: var(--accent)
        }
    }
`;
