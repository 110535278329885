/**
 * ActionsPart
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { If } from '@/cutils';
import { useI18n, useSelectAction } from '@/hooks/core';

import { IconButton } from '@mui/material';
import { Separator, SimpleCell, Spinner } from '@exode.ru/vkui';
import { Dropdown, TextTooltip } from '@exode.ru/vkui/dist/unstable';
import { Icon24ChevronDown, Icon24ChevronUp, Icon24DeleteOutline, Icon24MoreVertical } from '@vkontakte/icons';

import { ConfirmButton } from '@/components/Atoms/ConfirmButton';
import { useTaskBuilderContext } from '@/components/Task/Builder';


interface Props {
    className?: string;
}


const ActionsPart = (props: Props) => {

    const { className } = props;

    const {
        edit: {
            loading,
            onDelete,
            list: {
                onOrderChange,
                orderChangeModes,
            } = {},
        },
    } = useTaskBuilderContext();

    const { t } = useI18n('components.Task.Builder.parts.common');

    const {
        showTooltip,
        showDropdown,
        setShowDropdown,
    } = useSelectAction();

    const handleOrderChange = (
        e: React.MouseEvent<HTMLElement, MouseEvent>,
        direction: 'up' | 'down',
    ) => {
        e.stopPropagation();

        onOrderChange?.(direction);
    };

    return (
        <Dropdown action="click"
                  shown={showDropdown}
                  className="overflow-hidden"
                  onShownChange={setShowDropdown}
                  content={(
                      <>
                          <If is={_.isFunction(onOrderChange)}>
                              <If is={!!orderChangeModes?.includes('up')}>
                                  <SimpleCell className="!rounded-none"
                                              data-test="lesson.move-up"
                                              before={<Icon24ChevronUp/>}
                                              onClick={(e) => handleOrderChange(e, 'up')}>
                                      {t('moveUp')}
                                  </SimpleCell>
                              </If>

                              <If is={!!orderChangeModes?.includes('down')}>
                                  <SimpleCell className="!rounded-none"
                                              data-test="lesson.move-down"
                                              before={<Icon24ChevronDown/>}
                                              onClick={(e) => handleOrderChange(e, 'down')}>
                                      {t('moveDown')}
                                  </SimpleCell>
                              </If>

                              <If is={!!onDelete && !_.isEmpty(orderChangeModes)}>
                                  <Separator wide/>
                              </If>
                          </If>

                          <If is={!!onDelete}>
                              <ConfirmButton dataTest="practice.delete-task"
                                             onClick={(e) => e.stopPropagation()}
                                             confirmProps={{ children: t('confirmDeleteTask') }}
                                             callback={() => {
                                                 onDelete?.();
                                                 setShowDropdown(false);
                                             }}>
                                  <SimpleCell className="!rounded-none text-destructive"
                                              before={<Icon24DeleteOutline fill="var(--destructive)"/>}>
                                      {t('deleteTask?')}
                                  </SimpleCell>
                              </ConfirmButton>
                          </If>
                      </>
                  )}>

            <div onClick={(e) => e.stopPropagation()} data-test="common-actions.icon-button" className={[
                className,
                showDropdown ? 'bg-hover rounded-full thin-border !flex' : '',
            ].join(' ')}>
                <TextTooltip shown={showTooltip} placement="left" text={t('selectAction')}>
                    <div>
                        <IconButton>
                            <If is={!loading?.delete}>
                                <Icon24MoreVertical width={24} fill="var(--accent)"/>
                            </If>

                            <If is={!!loading?.delete}>
                                <Spinner/>
                            </If>
                        </IconButton>
                    </div>
                </TextTooltip>
            </div>
        </Dropdown>
    );
};


export { ActionsPart };
