/**
 * VideoTagPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { CourseService } from '@/services/Course/Course';

import { TagInlineText } from '@/components/Atoms/TagInlineText';


interface Props {
    duration: number;
    className?: string;
}


const VideoTagPart = (props: Props) => {

    const { duration, className } = props;

    return (
        <TagInlineText gradient="gray"
                       content={CourseService.formatDuration(duration)}
                       className={[ 'min-w-[29px]', className ].join(' ')}/>
    );
};


export { VideoTagPart };
