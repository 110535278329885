/**
 * Interfaces
 *
 * @author: exode <hello@exode.ru>
 */

export enum UserEditTab {
    Study = 'Study',
    Profile = 'Profile',
    Sessions = 'Sessions',
    CreateAccess = 'CreateAccess',
    StudyProgress = 'StudyProgress',
}
