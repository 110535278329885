/**
 * EmptyContentBlocksPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';

import { Icon24AudiobookOutline } from '@vkontakte/icons';

import { Placeholder } from '@/components/Atoms/Placeholder';


const EmptyContentBlocksPart = () => {

    const { t } = useI18n('components.Content.ContentLayoutBlockManage.parts');

    return (
        <Page.Row>
            <Placeholder iconSize={96}
                         header={t('startFillContent')}
                         className="my-auto m:pt-0 h-[calc(100%_-_52px)]"
                         icon={<Icon24AudiobookOutline className="text-accent opacity-[0.85]"/>}
                         content={(
                             <span className="m:w-full w-9/10 flex mx-auto">
                                 {t('timeToFillOutContent')}
                             </span>
                         )}/>
        </Page.Row>
    );
};


export { EmptyContentBlocksPart };
