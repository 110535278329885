/**
 * TopBarSkeleton
 *
 * @author: exode <hello@exode.ru>
 */

import { Skeleton } from '@mui/material';


const TopBarSkeleton = () => {
    return (
        <div className="flex flex-1 flex-col items-center justify-center gap-1">
            <Skeleton variant="rectangular" className="rounded-sm mt-1" width="120px" height="18px"/>
            <Skeleton variant="rectangular" className="rounded-sm" width="200px" height="8px"/>
        </div>
    );
};


export { TopBarSkeleton };
