/**
 * VariantAttachmentAtom
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { MultipleWithAttachmentTaskQuestion, StorageFileType } from '@/shared/types';

import { MultipleCheckboxConfig } from '../types';

import { CheckboxOptionAtomProps } from './CheckboxOptionAtom';

import { AudioOptionAtom } from './AudioOptionAtom';
import { FileOptionAtom } from './FileOptionAtom';
import { ImageOptionAtom } from './ImageOptionAtom';


interface Props extends CheckboxOptionAtomProps {
    checkbox: MultipleCheckboxConfig;
    variant: MultipleWithAttachmentTaskQuestion['variants'][number];
}


const VariantAttachmentAtom = (props: Props) => {

    const { variant, checkbox } = props;

    const { type } = variant.attachments?.main || {};

    switch (type) {
        case StorageFileType.Audio:
            return <AudioOptionAtom variant={variant} checkbox={checkbox}/>;

        case StorageFileType.File:
            return <FileOptionAtom variant={variant} checkbox={checkbox}/>;

        case StorageFileType.Image:
            return <ImageOptionAtom variant={variant} checkbox={checkbox}/>;

        default:
            return (<></>);
    }
};


export { VariantAttachmentAtom };
