/**
 * Manage context menu
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { ReactElement } from 'react';

import { Graphql, Platform } from '@/cutils';
import { ProductCurrency } from '@/shared/types';
import { useI18n, useI18nStatic } from '@/hooks/core/useI18n';

import { useLocation } from '@/router/index';
import { RoutePathType } from '@/router/paths';
import { courseTypeSubtitle } from '@/types/course';
import { productLaunchByDateStatus } from '@/types/product';
import { CourseType, ProductLaunchFindOneQuery } from '@/codegen/graphql';

import { PageAction, Router } from '@/services/Utils/Router';

import { Counter, SimpleCellProps } from '@exode.ru/vkui';
import { TextTooltip } from '@exode.ru/vkui/dist/unstable';

import {
    Icon24BookSpreadOutline,
    Icon24Filter,
    Icon24HelpOutline,
    Icon24KeyOutline,
    Icon24SendOutline,
    Icon24Square4Outline,
    Icon24StorefrontOutline,
    Icon24Users3Outline,
    Icon24UsersOutline,
    Icon24UserTagOutline,
    Icon24VideocamOutline,
    Icon24VideoCircleOutline,
    Icon28ArticleOutline,
    Icon28BankOutline,
    Icon28DiamondOutline,
    Icon28DiscountOutline,
    Icon28DoorArrowLeftOutline,
    Icon28EducationOutline,
    Icon28GameOutline,
    Icon28ListArrowLeftOutline,
    Icon28ListCheckOutline,
    Icon28MoneyRequestOutline,
    Icon28PaymentCardOutline,
    Icon28ShoppingCartOutline,
    Icon28SlidersOutline,
    Icon28StoryAddOutline,
    Icon28UsersOutline,
    Icon28UserSquareOutline,
    Icon28UserStarBadgeOutline,
    Icon28UserTagOutline,
    Icon28WrenchOutline,
} from '@vkontakte/icons';

import { LaunchBadgeStatusIcon } from '@/components/Product/LaunchBadgeStatusIcon';


export interface ContextMenuTab extends SimpleCellProps {
    /** Path to page */
    path: PageAction;
    /** Before element */
    before?: ReactElement;
    /** Substring parts of parent(!) pages */
    parentPaths?: (RoutePathType | string)[];
    /** Extra params for an item link (e.g., children subpages) */
    exactParams?: {
        key: string;
        value: string
    };
    /** Array of path matcher (if a path not enough) */
    extraPaths?: RoutePathType[];
    /** Subfields of item */
    subfields?: {
        /** Name of item */
        text: string;
        /** Path to page */
        path: PageAction;
        /** Array of path matcher (if a path not enough) */
        extraPaths?: RoutePathType[];
    }[];
}

export type ManageContextMenu = {
    [key in 'products'
        | 'launches'
        | 'groups'
        | 'course'
        | 'users'
        | 'seller'
        | 'admin'
        | 'school'
        | 'promotions'
        | 'sales']: {
        items: ContextMenuTab[]
    }
}

/**
 * Manage context menu
 * @param {PageAction['params']} params
 * @param {Record<any, any>} rest
 * @returns {ManageContextMenu}
 * @Note: Обернуть компоненту использования в observer
 */
export const manageContextMenu = (
    params: PageAction['params'],
    rest?: Record<any, any>,
): ManageContextMenu => {
    params = _.omit(params, [
        'page',
        'search',
    ]);

    const { route: { pageId } } = useLocation();

    const courseTypeSubtitles = courseTypeSubtitle();
    const productLaunchByDateStatuses = productLaunchByDateStatus();

    const { t } = useI18n('types.manage.context-menu');
    const { t: tStatic } = useI18nStatic('static.dictionary.declension.course');

    const menuItems: ManageContextMenu = {
        products: {
            items: [
                {
                    title: tStatic('single.nominated.VideoCourse'),
                    subtitle: courseTypeSubtitles[CourseType.VideoCourse],
                    before: <Icon24VideoCircleOutline/>,
                    path: { id: '/manage/courses/:page([0-9]+)', params: { ...params, type: CourseType.VideoCourse } },
                    exactParams: { key: 'type', value: CourseType.VideoCourse },
                    after: (
                        <Counter mode="contrast" className="thin-border bg-button-secondary">
                            {rest?.counter?.[CourseType.VideoCourse]}
                        </Counter>
                    ),
                },
                {
                    title: tStatic('single.nominated.TextCourse'),
                    subtitle: courseTypeSubtitles[CourseType.TextCourse],
                    before: <Icon24BookSpreadOutline/>,
                    exactParams: { key: 'type', value: CourseType.TextCourse },
                    path: { id: '/manage/courses/:page([0-9]+)', params: { ...params, type: CourseType.TextCourse } },
                    after: (
                        <Counter mode="contrast" className="thin-border bg-button-secondary">
                            {rest?.counter?.[CourseType.TextCourse]}
                        </Counter>
                    ),
                },
                {
                    disabled: true,
                    className: 'opacity-50',
                    title: tStatic('single.nominated.MasterClass'),
                    subtitle: t('inDevelopmentLaunchingSoon') || courseTypeSubtitles[CourseType.MasterClass],
                    before: <Icon24UserTagOutline/>,
                    exactParams: { key: 'type', value: CourseType.MasterClass },
                    path: { id: '/manage/courses/:page([0-9]+)', params: { ...params, type: CourseType.MasterClass } },
                    after: (
                        <Counter mode="contrast" className="thin-border bg-button-secondary">
                            {rest?.counter?.[CourseType.MasterClass]}
                        </Counter>
                    ),
                },
                {
                    disabled: true,
                    className: 'opacity-50',
                    title: tStatic('single.nominated.Webinar'),
                    subtitle: t('inDevelopmentLaunchingSoon') || courseTypeSubtitles[CourseType.Webinar],
                    before: <Icon24VideocamOutline/>,
                    exactParams: { key: 'type', value: CourseType.Webinar },
                    path: { id: '/manage/courses/:page([0-9]+)', params: { ...params, type: CourseType.Webinar } },
                    after: (
                        <Counter mode="contrast" className="thin-border bg-button-secondary">
                            {rest?.counter?.[CourseType.Webinar]}
                        </Counter>
                    ),
                },
                {
                    disabled: true,
                    className: 'opacity-50',
                    title: tStatic('single.nominated.PersonalLesson'),
                    subtitle: t('inDevelopmentLaunchingSoon') || courseTypeSubtitles[CourseType.PersonalLesson],
                    before: <Icon24UsersOutline/>,
                    exactParams: { key: 'type', value: CourseType.PersonalLesson },
                    path: {
                        id: '/manage/courses/:page([0-9]+)',
                        params: { ...params, type: CourseType.PersonalLesson },
                    },
                    after: (
                        <Counter mode="contrast" className="thin-border bg-button-secondary">
                            {rest?.counter?.[CourseType.PersonalLesson]}
                        </Counter>
                    ),
                },
            ],
        },
        course: {
            items: [
                {
                    title: t('content'),
                    subtitle: t('content&Practice'),
                    before: <Icon24Square4Outline/>,
                    path: { id: '/manage/course/:courseId([0-9]+)/content/:page([0-9]+)', params },
                },
                {
                    title: t('settings'),
                    subtitle: t('information&Customization'),
                    before: <Icon24Filter/>,
                    path: { id: '/manage/course/:courseId([0-9]+)/information/details', params },
                    parentPaths: [ '/manage/course/:courseId([0-9]+)/information' ],
                    subfields: [
                        {
                            text: t('details'),
                            path: {
                                id: '/manage/course/:courseId([0-9]+)/information/details',
                                params,
                            },
                        },
                        {
                            text: t('branding'),
                            path: {
                                id: '/manage/course/:courseId([0-9]+)/information/branding',
                                params,
                            },
                        },
                        {
                            text: t('authors'),
                            path: {
                                id: '/manage/course/:courseId([0-9]+)/information/author',
                                params,
                            },
                        },
                        {
                            text: t('curators'),
                            path: {
                                id: '/manage/course/:courseId([0-9]+)/information/curator',
                                params,
                            },
                        },
                        {
                            text: t('maintainingOrder'),
                            path: {
                                id: '/manage/course/:courseId([0-9]+)/information/order',
                                params,
                            },
                        },
                        {
                            text: t('seoOptimization'),
                            path: {
                                id: '/manage/course/:courseId([0-9]+)/information/seo',
                                params,
                            },
                        },
                        {
                            text: t('action'),
                            path: {
                                id: '/manage/course/:courseId([0-9]+)/information/actions',
                                params,
                            },
                        },
                    ],
                },
                {
                    title: t('faq'),
                    subtitle: t('answersToDoubts'),
                    path: { id: '/manage/course/:courseId([0-9]+)/faq', params },
                    extraPaths: [ '/manage/course/:courseId([0-9]+)/faq/:faqId([0-9]+)' ],
                    before: <Icon24HelpOutline/>,
                },
                {
                    title: t('launches'),
                    subtitle: t('datesTariffs&Publication'),
                    before: <Icon24SendOutline/>,
                    path: { id: '/manage/course/:courseId([0-9]+)/launches/:page([0-9]+)', params },
                },
                {
                    title: t('promoCodes'),
                    subtitle: t('couponManagement'),
                    before: <Icon28DiscountOutline width={24} height={24}/>,
                    path: { id: '/manage/course/:courseId([0-9]+)/discounts/:page([0-9]+)', params },
                    hidden: rest?.course?.product?.currency === ProductCurrency.Free,
                },
                {
                    title: t('groups'),
                    subtitle: t('separateUsers'),
                    path: { id: '/manage/course/:courseId([0-9]+)/groups/:page([0-9]+)', params },
                    before: (
                        <Icon24Users3Outline fill={(
                            rest?.course?.product?.launches?.length === 0
                                ? 'var(--icon_secondary)'
                                : undefined
                        )}/>
                    ),
                },
                {
                    title: t('students'),
                    subtitle: t('statistics&Progress'),
                    path: { id: '/manage/students/:page([0-9]+)/:courseId([0-9]+)', params },
                    before: (
                        <Icon24UsersOutline fill={(
                            rest?.course?.product?.launches?.length === 0
                                ? 'var(--icon_secondary)'
                                : undefined
                        )}/>
                    ),
                },
                {
                    title: t('checkingAssignments'),
                    subtitle: t('practiceFromStudents'),
                    path: {
                        id: '/manage/homeworks/:page([0-9]+)',
                        params: { folder: 'Practice', courseIds: `[${rest?.course?.id}]` },
                    },
                    before: (
                        <Icon28ListCheckOutline width={24} height={24} fill={(
                            rest?.course?.product?.launches?.length === 0
                                ? 'var(--icon_secondary)'
                                : undefined
                        )}/>
                    ),
                },
            ],
        },
        launches: {
            items: [
                {
                    title: t('dates&Tariffs'),
                    subtitle: t('sales&PricesSettings'),
                    before: <Icon24Filter/>,
                    path: {
                        id: '/manage/course/:courseId([0-9]+)/launches/:page([0-9]+)/:launchId([0-9]+)/settings',
                        params,
                    },
                    after: (
                        <Platform.School>
                            <ProductLaunchFindOneQuery children={(result) => (
                                <Graphql.Success result={result}>
                                    {({ productLaunchFindOne: { saleStatus } }) => (
                                        <TextTooltip placement="left" text={productLaunchByDateStatuses[saleStatus]}>
                                            <div>
                                                <LaunchBadgeStatusIcon size={18} status={saleStatus}/>
                                            </div>
                                        </TextTooltip>
                                    )}
                                </Graphql.Success>
                            )} skip={!params?.launchId} variables={{ launchId: +params.launchId! }}/>
                        </Platform.School>
                    ),
                },
                {
                    title: t('students'),
                    subtitle: t('statisticsProgress'),
                    before: <Icon24Users3Outline/>,
                    path: {
                        id: '/manage/students/:page([0-9]+)/:courseId([0-9]+)/:launchId([0-9]+)',
                        params,
                    },
                },
                {
                    title: t('publication'),
                    subtitle: t('availabilityInCatalog'),
                    path: {
                        id: '/manage/course/:courseId([0-9]+)/launches/:page([0-9]+)/:launchId([0-9]+)/publication',
                        params,
                    },
                    before: <Icon24StorefrontOutline/>,
                    after: (
                        <ProductLaunchFindOneQuery children={(result) => (
                            <Graphql.Success result={result}>
                                {({ productLaunchFindOne: { saleStatus } }) => (
                                    <TextTooltip placement="left" text={productLaunchByDateStatuses[saleStatus]}>
                                        <div>
                                            <LaunchBadgeStatusIcon size={18} status={saleStatus}/>
                                        </div>
                                    </TextTooltip>
                                )}
                            </Graphql.Success>
                        )} skip={!params?.launchId} variables={{ launchId: +params.launchId! }}/>
                    ),
                },
            ],
        },
        groups: {
            items: [
                {
                    title: t('students'),
                    subtitle: t('listOfStudents'),
                    before: <Icon24Users3Outline/>,
                    path: {
                        id: '/manage/course/:courseId([0-9]+)/groups/:page([0-9]+)/:groupId([0-9]+)/students',
                        params,
                    },
                },
                {
                    title: t('settings'),
                    subtitle: t('advancedSettings'),
                    before: <Icon24Filter/>,
                    path: {
                        id: '/manage/course/:courseId([0-9]+)/groups/:page([0-9]+)/:groupId([0-9]+)/settings',
                        params,
                    },
                },
            ],
        },
        users: {
            items: [
                {
                    title: t('userRights'),
                    subtitle: t('accesses&Verification'),
                    before: <Icon24KeyOutline width={24} height={24}/>,
                    path: { id: '/admin/users/:page([0-9]+)/:userId([0-9]+)/permissions', params },
                },
                {
                    title: t('allUsers'),
                    subtitle: t('addUserAndSettings'),
                    before: <Icon28UserSquareOutline/>,
                    path: { id: '/manage/school/users/:page([0-9]+)', params },
                },
                {
                    title: t('allStudents'),
                    subtitle: t('fromAllProducts'),
                    before: <Icon28EducationOutline/>,
                    path: { id: '/manage/students/:page([0-9]+)', params },
                },
            ],
        },
        admin: {
            items: [
                {
                    title: t('users'),
                    subtitle: t('setting&AccessRights'),
                    before: <Icon28UsersOutline/>,
                    path: { id: '/admin/users/:page([0-9]+)' },
                },
                {
                    title: t('partnershipRequest'),
                    subtitle: t('requestsListToPartners'),
                    before: <Icon28UserStarBadgeOutline/>,
                    path: { id: '/admin/partner-requests/:page([0-9]+)' },
                    extraPaths: [
                        '/admin/partner-requests/:page([0-9]+)/:requestId([0-9]+)',
                    ],
                },
                {
                    title: t('articles'),
                    before: <Icon28ArticleOutline/>,
                    subtitle: t('list&ArticlesCreation'),
                    path: { id: '/admin/articles/:page([0-9]+)' },
                    extraPaths: [
                        '/admin/article/:articleId([0-9]+)',
                    ],
                },
            ],
        },
        seller: {
            items: [
                {
                    title: t('sellerAccount'),
                    subtitle: t('information&Settings'),
                    before: <Icon28UserTagOutline/>,
                    path: { id: '/manage/seller' },
                },
                {
                    title: t('myEmployees'),
                    subtitle: t('settings&AccessRights'),
                    before: <Icon28UsersOutline/>,
                    path: { id: '/manage/seller/managers/:page([0-9]+)' },
                    parentPaths: [
                        '/manage/seller/managers',
                        '/manage/seller/product-managers',
                    ],
                    /** Release in next updates: next-release */
                    /** subfields: [
                     {
                     text: 'Сотрудники',
                     path: { id: '/manage/seller/managers/:page([0-9]+)' },
                     extraPaths: [
                     '/manage/seller/managers/add',
                     '/manage/seller/managers/:page([0-9]+)/:managerId([0-9]+)/permissions',
                     ],
                     },
                     {
                     text: 'Коллаборанты',
                     path: { id: '/manage/seller/product-managers/:page([0-9]+)' },
                     extraPaths: [
                     '/manage/seller/product-managers/add',
                     '/manage/seller/product-managers/:page([0-9]+)/:productManagerId([0-9]+)/permissions',
                     ],
                     },
                     ],*/
                },
            ],
        },
        school: {
            items: [
                {
                    title: t('schoolSettings'),
                    before: <Icon28SlidersOutline/>,
                    subtitle: t('information&Customization'),
                    path: { id: '/manage/school/settings/basic' },
                    parentPaths: [
                        '/manage/school/settings/basic',
                        '/manage/school/settings/preferences',
                        '/manage/school/settings/blocks',
                        '/manage/school/settings/left-menu',
                    ],
                    subfields: [
                        {
                            text: t('mainInformation'),
                            path: { id: '/manage/school/settings/basic' },
                        },
                        {
                            text: t('preferences&brand'),
                            path: { id: '/manage/school/settings/preferences' },
                        },
                        {
                            text: t('blocksSetting'),
                            path: { id: '/manage/school/settings/blocks' },
                        },
                        {
                            text: t('leftMenuSetting'),
                            path: { id: '/manage/school/settings/left-menu' },
                        },
                    ],
                },
                {
                    title: t('legalInformation'),
                    subtitle: t('details&Documents'),
                    before: <Icon28BankOutline/>,
                    path: { id: '/manage/school/legal', params },
                },
                {
                    title: t('acquiringSetting'),
                    subtitle: t('acceptingPayments'),
                    before: <Icon28PaymentCardOutline/>,
                    path: { id: '/manage/school/acquiring' },
                },
                /**{
                 title: t('analyticsTools'),
                 subtitle: t('connectingIntegrations'),
                 before: <Icon28GraphOutline/>,
                 path: { id: '/manage/school/analytics' },
                 },*/
                /**{
                 title: t('mailings&Notifications'),
                 subtitle: t('segmentAlerts'),
                 before: <Icon28Notifications/>,
                 path: { id: '/manage/school/notifications' },
                 },*/
                {
                    title: t('myEmployees'),
                    subtitle: t('settings&AccessRights'),
                    before: <Icon28UsersOutline/>,
                    path: { id: '/manage/school/managers/:page([0-9]+)', params },
                    extraPaths: [
                        '/manage/school/managers/add',
                        '/manage/school/managers/:page([0-9]+)/:managerId([0-9]+)/permissions',
                    ],
                },
                {
                    title: t('registrationSettings'),
                    subtitle: t('privacy&Authorization'),
                    before: <Icon28DoorArrowLeftOutline/>,
                    path: { id: '/manage/school/privacy', params },
                },
                {
                    title: t('forDevelopers'),
                    before: <Icon28WrenchOutline/>,
                    subtitle: t('integrations&Customization'),
                    path: { id: '/manage/school/webhooks', params },
                    parentPaths: [
                        '/manage/school/webhooks',
                        '/manage/school/settings/custom-code',
                    ],
                    subfields: [
                        {
                            text: t('webhooks'),
                            path: { id: '/manage/school/webhooks', params },
                        },
                        {
                            text: t('customCode'),
                            path: { id: '/manage/school/settings/custom-code', params },
                        },
                    ],
                },
                {
                    title: t('subscription&Tariff'),
                    subtitle: t('choosingTheRightTariff'),
                    before: <Icon28DiamondOutline/>,
                    path: {
                        id: '/manage/school/subscription',
                        params: { backTo: pageId },
                    },
                },
            ],
        },
        promotions: {
            items: [
                {
                    title: t('storyVideo'),
                    subtitle: t('short&UsefulVideos'),
                    before: <Icon28StoryAddOutline/>,
                    path: { id: '/manage/stories' },
                    parentPaths: [ '/manage/stories' ],
                },
                {
                    disabled: true,
                    className: 'opacity-50',
                    title: t('myTournaments'),
                    subtitle: t('inDevelopmentLaunchingSoon'),
                    before: <Icon28GameOutline/>,
                    path: { id: '/manage/tournament/create' },
                    parentPaths: [ '/tournament' ],
                    subfields: [
                        {
                            text: t('creatingTournament'),
                            path: { id: '/manage/tournament/create' },
                        },
                        {
                            text: t('tournamentHistory'),
                            path: {
                                id: '/tournaments/history',
                                params: { createdByMe: 'true' },
                            },
                            extraPaths: [
                                '/tournaments/history/ongoing/:page([0-9]+)',
                                '/tournaments/history/waiting/:page([0-9]+)',
                                '/tournaments/history/finished/:page([0-9]+)',
                            ],
                        },
                    ],
                },
            ],
        },
        sales: {
            items: [
                {
                    title: t('payoutOfFunds'),
                    subtitle: t('creating&History'),
                    before: <Icon28MoneyRequestOutline/>,
                    path: { id: '/manage/payouts/:page([0-9]+)' },
                },
                {
                    title: t('balanceOperations'),
                    subtitle: t('historyWithDetails'),
                    before: <Icon28ListArrowLeftOutline/>,
                    path: { id: '/manage/seller/balance/:page([0-9]+)' },
                },
                {
                    title: t('invoices'),
                    subtitle: t('sellingYourProducts'),
                    before: <Icon28ShoppingCartOutline/>,
                    path: { id: '/manage/invoices/:page([0-9]+)' },
                    extraPaths: [
                        '/manage/invoices/:page([0-9]+)/:invoiceUuid',
                    ],
                },
                {
                    title: t('payments'),
                    subtitle: t('invoicePaymentHistory'),
                    before: <Icon28PaymentCardOutline/>,
                    path: { id: '/manage/payments/:page([0-9]+)' },
                },
                /**{
                 title: 'Возвраты',
                 subtitle: 'Список возвратов',
                 before: <Icon28MoneyHistoryBackwardOutline/>,
                 path: { id: '/manage/refunds/:page([0-9]+)' },
                 },
                 {
                 title: 'Отчёт по доходу',
                 subtitle: 'За определенный период',
                 before: <Icon28StatisticsOutline/>,
                 path: { id: '/manage/reports/sales' },
                 },*/
            ],
        },
    };

    for (const [ __, section ] of _.entries(menuItems)) {
        section.items?.map((item: ContextMenuTab) => {
            item.subfields = item.subfields?.filter(({ path }) => Router.filterByPageId(path.id));

            if (item.subfields?.length) {
                item.path.id = item.subfields[0].path.id;
            }
        });

        section.items = section.items.filter(({ path }) => Router.filterByPageId(path.id));
    }

    return menuItems;
};
