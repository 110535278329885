/**
 * DraggableWordAtom
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ForwardedRef, forwardRef } from 'react';

import { useDraggable } from '@dnd-kit/core';

import { If } from '@/cutils';

import { Icon24CancelCircleFillRed } from '@vkontakte/icons';

import { useTaskBuilderContext } from '@/components/Task/Builder';

import { Skip } from '../types';

import { useDraggableStyles } from '../hooks/useDraggableStyles';


interface Props {
    id: string;
    text: string;
    isPlaced?: boolean;
    correctSkip?: Skip;
    isSelected?: boolean;
    onRemove?: (e: React.MouseEvent) => void;
    onClick?: () => void;
    isOverAnother?: boolean;
    answerIsCorrect?: boolean;
}


const DraggableWordAtom = forwardRef((props: Props, ref: ForwardedRef<HTMLDivElement>) => {

    const {
        id,
        text,
        onClick,
        onRemove,
        isPlaced,
        isSelected,
        correctSkip,
        isOverAnother,
        answerIsCorrect,
    } = props;

    const { show: { subMode } } = useTaskBuilderContext();

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        isDragging,
    } = useDraggable({
        id,
        data: { text },
        disabled: subMode === 'result',
    });

    const style = useDraggableStyles(transform, isDragging);

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        onClick?.();
    };

    const handleRemove = (e: React.MouseEvent) => {
        e.stopPropagation();

        onRemove?.(e);
    };

    if (isDragging) {
        return <div ref={setNodeRef} style={{ display: 'none' }}/>;
    }

    return (
        <div ref={ref} className="relative w-fit">
            <div ref={setNodeRef} style={style} onClick={handleClick} className={[
                'group flex items-center justify-center relative select-none px-4',
                'min-h-[36px] vk-rounded fs-content text-center cursor-move bg-content',
                isSelected ? 'bg-selected' : '',
                subMode === 'result'
                    ? (isPlaced
                        ? answerIsCorrect ? 'text-positive-shadow' : 'text-destructive-shadow'
                        : 'text-secondary-shadow')
                    : (!isPlaced || isOverAnother) ? 'accent-shadow' : 'text-secondary-shadow',
            ].join(' ')} {...listeners} {...attributes}>
                <span className={[
                    isPlaced && subMode === 'result' && !answerIsCorrect
                        ? 'line-through-diagonally font-normal opacity-75 pr-1.5'
                        : '',
                ].join(' ')}>
                    {text}
                </span>

                <If is={!!isPlaced && !!onRemove && subMode !== 'result'}>
                    <div onClick={handleRemove} className={[
                        'absolute items-center justify-center hidden',
                        'cursor-pointer opacity-0 transition-opacity z-10',
                        '-right-1 -top-1 group-hover:flex group-hover:opacity-100',
                    ].join(' ')}>
                        <Icon24CancelCircleFillRed width={20} height={20}/>
                    </div>
                </If>

                <If is={subMode === 'result' && !answerIsCorrect}>
                    <span>
                        {correctSkip?.text}
                    </span>
                </If>
            </div>
        </div>
    );
});


export { DraggableWordAtom };
