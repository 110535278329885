/**
 * TaskBuilder styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div`
    [data-test="content-element.content"] {
        .codex-editor__redactor {
            min-height: 30px !important;
        }
    }

    &[data-submode="preview"] {
        [data-component="BlockTitle"] {
            font-size: 19px;
        }

        [data-editor-output="true"] {
            h1, h2, h3, h4, h5, h6 {
                font-size: 19px;
            }
        }
    }
`;

export const ShowModePartContainer = styled.div`

`;
