/**
 * SchoolContextInfo
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, SaveStoreKeys, SavingStore } from '@/store/core/saving';

import { APP_DOMAIN } from '@/root/src/env';

import { Url } from '@/utils';
import { Page } from '@/pages/Core';
import { Graphql, If } from '@/cutils';
import { useLocation } from '@/router/index';
import { RoutePathType } from '@/router/paths';
import { useI18n, useOpenOrFocusTab } from '@/hooks/core';

import { SchoolStore } from '@/store/platform';
import { SchoolFindOneQuery } from '@/codegen/graphql';

import { Avatar, IconButton } from '@exode.ru/vkui';
import { TextTooltip } from '@exode.ru/vkui/unstable';
import { Icon28PictureOutline, Icon32ImageFormat } from '@vkontakte/icons';

import { ActiveBadgeStatus } from '@/components/Atoms/ActiveBadgeStatus';
import { AutoSaveFormStatus } from '@/components/Desktop/Manage/ContextAdminMenu';


const SchoolContextInfo = observer(() => {

    const { t } = useI18n('components.School.ContextInfo');

    const { route: { pageId, params: {} } } = useLocation();

    const [ openTab ] = useOpenOrFocusTab();

    const [ isEditing, isSaving ] = SavingStore.isEditingAndSaving([
        SaveStoreKeys.Seller,
        SaveStoreKeys.School,
    ]);

    const saveModePages: RoutePathType[] = [
        '/manage/school/settings/basic',
        '/manage/school/settings/preferences',
        '/manage/school/settings/blocks',
        '/manage/school/settings/left-menu',
        '/manage/school/settings/custom-code',
        '/manage/school/privacy',
        '/manage/school/disciplines',
        '/manage/school/legal',
        '/manage/school/managers/:page([0-9]+)/:managerId([0-9]+)/permissions',
        '/manage/school/product-managers/:page([0-9]+)/:productManagerId([0-9]+)/permissions',
    ];

    const previewModePages: RoutePathType[] = [
        '/manage/school/acquiring',
        '/manage/school/subscription',
        '/manage/school/analytics',
        '/manage/school/notifications',
        '/manage/school/legal',
        '/manage/school/managers/:page([0-9]+)',
        '/manage/school/managers/add',
        '/manage/school/product-managers/:page([0-9]+)',
        '/manage/school/product-managers/add',
        '/manage/school/users/:page([0-9]+)',
        '/manage/school/webhooks',

        '/manage/invoices/:page([0-9]+)',
        '/manage/invoices/:page([0-9]+)/:invoiceUuid',
        '/manage/payments/:page([0-9]+)',
        '/manage/refunds/:page([0-9]+)',
        '/manage/reports/sales',
        '/manage/stories',
        '/manage/stories/create',
        '/manage/stories/:storiesId([0-9]+)',
    ];

    const pages: RoutePathType[] = [
        ...saveModePages,
        ...previewModePages,
    ];

    return (
        <SchoolFindOneQuery children={(result) => (
            <>
                <Graphql.Loading result={result}>
                    <Page.Spinner mode="skeleton" wrapperClassName="mb-4 !p-0 h-[60px]"/>
                </Graphql.Loading>

                <Graphql.Success result={result}>
                    {({
                          schoolFindOne: school,
                          schoolFindOne: {
                              id,
                              name,
                              active,
                              iconUrl,
                          },
                      }) => (
                        <AutoSaveFormStatus pages={pages}
                                            isSaving={isSaving}
                                            isEditing={isEditing}
                                            title={<span title={name}>{name}</span>}
                                            mode={saveModePages.includes(pageId) ? 'save' : 'preview'}
                                            subtitle={{ preview: Url.getSchoolDomainUrl('text', APP_DOMAIN, school) }}
                                            after={(
                                                <TextTooltip offsetDistance={1}
                                                             text={t('preview')}
                                                             placement="bottom-end">
                                                    <div onClick={() => openTab({
                                                        id: `${id}`,
                                                        type: 'school',
                                                        url: Url.getSchoolDomainUrl('url', APP_DOMAIN, school),
                                                    })} className="right-button">
                                                        <IconButton hasActive={false}>
                                                            <Icon32ImageFormat width={28}
                                                                               height={28}
                                                                               fill="var(--accent)"/>
                                                        </IconButton>
                                                    </div>
                                                </TextTooltip>
                                            )}
                                            before={(
                                                <TextTooltip offsetSkidding={23} placement="bottom-start" text={[
                                                    active ? t('schoolIsActive') : t('schoolIsInactive'),
                                                ].join(' ')}>
                                                    <div className="mr-3">
                                                        <Avatar size={46}
                                                                mode="app"
                                                                shadow={!iconUrl}
                                                                src={iconUrl || ''}
                                                                className={[
                                                                    iconUrl ? 'bg-transparent' : '',
                                                                ].join(' ')}
                                                                badge={(
                                                                    <ActiveBadgeStatus isActive={!!active}/>
                                                                )}>
                                                            <If is={!iconUrl}>
                                                                <Icon28PictureOutline/>
                                                            </If>
                                                        </Avatar>
                                                    </div>
                                                </TextTooltip>
                                            )}/>
                    )}
                </Graphql.Success>

                <Graphql.Error result={result}/>
            </>
        )} skip={!SchoolStore.schoolId} variables={{ schoolId: SchoolStore.schoolId }}/>
    );
});


export { SchoolContextInfo };
