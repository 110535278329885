/**
 * EditorJsBlockContentElementShow
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer } from '@/store/core/config';

import { If } from '@/cutils';
import { useEditorJs } from '@/hooks/core';
import { EditorJsBlockContent } from '@/shared/types';

import { BlockTitle } from '@/components/Content/BlockTitle';
import { EditorJsOutput } from '@/components/Atoms/EditorJsOutput';
import { ContentElementShowProps } from '@/components/ContentElement/interfaces';


interface Props extends ContentElementShowProps<EditorJsBlockContent> {

}


const EditorJsBlockContentElementShow = observer((props: Props) => {

    const { contentElement: { uuid, title, content } } = props;

    const { isValidOutputData } = useEditorJs();

    return (
        <>
            <If is={!!title}>
                <BlockTitle title={title} className="mb-2 d:mt-4"/>
            </If>

            <If is={isValidOutputData(content)}>
                <EditorJsOutput id={uuid} data={content}/>
            </If>
        </>
    );
});


export { EditorJsBlockContentElementShow };
