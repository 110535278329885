/**
 * TextEditorAtom
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ForwardedRef, forwardRef } from 'react';

import { useI18n } from '@/hooks/core';

import { ContentEditable } from '@/components/Atoms/ContentEditable';


interface Props {
    onTextSelection: (range: Range, text: string, rect: DOMRect) => void;
    onClearSkips: () => void;
    onClearSelection: () => void;
}


const TextEditorAtom = forwardRef((props: Props, ref: ForwardedRef<HTMLDivElement>) => {

    const {
        onClearSkips,
        onTextSelection,
        onClearSelection,
    } = props;

    const { t } = useI18n('components.Task.Builder.edit.arrange');

    const handleMouseUp = (e: React.MouseEvent) => {
        const target = e.target as HTMLElement;

        if (target.classList.contains('skip-word')) {
            return;
        }

        const selection = window.getSelection();

        if (!selection || selection.rangeCount === 0) {
            return;
        }

        const range = selection.getRangeAt(0);
        const text = range.toString().trim();

        if (text && text.length > 0) {
            onTextSelection(
                range,
                text,
                range.getBoundingClientRect(),
            );
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if ((e.metaKey || e.ctrlKey) && e.key === 'a') {
            e.preventDefault();

            if (ref && 'current' in ref && ref.current) {
                const range = document.createRange();
                const selection = window.getSelection();

                range.selectNodeContents(ref.current);

                selection?.removeAllRanges();
                selection?.addRange(range);
            }
        } else if (e.key === 'Delete' || e.key === 'Backspace') {
            const selection = window.getSelection();

            if (ref && 'current' in ref
                && selection?.toString() === ref?.current?.textContent
            ) {
                e.preventDefault();

                if (ref.current) {
                    const range = document.createRange();

                    ref.current.innerHTML = '';
                    ref.current.classList.add('empty');

                    onClearSkips();
                    onClearSelection();

                    ref.current.focus();

                    range.setStart(ref.current, 0);
                    range.collapse(true);
                    selection?.removeAllRanges();
                    selection?.addRange(range);
                }
            }
        }
    };

    const handleInput = (e: React.FormEvent<HTMLDivElement>) => {
        const target = e.target as HTMLElement;
        const isEmpty = !target.textContent?.trim();

        if ((e.target as HTMLElement).classList.contains('skip-word')) {
            return;
        }

        target.classList[isEmpty ? 'add' : 'remove']('empty');

        if (isEmpty) {
            onClearSkips();
            onClearSelection();
        }
    };

    return (
        <ContentEditable ref={ref}
                         onInput={handleInput}
                         onKeyDown={handleKeyDown}
                         onMouseUp={handleMouseUp}
                         placeholder={t('inputTextAndSelectTargetWords')}
                         className="empty min-h-[100px] whitespace-pre-line fs-content"/>
    );
});


export { TextEditorAtom };
