/**
 * InviteToGroupView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { Form, Formik } from 'formik';

import { Notify } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { useLocation } from '@/router/index';
import { Router } from '@/services/Utils/Router';
import { useManageGroupMembers } from '@/hooks/apollo';

import { Button } from '@exode.ru/vkui';

import { StickyButton } from '@/components/Atoms/StickyButton';

import { UsersSelectView } from '@/pages/Manage/School/Users';


const InviteToGroupView = () => {

    const { t } = useI18n('pages.Manage.Courses.Groups.GroupsMain.views');

    const { route: { params } } = useLocation<{ groupId: string }>();

    const {
        createMembers,
        createMembersLoading,
    } = useManageGroupMembers();

    return (
        <Formik onSubmit={(values) => {
            createMembers(values.groupId, values.memberUserIds, () => {
                Router.replaceModal();

                Notify.toast.success(t('membersAdded'));
            });
        }} initialValues={{
            memberUserIds: [],
            groupId: +params.groupId,
        }}>
            {({ values, handleSubmit, setFieldValue }) => (
                <Form onSubmit={handleSubmit} className="flex flex-col justify-between h-full px-3">
                    <UsersSelectView onChange={(profiles) => {
                        setFieldValue('memberUserIds', _.map(profiles, 'user.id'));
                    }}/>

                    <StickyButton className="px-0 pb-3">
                        <Button size="l"
                                stretched
                                type="submit"
                                className="mt-3"
                                loading={createMembersLoading}
                                disabled={createMembersLoading || !values.memberUserIds?.length}>
                            {t('inviteToGroup')}
                        </Button>
                    </StickyButton>
                </Form>
            )}
        </Formik>
    );
};


export { InviteToGroupView };
