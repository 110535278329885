/**
 * ParagraphPart
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { If } from '@/cutils';
import { InnerHtml } from '@/components/Atoms/InnerHtml';


const ParagraphPart = ({ data, style }: any) => {

    const content = _.isString(data)
        ? data
        : _.isString(data?.text) ? data.text : '';

    return (
        <If is={!!content}>
            <p className="editor-js-paragraph fs-content" style={style}>
                <InnerHtml content={content} include={[ 'timeCodes' ]}/>
            </p>
        </If>
    );
};


export { ParagraphPart };
