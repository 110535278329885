/**
 * UseSkipWord
 *
 * @author: exode <hello@exode.ru>
 */

import { RefObject } from 'react';

import { Skip } from '../types';

import { handleContentEditablePaste } from '@/components/Atoms/ContentEditable/helper';


export const useSkipWord = (editorRef: RefObject<HTMLDivElement>) => {

    const moveCursorAfterSkipWord = (skipWord: HTMLElement) => {
        const range = document.createRange();

        range.setStartAfter(skipWord);
        range.collapse(true);

        const selection = window.getSelection();

        selection?.removeAllRanges();
        selection?.addRange(range);

        editorRef.current?.focus();
    };

    const createSkipWordElement = (
        text: string,
        uuid: string,
        listeners: {
            onChange: (uuid: string, text: string) => void;
            onClick: (element: HTMLElement) => void;
        },
    ) => {
        const { onChange, onClick } = listeners;

        const span = document.createElement('span');
        const wrapper = document.createElement('span');

        /** No-break space */
        const space = document.createTextNode('\u00A0');

        wrapper.className = 'skip-word-wrapper';

        span.textContent = text;
        span.contentEditable = 'true';
        span.dataset.skipText = text;
        span.dataset.skipUuid = uuid;
        span.className = 'px-2 py-1 vk-rounded skip-word bg-positive-20 word-break';

        wrapper.appendChild(span);
        wrapper.appendChild(space);

        span.addEventListener('paste', handleContentEditablePaste);

        span.addEventListener('keydown', (e) => {
            const target = e.target as HTMLElement;
            const selection = window.getSelection();

            if (e.key === 'ArrowRight' && selection) {
                const range = selection.getRangeAt(0);
                const isAtEnd = range.endOffset === target.textContent?.length;

                if (isAtEnd) {
                    e.preventDefault();
                    moveCursorAfterSkipWord(wrapper);
                }
            }
        });

        span.addEventListener('click', (e) => {
            e.stopPropagation();
            onClick(span);
        });

        span.addEventListener('blur', (e: FocusEvent) => {
            const target = e.target as HTMLElement;
            const selection = window.getSelection();

            if (!target.contains(selection?.anchorNode || null)) {
                const range = document.createRange();

                range.selectNodeContents(editorRef.current!);

                range.collapse(false);
                selection?.removeAllRanges();
                selection?.addRange(range);
            }
        });

        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'characterData' || mutation.type === 'childList') {
                    const newText = span.textContent || '';

                    if (newText !== span.dataset.skipText) {
                        span.dataset.skipText = newText;
                        onChange(uuid, newText);
                    }
                }
            });
        });

        observer.observe(span, {
            characterData: true,
            childList: true,
            subtree: true,
        });

        return wrapper;
    };

    const transformSkipPlaceholdersToDOM = (
        textWithSkips: string,
        skips: Skip[],
        listeners: Parameters<typeof createSkipWordElement>[2],
    ): HTMLDivElement => {
        const skipRegex = /(\|\_\[skip:[^\]]+\]\_\|)/g;
        const container = document.createElement('div');

        const parts = textWithSkips?.split(skipRegex) || [];

        for (const part of parts) {
            if (!part.match(skipRegex)) {
                container.appendChild(document.createTextNode(part));

                continue;
            }

            const uuid = part.trim();
            const skip = skips.find((s) => s.uuid === uuid);

            if (!skip) {
                container.appendChild(document.createTextNode(part));

                continue;
            }

            const skipElement = createSkipWordElement(
                skip.text,
                skip.uuid,
                listeners,
            );

            container.appendChild(skipElement);
        }

        return container;
    };

    return {
        createSkipWordElement,
        transformSkipPlaceholdersToDOM,
    };
};
