/**
 * SettingsDropdownParts
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useState } from 'react';

import { IS_BIZ_WELCOME, IS_MARKETPLACE } from '@/root/src/env';

import { RouterStore } from '@/store/core/router';
import { ProfileStore } from '@/store/user/profile';
import { PreferenceStore } from '@/store/preference/preference';
import { observer, UserAuthStore } from '@/store/user/auth';

import { PageSpace } from '@/shared/types';
import { useConfirm, useI18n } from '@/hooks/core';
import { Graphql, If, Link, Platform } from '@/cutils';

import { Dropdown, DropdownProps } from '@exode.ru/vkui/dist/unstable';
import { CellButton, Separator, SimpleCell } from '@exode.ru/vkui';

import {
    Icon20GearCircleFillGray,
    Icon24DoorArrowRightOutline,
    Icon24GearOutline,
    Icon24HieroglyphCharacterOutline,
    Icon24MoneyRequestOutline,
    Icon24SunOutline,
    Icon24UsersOutline,
    Icon28MoonOutline,
} from '@vkontakte/icons';

import { GetUserProfileQuery } from '@/codegen/graphql';

import { HeaderItem } from '@/components/Desktop/Panel';
import { UserAvatar } from '@/components/Atoms/UserAvatar';
import { PreferenceThemeToggle } from '@/components/Preference/Theme';

import { LanguageSetItem } from '@/pages/User/Settings/items/LanguageSetItem';


interface Props {
    placement?: 'header' | 'slim-menu';
    dropdownProps?: Partial<DropdownProps>;
}


const SettingsDropdownPart = observer((props: Props) => {

    const {
        placement = 'header',
        dropdownProps = {
            offsetSkidding: 48,
            offsetDistance: -60,
            placement: 'left-end',
        },
    } = props;

    const [ showDropdown, setShowDropdown ] = useState(false);
    const [ showChildDropdown, setShowChildDropdown ] = useState(false);

    const { t } = useI18n('components.Desktop.Panel.Header');

    const { user, avatar, fullName } = ProfileStore;

    const commonUserCellProps = {
        size: 34,
        showUserOnlineBadge: false,
        dataTest: 'header.rightAvatar',
        badge: (IS_BIZ_WELCOME || placement === 'slim-menu')
            ? undefined
            : <Icon20GearCircleFillGray width={16} height={16}/>,
    };

    const { openConfirm: openConfirmLogout } = useConfirm({
        title: t('logoutFromAccount'),
        subtitle: t('logoutConfirm'),
        onConfirm: () => UserAuthStore.logout(),
        buttons: {
            confirm: { text: t('logout') },
        },
    });

    return (
        <Dropdown action="click"
                  shown={showDropdown}
                  className="overflow-hidden"
                  onShownChange={(shown) => !showChildDropdown && setShowDropdown(shown)}
                  content={(
                      <div>
                          <Platform.If platforms={[
                              PageSpace.SchoolPlatform,
                              PageSpace.MarketplacePlatform,
                          ]}>
                              <Link pushPage={{ id: '/settings' }} onClick={() => setShowDropdown(false)}>
                                  <SimpleCell className="!rounded-none"
                                              before={<Icon24GearOutline/>}
                                              subtitle={t('accountSettings')}>
                                      {t('settings')}
                                  </SimpleCell>
                              </Link>
                          </Platform.If>

                          <If is={IS_MARKETPLACE || IS_BIZ_WELCOME || UserAuthStore?.isManager}>
                              <Link pushModal={{ id: 'user-invite' }} onClick={() => setShowDropdown(false)}>
                                  <SimpleCell className="!rounded-none" subtitle={(
                                      !IS_MARKETPLACE
                                          ? t('getPersonalLink')
                                          : t('bonusesForEveryFriend')
                                  )} before={(
                                      !IS_MARKETPLACE
                                          ? <Icon24MoneyRequestOutline/>
                                          : <Icon24UsersOutline/>
                                  )}>
                                      {!IS_MARKETPLACE ? t('partnership') : t('inviteFriends')}
                                  </SimpleCell>
                              </Link>
                          </If>

                          <LanguageSetItem languageDropdownProps={{
                              componentProps: {
                                  className: '!rounded-none',
                                  before: <Icon24HieroglyphCharacterOutline/>,
                                  subtitle: <span className="block min-w-[120px]">{t('changeLanguage')}</span>,
                              },
                              dropdownProps: {
                                  offsetSkidding: 0,
                                  offsetDistance: 0,
                                  placement: 'left-start',
                                  onShownChange: (shown) => setShowChildDropdown(shown),
                              },
                          }}/>

                          <If is={RouterStore.type === 'iframe'}>
                              <PreferenceThemeToggle>
                                  <SimpleCell className="!rounded-none" subtitle={t('toggleTheme')} before={(
                                      PreferenceStore.isDark
                                          ? <Icon24SunOutline className="text-accent"/>
                                          : <Icon28MoonOutline className="text-accent" width={24} height={24}/>
                                  )}>
                                      {t('theme')}
                                  </SimpleCell>
                              </PreferenceThemeToggle>
                          </If>

                          <Separator wide/>

                          <CellButton mode="danger"
                                      data-test="header.logout"
                                      className="!rounded-none"
                                      subtitle={t('endCurrentSession')}
                                      before={<Icon24DoorArrowRightOutline/>}
                                      onClick={() => {
                                          setShowDropdown(false);
                                          openConfirmLogout();
                                      }}>
                              {t('logout')}
                          </CellButton>
                      </div>
                  )} {...dropdownProps}>
            <div>
                <HeaderItem size={placement === 'slim-menu' ? '44px' : undefined}
                            className={placement !== 'slim-menu' ? 'ml-1' : ''}
                            background={placement === 'slim-menu' ? 'none' : undefined}>
                    <GetUserProfileQuery children={(result) => (
                        <>
                            <Graphql.Loading result={result}>
                                <UserAvatar name={fullName}
                                            userId={user?.id}
                                            src={avatar?.medium || ''} {...commonUserCellProps}/>
                            </Graphql.Loading>

                            <Graphql.Success result={result}>
                                {({ profileFindOne: profile }) => (
                                    <UserAvatar name={profile?.fullName || ''}
                                                userId={profile?.user?.id || 0}
                                                src={profile?.avatar?.medium || avatar?.medium || ''}
                                                {...commonUserCellProps}/>
                                )}
                            </Graphql.Success>

                            <Graphql.Error result={result}>
                                <UserAvatar name={fullName}
                                            userId={user?.id}
                                            src={avatar?.medium || ''} {...commonUserCellProps}/>

                            </Graphql.Error>
                        </>
                    )} variables={{ userId: ProfileStore?.user?.id }}/>
                </HeaderItem>
            </div>
        </Dropdown>
    );
});


export { SettingsDropdownPart };
