/**
 * ArrangeEdit styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div`
    .skip-word {
        cursor: text;
        outline: none;
        position: relative;
        display: inline-block;
    }

    .skip-word:focus {
        box-shadow: 0 0 0 2px rgba(139, 92, 246, 0.3);
    }

    .skip-word:focus::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -1px;
        height: 2px;
        background-color: #8B5CF6;
    }

    .skip-word-wrapper {
        display: inline;
        white-space: pre-wrap;
    }
`;
