/**
 * MessagesFileUploadView
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ChangeEvent, useState } from 'react';

import { ConfigStore, observer } from '@/store/core/config';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { FileExtension } from '@/shared/types';

import { Dropdown } from '@exode.ru/vkui/dist/unstable';
import { SimpleCell, Spinner, WriteBarIcon } from '@exode.ru/vkui';
import { Icon24DocumentPlusOutline, Icon24PicturePlusOutline, Icon28CancelOutline } from '@vkontakte/icons';


interface Props {
    isEditing: boolean;
    cancelEdit: () => void;
    editChatMessageLoading: boolean;
    loadFilesWithPreviews: (e: ChangeEvent<HTMLInputElement>) => void;
}


const MessagesFileUploadIconView = observer((props: Props) => {

    const {
        cancelEdit,
        isEditing,
        editChatMessageLoading,
        loadFilesWithPreviews: _loadFilesWithPreviews,
    } = props;

    const { t } = useI18n('pages.Chat.Dialog');

    const [ showDropdown, setShowDropdown ] = useState(false);

    const loadFilesWithPreviews = (e: ChangeEvent<HTMLInputElement>) => {
        _loadFilesWithPreviews(e);
        e.target.value = '';
    };

    return (
        <>
            <If is={isEditing}>
                <If is={editChatMessageLoading}>
                    <Spinner/>
                </If>

                <If is={!editChatMessageLoading}>
                    <Icon28CancelOutline onClick={cancelEdit}
                                         className="cursor-pointer"
                                         fill={ConfigStore.isDesktop ? '' : 'var(--accent)'}/>
                </If>
            </If>

            <If is={!isEditing}>
                <Dropdown action="click"
                          shown={showDropdown}
                          className="overflow-hidden"
                          onShownChange={setShowDropdown}
                          content={(
                              <>
                                  <label htmlFor="image-and-video-input">
                                      <SimpleCell className="!rounded-none" before={<Icon24PicturePlusOutline/>}>
                                          {t('photoOrVideo')}
                                      </SimpleCell>
                                  </label>

                                  <label htmlFor="file-input">
                                      <SimpleCell className="!rounded-none" before={<Icon24DocumentPlusOutline/>}>
                                          {t('file')}
                                      </SimpleCell>
                                  </label>
                              </>
                          )}>
                    <WriteBarIcon mode="attach" data-test="chat.attach" onClick={() => setShowDropdown(showDropdown)}/>
                </Dropdown>
            </If>

            <input id="image-and-video-input"
                   multiple
                   type="file"
                   className="hidden"
                   accept={FileExtension.ImageAndVideo}
                   onChange={loadFilesWithPreviews}/>

            <input id="file-input"
                   multiple
                   type="file"
                   className="hidden"
                   accept={FileExtension.Docs}
                   onChange={loadFilesWithPreviews}/>
        </>
    );
});


export { MessagesFileUploadIconView };
