/**
 * VideoContentElementEdit
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { Formik } from 'formik';

import React from 'react';

import { useI18n, useOnceUpdate } from '@/hooks/core';

import { FileExtension, VideoContent, VideoContentType } from '@/shared/types';

import { Url } from '@/utils';
import { AutoSaveForm, Field, If } from '@/cutils';
import { ContentElementType } from '@/codegen/graphql';

import { FormItem, Input } from '@exode.ru/vkui';

import { FileUpload } from '@/components/Atoms/FileUpload';
import { getContentElementContextValue } from '@/components/ContentElement';

import { BlockHeaderPart, BlockWrapperPart } from '../parts/edit';

import { ContentElementEditProps } from '../interfaces';


interface Props extends ContentElementEditProps<VideoContent> {

}


const VideoContentElementEdit = (props: Props) => {

    const {
        list,
        loading,
        onEdit,
        update,
        onDelete,
        options,
        contentElement,
    } = props;

    const { t } = useI18n('components.ContentElement.edit');

    const {
        edit: {
            withTitle = true,
            blockHeaderProps = {},
            blockWrapperProps = {},
            BlockWrapper = BlockWrapperPart,
        } = {},
    } = getContentElementContextValue(ContentElementType.Video);

    const {
        initialValues,
        handleUpdateSubmit,
    } = useOnceUpdate(
        _.pick(contentElement, [ 'title', 'content' ]),
        async (values) => update?.(values),
    );

    return (
        <Formik initialValues={initialValues} onSubmit={handleUpdateSubmit}>
            {({ values, handleSubmit, handleChange, setFieldValue }) => (
                <AutoSaveForm onChange={() => handleSubmit()} onInstantChange={() => onEdit?.()}>
                    <BlockWrapper index={list?.index} {...blockWrapperProps}>
                        <BlockHeaderPart list={list} loading={loading} onDelete={onDelete} {...blockHeaderProps}/>

                        <FormItem className="px-0 pt-0.5" top={t('uploadVideoOrSetLink')}>
                            <FileUpload width="100%"
                                        height="320px"
                                        showInfo={false}
                                        multiple={false}
                                        space={options.space}
                                        accept={FileExtension.Video}
                                        id={`lesson-video-${list?.index}`}
                                        previewVideo={values?.content?.location}
                                        onFileUpload={([ file ]) => {
                                            const video: VideoContent = {
                                                type: Url.videoUrlIsThirdParty(file.location || '')
                                                    ? VideoContentType.ThirdParty
                                                    : VideoContentType.Local,
                                                location: file.location || '',
                                                duration: file.meta?.duration || 0,
                                                meta: _.pick(file.meta, [
                                                    'size',
                                                    'encoding',
                                                    'mimeType',
                                                    'originalName',
                                                ]),
                                            };

                                            if (_.isEqual(video, values.content)) {
                                                return;
                                            }

                                            setFieldValue('content', video);

                                            handleSubmit();
                                        }}/>
                        </FormItem>

                        <If is={withTitle}>
                            <FormItem className="px-0 pt-0.5" top={t('videoTitle')}>
                                <Input name="title"
                                       value={values.title || ''}
                                       data-test="content-element.title"
                                       placeholder={t('enterVideoTitle')}
                                       onChange={Field.transform.ignoreSpaceOnChange(handleChange)}/>
                            </FormItem>
                        </If>
                    </BlockWrapper>
                </AutoSaveForm>
            )}
        </Formik>
    );
};


export { VideoContentElementEdit };
