/**
 * VariantMainAttachmentAtom
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Link } from '@/cutils';

import { MultipleWithAttachmentTaskQuestion, StorageFileType } from '@/shared/types';

import { Avatar } from '@exode.ru/vkui';
import { Icon24DocumentTextOutline, Icon24VideoSquareOutline } from '@vkontakte/icons';

import { PhotoProvider, PhotoView } from '@/components/Atoms/PhotoProvider';


interface Props {
    variant: MultipleWithAttachmentTaskQuestion['variants'][number];
}


const VariantMainAttachmentAtom = (props: Props) => {

    const { variant } = props;

    const { type, location = '' } = variant.attachments?.main || {};

    switch (type) {
        case StorageFileType.Audio:
            return (
                <audio controls className="flex flex-1 h-[42px] thin-border vk-rounded">
                    <source src={location}/>
                </audio>
            );

        case StorageFileType.Image:
            return (
                <PhotoProvider>
                    <PhotoView src={location}>
                        <Avatar size={40} src={location} mode="app" className="vk-rounded cursor-pointer"/>
                    </PhotoView>
                </PhotoProvider>
            );

        case StorageFileType.Video:
            return (
                <Link toOuter={location}>
                    <Avatar size={40} mode="app">
                        <Icon24VideoSquareOutline fill="var(--accent)"/>
                    </Avatar>
                </Link>
            );

        case StorageFileType.File:
            return (
                <Link toOuter={location}>
                    <Avatar size={40} mode="app">
                        <Icon24DocumentTextOutline fill="var(--accent)"/>
                    </Avatar>
                </Link>
            );

        default:
            return (<></>);
    }
};


export { VariantMainAttachmentAtom };
