/**
 * SessionsTab
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';
import { Graphql, If } from '@/cutils';
import { useSchoolSession } from '@/hooks/apollo';
import { SchoolUserSessionFindManyQuery } from '@/codegen/graphql';

import { Button } from '@exode.ru/vkui';
import { Icon28DoorArrowRightOutline, Icon28LocationMapOutline } from '@vkontakte/icons';

import { Placeholder } from '@/components/Atoms/Placeholder';
import { ConfirmButton } from '@/components/Atoms/ConfirmButton';
import { TagInlineText } from '@/components/Atoms/TagInlineText';
import { SessionItem } from '@/components/User/Settings/SessionItem';

import { SessionActionsDropdown } from '../dropdowns/SessionActionsDropdown';


interface Props {
    userId: number;
}


const SessionsTab = (props: Props) => {

    const { userId } = props;

    const { t } = useI18n('pages.Manage.School.Users.views.EditUserFormView');

    const { killAllSessions, killAllSessionsLoading } = useSchoolSession();

    return (
        <SchoolUserSessionFindManyQuery children={(result) => (
            <>
                <Graphql.Loading result={result}>
                    <Page.Spinner/>
                </Graphql.Loading>

                <Graphql.Success result={result}>
                    {({ schoolUserSessionFindMany: { items: sessions } }) => (
                        <>
                            <If is={!_.isEmpty(sessions?.filter((e) => e.alive))}>
                                <ConfirmButton text={t('clickToConfirmKillSession')}
                                               callback={() => killAllSessions(userId)}
                                               confirmProps={{
                                                   before: <Icon28DoorArrowRightOutline fill="var(--destructive)"/>,
                                                   children: (
                                                       <span className="text-destructive">
                                                           {t('confirmKillSessionAllSession')}
                                                       </span>
                                                   ),
                                               }}>
                                    <Button stretched
                                            size="l"
                                            mode="secondary"
                                            loading={killAllSessionsLoading}
                                            disabled={killAllSessionsLoading}
                                            before={<Icon28DoorArrowRightOutline/>}
                                            className="mb-4 bg-hover text-destructive">
                                        {t('killAllSessionBtn')}
                                    </Button>
                                </ConfirmButton>
                            </If>

                            {sessions?.map((session) => (
                                <SessionItem key={session.id}
                                             hasHover={false}
                                             hasActive={false}
                                             session={session}
                                             className="px-0"
                                             indicator={<></>}
                                             onClick={() => {}}
                                             after={(
                                                 <div className="flex items-center gap-2">
                                                     <If is={session.alive}>
                                                         <SessionActionsDropdown sessionId={session.id}/>
                                                     </If>

                                                     <TagInlineText size="m"
                                                                    isPointer={false}
                                                                    gradient={session.isOnline ? 'green' : 'secondary'}
                                                                    className={[
                                                                        'text-xs !px-2.5 !py-1.5',
                                                                        'first-letter:capitalize',
                                                                    ].join(' ')}
                                                                    content={(session.alive
                                                                            ? session.isOnline
                                                                                ? t('online')
                                                                                : t('offline')
                                                                            : t('loggedOut')
                                                                    )}/>
                                                 </div>
                                             )}/>
                            ))}

                            <If is={_.isEmpty(sessions)}>
                                <Placeholder iconSize={72} header={t('sessionsNotFound')} icon={(
                                    <Icon28LocationMapOutline className="pt-0 text-accent opacity-[0.85]"/>
                                )} content={(
                                    <span className="m:w-full w-9/10 flex mx-auto">
                                        {t('sessionHistoryWillAppearHere')}
                                    </span>
                                )}/>
                            </If>
                        </>
                    )}
                </Graphql.Success>

                <Graphql.Error result={result}/>
            </>
        )} variables={{ list: {}, filter: { userIds: [ userId ] } }}/>
    );
};


export { SessionsTab };
