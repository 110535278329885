/**
 * DesktopHeaderMenu
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement, ReactNode } from 'react';

import { ConfigStore, observer } from '@/store/core/config';

import { useI18n } from '@/hooks/core';
import { useLocation } from '@/router/index';
import { RoutePathType } from '@/router/paths';
import { Router } from '@/services/Utils/Router';
import { Link, Platform, Unauth } from '@/cutils';

import { shouldManageNavigationBeShown } from '@/components/Desktop/Manage/ManageServicesList';

import { HeaderItemPart } from './parts/HeaderItemPart';


interface Props {
    mobileTitle: string;
    responsiveMode?: 'isDesktopOnly' | 'isDesktop';
    items?: {
        title?: string;
        before?: ReactElement;
        path: {
            id: RoutePathType;
            params?: Record<string, string>;
        };
        extraPaths?: RoutePathType[];
    }[];
    after?: ReactNode;
    isActiveCb?: (
        id: RoutePathType,
        extraPaths?: RoutePathType[],
    ) => boolean;
}


const DesktopHeaderMenu = observer((props: Props) => {

    const { mobileTitle, responsiveMode = 'isDesktopOnly' } = props;

    const { route: { pageId } } = useLocation();

    const { t } = useI18n('components.Desktop.Navigation.DesktopHeaderMenu');

    const firstManageItem = Router.getFirstManageDrawerItem();
    const isManageMode = shouldManageNavigationBeShown() && firstManageItem?.path?.id;

    const {
        items = [
            {
                path: {
                    id: isManageMode && firstManageItem?.path?.id
                        ? firstManageItem.path.id
                        : '/education',
                },
                title: isManageMode ? t('controlPanel') : t('education'),
            },
            {
                path: { id: '/catalog' },
                title: t('catalog'),
                extraPaths: [
                    '/',
                    '/catalog/:page([0-9]+)',
                ],
            },
            {
                path: { id: '/support' },
                title: 'FAQ',
            },
            {
                path: { id: '/manage/my-schools' },
                title: t('mySchools'),
            },
            {
                path: { id: '/school/create' },
                title: t('createSchool'),
            },
        ],
        after = (
            <Platform.Market>
                <Unauth>
                    <HeaderItemPart hasHover={false}
                                    title={t('createCourse')}
                                    textClassName="text-marker"
                                    onClick={() => {
                                        Router.replaceToModalAfterLogin(
                                            '/education',
                                            'short-form-become-partner',
                                        );
                                    }}/>
                </Unauth>
            </Platform.Market>
        ),
        isActiveCb = (
            id: RoutePathType,
            extraPaths?: RoutePathType[],
        ) => id === pageId || extraPaths?.includes(pageId),
    } = props;

    if (!ConfigStore[responsiveMode]) {
        return (
            <>{mobileTitle}</>
        );
    }

    return (
        <div className="flex h-[var(--panelheader\_height)] flex-1 gap-2">
            {items?.filter(({ path: { id } }) => Router.filterByPageId(id))
                .map(({ path: { id, params }, extraPaths, ...rest }) => (
                    <Link pushPage={{ id, params }}>
                        <HeaderItemPart active={isActiveCb(id, extraPaths)} {...rest}/>
                    </Link>
                ))
            }

            <>{after}</>
        </div>
    );
});


export { DesktopHeaderMenu };
