/**
 * EditUserFormMarketplaceHeader
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Page } from '@/pages/Core';

import { ProfileFindOneQuery, UserSexType } from '@/codegen/graphql';

import { Graphql, If } from '@/cutils';

import { Group, MiniInfoCell, Separator, Title } from '@exode.ru/vkui';
import { Icon20GlobeOutline, Icon20PlaceOutline, Icon20RectangleInfoOutline } from '@vkontakte/icons';

import { User } from '@/components/Atoms/User';
import { UserAvatar } from '@/components/Atoms/UserAvatar';
import { LastOnlineText } from '@/components/Profile/Page';


interface Props {
    userId: number;
}


const EditUserFormMarketplaceHeader = (props: Props) => {

    const { userId } = props;

    return (
        <>
            <div className="p-7 pb-6 items-start">
                <ProfileFindOneQuery children={(result) => (
                    <>
                        <Graphql.Loading result={result}>
                            <Page.Spinner/>
                        </Graphql.Loading>

                        <Graphql.Success result={result}>
                            {({ profileFindOne: profile }) => (
                                <div className="flex flex-1 gap-6 text-left">
                                    <UserAvatar size={120}
                                                userId={userId}
                                                name={profile.fullName || ''}
                                                verified={profile.user.verified}
                                                src={profile.avatar.maximum || ''}/>

                                    <div className="flex flex-1 flex-col items-start gap-1 max-w-full overflow-hidden">
                                        <Title level="1" weight="3" className="line-clamp-1">
                                            <User.Name profile={profile}/>
                                        </Title>

                                        <Group mode="plain"
                                               padding="s"
                                               className="w-full m:w-[calc(100%_-_40px)] info-group m-thin-border">
                                            <MiniInfoCell textWrap="short"
                                                          className="info-group-cell d:px-0"
                                                          before={<Icon20RectangleInfoOutline/>}>
                                                @{profile.user.domain}
                                            </MiniInfoCell>

                                            <MiniInfoCell textWrap="short"
                                                          before={<Icon20GlobeOutline/>}
                                                          className="info-group-cell d:px-0">
                                                <LastOnlineText userId={userId}
                                                                lastOnlineAt={profile.user.lastOnlineAt}
                                                                sex={profile.sex || UserSexType.Ufo}/>
                                            </MiniInfoCell>

                                            <If is={!!profile.city || !!profile.country}>
                                                <MiniInfoCell before={<Icon20PlaceOutline/>}
                                                              className="info-group-cell d:px-0">
                                                    {[ profile.city, profile.country ].filter(e => e).join(', ')}
                                                </MiniInfoCell>
                                            </If>
                                        </Group>
                                    </div>
                                </div>
                            )}
                        </Graphql.Success>

                        <Graphql.Error result={result}/>
                    </>
                )} variables={{ userId }}/>
            </div>

            <Separator wide/>
        </>
    );
};


export { EditUserFormMarketplaceHeader };
