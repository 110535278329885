/**
 * ContentPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PageStore } from '@/store/core/page';
import { observer } from '@/store/preference/preference';

import { If, Portal } from '@/cutils';

import { ContentIconsPart } from '../parts/ContentIconsPart';


const ContentPart = observer(() => {
    return (
        <div className="viewport__content vkuiSplitCol--spaced h-full">
            <div data-test="page-header" className="flex items-center justify-between">
                <Portal renderer id="panel-header-page:content"/>
                <Portal renderer id="panel-header-page:d:context"/>

                <If is={PageStore.mode !== 'slim-menu'}>
                    <div className="flex items-center h-full w-fit ml-auto">
                        <ContentIconsPart/>
                    </div>
                </If>
            </div>
        </div>
    );
});


export { ContentPart };
