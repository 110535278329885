/**
 * MappingEditPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { MappingTaskQuestion } from '@/shared/types';

import { TaskBuilderEditProps } from '@/components/Task/Builder';


interface Props extends TaskBuilderEditProps<MappingTaskQuestion> {

}


const MappingEdit = (props: Props) => {

    const {} = props;

    return (
        <>
        </>
    );
};


export { MappingEdit };
