/**
 * ExistUserListItem
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If, Link } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { SchoolUserManageCreateResult } from '@/types/user';

import { Cell, FormItem } from '@exode.ru/vkui';
import { Icon16AddCircleFillWhite, Icon16LinkOutline } from '@vkontakte/icons';

import { User } from '@/components/Atoms/User';
import { UserAvatar } from '@/components/Atoms/UserAvatar';
import { TagInlineText } from '@/components/Atoms/TagInlineText';
import { CustomScrollbar } from '@/components/Atoms/Styled';

import { UserEditTab } from '@/pages/Manage/School/Users';


interface Props {
    existUsers: SchoolUserManageCreateResult['exist'];
    createdUsers: SchoolUserManageCreateResult['created'];
}


const ExistUserListItem = (props: Props) => {

    const { existUsers, createdUsers } = props;

    const { t } = useI18n('pages.Manage.School.Users.items');

    return (
        <div className="flex flex-col gap-3 mt-3">
            <FormItem className="p-0" top={t('alreadyExistUsers', { countOfExist: existUsers.length })}>
                <CustomScrollbar className="flex flex-col bg-hover vk-rounded thin-border-border max-h-[300px] overflow-auto">
                    {existUsers.map(({ id: userId, profile }) => (
                        <Cell hasActive={false}
                              hasHover={false}
                              className="thin-border-bottom py-0.5 last:!border-none"
                              before={(
                                  <UserAvatar size={42}
                                              userId={userId}
                                              className="mr-3"
                                              name={profile.fullName || ''}
                                              src={profile.avatar.medium || ''}/>
                              )}
                              subtitle={(
                                  <Link blank toOuter={[
                                      `/manage/school/users/1`,
                                      `modal=manage-user-edit&tab=${UserEditTab.CreateAccess}&userId=${userId}`,
                                  ].join('?')}>
                                      <div className="flex items-center text-accent">
                                          {t('addAccessForUser')}

                                          <Icon16LinkOutline/>
                                      </div>
                                  </Link>
                              )}>
                            <User.Name profile={profile}/>
                        </Cell>
                    ))}
                </CustomScrollbar>
            </FormItem>

            <If is={!!createdUsers?.length}>
                <div className="m-auto mt-3">
                    <TagInlineText size="l"
                                   gradient="green"
                                   isPointer={false}
                                   className="fs-14"
                                   icon={<Icon16AddCircleFillWhite/>}
                                   content={t('createdUsers', { count: createdUsers?.length })}/>
                </div>
            </If>
        </div>
    );
};


export { ExistUserListItem };
