/**
 * MultipleEdit
 *
 * @Note: Одна компонента сразу для нескольких типов заданий
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { FieldArray, Formik } from 'formik';

import { AutoSaveForm } from '@/cutils';

import { MultipleWithAttachmentTaskQuestion } from '@/shared/types';

import { TaskBuilderEditProps } from '@/components/Task/Builder';

import { VariantsListPart } from './VariantsListPart';


interface Props extends TaskBuilderEditProps<MultipleWithAttachmentTaskQuestion> {

}


const MultipleEdit = (props: Props) => {

    const { task, update, onInstantChange } = props;

    return (
        <Formik onSubmit={({ question }) => update({ question })}
                initialValues={{ question: { variants: task.question.variants || [] } }}>
            {({ handleSubmit, values: { question: { variants } } }) => (
                <AutoSaveForm onChange={handleSubmit}
                              className="flex flex-col gap-2 px-4"
                              onInstantChange={() => onInstantChange?.()}>
                    <FieldArray name="question.variants">
                        {(helpers) => (
                            <VariantsListPart task={task}
                                              helpers={helpers}
                                              variants={variants}
                                              data-test="task.variants-list"
                                              onInstantChange={onInstantChange}/>
                        )}
                    </FieldArray>
                </AutoSaveForm>
            )}
        </Formik>
    );
};


export { MultipleEdit };
