/**
 * DesktopSlimMenu component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PageStore } from '@/store/core/page';
import { UserAuthStore } from '@/store/user/auth';
import { PreferenceStore } from '@/store/preference/preference';
import { observer, RouterStore } from '@/store/core/router';

import { useI18n } from '@/hooks/core';
import { If, Portal, Responsive } from '@/cutils';
import { useChatUnread, useNotificationUnread } from '@/hooks/apollo';

import {
    Icon24DoorArrowLeftOutline,
    Icon24HomeOutline,
    Icon24MessageOutline,
    Icon24NotificationOutline,
    Icon24SquareStackUpOutline,
    Icon24SunOutline,
    Icon28MoonOutline,
} from '@vkontakte/icons';

import { SettingsDropdownPart } from '@/components/Desktop/Panel/Header';
import { PreferenceThemeToggle } from '@/components/Preference/Theme';
import { WindowsCustomScrollbar } from '@/components/Atoms/Styled';

import { SlimMenuButtonIcon, SlimMenuButtonIconProps } from './parts/SlimMenuButtonIcon';


const DesktopSlimMenu = observer(() => {

    const { t } = useI18n('components.Desktop.Navigation.Menu');

    const { unreadCountChat } = useChatUnread('All');
    const { unreadCountNotification } = useNotificationUnread();

    const topButtons: SlimMenuButtonIconProps[] = [
        {
            icon: <Icon24HomeOutline/>,
            tooltipText: t('education'),
            linkProps: { pushPage: { id: '/education' } },
        },
        {
            hidden: !UserAuthStore.isLoggedIn,
            counter: unreadCountChat,
            tooltipText: t('chat'),
            icon: <Icon24MessageOutline/>,
            linkProps: { pushPage: { id: '/chat' } },
        },
        {
            icon: <Icon24SquareStackUpOutline/>,
            tooltipText: t('catalog'),
            linkProps: { pushPage: { id: '/catalog' } },
        },
    ];

    const bottomButtons: SlimMenuButtonIconProps[] = [
        {
            tooltipText: t('changeTheme'),
            hidden: PreferenceStore.toggleIsDisabled,
            icon: (
                <PreferenceThemeToggle>
                    {(PreferenceStore.isDark
                            ? <Icon24SunOutline fill="var(--accent)"/>
                            : <Icon28MoonOutline width={24} height={24} fill="var(--accent)"/>
                    )}
                </PreferenceThemeToggle>
            ),
        },
        {
            counter: unreadCountNotification,
            tooltipText: t('notifications'),
            hidden: !UserAuthStore.isLoggedIn,
            icon: <Icon24NotificationOutline/>,
            linkProps: { pushPage: { id: '/notifications' } },
        },
        {
            type: 'avatar',
            tooltipText: '',
            hidden: !UserAuthStore.isLoggedIn,
            icon: (
                <SettingsDropdownPart placement="slim-menu" dropdownProps={{
                    offsetSkidding: 65,
                    offsetDistance: -39,
                }}/>
            ),
        },
        {
            tooltipText: t('login'),
            hidden: UserAuthStore.isLoggedIn,
            icon: <Icon24DoorArrowLeftOutline/>,
            linkProps: { pushPage: { id: '/login' } },
        },
    ];

    return (
        <Responsive.Desktop>
            <div className={[
                'fixed py-1 box-border',
                'left-0 top-0 bg-content-75--blur',
                /** Border */
                RouterStore.type !== 'fullscreen'
                    ? 'thin-border-right border-separator'
                    : '',
                /** Height */
                RouterStore.type !== 'fullscreen'
                    ? 'mt-header h-full-[-panel-header]'
                    : 'h-full',
            ].join(' ')}>
                <div className={[
                    'h-full w-[70px] p-1.5 box-border',
                    'flex flex-col items-center justify-between',
                ].join(' ')}>
                    <div className="flex flex-col gap-3">
                        {topButtons.map((button, index) => (
                            <SlimMenuButtonIcon key={index} {...button}/>
                        ))}

                        <Portal renderer id="menu:slim-top"/>
                    </div>

                    <div className="flex flex-col gap-3 pb-1">
                        {bottomButtons.map((button, index) => (
                            <SlimMenuButtonIcon key={index} {...button}/>
                        ))}
                    </div>
                </div>
            </div>

            <If is={PageStore.slimMenuDrawer}>
                <WindowsCustomScrollbar className={[
                    'sticky top-0 w-[400px] min-w-[400px] h-[100dvh]',
                    'thin-border-left thin-border-right bg-page-initial overflow-y-auto',
                ].join(' ')}>
                    <Portal renderer id="menu:slim-drawer"/>
                </WindowsCustomScrollbar>
            </If>
        </Responsive.Desktop>
    );
});


export { DesktopSlimMenu };
