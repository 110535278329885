/**
 * FilterView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { useI18n } from '@/hooks/core';
import { observer } from '@/pages/Core';

import { ProductLaunchStatus } from '@/codegen/graphql';
import { practiceAttemptStatus } from '@/types/practice';

import { Icon20ListAddOutline } from '@vkontakte/icons';

import { CourseSearchSelect } from '@/components/Course/CourseSearchSelect';
import { SubNavigationSelect } from '@/components/Atoms/SubNavigationSelect';

import { useManageHomeworkFilter } from '../filter';


const FilterView = observer(() => {

    const { t } = useI18n('pages.Manage.Homework.views');

    const practiceAttemptStatuses = practiceAttemptStatus();

    const { urlFilter, setUrlFilter } = useManageHomeworkFilter();

    return (
        <div className="flex items-center flex-wrap px-8 gap-3 mt-5">
            <SubNavigationSelect name="statuses"
                                 initialTitle={t('all')}
                                 value={urlFilter.statuses?.[0]}
                                 filterIcon={practiceAttemptStatuses[urlFilter.statuses[0]]?.icon}
                                 onChange={(__, value) => setUrlFilter({ statuses: value === 'all' ? [] : [ value ] })}
                                 navigationButtonProps={{
                                     style: {
                                         color: practiceAttemptStatuses[urlFilter.statuses[0]]?.bgColor,
                                     },
                                 }}
                                 options={(
                                     _.map(_.entries({
                                         'all': {
                                             name: t('all'),
                                             bgColor: 'bg-selected',
                                             icon: <Icon20ListAddOutline fill="var(--text_primary)"/>,
                                         },
                                         ...practiceAttemptStatus,
                                     }), ([ key, { name, bgColor, icon } ]) => ({
                                         value: key,
                                         label: name || '',
                                         icon: (
                                             <div className={[ 'p-0.5 rounded-full', bgColor ].join(' ')}>
                                                 {icon}
                                             </div>
                                         ),
                                     }))
                                 )}/>

            <div className="flex">
                <CourseSearchSelect onSelect={({ value }) => setUrlFilter({ courseIds: [ value ] })} filter={{
                    product: {
                        launch: {
                            statuses: [ ProductLaunchStatus.Published ],
                        },
                    },
                }}/>
            </div>
        </div>
    );
});


export { FilterView };
