/**
 * EmptyContentView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { Icon28ArchiveCheckOutline } from '@vkontakte/icons';

import { Placeholder } from '@/components/Atoms/Placeholder';


const EmptyContentView = () => {

    const { t } = useI18n('pages.Manage.Homework.views');

    return (
        <Placeholder iconSize={120}
                     innerPadding="12px"
                     header={t('practiceNotFound')}
                     className="my-auto m:pt-0 min-h-[calc(100vh_-_435px)]"
                     icon={<Icon28ArchiveCheckOutline className="text-accent opacity-[0.85]"/>}
                     content={(
                         <span className="m:w-full w-9/10 flex mx-auto">
                             {t('practicePlaceholderContent')}
                         </span>
                     )}/>
    );
};


export { EmptyContentView };
