/**
 * DroppableSlotAtom
 *
 * @author: exode <hello@exode.ru>
 */

import React, { cloneElement, ReactElement, useEffect, useRef, useState } from 'react';

import { useDroppable } from '@dnd-kit/core';

import { If } from '@/cutils';

import { useTaskBuilderContext } from '@/components/Task/Builder';


interface Props {
    id: string;
    children?: ReactElement;
    onClick?: () => void;
    onRemoveWord?: () => void;
    isHighlighted?: boolean;
    placeholder?: string;
    answerIsCorrect?: boolean;
}


const DroppableSlotAtom = (props: Props) => {

    const {
        id,
        onClick,
        children,
        placeholder,
        isHighlighted,
        answerIsCorrect,
    } = props;

    const { show: { subMode } } = useTaskBuilderContext();

    const childrenRef = useRef<HTMLDivElement>(null);

    const [ childrenWidth, setChildrenWidth ] = useState<number>();

    const { setNodeRef, isOver } = useDroppable({ id });

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();

        if (!children) {
            onClick?.();
        }
    };

    useEffect(() => {
        if (childrenRef.current) {
            setChildrenWidth(childrenRef.current.offsetWidth || undefined);
        }
    }, [ children, isOver ]);

    return (
        <div ref={setNodeRef} onClick={handleClick} style={{ minWidth: childrenWidth }} className={[
            'inline-block min-h-[36px] vk-rounded mb-1 align-middle min-w-[35px]',
            !children ? '!min-w-[120px]' : '',
            subMode === 'result'
                ? children ? '' : (answerIsCorrect ? 'text-positive-shadow' : 'text-destructive-shadow')
                : !isHighlighted
                    ? (isOver ? 'accent-shadow bg-selected' : 'text-secondary-shadow')
                    : (!children ? 'bg-hover text-primary-shadow cursor-pointer' : ''),
        ].join(' ')}>
            {children && cloneElement(children, {
                ref: childrenRef,
                isOverAnother: isOver,
            })}

            <If is={!children && subMode === 'result'}>
                <div className="w-fit text-center text-destructive opacity-80 px-3 mx-auto">
                    {placeholder}
                </div>
            </If>
        </div>
    );
};


export { DroppableSlotAtom };
