/**
 * SentenceAtom
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { Fragment } from 'react';

import { QuizQuestion } from '../types';
import { useWordPlacement } from '../hooks/useWordPlacement';

import { DraggableWordAtom } from './DraggableWordAtom';
import { DroppableSlotAtom } from './DroppableSlotAtom';


interface Props {
    question: QuizQuestion;
    answers: Record<string, string>;
    onRemoveWord: (slotId: string) => void;
    onSlotClick: (slotId: string) => void;
    selectedWordId: string | null;
}


const SentenceAtom = (props: Props) => {

    const {
        question,
        answers,
        onSlotClick,
        onRemoveWord,
        selectedWordId,
    } = props;

    const parts = question.textWithSkips.split(/(\|_\[skip:[^\]]+\]_\|)/);

    const {
        getWordById,
        getAllWords,
        normalizeUuid,
    } = useWordPlacement();

    const allWords = getAllWords(question.skips, question.fakeSkips);

    return (
        <div className="inline-block fs-content" style={{ lineHeight: '190%' }}>
            {parts.map((part, index) => {
                if (part.startsWith('|_[skip:')) {
                    const wordId = answers[part];
                    const wordText = getWordById(wordId, allWords);

                    const skip = _.find(question.skips, { uuid: part });

                    const answerIsCorrect = skip?.uuid === normalizeUuid(wordId)
                        || skip?.text === wordText;

                    return (
                        <DroppableSlotAtom key={part}
                                           id={part}
                                           placeholder={skip?.text}
                                           onClick={() => onSlotClick(part)}
                                           answerIsCorrect={answerIsCorrect}
                                           onRemoveWord={() => onRemoveWord(part)}
                                           isHighlighted={selectedWordId !== null}>
                            {wordId ? (
                                <DraggableWordAtom id={wordId}
                                                   isPlaced={true}
                                                   text={wordText}
                                                   correctSkip={skip}
                                                   onRemove={() => onRemoveWord(part)}
                                                   answerIsCorrect={answerIsCorrect}/>
                            ) : undefined}
                        </DroppableSlotAtom>
                    );
                }

                return <Fragment key={index}>{part}</Fragment>;
            })}
        </div>
    );
};


export { SentenceAtom };
