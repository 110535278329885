/**
 * Helper
 *
 * @author: exode <hello@exode.ru>
 */

import { AppColorScheme } from '@/codegen/graphql';


export const courseCardColors = {
    BrightLight: [
        '#D0FA9B',
        '#77CBFA',
        '#D6D4F0',
        '#B3D9EA',
        '#F5F3C6',
        '#D2FCB5',

    ],
    SpaceGray: [
        'linear-gradient(to right, rgba(0, 164, 255, 0.18), rgba(73, 137, 173, 0.71))',
        'linear-gradient(to right, rgba(65, 54, 197, 0.2), rgba(155, 147, 255, 0.27))',
        'linear-gradient(to right, rgba(7, 90, 128, 0.22), rgba(62, 93, 105, 0.56))',
    ],
};

export const getCourseCardColors = (
    course: {
        id: number;
        preferenceSettings: {
            cardColor?: {
                BrightLight?: { value: string } | null
                SpaceGray?: { value: string } | null
            } | null
        }
    },
    isDark = false,
) => {
    const { id, preferenceSettings } = course;

    const currentAppScheme = isDark
        ? AppColorScheme.SpaceGray
        : AppColorScheme.BrightLight;

    const customColor = preferenceSettings.cardColor?.[currentAppScheme]?.value;

    const defaultColors = courseCardColors[currentAppScheme];

    return customColor || defaultColors[id % Object.keys(defaultColors).length];
};

