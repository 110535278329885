/**
 * MessageItem
 *
 * @author: exode <hello@exode.ru>
 */

import moment from 'moment';

import React, { MouseEvent, MutableRefObject, useState } from 'react';

import { ConfigStore } from '@/store/core/config';
import { observer, useStore } from '@/pages/Core';
import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';

import { ChatMessageStatus } from '@/codegen/graphql';

import { useI18n } from '@/hooks/core';
import { Parse, StringUtil } from '@/utils';
import { ComponentHelper } from '@/helpers/react';
import { useChatSendMessage } from '@/hooks/apollo';
import { If, Link, SvgComponent, useRedirect } from '@/cutils';

import { ChatService } from '@/services/Chat/Chat';
import { ChatMessageItem, ChatMessageItemNullable, chatMessageStatusIcon } from '@/types/chat';

import { ClickAwayListener } from '@mui/material';
import { TextTooltip } from '@exode.ru/vkui/unstable';
import { Button, Caption, SimpleCell, Text } from '@exode.ru/vkui';

import { User } from '@/components/Atoms/User';
import { UserAvatar } from '@/components/Atoms/UserAvatar';

import {
    Icon16CheckDoubleOutline,
    Icon16DeleteOutline,
    Icon16Replay,
    Icon16Reply,
    Icon20CameraOutline,
    Icon28CheckCircleOff,
    Icon28CheckCircleOn,
} from '@vkontakte/icons';

import { ReplyItem } from '../ReplyItem';
import { MessageMediaItem } from '../MessageMediaItem';
import { MessageReaderWrapperItem } from '../MessageReaderWrapperItem';

import { MessageItemContainer } from '../styled';
import { ActionButtonsContainer, MessageContainer } from './MessageItem.styled';


interface Props {
    index: number;
    chatId: number;
    message: ChatMessageItem;
    inputRef: MutableRefObject<HTMLTextAreaElement | null>;
    position: { pageX: number, pageY: number };
    setPosition: ({ pageX, pageY }: { pageX: number, pageY: number }) => void;
    contextId: number | null;
    setContextId: (id: null | number) => void;
    messages: ChatMessageItemNullable;
}


const MessageItem = observer((props: Props) => {

    const {
        message,
        messages = [],
        chatId,
        contextId,
        inputRef,
        setContextId,
        position,
        setPosition,
    } = props;

    const { t } = useI18n('pages.Chat.Dialog');

    const { store, input } = useStore(ChatDialogsPageStore);

    const { redirect, handleInnerHtmlLinks } = useRedirect();

    const { sendChatMessage, removeUnsentChatMessage, sendChatMessageLoading } = useChatSendMessage();

    const [ isHovered, setIsHovered ] = useState<number | null>(null);

    const { id: messageId, status, user, isRead, createdAt, medias, isMine, replyMessage } = message;

    const isDesktop = ConfigStore.isDesktop;
    const isCompact = ChatService.hasGapByInterval(message.id, messages || []);
    const isSelected = store.state.selectedMessages?.[chatId]?.find((item) => item.id === messageId);

    const isSending = message.status === ChatMessageStatus.Sending;
    const isSent = [ ChatMessageStatus.Sent, ChatMessageStatus.Scheduled ].includes(message.status);
    const unsentMessage = store.state.unsentMessages[chatId]?.find((item) => item.messageId === messageId);

    const {
        text: messageText,
        className: messageTextClassName,
    } = Parse.parseAndWrapEmoji(
        ComponentHelper.linkifyHtml(
            Parse.nlToBr(message.text || ''),
        ),
    );

    const onContextMenu = (event: MouseEvent<HTMLDivElement>, id: number) => {
        event.preventDefault();
        setPosition({ pageX: event.pageX, pageY: event.pageY });
        setContextId(id);
    };

    const selectIconClassName = [
        'mb-1 mr-2 self-start cursor-pointer m:hidden sticky top-4 bottom-4',
        isCompact ? '!my-auto' : 'mt-[10px]',
        isHovered || isSelected ? 'visible' : 'invisible',
    ].join(' ');

    const editedAtElement = (
        <TextTooltip placement="left">
            <Caption level="2"
                     weight="regular"
                     title={moment(message.editedAt).calendar()}
                     className={'text-subhead h-[16px]' + (isCompact ? 'absolute top-0' : 'right-2')}>
                {t('edit')}
            </Caption>
        </TextTooltip>
    );

    const statusElement = (
        <If is={isMine}>
            <div className={[
                'text-accent',
                isCompact ? 'relative' : 'absolute',
                message.editedAt && !isCompact ? 'left-[40px]' : 'left-0',
            ].join(' ')}>
                <SvgComponent svgProps={{
                    width: 18,
                    height: 18,
                }} element={(
                    <>
                        <If is={isRead}><Icon16CheckDoubleOutline/></If>
                        <If is={!isRead}>{chatMessageStatusIcon[status]}</If>
                    </>
                )}/>
            </div>
        </If>
    );

    const actionsElement = (
        <If is={!!unsentMessage}>
            <ActionButtonsContainer>
                <Button id="repeat"
                        size="s"
                        mode="tertiary"
                        loading={sendChatMessageLoading}
                        after={<Icon16Replay width={12} height={12} fill="var(--accent)"/>}
                        onClick={() => sendChatMessage({ ...unsentMessage!, ignoreCache: true }, () => {
                            store.removeUnsentMessage(chatId, messageId);
                        })}>
                    {t('repeat')}
                </Button>

                <Button size="s" id="delete" mode="tertiary" onClick={() => {
                    removeUnsentChatMessage(chatId, messageId);
                    store.removeUnsentMessage(chatId, messageId);
                }} after={<Icon16DeleteOutline width={12} height={12} fill="var(--dynamic_red)"/>}>
                    {t('delete')}
                </Button>
            </ActionButtonsContainer>
        </If>
    );

    return (
        <MessageReaderWrapperItem message={props.message}>
            <MessageContainer key={messageId}
                              data-id={messageId}
                              className={[ isSelected ? 'message-selected' : '' ].join(' ')}>
                <ClickAwayListener mouseEvent="onMouseDown"
                                   touchEvent="onTouchStart"
                                   onClickAway={() => setContextId(null)}>
                    <MessageItemContainer key={messageId}
                                          data-test="chat.message-item"
                                          onMouseLeave={() => isDesktop && isSent && setIsHovered(null)}
                                          onMouseEnter={() => isDesktop && isSent && setIsHovered(messageId)}
                                          onClick={() => !StringUtil.getSelection() && isSent && (
                                              store.handleSelectMessage({ message, chatId })
                                          )}
                                          className={[
                                              `cursor-pointer flex p-2 rounded-[6px] mb-[2px] bg-selectable-wrapper`,
                                              isSelected ? 'bg-selected' : '',
                                              isSending ? 'bg-hover' : '',
                                              isCompact ? 'compact-message py-0.5' : 'regular-message',
                                          ].join(' ')}
                                          onContextMenu={(e) => {
                                              onContextMenu(e, messageId);

                                              store.handleSelectMessage({ message, chatId, withResetAnother: true });
                                          }}>

                        {/** Select icon element */}
                        <>
                            <If is={!isSelected}>
                                <Icon28CheckCircleOff width={20}
                                                      height={20}
                                                      fill="var(--accent)"
                                                      className={selectIconClassName}/>
                            </If>

                            <If is={!!isSelected}>
                                <Icon28CheckCircleOn width={20}
                                                     height={20}
                                                     fill="var(--accent)"
                                                     className={selectIconClassName}/>
                            </If>

                            <If is={!!isHovered}>
                                <TextTooltip placement="top" text={t('answer')}>
                                    <Icon16Reply fill="var(--accent)"
                                                 className="absolute opacity-70 hover:opacity-100 z-20 top-[5px] right-[10px]"
                                                 onClick={(e) => {
                                                     e.preventDefault();
                                                     e.stopPropagation();

                                                     inputRef.current?.focus();
                                                     store.setInput('isEditing', 0);
                                                     store.setInput('replyMessage', {
                                                         ...input.replyMessage,
                                                         [chatId]: message,
                                                     });
                                                 }}/>
                                </TextTooltip>
                            </If>
                        </>

                        <If is={contextId === messageId}>
                            {/** Future message context menu */}

                            <div className="fixed z-20 next-release"
                                 onClick={() => setContextId(null)}
                                 style={{ top: position.pageY, left: position.pageX }}>
                                <div className="rounded-xl bg-content thin-border">
                                    <SimpleCell hasActive={false}
                                                className="!rounded-b-none"
                                                after={<Icon20CameraOutline className="ml-3"/>}>
                                        Action
                                    </SimpleCell>
                                </div>
                            </div>
                        </If>

                        <If is={!isCompact}>
                            <div className="h-fit" onClick={(e) => e.stopPropagation()}>
                                <UserAvatar hasLink
                                            size={34}
                                            lazy={false}
                                            userId={user.id}
                                            showUserOnlineBadge={false}
                                            name={user.profile.fullName}
                                            src={user.profile.avatar.medium || ''}
                                            className="mr-2.5 flex h-fit mt-[4px] sticky top-2 bottom-2"/>
                            </div>
                        </If>

                        <div className="flex flex-col flex-1">
                            <If is={!isCompact}>
                                <div className="flex items-center justify-between gap-3">
                                    <Link stopPropagation blank={ConfigStore.isDesktop} pushPage={{
                                        id: '/@:userId([0-9_A-Za-z]+)',
                                        params: { userId: `${user.id}` },
                                    }}>
                                        <Text className="text-accent fs-13" weight="semibold">
                                            <User.Name profile={user.profile}/>
                                        </Text>
                                    </Link>

                                    <If is={!isHovered}>
                                        <div className="flex items-center relative gap-2">
                                            <If is={!!message.editedAt}>
                                                {editedAtElement}
                                            </If>

                                            {statusElement}

                                            <Caption level="2"
                                                     weight="regular"
                                                     className="first-letter:capitalize text-subhead ml-5">
                                                {ChatService.parseChatDate(createdAt)}
                                            </Caption>
                                        </div>
                                    </If>
                                </div>
                            </If>

                            <div className={[
                                `flex flex-col fs-13 relative`,
                                messageTextClassName,
                                isCompact ? 'py-0.5 ml-[2.75rem]' : '',
                            ].join(' ')}>
                                <Text weight="regular" className={[
                                    `fs-14 m:fs-16`,
                                    isCompact && 'relative',
                                    messageTextClassName.includes('emoji') && 'w-full',
                                ].join(' ')}>
                                    <If is={!!replyMessage}>
                                        <ReplyItem replyMessage={replyMessage}/>
                                    </If>

                                    <If is={!!messageText}>
                                        <span data-test="chat.message-item.text"
                                              style={{ wordWrap: 'break-word' }}
                                              dangerouslySetInnerHTML={{ __html: ComponentHelper.sanitizeHtml(messageText) }}
                                              ref={(ref) => ref && handleInnerHtmlLinks(ref, (href) => redirect(href), true)}
                                              className="message-text pr-[56px] whitespace-pre-wrap flex items-center flex-wrap word-break"/>
                                    </If>

                                    <If is={!isHovered}>
                                        <div className="absolute right-0 top-[2px]">
                                            <If is={isCompact && !!message.editedAt}>
                                                <div className="mr-[20px]">
                                                    {editedAtElement}
                                                </div>
                                            </If>

                                            <If is={isCompact && !message.editedAt}>
                                                {statusElement}
                                            </If>

                                            <If is={isCompact && !!message.editedAt}>
                                                <div className="absolute right-0 top-0">
                                                    {statusElement}
                                                </div>
                                            </If>
                                        </div>
                                    </If>
                                </Text>

                                <If is={!!medias?.length}>
                                    <div className={!!messageText?.trim() || !isCompact ? 'mt-2' : ''}>
                                        <MessageMediaItem chatId={chatId} message={message}/>
                                    </div>
                                </If>
                            </div>
                        </div>
                    </MessageItemContainer>
                </ClickAwayListener>
            </MessageContainer>

            {actionsElement}
        </MessageReaderWrapperItem>
    );
});


export { MessageItem };
