/**
 * ManageSchoolWebhookModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';
import { Graphql, If, Modal } from '@/cutils';
import { useStableModalParams } from '@/router/index';

import { Title } from '@exode.ru/vkui';

import { WebhookEndpointFindOneQuery } from '@/codegen/graphql';

import { WebhookFormView } from '@/pages/Manage/School/Webhooks/views/WebhookFormView';


interface Props {
    id: string;
}


const ManageSchoolWebhookModal = (props: Props) => {

    const { t } = useI18n('modals.Manage.School.SchoolWebhook');

    const [ { endpointId } ] = useStableModalParams();

    return (
        <Modal.Card id={props.id} className="modal:w-550" subheader={t('enterLinkAndChooseEvents')} header={(
            <Title level="2" weight="3" className="modal-title">
                {!!endpointId ? t('webhookEdit') : t('webhookCreate')}
            </Title>
        )}>
            <Modal.Body fullHeight isScrollable>
                <If is={!endpointId}>
                    <WebhookFormView/>
                </If>

                <If is={!!endpointId}>
                    <WebhookEndpointFindOneQuery children={(result) => (
                        <>
                            <Graphql.Loading result={result}>
                                <Page.Spinner wrapperClassName="!m-3"/>
                            </Graphql.Loading>

                            <Graphql.Success result={result}>
                                {({ webhookEndpointFindOne: webhook }) => (
                                    <WebhookFormView webhook={webhook}/>
                                )}
                            </Graphql.Success>

                            <Graphql.Error result={result}/>
                        </>
                    )} variables={{ endpointId: +endpointId }}/>
                </If>
            </Modal.Body>
        </Modal.Card>
    );
};


export { ManageSchoolWebhookModal };
