/**
 * ChatMessagesView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { useRef, useState } from 'react';

import { observer, Page, useStore } from '@/pages/Core';
import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';

import {
    ChatException,
    ChatFindOneTopBarQuery,
    ChatMessageFindManyQuery,
    useChatFindOneTopBarQuery,
} from '@/codegen/graphql';

import { useI18n } from '@/hooks/core';
import { getGqlCause } from '@/api/graphql';
import { RoutePathType } from '@/router/paths';
import { ChatMessageItem } from '@/types/chat';
import { Graphql, If, Redirect } from '@/cutils';
import { useConfirmDeleteMessages } from '@/hooks/apollo';

import { ErrorFallback } from '@/components/App/ErrorFallback';

import { MessagesViewProvider } from '../contexts/MessagesViewContext';

import { InputView } from './InputView';
import { PinnedView } from './PinnedView';
import { MessagesView } from './MessagesView';
import { ChatNoMessagesView } from './ChatNoMessagesView';

import { TopBarPart } from '../parts/TopBarPart';
import { TopBarSkeleton } from '../parts/TopBarSkeleton';
import { TopBarBlankPart } from '../parts/TopBarBlankPart';


interface Props {
    personalUserId?: number;
    backOrTo?: RoutePathType;
    input?: {
        placeholder?: string;
    };
    blank?: {
        title: string;
        subtitle?: string;
        noMessageSubtitle?: string;

    };
}


const ChatMessagesView = observer((props: Props) => {

    const { backOrTo, personalUserId, blank } = props;

    const { t } = useI18n('pages.Chat.Dialog');

    const {
        store,
        input,
        list,
        filter,
        sort,
        params: {
            chatId,
            chatMessage,
            isTicketCreating,
        },
    } = useStore(ChatDialogsPageStore);

    const { data: topBarData } = useChatFindOneTopBarQuery({
        skip: !chatId,
        variables: { chatId: +chatId },
    });

    const [ pinnedMessages ] = useState<ChatMessageItem[]>([]);

    const { openConfirmDeleteMessages } = useConfirmDeleteMessages(+chatId);

    const myMemberIsBlocked = topBarData?.chatFindOne.myMember?.blocked;
    const companionMemberIsBlocked = topBarData?.chatFindOne.personalCompanionMember?.blocked;

    const inputRef = useRef<HTMLTextAreaElement | null>(null);
    const chatMessagesRef = useRef<HTMLDivElement | null>(null);

    /** Scroll to bottom (to last message) */
    const scrollToBottom = (smooth = false) => {
        chatMessagesRef.current?.scrollTo({
            left: 0,
            top: chatMessagesRef.current.scrollHeight,
            behavior: smooth ? 'smooth' : undefined,
        });
    };

    const resetSelectedMessage = () => (
        store.setSelectedMessages({})
    );

    const cancelEdit = () => {
        store.setInput('isEditing', 0);
        store.restoreDraftMessage(+chatId);

        resetSelectedMessage();
    };

    const handleEditMessage = () => {
        if (!chatId) {
            return;
        }

        store.mergeInput({
            replyMessage: _.omit(input.replyMessage, chatId),
        });

        store.mergeInput({
            message: store.state.selectedMessages[+chatId][0].text ?? '',
            isEditing: store.state.selectedMessages[+chatId][0].id,
        });

        resetSelectedMessage();

        inputRef.current?.focus();
    };

    const handleDeleteMessage = () => {
        openConfirmDeleteMessages();
    };

    const handleClearReply = (chatId: number | string) => {
        store.setInput('replyMessage', _.omit(input.replyMessage, `${chatId}`));
    };

    const handleImportantMessage = () => {

    };

    const handlePinMessage = () => {

    };

    const handleUnpinMessage = () => {

    };

    const handleReplyMessage = () => {
        if (!chatId || !store.state.selectedMessages[+chatId]) {
            return;
        }

        inputRef.current?.focus();
        store.setInput('isEditing', 0);

        store.setInput('replyMessage', {
            ...input.replyMessage,
            [chatId]: store.state.selectedMessages[+chatId]?.[0],
        });

        resetSelectedMessage();
    };

    return (
        <MessagesViewProvider value={{
            scrollToBottom,
            handleEditMessage,
        }}>
            <If is={!!chatId}>
                <div className="flex justify-between items-center py-2 px-2 thin-border-bottom h-[36px]">
                    <ChatFindOneTopBarQuery children={(result) => (
                        <>
                            <Graphql.Loading result={result}>
                                <TopBarSkeleton/>
                            </Graphql.Loading>

                            <Graphql.Success result={result}>
                                {({ chatFindOne: chat }) => (
                                    <TopBarPart chat={chat}
                                                backOrTo={backOrTo}
                                                handlePinMessage={handlePinMessage}
                                                handleEditMessage={handleEditMessage}
                                                handleReplyMessage={handleReplyMessage}
                                                handleDeleteMessage={handleDeleteMessage}
                                                handleImportantMessage={handleImportantMessage}/>

                                )}
                            </Graphql.Success>

                            <Graphql.Error result={result}>
                                {(error) => (
                                    <>
                                        <If is={getGqlCause(error) === ChatException.NotChatMember}>
                                            <></>
                                        </If>

                                        <If is={getGqlCause(error) !== ChatException.NotChatMember}>
                                            <TopBarSkeleton/>
                                        </If>
                                    </>
                                )}
                            </Graphql.Error>
                        </>
                    )} skip={!chatId} variables={{ chatId: +chatId }}/>
                </div>

                <ChatMessageFindManyQuery key={chatId} children={(result) => (
                    <>
                        <Graphql.Loading result={result}>
                            <Page.Spinner/>
                        </Graphql.Loading>

                        <Graphql.Success result={result}>
                            {({ chatMessageFindMany: { items: messages, cursor } }) => (
                                <>
                                    <If is={!!pinnedMessages.length}>
                                        <PinnedView messages={pinnedMessages} handleUnpinMessage={handleUnpinMessage}/>
                                    </If>

                                    <MessagesView cursor={cursor}
                                                  messages={messages}
                                                  inputRef={inputRef}
                                                  fetchMore={result.fetchMore}
                                                  chatMessagesRef={chatMessagesRef}
                                                  arePinned={!!pinnedMessages.length}
                                                  topBarData={topBarData?.chatFindOne}/>
                                </>
                            )}
                        </Graphql.Success>

                        <Graphql.Error result={result}>
                            {(error) => (
                                <>
                                    <If is={getGqlCause(error) === ChatException.NotChatMember}>
                                        <Redirect to="/chat" replace/>
                                    </If>

                                    <If is={getGqlCause(error) !== ChatException.NotChatMember}>
                                        <ErrorFallback message={error?.message} onClick={() => result.refetch()}/>
                                    </If>
                                </>
                            )}
                        </Graphql.Error>
                    </>
                )} variables={{
                    chatId: +chatId,
                    list: { ...list.messages },
                    filter: { ...filter.messages },
                    sort: { ...sort.messages },
                }}/>

                <InputView chatId={+chatId}
                           inputRef={inputRef}
                           value={chatMessage}
                           cancelEdit={cancelEdit}
                           handleClearReply={handleClearReply}
                           topBarData={topBarData?.chatFindOne}
                           resetSelectedMessage={resetSelectedMessage}
                           isBlocked={myMemberIsBlocked || companionMemberIsBlocked}
                           placeholder={(
                               myMemberIsBlocked
                                   ? t('messagesAreBlocked')
                                   : companionMemberIsBlocked ? t('youAreLimitedMessages') : ''
                           )}/>
            </If>

            <If is={!!personalUserId && !chatId || !!blank}>
                <div className="flex flex-col w-full relative">
                    <If is={!blank}>
                        <div className="flex justify-between items-center py-2 px-2 thin-border-bottom h-[36px]">
                            <TopBarPart backOrTo={backOrTo}
                                        personalUserId={personalUserId}
                                        handleEditMessage={handleEditMessage}
                                        handlePinMessage={handlePinMessage}
                                        handleReplyMessage={handleReplyMessage}
                                        handleDeleteMessage={handleDeleteMessage}
                                        handleImportantMessage={handleImportantMessage}/>
                        </div>
                    </If>

                    <If is={!!blank}>
                        <TopBarBlankPart title={`${blank?.title}`} subtitle={blank?.subtitle}/>
                    </If>

                    <ChatNoMessagesView subtitle={blank?.noMessageSubtitle}/>

                    <InputView inputRef={inputRef}
                               value={chatMessage}
                               cancelEdit={cancelEdit}
                               personalUserId={personalUserId}
                               handleClearReply={handleClearReply}
                               isTicketCreating={!!isTicketCreating}
                               placeholder={props.input?.placeholder}
                               resetSelectedMessage={resetSelectedMessage}/>
                </div>
            </If>
        </MessagesViewProvider>
    );
});


export { ChatMessagesView };
