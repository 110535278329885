/**
 * StudyProgressTab
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import moment from 'moment';

import React, { useEffect } from 'react';

import { ManageSchoolUsersPageStore } from '@/pages/Manage/School/Users/store';

import { Page } from '@/pages/Core';
import { ArrayUtil } from '@/utils';
import { Graphql, If } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { useStableModalParams } from '@/router/index';
import { CourseService } from '@/services/Course/Course';

import { ProductAccessFindOneQuery } from '@/codegen/graphql';

import { Header, InfoRow, List } from '@exode.ru/vkui';
import { Icon28EducationOutline } from '@vkontakte/icons';

import { Placeholder } from '@/components/Atoms/Placeholder';
import { InfoRowWrapper } from '@/components/Atoms/InfoRowWrapper';

import { UserEditTab } from '@/pages/Manage/School/Users';

import { StudyProgressLessonItem } from '../items/StudyProgressLessonItem';


const StudyProgressTab = () => {

    const [ { accessId } ] = useStableModalParams();

    const { setUserEditActiveTab } = ManageSchoolUsersPageStore;

    const { t } = useI18n('pages.Manage.School.Users.views.EditUserFormView');

    useEffect(() => {
        if (!accessId) {
            setUserEditActiveTab(UserEditTab.Study);
        }
    }, []);

    return (
        <ProductAccessFindOneQuery skip={!accessId} children={(result) => (
            <>
                <Graphql.Loading result={result}>
                    <Page.Spinner/>
                </Graphql.Loading>

                <Graphql.Success result={result}>
                    {({ productAccessFindOne: access }) => {
                        const {
                            price,
                            launch,
                            product,
                            participant,
                            product: { course },
                        } = access;

                        const lessons = CourseService.combineLessonsWithProgress(
                            access.product,
                            access.participant,
                        );

                        const currentLesson = CourseService.getCurrentLessonByProgress(
                            product,
                            participant,
                        );

                        const {
                            totalCount,
                            completedCount,
                            progressPercent,
                        } = CourseService.getLessonsAnalytics(lessons);

                        return (
                            <>
                                <Header mode="secondary" className="px-0">
                                    {t('information')}
                                </Header>

                                <InfoRowWrapper cells={[
                                    <InfoRow header={t('courseName')}>
                                        {course?.name}
                                    </InfoRow>,

                                    <InfoRow header={t('accessExpireAt')}>
                                        {access.expireAt
                                            ? moment(access.expireAt).format('D MMM YYYY HH:mm')
                                            : '—'
                                        }
                                    </InfoRow>,
                                ]}/>

                                <InfoRowWrapper className="mt-4" cells={[
                                    <InfoRow header={t('launchName')}>
                                        {launch?.name}
                                    </InfoRow>,

                                    <InfoRow header={t('priceName')}>
                                        {price?.title || '—'}
                                    </InfoRow>,
                                ]}/>

                                <Header mode="secondary" className="px-0">
                                    <div className="flex items-center gap-1">
                                        {t('progress')}
                                        <>{' • '}</>
                                        <>{progressPercent} % ({completedCount}/{totalCount})</>
                                    </div>
                                </Header>

                                <List className="flex flex-col gap-2">
                                    {ArrayUtil.groupByParent(
                                        lessons,
                                        'id',
                                        'parent.id',
                                    ).map((parent, index) => (
                                        <React.Fragment key={index}>
                                            <StudyProgressLessonItem lesson={parent}
                                                                     index={index + 1}
                                                                     currentLesson={currentLesson}/>

                                            <List className="flex flex-col gap-2 pl-5">
                                                {parent.items.map((lesson, index) => (
                                                    <StudyProgressLessonItem key={index}
                                                                             lesson={lesson}
                                                                             index={index + 1}
                                                                             currentLesson={currentLesson}/>
                                                ))}
                                            </List>
                                        </React.Fragment>
                                    ))}
                                </List>

                                <If is={_.isEmpty(course?.lessons)}>
                                    <Placeholder iconSize={72} header={t('progressNotExist')} icon={(
                                        <Icon28EducationOutline className="pt-0 text-accent opacity-[0.85]"/>
                                    )} content={(
                                        <span className="m:w-full w-9/10 flex mx-auto">
                                            {t('studentProgressWillAppearHere')}
                                        </span>
                                    )}/>
                                </If>
                            </>
                        );
                    }}
                </Graphql.Success>
            </>
        )} variables={{ accessId: +accessId }}/>
    );
};


export { StudyProgressTab };
