/**
 * School types
 *
 * @author: exode <hello@exode.ru>
 */

import { useI18n } from '@/hooks/core';

import { GqlResult } from '@/types/graphql';

import {
    SchoolAcquiringFindManyQueryResult,
    SchoolContactRole,
    SchoolFindOneManageQueryResult,
    SchoolFindOneQueryResult,
    WebhookEndpointFragmentFragment,
    WebhookEvent,
} from '@/codegen/graphql';


export type SchoolWebhookEndpointItem = WebhookEndpointFragmentFragment;
export type SchoolItem = GqlResult<SchoolFindOneQueryResult>['schoolFindOne'];
export type SchoolManageItem = GqlResult<SchoolFindOneManageQueryResult>['schoolFindOne'];
export type SchoolAcquiringListItem = NonNullable<GqlResult<SchoolAcquiringFindManyQueryResult>['schoolAcquiringFindMany']['items']>[number];

export const schoolContactRole = () => {

    const { t } = useI18n('types.school');

    return {
        [SchoolContactRole.Support]: t('support'),
        [SchoolContactRole.Manager]: t('manager'),
        [SchoolContactRole.Director]: t('director'),
    } as const;
};

export const schoolWebhookEvent = () => {

    const { t } = useI18n('types.school');

    return {
        [WebhookEvent.CourseCompleted]: {
            title: t('webhookCourseCompleted'),
            description: t('webhookCourseCompletedDescription'),
        },
        [WebhookEvent.CourseLessonPracticeDetailedSent]: {
            title: t('webhookCourseLessonPracticeDetailedSent'),
            description: t('webhookCourseLessonPracticeDetailedSentDescription'),
        },
        [WebhookEvent.CourseLessonPracticeAutoVerifySent]: {
            title: t('webhookCourseLessonPracticeAutoVerifySent'),
            description: t('webhookCourseLessonPracticeAutoVerifySentDescription'),
        },
        [WebhookEvent.CourseProgressChanged]: {
            title: t('webhookCourseProgressChanged'),
            description: t('webhookCourseProgressChangedDescription'),
        },
        [WebhookEvent.UserSignedUp]: {
            title: t('webhookUserSignedUp'),
            description: t('webhookUserSignedUpDescription'),
        },
        [WebhookEvent.UserJoinedByReferral]: {
            title: t('webhookUserJoinedByReferral'),
            description: t('webhookUserJoinedByReferralDescription'),
        },
        [WebhookEvent.UserCreatedViaLms]: {
            title: t('webhookUserCreatedViaLms'),
            description: t('webhookUserCreatedViaLmsDescription'),
        },
        [WebhookEvent.UserSignedIn]: {
            title: t('webhookUserSignedIn'),
            description: t('webhookUserSignedInDescription'),
        },
        [WebhookEvent.UserLoggedOut]: {
            title: t('webhookUserLoggedOut'),
            description: t('webhookUserLoggedOutDescription'),
        },
        [WebhookEvent.ProductEnrolledViaLms]: {
            title: t('webhookProductEnrolledViaLms'),
            description: t('webhookProductEnrolledViaLmsDescription'),
        },
        [WebhookEvent.ProductEnrolledViaPayment]: {
            title: t('webhookProductEnrolledViaPayment'),
            description: t('webhookProductEnrolledViaPaymentDescription'),
        },
        [WebhookEvent.ProductRefundCompleted]: {
            title: t('webhookProductRefundCompleted'),
            description: t('webhookProductRefundCompletedDescription'),
        },
        [WebhookEvent.ProductAccessSubscriptionEnding7Days]: {
            title: t('webhookProductAccessSubscriptionEnding7Days'),
            description: t('webhookProductAccessSubscriptionEnding7DaysDescription'),
        },
        [WebhookEvent.ProductAccessSubscriptionEnding1Day]: {
            title: t('webhookProductAccessSubscriptionEnding1Day'),
            description: t('webhookProductAccessSubscriptionEnding1DayDescription'),
        },
        [WebhookEvent.PaymentCompleted]: {
            title: t('webhookPaymentCompleted'),
            description: t('webhookPaymentCompletedDescription'),
        },
    } as const;
};
