/**
 * HeaderView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, PageStore } from '@/store/core/page';

import { Link } from '@/cutils';
import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';

import { Button } from '@exode.ru/vkui';
import { Icon24AddSquareOutline } from '@vkontakte/icons';

import { PageModeSwitcher } from '@/components/Desktop/Navigation';


const HeaderView = observer(() => {

    const { t } = useI18n('pages.Manage.School.Users.views');

    return (
        <Page.MainHeader title={t('allSchoolUsers')}
                         stacked={PageStore.mode === 'slim-menu'}
                         subtitle={t('fullUserInformationOfYourSchool')}
                         actions={(
                             <PageModeSwitcher>
                                 <Link pushModal={{ id: 'manage-user-create' }}>
                                     <Button size="m"
                                             mode="commerce"
                                             data-test="user.add"
                                             before={<Icon24AddSquareOutline/>}>
                                         {t('addUsers')}
                                     </Button>
                                 </Link>
                             </PageModeSwitcher>
                         )}/>
    );
});


export { HeaderView };
