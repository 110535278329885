/**
 * HeaderLogoPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PageStore } from '@/store/core/page';
import { SchoolStore } from '@/store/platform';
import { PreferenceStore } from '@/store/preference/preference';
import { observer, UserAuthStore } from '@/store/user/auth';

import { PageSpace } from '@/shared/types';
import { If, Link, Platform } from '@/cutils';
import { mainPageRouting } from '@/tabs/Main';

import { SchoolBlockMode } from '@/codegen/graphql';

import { Title } from '@exode.ru/vkui';

import { PlatformLogo } from '@/components/Platform/PlatformLogo';


const HeaderLogoPart = observer(() => {
    return (
        <Link pushPage={{ id: mainPageRouting('logo', UserAuthStore.isLoggedIn) }}>
            <div className="flex items-center select-none">
                <PlatformLogo/>

                <Title level="2" weight="3" className={[
                    'vkuiPanelHeader__content-in PanelHeader__content-in fs-23 mb-1',
                    PageStore.mode === 'slim-menu' ? 'ldo:hidden' : '',
                ].join(' ')}>
                    <span className="block font-vk-sans-display max-w-[200px] pt-[1.5px] overflow-ellipsis">
                        <Platform.If platforms={[
                            PageSpace.MarketplacePlatform,
                            PageSpace.BizWelcomePlatform,
                        ]}>
                            <>exode</>
                        </Platform.If>

                        <Platform.School>
                            <If is={SchoolStore.blocks?.logo?.mode === SchoolBlockMode.Text}>
                                <>{SchoolStore.blocks?.logo?.text}</>
                            </If>

                            <If is={SchoolStore.blocks?.logo?.mode === SchoolBlockMode.Image}>
                                <If is={!SchoolStore.blocks?.logo?.url}>
                                    <>{SchoolStore.blocks?.logo?.text}</>
                                </If>

                                <If is={!!SchoolStore.blocks?.logo?.url}>
                                    <img alt="school-logo"
                                         src={SchoolStore.blocks?.logo?.url || ''}
                                         style={{ filter: PreferenceStore.isDark ? 'invert(1)' : 'none' }}/>
                                </If>
                            </If>
                        </Platform.School>
                    </span>
                </Title>
            </div>
        </Link>
    );
});


export { HeaderLogoPart };
