/**
 * CatalogView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { CatalogTabStore } from '../store';

import { Graphql, If } from '@/cutils';
import { usePagination } from '@/hooks/core';
import { observer, useStore } from '@/pages/Core';

import { CourseFindManyQuery } from '@/codegen/graphql';

import { Pagination } from '@/components/Atoms/Pagination';
import { CourseCard, CourseGrid } from '@/components/Course/CourseCard';
import { CatalogTabContentSkeleton } from '@/components/Atoms/Skeleton/CatalogTabContent';

import { EmptyCourseListView } from '@/tabs/Education/views/EmptyCourseListView';

import { CatalogCoursesHeaderItem } from '../items/CatalogCoursesHeaderItem';
import { catalogCourseCardCrossPageParams, useCatalogCourseFilter } from '../filter';


const CatalogCoursesView = observer(() => {

    const { store, sort } = useStore(CatalogTabStore);

    const { dtoFilter } = useCatalogCourseFilter();

    const { page, list, onPageChange } = usePagination({
        store,
        path: '/catalog/:page([0-9]+)',
    });

    return (
        <div className="viewport__container mx-auto w-full">
            <CourseFindManyQuery children={(result) => (
                <>
                    <Graphql.Loading result={result}>
                        <CatalogCoursesHeaderItem isLoading count={0}/>

                        <CatalogTabContentSkeleton count={15}/>
                    </Graphql.Loading>

                    <Graphql.Success result={result}>
                        {({ courseFindMany: { pages, items, count } }) => (
                            <>
                                <CatalogCoursesHeaderItem count={count}/>

                                <CourseGrid>
                                    {items?.map((course) => (
                                        <CourseCard colorful
                                                    key={course.id}
                                                    course={course}
                                                    pickRouteParams={catalogCourseCardCrossPageParams}
                                                    mode={course.currentLesson ? 'progress' : 'default'}/>
                                    ))}
                                </CourseGrid>

                                <Pagination boundaryCount={1}
                                            currentPage={page}
                                            totalPages={pages}
                                            className="mt-4 d:mt-8"
                                            onChange={(value) => onPageChange(value, result.refetch)}/>

                                <If is={!items || _.isEmpty(items)}>
                                    <EmptyCourseListView/>
                                </If>
                            </>
                        )}
                    </Graphql.Success>

                    <Graphql.Error result={result}/>
                </>
            )} variables={{
                filter: dtoFilter,
                list: { ...list },
                sort: { ...sort },
            }}/>
        </div>
    );
});


export { CatalogCoursesView };
