/**
 * ChatDialogsPage
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactNode } from 'react';

import { observer, Page } from '@/pages/Core';
import { ConfigStore } from '@/store/core/config';

import { ChatFindOnePersonalQuery } from '@/codegen/graphql';

import { useI18n } from '@/hooks/core';
import { useLocation } from '@/router/index';
import { Graphql, If, Responsive } from '@/cutils';

import { ListPanelView } from './views/ListPanelView';
import { ChatMessagesView } from './views/ChatMessagesView';
import { PersonalUserView } from './views/PersonalUserView';
import { ChatNotOpenedView } from './views/ChatNotOpenedView';


export interface ChatDialogsPageProps {
    listPanel?: {
        action?: ReactNode;
        filters?: ReactNode;
        search?: ReactNode;
        listPanel?: ReactNode;
    };
}


const ChatDialogsPage = observer((props: ChatDialogsPageProps) => {

    const { route: { pageId, params } } = useLocation();

    const {
        chatId,
        personalUserId,
        blankTitle,
        blankSubtitle,
        blankPlaceholder,
        blankNoMessageSubtitle,
    } = params;

    const {
        listPanel,
        listPanel: {
            listPanel: listPanelView = (
                <ListPanelView activeChatId={+chatId} listPanel={listPanel}/>
            ),
        } = {},
    } = props;

    const { t } = useI18n('pages.Chat.Dialog');

    return (
        <Page.Wrapper removeBottomSpace mode="fillable">
            <Page.Head>
                <Page.Header title={t('messenger')} backTo={(
                    pageId === '/chat/attachments'
                        ? `/chat?chatId=${chatId}`
                        : chatId && pageId === '/chat'
                            ? '/chat'
                            : '/menu'
                )}/>
            </Page.Head>

            <Page.Content>
                <Page.Row fullHeight innerNoVerticalPadding fullSize={ConfigStore.isDesktop} className="py-0">
                    <div className="flex flex-1">
                        <Responsive.Mobile>
                            <If is={!chatId && !personalUserId && !blankTitle}>
                                <>{listPanelView}</>
                            </If>

                            <If is={!!chatId}>
                                <div className="flex flex-col justify-between m:flex-1 w-full relative d-thin-border">
                                    <ChatMessagesView backOrTo="/chat"/>
                                </div>
                            </If>

                            <If is={!!personalUserId}>
                                <ChatFindOnePersonalQuery fetchPolicy="network-only" children={(result) => (
                                    <>
                                        <Graphql.Loading result={result}>
                                            <Page.Spinner/>
                                        </Graphql.Loading>

                                        <Graphql.Success result={result}>
                                            {({ chatFindOnePersonal: chat }) => (
                                                <PersonalUserView personalUserId={+personalUserId} chat={chat}/>
                                            )}
                                        </Graphql.Success>

                                        <Graphql.Error result={result}>
                                            <ChatMessagesView personalUserId={+personalUserId}/>
                                        </Graphql.Error>
                                    </>
                                )} variables={{ userId: +personalUserId }}/>
                            </If>

                            <If is={!!blankTitle}>
                                <ChatMessagesView input={{ placeholder: blankPlaceholder }} blank={{
                                    title: blankTitle,
                                    subtitle: blankSubtitle,
                                    noMessageSubtitle: blankNoMessageSubtitle,
                                }}/>
                            </If>
                        </Responsive.Mobile>

                        <Responsive.Desktop>
                            <>{listPanelView}</>

                            <If is={!!chatId}>
                                <div className="flex flex-col justify-between w-full relative">
                                    <ChatMessagesView/>
                                </div>
                            </If>

                            <If is={!!personalUserId}>
                                <ChatFindOnePersonalQuery fetchPolicy="network-only" children={(result) => (
                                    <>
                                        <Graphql.Loading result={result}>
                                            <Page.Spinner/>
                                        </Graphql.Loading>

                                        <Graphql.Success result={result}>
                                            {({ chatFindOnePersonal: chat }) => (
                                                <PersonalUserView personalUserId={+personalUserId} chat={chat}/>
                                            )}
                                        </Graphql.Success>

                                        <Graphql.Error result={result}>
                                            <ChatMessagesView personalUserId={+personalUserId}/>
                                        </Graphql.Error>
                                    </>
                                )} variables={{ userId: +personalUserId }}/>
                            </If>

                            <If is={!!blankTitle}>
                                <ChatMessagesView input={{
                                    placeholder: blankPlaceholder,
                                }} blank={{
                                    title: blankTitle,
                                    subtitle: blankSubtitle,
                                    noMessageSubtitle: blankNoMessageSubtitle,
                                }}/>
                            </If>

                            <If is={!chatId && !personalUserId && !blankTitle}>
                                <ChatNotOpenedView/>
                            </If>
                        </Responsive.Desktop>
                    </div>
                </Page.Row>
            </Page.Content>
        </Page.Wrapper>
    );
});


export { ChatDialogsPage };
