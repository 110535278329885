/**
 * Router
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { MouseEvent } from 'react';

import i18n from 'i18next';

import { RouterStore } from '@/store/core/router';
import { UserAuthStore } from '@/store/user/auth';
import { ConfigStore } from '@/store/core/config';

import { CURRENT_PLATFORM, SUBDOMAIN } from '@/root/src/env';

import { PageParams } from 'router.tsx';
import { router } from '@/router/index';
import { Routes } from '@/router/routes';

import { Storage } from '@/api/storage';
import { ModalTypes } from '@/modals/index';
import { Permissions } from '@/types/rbac';
import { PageSpace } from '@/shared/types';
import { RoutePathType } from '@/router/paths';
import { DocumentEvent } from '@/types/window';
import { Permission, UserStateKey } from '@/codegen/graphql';
import { RouterEventType, RouteType } from '@/types/router';

import {
    DrawerSectionType,
    manageContextMenu,
    ManageContextMenu,
    ManageDrawerSections,
    manageDrawerSections,
} from '@/types/manage';

import { matchPath } from '@/router/helper';
import { getUserState } from '@/hooks/apollo';
import { ObjectUtil, Time, Url } from '@/utils';
import { waitDocumentEvent } from '@/helpers/ui';
import { UserRbacService } from '@/services/User/Rbac';


export interface PageAction {
    id: RoutePathType;
    params?: PageParams;
}


class Router {

    /**
     * Интервал в миллисекундах
     */
    static timeout = 600;

    /**
     * Получение состояния роутинга с хука
     * @returns {Router}
     */
    private static get router() {
        return router;
    }

    /**
     * Get current url params
     * @returns {string}
     */
    static get urlQueryParams() {
        return Url.objectToQuery(this.routerParams);
    }

    /**
     * Параметры в типе объекта (page params + router params)
     * @returns {PageParams}
     */
    static get routerParams() {
        return _.cloneDeep(RouterStore.params);
    }

    /**
     * Ожидание обновление роутера
     * @returns {Promise<void>}
     */
    static async afterUpdate() {
        return this.router.afterUpdate();
    }

    /**
     * Проверяем заполненность общих параметров
     * @param {RoutePathType} pageId
     * @param {PageParams} params
     * @returns {PageParams}
     */
    static checkParams(
        pageId: RoutePathType,
        params: PageParams = {},
    ) {
        if (pageId.includes('/:page([0-9]+)') && params && !params?.page) {
            params.page = '1';
        }

        return params;
    }

    /**
     * Сравнение страниц на тождественность (по id, параметрам, равенство)
     * @param {RouteType} next
     * @param {RouteType} prev
     * @returns {{equal: boolean, pageIsSame: boolean, paramsIsSame: boolean}}
     * @private
     */
    static comparePages(
        next: Partial<RouteType>,
        prev?: Partial<RouteType>,
    ) {
        const pageIsSame = next.pageId === prev?.pageId;
        const paramsIsSame = ObjectUtil.isEqual(next.params, prev?.params);

        return { pageIsSame, paramsIsSame, equal: pageIsSame && paramsIsSame };
    }

    /**
     * Переход на страницу с заменой в стеке истории
     * @param {RoutePathType} pageId
     * @param {PageParams} params
     * @param {boolean} closeModal
     */
    static replacePage(
        pageId: RoutePathType,
        params?: PageParams,
        closeModal?: boolean,
    ) {
        if (closeModal && RouterStore.hasOverlay) {
            (async () => {
                this.replaceModal();

                await this.router.afterUpdate();

                return this.router.replacePage(pageId, this.checkParams(pageId, params));
            })();

            return;
        }

        return this.router.replacePage(pageId, this.checkParams(pageId, params));
    }

    /**
     * Закрытие модального окна или переход на страницу с заменой в стеке истории
     * @param {RoutePathType} pageId
     * @param {PageParams} params
     */
    static replaceModalOrPushPage(
        pageId: RoutePathType,
        params?: PageParams,
    ) {
        if (this.hasModal()) {
            return this.replaceModal();
        }

        return this.replacePage(pageId, params);
    }

    /**
     * Переход на модальное окно только после входа
     * @param {RoutePathType} pageId
     * @param {ModalTypes} modalId
     * @param {PageParams} params
     * @param {PageParams} toModalParams
     */
    static replaceToModalAfterLogin(
        pageId: RoutePathType,
        modalId?: ModalTypes,
        params?: PageParams,
        toModalParams?: PageParams,
    ) {
        const exclude = [ 'to', 'toParams', 'toModalParams' ];

        if (modalId && UserAuthStore.isLoggedIn) {
            return this.pushModal(modalId, _.omit(toModalParams, exclude));
        }

        this.replacePage('/login', {
            ..._.omit(params, exclude),
            to: pageId,
            toModalParams: Url.objectToQuery({
                modal: modalId,
                ..._.omit(toModalParams, exclude),
                ..._.omit(this.routerParams, exclude),
            }),
        });
    }

    /**
     * Переход на страницу с добавлением в стек истории
     * @param {RoutePathType} pageId
     * @param {PageParams} params
     * @param {boolean} closeModal
     * @param {React.MouseEvent} event
     */
    static pushPage(
        pageId: RoutePathType,
        params?: PageParams,
        closeModal?: boolean,
        event?: MouseEvent,
    ) {
        const isSamePage = pageId === RouterStore.pageId && this.paramsIsEqual(params);

        if (!closeModal && isSamePage) {
            return;
        }

        if (closeModal && RouterStore.hasOverlay) {
            (async () => {
                this.replaceModal();

                await this.router.afterUpdate();

                if (isSamePage) {
                    return;
                }

                return this.router.pushPage(pageId, this.checkParams(pageId, params));
            })();

            return;
        }

        if (event?.ctrlKey || event?.metaKey) {
            return window.open(this.compileLink({ id: pageId, params }));
        }

        return this.router.pushPage(pageId, this.checkParams(pageId, params));
    }

    /**
     * Получение реальной ссылки
     * @param {PageAction} pageAction
     */
    static compileLink(pageAction: PageAction) {
        return this.router.compile(
            pageAction.id,
            Router.checkParams(pageAction.id, pageAction.params),
        );
    }

    /**
     * Push on desktop, replace on mobile
     * @param {RoutePathType} pageId
     * @param {PageParams} params
     * @param {boolean} closeModal
     * @returns {undefined | void}
     */
    static pushOnDReplaceOnM(
        pageId: RoutePathType,
        params?: PageParams,
        closeModal?: boolean,
    ) {
        return this[ConfigStore.isDesktop ? 'pushPage' : 'replacePage'](
            pageId,
            params,
            closeModal,
        );
    }

    /**
     * Открытие модального окна
     * @param {ModalTypes} modalId
     * @param {PageParams} params
     */
    static pushModal(
        modalId: ModalTypes,
        params?: PageParams,
    ) {
        return this.router.pushModal(modalId, params);
    }

    /**
     * Открытие popout
     * @param {string} popoutId
     * @param {PageParams} params
     */
    static pushPopout(
        popoutId: string,
        params?: PageParams,
    ) {
        return this.router.pushPopup(popoutId, params);
    }

    /**
     * Закрытие модального окна
     */
    static replaceModal() {
        return RouterStore.isModal && this.router.popPage();
    }

    /**
     * Закрытие модального окна (отложенное)
     * @param {number} timeout
     * @param {boolean} before
     * @returns {Promise<void>}
     */
    static async replaceModalWithTimeout(
        timeout = this.timeout,
        before = false,
    ) {
        before && await Time.timer(timeout);

        this.replaceModal();

        await Time.timer(timeout);
    }

    /**
     * Закрытие popout
     */
    static replacePopout() {
        return this.router.popPage();
    }

    /**
     * Изменение параметров для текущей страницы
     * @param {PageParams} params
     */
    static updateParams(params?: PageParams) {
        params = { ...RouterStore.params, ...params };

        return this.router.replacePage(RouterStore.pageId, params);
    }

    /**
     * Сравнение состояния и определение новой страницы, новых параметров
     * @param {RouterEventType} e
     * @returns {{pageIsSame: boolean, paramsIsSame: boolean}}
     */
    static compareRouterState(e: RouterEventType) {
        const { next, prev } = e.detail;

        return this.comparePages(next, prev);
    }

    /**
     * Сравнение текущих параметров и новых на тождественность
     * @param params
     * @returns {boolean}
     */
    static paramsIsEqual(params: PageParams | undefined) {
        return ObjectUtil.isEqual(this.routerParams, params);
    }

    /**
     * Проверка открыто ли модальное окно
     * @param {ModalTypes} modal
     * @returns {boolean}
     */
    static hasModal(modal?: ModalTypes) {
        return modal ? RouterStore.activeModal === modal : !!RouterStore.activeModal;
    }

    /**
     * Открытие главной страницы или редирект после входа в аккаунт (или регистрации)
     * @param {PageParams} params
     */
    static async openAfterLogin(params?: PageParams) {
        const pageId = params?.to || '/';
        const pageParams = params?.toParams || '';

        /** Case: check PersonalInfoFilled */
        const {
            value: personalInfoFilled,
        } = await getUserState(UserStateKey.PersonalInfoFilled);

        if (!personalInfoFilled) {
            ConfigStore.isDesktop
                ? this.pushModal('user-main-info', params)
                : this.replacePage('/profile/main-info', params);

            await waitDocumentEvent(DocumentEvent.ProfilePersonalInfoFilledSuccess);
        }

        /** Continue to open page */

        if (params?.toModalParams) {
            const { modal, ...rest } = Url.parseQuery(params.toModalParams);

            this.replacePage(pageId as RoutePathType);
            await this.afterUpdate();

            this.pushModal(modal, { ...rest });
        } else {
            this.replacePage(
                pageId as RoutePathType,
                Url.parseQuery(pageParams) as PageParams,
            );
        }
    }

    /**
     * Получение состояние переадресация при входе через провайдеры (VK/Apple)
     * @param {string} query
     * @returns {string}
     */
    static getStateAfterLogin(query = '') {
        return [
            query,
            Url.objectToQuery({
                ...this.routerParams,
                /** Rewrite state param for auth providers (redirect to correct subdomain) */
                state: [
                    this.routerParams.state,
                    `school:${window.exode.school?.id}`,
                    `language:${Storage.get('user:language')}`,
                    `subdomain:${!this.isDefaultSubdomain() ? `${SUBDOMAIN}.` : ''}`,
                ].filter(e => e).join(','),
            }),
        ].filter(e => e).join('&');
    }

    /**
     * Проверка subdomain дефолтный
     * @param {string | undefined} subdomain
     * @returns {boolean}
     */
    static isDefaultSubdomain(
        subdomain = SUBDOMAIN,
    ) {
        return [
            'exode',
            'staging',
            'localhost',
        ].includes(subdomain);
    }

    /**
     * Обертка только для выполнения авторизованных действий
     * @param {() => void} cb
     * @param additionalToParams
     */
    static authAction(
        cb: () => void,
        additionalToParams?: Record<any, any>,
    ) {
        if (!UserAuthStore.isLoggedIn) {
            this.replacePage('/login', {
                to: RouterStore.pageId,
                toParams: Url.objectToQuery({
                    ...this.routerParams,
                    ...additionalToParams,
                }),
            });

            return;
        }

        return cb();
    }

    /**
     * Фильтрация по space
     * @param {PageSpace[]} pageSpaces
     */
    static filterBySpaces(pageSpaces?: PageSpace[]) {
        let show = true;

        if (!pageSpaces || !pageSpaces.length) {
            return show;
        }

        /** Filter by platform */
        const platformSpaces = [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.BizWelcomePlatform,
        ];

        const isDependByPlatform = _.intersection(platformSpaces, pageSpaces).length > 0;

        show = show && (!isDependByPlatform || isDependByPlatform && pageSpaces.includes(CURRENT_PLATFORM));

        return show;
    }

    /**
     * Фильтрация по правам доступа
     * @param {Permissions} permissions
     */
    static filterByPermissions(permissions?: Permissions) {
        return UserRbacService.canAny(UserAuthStore.combinedPermissions, ...permissions || []);
    }

    /**
     * Фильтрация по page id
     * @param {RoutePathType} pageId
     */
    static filterByPageId(
        pageId: RoutePathType | undefined | null,
    ) {
        return pageId
            && this.filterBySpaces(Routes[pageId]?.spaces)
            && this.filterByPermissions(Routes[pageId]?.auth);
    }

    /**
     * Активность по page spaces
     * @param {PageSpace[]} navigationSpaces
     */
    static isActiveBySpaces(
        navigationSpaces?: PageSpace[],
    ) {
        return _.intersection(navigationSpaces, Routes[RouterStore.pageId]?.spaces).length > 0;
    }

    /**
     * Получение первого элемента по drawer sections
     */
    static getFirstManageDrawerItem() {
        const sections = manageDrawerSections(i18n.t);

        for (const [ section, { items } ] of _.entries(sections)) {
            if (!_.keys(items)?.length) {
                continue;
            }

            return this.getFirstManageDrawerSectionItem(
                sections,
                section as DrawerSectionType,
            );
        }
    }

    /**
     * Получение первого элемента в конкретной секции
     * @param {ManageDrawerSections} sections
     * @param {DrawerSectionType} section
     */
    static getFirstManageDrawerSectionItem(
        sections: ManageDrawerSections,
        section: DrawerSectionType,
    ) {
        const { items } = sections[section];

        for (const [ k, { path } ] of _.entries(items)) {
            if (path) {
                return items[k];
            }
        }
    }

    /**
     * Получение первого доступного context menu элемента
     * @param {keyof ManageContextMenu} section
     * @Note: can return null
     */
    static getFirstContextMenuItem(
        section: keyof ManageContextMenu,
    ) {
        return manageContextMenu(RouterStore.params)[section].items[0];
    }

    /**
     * Получение перечня прав context menu секции
     * @param {keyof ManageContextMenu} section
     * @Note: can return empty array
     */
    static getContextMenuSectionPermissions(
        section: keyof ManageContextMenu,
    ): Permission[] {
        const { items } = manageContextMenu({})[section];

        return _.uniq([
            ..._.flatten(
                items.map((e) => (
                    _.flatten(e.path && Routes[e.path.id]?.auth || [])
                        .filter(e => e) as unknown as Permission
                )),
            ),
            ..._.flatten(
                items.map((e) => (
                    _.flatten(e.subfields?.map((e) => (
                            _.flatten(Routes[e.path.id]?.auth)
                                .filter(e => e)
                        ),
                    )) || []
                )),
            ),
        ]) as Permission[];
    }

    /**
     * Поиск совпадения url с path всех страниц
     * @param {string} link
     */
    static matchLinkPathAppRoute(link: string) {
        try {
            return matchPath(
                link.includes('http')
                    ? new URL(link).href
                    : link,
            );
        } catch (error) {
            return undefined;
        }
    }

}


export { Router };
