/**
 * WordListAtom
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useDroppable } from '@dnd-kit/core';

import { If } from '@/cutils';
import { useFixedDimensions, useI18n } from '@/hooks/core';

import { useTaskBuilderContext } from '@/components/Task/Builder';

import { Skip } from '../types';
import { DraggableWordAtom } from './DraggableWordAtom';


interface Props {
    words: Skip[];
    selectedWordId: string | null;
    onWordClick: (wordId: string) => void;
    onDrop?: () => void;
}


const WordListAtom = (props: Props) => {

    const { words, selectedWordId, onWordClick } = props;

    const { t } = useI18n('components.Task.Builder.parts.answer');

    const { show: { subMode } } = useTaskBuilderContext();

    const { setNodeRef, isOver } = useDroppable({
        id: 'word-list',
    });

    const {
        ref: heightRef,
        style: heightStyle,
    } = useFixedDimensions({ fixHeight: true });

    return (
        <div ref={setNodeRef} className={[
            'thin-border vk-rounded min-h-[35px]',
            isOver ? 'bg-hover' : 'bg-hover-50',
        ].join(' ')}>
            <div ref={heightRef} style={heightStyle}>
                <div className="flex flex-wrap gap-3 p-4">
                    {words.map((word) => (
                        <DraggableWordAtom key={word.uuid}
                                           id={word.uuid}
                                           text={word.text}
                                           onClick={() => onWordClick(word.uuid)}
                                           isSelected={selectedWordId === word.uuid}/>
                    ))}

                    <If is={!words?.length}>
                        <div className={[
                            'flex flex-1 items-center justify-center',
                            'text-secondary fs-13 m-auto text-center',
                        ].join(' ')}>
                            {subMode === 'preview'
                                ? t('forPreviewSelfArranged')
                                : t('greatAllWordsArranged')
                            }
                        </div>
                    </If>
                </div>
            </div>
        </div>
    );
};


export { WordListAtom };
