/**
 * Filter useSellerManagersFilter
 *
 * @author: exode <hello@exode.ru>
 */

import { useUrlFilters } from '@/hooks/core';

import { ManageSellerManagersPageStore } from './store';

import { Permission, SellerManagerType } from '@/codegen/graphql';


export const defaultFilter = {
    search: '',
    types: [ SellerManagerType.Seller ],
    active: null as unknown as boolean,
    productIds: [] as number[],
    permissions: [] as Permission[],
    combinedPermissions: [] as Permission[],
};

export const dtoFilterTransformer = (filter: typeof defaultFilter) => ({
    ...filter,
    types: filter.types?.length ? filter.types : undefined,
});

export const useSellerManagersFilter = () => useUrlFilters(
    { ...defaultFilter },
    [],
    dtoFilterTransformer,
    {
        filterField: 'managers',
        store: ManageSellerManagersPageStore,
    },
);
