/**
 * ManageProductDiscountsPage
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';

import { DiscountManageFindManyDocument } from '@/codegen/graphql';

import { MainContentView } from './views/MainContentView';


const ManageProductDiscountsPage = () => {

    const { t } = useI18n('pages.Manage.Product.Discount');

    return (
        <Page.Wrapper>
            <Page.Head>
                <Page.Header title={t('promoCodes')} refetchDocs={[ DiscountManageFindManyDocument ]}/>
            </Page.Head>

            <Page.Content>
                <MainContentView/>
            </Page.Content>
        </Page.Wrapper>
    );
};


export { ManageProductDiscountsPage };
