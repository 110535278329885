/**
 * UseDndSensors
 *
 * @author: exode <hello@exode.ru>
 */

import { PointerSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';


export const useDndSensors = () => {
    return useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
            },
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                distance: 10,
            },
        }),
    );
};

