/**
 * Filter useSchoolManageWebhooksFilter
 *
 * @author: exode <hello@exode.ru>
 */

import { useUrlFilters } from '@/hooks/core';

import { ManageSchoolWebhooksPageStore } from './store';

import { WebhookEvent } from '@/codegen/graphql';


export const defaultFilter = {
    events: [] as WebhookEvent[],
};

export const dtoFilterTransformer = (filter: typeof defaultFilter) => filter;


export const useSchoolManageWebhooksFilter = () => useUrlFilters(
    { ...defaultFilter },
    [],
    dtoFilterTransformer,
    {
        store: ManageSchoolWebhooksPageStore,
    },
);
