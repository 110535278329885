/**
 * CeContentWrapper styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { responsive } from '@/styles/modules/responsive';


const CeContentWrapper = styled.div`
    ${responsive([ 'gt-tablet' ])} {
        [data-component="BlockTitle"] {
            font-size: 24px;
        }

        .Group__inner {
            padding: 0 !important;

            > section {
                padding: 0 !important;
            }

            &:after {
                display: none;
            }
        }
    }
`;


export { CeContentWrapper };
