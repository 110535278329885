/**
 * StudyProgressLessonItem
 *
 * @author: exode <hello@exode.ru>
 */

import moment from 'moment';

import React from 'react';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { ProductAccessProgressItem } from '@/types/product';
import { CourseProgressLessonStatus } from '@/codegen/graphql';
import { CourseProgressItem, lessonProgressStatus, lessonProgressStatusIcon } from '@/types/course';

import { Avatar, RichCell } from '@exode.ru/vkui';
import { Icon28ArrowRightOutline } from '@vkontakte/icons';

import { TagInlineText } from '@/components/Atoms/TagInlineText';
import { HomeWorkTagPart, VideoTagPart } from '@/components/Course/LessonCard';


interface Props {
    index: number;
    lesson: NonNullable<ProductAccessProgressItem['product']['course']>['lessons'][number] & {
        myProgressStatus?: CourseProgressLessonStatus;
        lessonProgress?: CourseProgressItem;
    };
    /** Текущий урок из course progress */
    currentLesson?: CourseProgressItem['lesson'] | null;
}


const StudyProgressLessonItem = (props: Props) => {

    const {
        index,
        lesson,
        currentLesson,
        lesson: { myProgressStatus },
    } = props;

    const { t } = useI18n('pages.Manage.School.Users.views.EditUserFormView');

    const lessonProgressStatuses = lessonProgressStatus();

    return (
        <RichCell key={index}
                  hasHover={false}
                  hasActive={false}
                  className="thin-border after-children-h-full px-2 bg-hover-50"
                  before={(
                      <If is={lesson.withContent}>
                          <Avatar mode="app" size={50} className="!bg-transparent">
                              {myProgressStatus
                                  ? lessonProgressStatusIcon[myProgressStatus]
                                  : <Icon28ArrowRightOutline/>
                              }
                          </Avatar>
                      </If>
                  )}
                  subhead={(
                      !lesson.parent
                          ? t('moduleIndex', { index })
                          : t('lessonIndex', { index })
                  )}
                  caption={lesson.withContent ? (
                      <>
                          {lessonProgressStatuses[myProgressStatus || CourseProgressLessonStatus.NotStarted]}

                          <If is={!!lesson.blocksInfo.totalVideoDuration}>
                              <VideoTagPart duration={lesson.blocksInfo.totalVideoDuration || 0} className="ml-1.5"/>
                          </If>

                          <If is={lesson.withPractice}>
                              <HomeWorkTagPart className="ml-1.5"/>
                          </If>
                      </>
                  ) : undefined}
                  after={(
                      lesson.id === currentLesson?.id
                          ? <TagInlineText size="m" gradient="blue" isPointer={false} content={t('current')}/>
                          : undefined
                  )}
                  afterCaption={(
                      lesson.id === currentLesson?.id
                          ? t('currentWithTime', {
                              updatedAt: moment(lesson.lessonProgress?.updatedAt)
                                  .format('D MMM YYYY HH:mm'),
                          })
                          : undefined
                  )}>
            {lesson.name}
        </RichCell>
    );
};


export { StudyProgressLessonItem };
