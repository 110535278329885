/**
 * ArrangeShow
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useFormikContext } from 'formik';

import { DndContext, DragOverlay } from '@dnd-kit/core';

import { If, useAutoSaveFormContext } from '@/cutils';
import { ArrangeTaskAnswer, ArrangeTaskQuestion } from '@/shared/types';

import { TaskBuilderShowProps, useTaskBuilderContext } from '@/components/Task/Builder';

import { DraggableWordAtom, SentenceAtom, WordListAtom } from './atoms';

import { useDndSensors } from './hooks/useDndSensors';
import { useWordPlacement } from './hooks/useWordPlacement';


interface Props extends TaskBuilderShowProps<
    ArrangeTaskQuestion,
    ArrangeTaskAnswer
> {

}


const ArrangeShow = (props: Props) => {

    const { task: { question }, answer = {} } = props;

    const sensors = useDndSensors();
    const { setFieldValue } = useFormikContext();
    const { show: { subMode } } = useTaskBuilderContext();

    const { handleAutoSaveChange } = useAutoSaveFormContext();

    const {
        transformAnswersToDOM,
        transformQuestionToDOM,
    } = useWordPlacement();

    const {
        answers,
        activeId,
        removeWord,
        getAllWords,
        getWordById,
        selectedWordId,
        handleDragStart,
        handleDragEnd,
        handleWordClick,
        handleSlotClick,
        getAvailableWords,
        transformAnswersToDto,
    } = useWordPlacement({
        initialAnswers: subMode === 'preview'
            ? transformQuestionToDOM(question.skips)
            : transformAnswersToDOM(question.skips, answer?.quiz),
        onChange: (answers) => {
            const answer = transformAnswersToDto(
                answers,
                { ...question.skips, ...question.fakeSkips },
            );

            setFieldValue('quiz', answer);
            handleAutoSaveChange();
        },
    });

    const words = getAllWords(
        question.skips,
        question.fakeSkips,
    );

    const availableWords = getAvailableWords(words);

    return (
        <DndContext sensors={sensors} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
            <div className={[
                'flex flex-col gap-6',
                subMode === 'result' ? 'pointer-events-none' : '',
            ].join(' ')}>
                <WordListAtom words={availableWords} onWordClick={handleWordClick} selectedWordId={selectedWordId}/>

                <SentenceAtom answers={answers}
                              question={question}
                              onRemoveWord={removeWord}
                              onSlotClick={handleSlotClick}
                              selectedWordId={selectedWordId}/>
            </div>

            <DragOverlay dropAnimation={null}>
                <If is={!!activeId}>
                    <div style={{ pointerEvents: 'none' }}>
                        <DraggableWordAtom id={`${activeId}`} text={getWordById(`${activeId}`, words)}/>
                    </div>
                </If>
            </DragOverlay>
        </DndContext>
    );
};


export { ArrangeShow };
