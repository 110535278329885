/**
 * PanelHeaderTab component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, RouterStore } from '@/store/core/router';

import { Routes } from '@/router/routes';

import { Responsive } from '@/cutils';

import { PageTabHeaderPart } from './parts/PageTabHeaderPart';


type HeaderInterface = {
    [index: string]: any;
}

interface PanelHeaderSpaceProps {
    offset?: number;
}


const PanelHeaderTab = observer(() => {

    const headers: HeaderInterface = {
        tab: <PageTabHeaderPart/>,
        page: <PageTabHeaderPart/>,
        iframe: <PageTabHeaderPart/>,
        fullscreen: <PageTabHeaderPart/>,
    };

    return Routes[RouterStore.viewId]?.header || headers[RouterStore.type];
});


const PanelHeaderSpace = (props: PanelHeaderSpaceProps) => {

    const { offset = 18 } = props;

    return (
        <>
            <Responsive.Desktop>
                <div style={{ height: `${offset}px` }} className="w-100"/>
            </Responsive.Desktop>
        </>
    );
};


export { PanelHeaderTab, PanelHeaderSpace };
