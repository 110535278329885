/**
 * ProfileSearchWrapper component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { observer, Page } from '@/pages/Core';
import { ConfigStore } from '@/store/core/config';

import { SearchProfileQuery } from '@/codegen/graphql';

import { DebounceInput } from 'react-debounce-input';

import { Search } from '@exode.ru/vkui';

import { Graphql, If } from '@/cutils';
import { DebouncedInput } from '@/types/html';

import { useProfileSearchFilter } from './filter';


interface Props {
    successComponent: ReactElement;
    loadingComponent?: ReactElement;
    showLoading?: boolean;
}


const ProfileSearchWrapper = observer((props: Props) => {

    const { successComponent, loadingComponent, showLoading = true } = props;

    const { urlFilter, setUrlFilter } = useProfileSearchFilter();

    return (
        <>
            <Page.Row mobileSeparator="hide">
                <DebounceInput minLength={1}
                               maxLength={15}
                               debounceTimeout={500}
                               autoFocus={ConfigStore.isDesktop}
                               element={Search as DebouncedInput}
                               value={urlFilter.profileSearch || ''}
                               onChange={({ target: { value } }) => setUrlFilter({ profileSearch: value })}/>
            </Page.Row>

            <If is={!!urlFilter.profileSearch?.length}>
                <SearchProfileQuery notifyOnNetworkStatusChange children={(result) => (
                    <>
                        <Graphql.Loading result={result}>
                            {!showLoading ?
                                <></>
                                : loadingComponent
                                    ? React.cloneElement(loadingComponent, { loading: result.loading })
                                    : <Page.Spinner/>
                            }
                        </Graphql.Loading>

                        <Graphql.Success result={result}>
                            {({ profileFindMany: profiles }) => (
                                <Page.Row>
                                    {React.cloneElement(successComponent, { profiles })}
                                </Page.Row>
                            )}
                        </Graphql.Success>

                        <Graphql.Error result={result}/>
                    </>
                )} variables={{
                    list: {
                        skip: 0,
                        take: 20,
                    },
                    filter: {
                        search: urlFilter.profileSearch,
                        user: {
                            active: true,
                            search: urlFilter.profileSearch,
                        },
                    },
                }}/>
            </If>
        </>
    );
});


export { ProfileSearchWrapper };
