/**
 * VariantUploadButtonAtom
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { Avatar, Spinner } from '@exode.ru/vkui';
import { TextTooltip } from '@exode.ru/vkui/dist/unstable';
import { Icon24Attach, Icon24PenOutline } from '@vkontakte/icons';

import { MultipleWithAttachmentTaskQuestion } from '@/shared/types';


interface Props {
    variant: MultipleWithAttachmentTaskQuestion['variants'][number];
    loading?: boolean;
    disabled?: boolean;
    progress?: number;
}


const VariantUploadButtonAtom = (props: Props) => {

    const { variant, loading = false, progress = 0 } = props;

    const { t } = useI18n('components.Task.Builder.parts.common');

    return (
        <TextTooltip shown={loading ? true : undefined} placement="right" text={(
            !loading
                ? (
                    !variant.attachments?.main?.location
                        ? t('addAttachment')
                        : t('replaceAttachment')
                )
                : `${progress} %`
        )}>
            <Avatar size={40} mode="app" shadow={false} className="bg-transparent cursor-pointer">
                <If is={!loading}>
                    <If is={!variant.attachments?.main?.location}>
                        <Icon24Attach fill="var(--accent)"/>
                    </If>

                    <If is={!!variant.attachments?.main?.location}>
                        <Icon24PenOutline fill="var(--icon_secondary)"/>
                    </If>
                </If>

                <If is={loading}>
                    <Spinner className="text-accent"/>
                </If>
            </Avatar>
        </TextTooltip>
    );
};


export { VariantUploadButtonAtom };
