/**
 * SearchModePanelView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { MutableRefObject } from 'react';

import { ChatDialogsPageStore } from '../store';
import { observer, useStore } from '@/pages/Core';

import { useI18n } from '@/hooks/core';
import { Graphql, If, ScrollTo } from '@/cutils';
import { ChatAndProfileSearchFindManyQuery, ChatEntity, ChatType } from '@/codegen/graphql';

import { Icon20Search } from '@vkontakte/icons';

import { LastOnlineText } from '@/components/Profile/Page';
import { UserListSkeleton } from '@/components/Atoms/Skeleton/UserList';
import { PlaceholderWrapper } from '@/components/Atoms/PlaceholderWrapper';

import { ChatListSeparatorItem } from '@/pages/Chat/Dialog';

import { DialogsListViewProps } from './DialogsListView';

import { ChatCellItem } from '../items/ChatCellItem';


interface Props {
    parentRef: MutableRefObject<HTMLDivElement | null>;
    onChatCellClick: DialogsListViewProps['onChatCellClick'];
    options: {
        chatSearch: string;
        profileSkip?: boolean;
        activeChatId?: number;
        personalUserId?: number;
        chetCellIsRounded?: boolean;
    };
}


const SearchModePanelView = observer((props: Props) => {

    const {
        parentRef,
        onChatCellClick,
        options: {
            chatSearch,
            activeChatId,
            personalUserId,
            profileSkip = !chatSearch,
            chetCellIsRounded = false,
        },
    } = props;

    const { t } = useI18n('pages.Chat.Dialog');

    const { list, sort } = useStore(ChatDialogsPageStore);

    return (
        <ChatAndProfileSearchFindManyQuery fetchPolicy="network-only" children={(result) => (
            <>
                <Graphql.Loading result={result}>
                    <If is={!!result.previousData}>
                        <ScrollTo element={parentRef.current}/>
                    </If>

                    <UserListSkeleton baseAvatarSize={50} listClassName="gap-[1px]" itemClassName="px-2.5 my-[6px]"/>
                </Graphql.Loading>

                <Graphql.Success result={result}>
                    {({
                          chatFindMany: { items: chatItems },
                          profileFindMany: { items: profiles } = { items: [] },
                      }) => (
                        <PlaceholderWrapper count={(chatItems?.length || 0) + (profiles?.length || 0)} placeholder={{
                            iconSize: 72,
                            innerPadding: '0px',
                            header: t('chatsWereNotFound'),
                            className: 'm:pt-0 my-auto h-[calc(100%_-_54px)]',
                            icon: <Icon20Search className="pt-0 text-accent opacity-[0.85]"/>,
                            content: (
                                <span className="m:w-full w-9/10 flex mx-auto">
                                    {t('makeSureYourSearchIsCorrect')}
                                </span>
                            ),
                        }}>
                            <>
                                <If is={!_.isEmpty(profiles)}>
                                    <ChatListSeparatorItem caption={t('searchByUsers')} isRounded={chetCellIsRounded}/>
                                </If>

                                {profiles?.map((profile) => (
                                    <ChatCellItem key={profile.user.id}
                                                  lastActionText=""
                                                  isRounded={chetCellIsRounded}
                                                  isActive={personalUserId === profile.user.id}
                                                  onChatCellClick={() => {
                                                      onChatCellClick('user', { userId: profile.user.id });
                                                  }}
                                                  lastMessageText={(
                                                      <LastOnlineText userId={profile.user.id}
                                                                      sex={profile.sex}
                                                                      lastOnlineAt={profile.user.lastOnlineAt}/>
                                                  )}
                                                  chat={{
                                                      type: ChatType.Personal,
                                                      name: profile.fullName,
                                                      description: '',
                                                      icon: profile.avatar.medium,
                                                      countUnread: 0,
                                                      personalCompanionMember: {
                                                          user: {
                                                              profile,
                                                              ...profile.user,
                                                          },
                                                      },
                                                      lastMessage: {
                                                          createdAt: '-',
                                                          user: { profile: { fullName: t('startChatting') } },
                                                      },
                                                  } as ChatEntity}/>
                                ))}

                                <If is={!_.isEmpty(chatItems)}>
                                    <ChatListSeparatorItem caption={t('searchByChats')} isRounded={chetCellIsRounded}/>
                                </If>

                                {chatItems?.map((chat) => (
                                    <ChatCellItem key={chat.id}
                                                  chat={chat}
                                                  isRounded={chetCellIsRounded}
                                                  onChatCellClick={onChatCellClick}
                                                  isActive={activeChatId === chat.id}/>
                                ))}
                            </>
                        </PlaceholderWrapper>
                    )}
                </Graphql.Success>

                <Graphql.Error result={result}/>
            </>
        )} nextFetchPolicy="network-only" variables={{
            profileSkip,
            chatList: { ...list.chats },
            chatSort: { ...sort.chats },
            chatFilter: {
                search: chatSearch,
                types: _.values(ChatType)
                    .filter((type) => !profileSkip
                        ? ![ ChatType.Personal ].includes(type)
                        : true,
                    ),
            },
            profileList: { ...list.profile },
            profileFilter: {
                search: chatSearch,
                user: {
                    active: true,
                    search: chatSearch,
                },
            },
        }}/>
    );
});


export { SearchModePanelView };
