/**
 * Environment variables
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { Url } from '@/utils';
import { AppPlatformType } from '@/types/core';
import { getSessionLauncher } from '@/helpers/app';
import { SessionLauncher } from '@/codegen/graphql';
import { AppServeDomain, AppServeProduct, PageSpace } from '@/shared/types';

import packageJson from '@/root/package.json';


/** CHANGEABLE: */

/** @SSR/@CSR: Состояние приложения 'production' | 'staging' | 'development' */
export const NODE_ENV: string = process.env.REACT_APP_NODE_ENV?.trim()
    || process.env.NODE_ENV?.trim()
    || 'development';

/** @CSR: Поддомен развертывания */
export const SUBDOMAIN = typeof window !== 'undefined'
    ? window.location.hostname.split('.').splice(0, 1)?.[0]
    : '';

/** @CSR: Домен развертывания (ru, uz, kz, ...) */
export const SERVE_DOMAIN = typeof window !== 'undefined'
    ? _.capitalize(window.location.hostname.split('.').at(-1)) as AppServeDomain
    : AppServeDomain.Ru;

/** @CSR: Домен развертывания (exode) */
const DOMAIN = typeof window !== 'undefined'
    ? window.location.hostname.split('.').at(-2)
    : 'exode';

/** @CSR: Целевая платформа релиза */
export const OS_PLATFORM: AppPlatformType = [ 'android', 'ios' ].includes(SUBDOMAIN)
    ? SUBDOMAIN as AppPlatformType
    : (
        process.env.REACT_APP_PLATFORM
            ? process.env.REACT_APP_PLATFORM.trim() as AppPlatformType
            : 'web'
    );

/** Версия API в запросах приложения */
export const API_VERSION: string = '2';

/** BUSINESS-PLATFORM: */

/** @CSR: Контекст платформы "Маркетплейс" */
export const MARKETPLACE = typeof window !== 'undefined'
    ? window.exode.marketplace
    : null;

/** @SSR/@CSR: Контекст платформы "Школа" */
export const IS_MARKETPLACE: boolean = typeof window !== 'undefined'
    ? !!MARKETPLACE
    : process.env.REACT_APP_PRODUCT === AppServeProduct.Marketplace;

/** @CSR: Контекст платформы "Школа" */
export const IS_SCHOOL: boolean = typeof window !== 'undefined'
    ? !!window.exode.school
    : false;

/** Домен управления аккаунтами .biz */
export const BIZ_WELCOME_SUBDOMAIN = 'welcome';

/** @CSR: Платформа управления аккаунтами .biz */
export const IS_BIZ_WELCOME = SUBDOMAIN === BIZ_WELCOME_SUBDOMAIN;

/** @SSR/@CSR: Домен школ (без своего домена) */
export const SCHOOL_BIZ_TLD = 'biz';

/** @CSR: Текущая платформа */
export const CURRENT_PLATFORM = IS_SCHOOL
    ? PageSpace.SchoolPlatform
    : (
        IS_BIZ_WELCOME
            ? PageSpace.BizWelcomePlatform
            : PageSpace.MarketplacePlatform
    );

/** DEPENDENT: */

/** @CSR: Текущая версия приложения */
export const APP_VERSION = ((): string => {
    const search = Url.parseQuery(
        typeof window !== 'undefined'
            ? window.location.search
            : '',
    );

    return search?.APP_VERSION || packageJson.version;
})();

/** @CSR: Номер сборки */
export const BUILD_HASH = packageJson.buildhash;

/** @CSR: Приложение для встраивания в native web view */
export const IS_NATIVE: boolean = [ 'android', 'ios' ].includes(OS_PLATFORM);

/** @CSR: Приложение для vk mini apps */
export const IS_VK: boolean = [ OS_PLATFORM, SUBDOMAIN ].includes('vk');

/** @CSR: Приложение для браузера (веб-сайта) */
export const IS_WEB: boolean = !IS_NATIVE && !IS_VK && OS_PLATFORM === 'web';

/** @CSR: Лаунчер */
export const LAUNCHER: SessionLauncher = getSessionLauncher();

/** @CSR: Состояние приложения в разработке */
export const IS_DEV: boolean = NODE_ENV === 'development';

/** @SSR/@CSR: Состояние приложения в тестировании */
export const IS_STAGING: boolean = NODE_ENV === 'staging';

/** @SSR/@CSR: Состояние приложения в продакшене */
export const IS_PROD: boolean = NODE_ENV === 'production';

/** @SSR/@CSR: Хост для express приложения */
export const SERVER_HOST: string = process.env.HOST || process.env.REACT_APP_SERVER_HOST || '0.0.0.0';

/** @SSR/@CSR: Порт для express приложения */
export const SERVER_PORT: number = Number(process.env.PORT || process.env.REACT_APP_SERVER_PORT || 3006);

/** @SSR/@CSR: Протокол обращения к API */
export const HTTP_PROTOCOL: 'http' | 'https' = IS_DEV ? 'http' : 'https';

/** @SSR/@CSR: Протокол обращения к GraphQL WS */
export const WS_PROTOCOL: 'ws' | 'wss' = IS_DEV ? 'wss' : 'wss';

/** @CSR: Домен API */
export const API_DOMAIN: string = IS_DEV
    ? 'localhost:3010'
    : (IS_STAGING ? `staging.${DOMAIN}.${SERVE_DOMAIN}` : `${DOMAIN}.${SERVE_DOMAIN}`);


/** @CSR: Домен сайта (без протокола и без поддомена школы) */
export const APP_DOMAIN: string = IS_DEV
    ? ('localhost:' + SERVER_PORT)
    : (IS_STAGING ? `staging.${DOMAIN}.${SERVE_DOMAIN}` : `${DOMAIN}.${SERVE_DOMAIN}`).toLowerCase();

/** @CSR: Текущий домен - школы и текущий URL на базовом домене */
export const DOMAIN_IS_SCHOOL_BASE = IS_SCHOOL && DOMAIN === 'exode';

/** @CSR: Домен сайта (полный, если школа - то с доменом школы) */
export const APP_DOMAIN_FULL = `${IS_SCHOOL ? (DOMAIN_IS_SCHOOL_BASE ? (SUBDOMAIN + '.') : '') : ''}${APP_DOMAIN}`;

/** @CSR: Url сайта (домашняя страница) */
export const APP_URL: string = `${HTTP_PROTOCOL}://${APP_DOMAIN_FULL}`;

/** @CSR: Файловое хранилище (max size in bytes) */
export const STORAGE_UPLOAD_MAX_SIZE = 5120 * 1e9;

/** @CSR: Стартовый капитал пользователя */
export const WALLET_START_CAPITAL = 3000;

/** @CSR: Поддержка exode */
export const EXODE_MARKET_SUPPORT_TG_URL = 'https://t.me/exode_support';
export const EXODE_BIZ_SUPPORT_TG_URL = 'https://t.me/exode_biz_support';
