/**
 * Webhook
 *
 * @author: exode <hello@exode.ru>
 */

import { WebhookEvent } from '@/libs/modules/webhook/interfaces/webhook.enum';


export const webhookSellerEvents = [

    /** Course */
    WebhookEvent.CourseCompleted,
    WebhookEvent.CourseLessonPracticeDetailedSent,
    WebhookEvent.CourseLessonPracticeAutoVerifySent,
    WebhookEvent.CourseProgressChanged,

    /** User */
    WebhookEvent.UserSignedUp,
    WebhookEvent.UserJoinedByReferral,
    WebhookEvent.UserCreatedViaLms,
    WebhookEvent.UserSignedIn,
    WebhookEvent.UserLoggedOut,

    /** Product */
    WebhookEvent.ProductEnrolledViaLms,
    WebhookEvent.ProductEnrolledViaPayment,
    WebhookEvent.ProductRefundCompleted,
    WebhookEvent.ProductAccessSubscriptionEnding7Days,
    WebhookEvent.ProductAccessSubscriptionEnding1Day,

    /** Payment */
    WebhookEvent.PaymentCompleted,

] as const;

