/**
 * AddFakeSkipAtom
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useState } from 'react';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { IconButton } from '@mui/material';
import { Button, Input } from '@exode.ru/vkui';

import {
    Icon20AddCircleFillGray,
    Icon20ListDeleteOutline,
    Icon24ArrowTurnLeftOutline,
    Icon24CancelOutline,
} from '@vkontakte/icons';


interface Props {
    onAddFakeWord: (text: string) => void;
}


const AddFakeSkipAtom = (props: Props) => {

    const { onAddFakeWord } = props;

    const { t } = useI18n('components.Task.Builder.edit.arrange');

    const [ newFakeWord, setNewFakeWord ] = useState('');
    const [ isInputVisible, setIsInputVisible ] = useState(false);

    const handleAddFakeWord = () => {
        if (newFakeWord.trim()) {
            onAddFakeWord(newFakeWord.trim());
            setNewFakeWord('');
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent) => {
        switch (e.key) {
            case 'Enter':
                handleAddFakeWord();
                break;

            case 'Escape':
                setIsInputVisible(false);
                setNewFakeWord('');
                break;
        }
    };

    const handleCancel = () => {
        setIsInputVisible(false);
        setNewFakeWord('');
    };

    const handleBlur = (e: React.FocusEvent) => {
        const relatedTarget = e.relatedTarget as HTMLElement;

        const isAddButton = relatedTarget?.classList.contains('add-fake-word-btn');
        const isCancelButton = relatedTarget?.classList.contains('cancel-fake-word-btn');

        if (!isAddButton && !isCancelButton && !newFakeWord.trim()) {
            setIsInputVisible(false);
            setNewFakeWord('');
        }
    };

    return (
        <>
            <If is={isInputVisible}>
                <div className="flex items-center gap-3">
                    <Input autoFocus
                           className="w-full"
                           value={newFakeWord}
                           onBlur={handleBlur}
                           onKeyDown={handleKeyPress}
                           after={<Icon24ArrowTurnLeftOutline/>}
                           placeholder={t('fillIncorrectAndPressEnter')}
                           onChange={(e) => setNewFakeWord(e.target.value)}
                           before={<Icon20ListDeleteOutline fill="var(--dynamic_red)"/>}/>

                    <div className="bg-input rounded-full">
                        <IconButton onClick={handleCancel}>
                            <Icon24CancelOutline fill="var(--accent)"/>
                        </IconButton>
                    </div>
                </div>
            </If>

            <If is={!isInputVisible}>
                <Button size="l"
                        mode="tertiary"
                        before={<Icon20AddCircleFillGray/>}
                        onClick={() => setIsInputVisible(true)}>
                    {t('addIncorrectVariant')}
                </Button>
            </If>
        </>
    );
};


export { AddFakeSkipAtom };
