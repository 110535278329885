/**
 * CreateWebhookInput
 *
 * @author: exode <hello@exode.ru>
 */

import { IsUrl, MaxLength } from 'class-validator';

import { isMinMax } from '@/libs/class-validator/constants';


export class CreateWebhookInput {

    @IsUrl({}, { message: 'Введите корректный URL адрес' })
    @MaxLength(255, { message: isMinMax(255, 'URL', 'max', 'должна') })
    readonly url: string;

}
