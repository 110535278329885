/**
 * IsPhoneNumber validator
 *
 * @author: exode <hello@exode.ru>
 */

import { registerDecorator, ValidationOptions } from 'class-validator';

import { Parse } from '@/utils/Parse';


export function IsPhoneNumber(
    validationOptions?: ValidationOptions,
    isOptional?: boolean,
) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            name: 'IsPhoneNumber',
            target: object.constructor,
            propertyName: propertyName,
            constraints: [ isOptional ],
            options: validationOptions,
            validator: {
                validate(value: string) {
                    if (isOptional && !value) {
                        return true;
                    }

                    const { isValid } = Parse.phone(value);

                    return isValid;
                },
                defaultMessage() {
                    return `${validationOptions?.message}`;
                },
            },
        });
    };
}
