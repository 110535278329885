/**
 * LegalDocView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { Form, Formik } from 'formik';
import { createValidator } from 'class-validator-formik';

import { CreateDocumentItemInput } from '@/libs/class-validator';

import { Field, If } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { FileUtil, ObjectUtil } from '@/utils';
import { FileExtension } from '@/shared/types';
import { useSchoolLegal } from '@/hooks/apollo';
import { Router } from '@/services/Utils/Router';
import { SellerDocumentItem, sellerOrganizationSelfUploadedDocumentType } from '@/types/seller';

import { Button, FormItem, Input, SegmentedControl, Select } from '@exode.ru/vkui';

import {
    CreateOrganizationDocumentSellerInput,
    SellerOrganizationDocumentUploadMode,
    SellerOrganizationSelfUploadedDocumentType,
    StorageSpace,
} from '@/codegen/graphql';

import { FileUpload } from '@/components/Atoms/FileUpload';


interface Props {
    document?: SellerDocumentItem;
}


const LegalDocView = (props: Props) => {

    const { document } = props;

    const { t } = useI18n('pages.Manage.School.Legal');

    const selfUploadedDocumentTypes = sellerOrganizationSelfUploadedDocumentType();

    const {
        documentCreate,
        documentCreateLoading,
        documentUpdate,
        documentUpdateLoading,
        getInitialValues,
    } = useSchoolLegal();

    const initialValues = getInitialValues(document);

    const onSubmit = (values: CreateOrganizationDocumentSellerInput) => {
        document?.id
            ? documentUpdate(
                document.id,
                values,
                () => Router.replaceModal(),
            )
            : documentCreate(
                values,
                () => Router.replaceModal());
    };

    return (
        <Formik enableReinitialize
                onSubmit={onSubmit}
                initialValues={initialValues}
                validate={createValidator(CreateDocumentItemInput)}>
            {({
                  values,
                  errors,
                  touched,
                  isValid,
                  handleBlur,
                  handleChange,
                  setFieldValue,
              }) => (
                <Form>
                    <FormItem className="p-0"
                              top={t('documentName')}
                              status={Field.status(errors, touched, 'name')}
                              bottom={Field.message(errors, touched, 'name')}>
                        <Input name="name"
                               onBlur={handleBlur}
                               value={values.name}
                               placeholder={t('enterName')}
                               data-test="organization-doc.name"
                               onChange={Field.transform.ignoreSpaceOnChange(handleChange)}/>
                    </FormItem>

                    <FormItem className="p-0" top={t('documentType')}>
                        <Select name="config.type"
                                onChange={handleChange}
                                value={values.config.type}
                                data-test="organization-doc.config.type"
                                options={_.map(_.entries(selfUploadedDocumentTypes), ([ value ]) => ({
                                    value,
                                    label: selfUploadedDocumentTypes[value as SellerOrganizationSelfUploadedDocumentType],
                                }))}/>
                    </FormItem>

                    <FormItem className="p-0 mt-5">
                        <SegmentedControl name="config.uploadMode"
                                          value={values.config.uploadMode}
                                          onChange={(value) => setFieldValue('config.uploadMode', value)}
                                          options={[
                                              {
                                                  label: t('link'),
                                                  value: SellerOrganizationDocumentUploadMode.Link,
                                              },
                                              {
                                                  label: t('upload'),
                                                  value: SellerOrganizationDocumentUploadMode.File,
                                              },
                                          ]}/>
                    </FormItem>

                    <If is={values.config.uploadMode === SellerOrganizationDocumentUploadMode.File}>
                        <FormItem top={`${t('uploadTheDocument')} *`} className="p-0 mb-5 mt-2">
                            <FileUpload width="100%"
                                        height="220px"
                                        multiple={false}
                                        id="school-legal-docs"
                                        shouldResetFiles={false}
                                        space={StorageSpace.School}
                                        accept={FileExtension.Docs}
                                        onFileUpload={([ file ]) => {
                                            setFieldValue('location', file.location);
                                        }}
                                        getPreviewDoc={(fileInfo) => ({
                                            src: values.location,
                                            fileRowProps: {
                                                location: values.location,
                                                name: fileInfo?.name
                                                    || document?.storage?.meta.originalName
                                                    || values.name,
                                                mimeType: [
                                                    'application',
                                                    FileUtil.getFileUrlExtension(
                                                        fileInfo?.fileExtension || values.location || '',
                                                    ),
                                                ].join('/'),
                                            },
                                        })}/>
                        </FormItem>
                    </If>

                    <If is={values.config.uploadMode === SellerOrganizationDocumentUploadMode.Link}>
                        <FormItem className="p-0 mt-2"
                                  top={`${t('linkToTheFile')} *`}
                                  status={Field.status(errors, touched, 'location')}
                                  bottom={Field.message(errors, touched, 'location')}>
                            <Input name="location"
                                   onBlur={handleBlur}
                                   value={values.location}
                                   placeholder={t('enterLink')}
                                   onChange={Field.transform.ignoreSpaceOnChange(handleChange)}/>
                        </FormItem>
                    </If>

                    <Button stretched
                            size="l"
                            type="submit"
                            className="mt-5"
                            data-test="organization-doc.save"
                            loading={documentCreateLoading || documentUpdateLoading}
                            disabled={(
                                !isValid
                                || documentCreateLoading
                                || documentUpdateLoading
                                || ObjectUtil.isEqual(initialValues, values)
                            )}>
                        {t('save')}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};


export { LegalDocView };
