/**
 * EditorJsBlockContentElementEdit
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { Formik } from 'formik';

import { OutputData } from '@editorjs/editorjs';

import { useEditorJs, useI18n } from '@/hooks/core';
import { EditorJsBlockContent } from '@/shared/types';
import { ContentElementType } from '@/codegen/graphql';
import { AutoSaveForm, editorJsTools, Field, i18nEditorJs, If, ReactEditorJs } from '@/cutils';

import { FormItem, Input } from '@exode.ru/vkui';

import { EditorJsTextArea } from '@/components/Atoms/EditorJsTextArea';
import { getContentElementContextValue } from '@/components/ContentElement';

import { BlockHeaderPart, BlockWrapperPart } from '../parts/edit';

import { ContentElementEditProps } from '../interfaces';


interface Props extends ContentElementEditProps<EditorJsBlockContent> {

}


const EditorJsBlockContentElementEdit = (props: Props) => {

    const {
        list,
        loading,
        onEdit,
        update,
        onDelete,
        options,
        contentElement,
    } = props;

    const { t } = useI18n('components.ContentElement.edit');

    const {
        edit: {
            withTitle = true,
            blockHeaderProps = {},
            blockWrapperProps = {},
            BlockWrapper = BlockWrapperPart,
        } = {},
    } = getContentElementContextValue(ContentElementType.EditorJsBlock);

    const {
        editorCore,
        handleInitialize,
        timeoutHandleEditorJsChange,
    } = useEditorJs();

    return (
        <Formik initialValues={_.pick(contentElement, [ 'title', 'content' ])} onSubmit={(values) => update?.(values)}>
            {({ values, handleSubmit, handleChange, setFieldValue }) => (
                <AutoSaveForm onChange={() => handleSubmit()} onInstantChange={() => onEdit?.()}>
                    <BlockWrapper index={list?.index} style={{
                        position: 'relative',
                        zIndex: list ? (list.totalCount - list.index) + 1 : undefined,
                    }} {...blockWrapperProps}>
                        <BlockHeaderPart list={list} loading={loading} onDelete={onDelete} {...blockHeaderProps}/>

                        <If is={withTitle}>
                            <FormItem className="px-0 pt-0.5" top={t('blockHeader')}>
                                <Input name="title"
                                       value={values.title || ''}
                                       data-test="content-element.title"
                                       placeholder={t('enterBlockHeader')}
                                       onChange={Field.transform.ignoreSpaceOnChange(handleChange)}/>
                            </FormItem>
                        </If>

                        <FormItem className="px-0" top={t('blockContent')}>
                            <EditorJsTextArea dataTest="content-element.content" onInput={() => onEdit?.()}>
                                <ReactEditorJs i18n={i18nEditorJs()}
                                               holder={contentElement.uuid}
                                               onInitialize={handleInitialize}
                                               placeholder={t('toSelectTool')}
                                               key={`content.${contentElement.uuid}`}
                                               tools={editorJsTools({ space: options.space })}
                                               defaultValue={values.content as unknown as OutputData}
                                               onChange={() => timeoutHandleEditorJsChange({
                                                   values,
                                                   editorCore,
                                                   setFieldValue,
                                                   fieldName: 'content',
                                                   callback: () => handleSubmit(),
                                               })}/>
                            </EditorJsTextArea>
                        </FormItem>
                    </BlockWrapper>
                </AutoSaveForm>
            )}
        </Formik>
    );
};


export { EditorJsBlockContentElementEdit };
