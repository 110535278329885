/**
 * SelectionTooltipAtom
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, PreferenceStore } from '@/store/preference/preference';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { Icon20DeleteOutline, Icon20LineBottom } from '@vkontakte/icons';

import { TooltipPosition } from '../types';


interface Props {
    mode: 'create' | 'remove';
    position: TooltipPosition;
    onCreateSkip: () => void;
    onRemoveSkip: () => void;
}


const SelectionTooltipAtom = observer((props: Props) => {

    const {
        mode,
        position,
        onCreateSkip,
        onRemoveSkip,
    } = props;

    const { t } = useI18n('components.Task.Builder.edit.arrange');

    return (
        <div style={{ left: position.x, top: position.y }}
             onClick={mode === 'remove' ? onRemoveSkip : onCreateSkip}
             className={[
                 'cursor-pointer vkuiTextTooltip TextTooltip',
                 'tooltip-button z-10 transform -translate-x-1/2',
                 mode === 'remove' ? 'skip-tooltip absolute' : 'fixed',
                 !PreferenceStore.isDark ? 'vkuiTextTooltip--black' : 'vkuiTextTooltip--white',
             ].join(' ')}>
            <If is={mode === 'create'}>
                <div className="flex gap-2 items-center pr-1">
                    <Icon20LineBottom fill="var(--dynamic_green)"/>

                    <span className="fs-14 whitespace-nowrap">
                        {t('createSkip')}
                    </span>
                </div>
            </If>

            <If is={mode === 'remove'}>
                <div className="flex gap-2 items-center pr-1">
                    <Icon20DeleteOutline fill="var(--dynamic_red)"/>

                    <span className="fs-14 whitespace-nowrap">
                        {t('deleteSkip')}
                    </span>
                </div>
            </If>
        </div>
    );
});


export { SelectionTooltipAtom };
