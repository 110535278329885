/**
 * DesktopPanelHeader component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer } from 'mobx-react';
import { PageStore } from '@/store/core/page';

import { EpicViewType } from '@/types/router';
import { If, Responsive } from '@/cutils';

import { PanelHeader } from '@exode.ru/vkui';

import { Container } from './DesktopPanelHeader.styled';

import { LeftPart } from './parts/LeftPart';
import { RightPart } from './parts/RightPart';
import { ContentPart } from './parts/ContentPart';


interface Props {
    type: EpicViewType;
}


const DesktopPanelHeader = observer((props: Props) => {

    const { type } = props;

    return (
        <If is={type !== 'fullscreen'}>
            <Responsive.Desktop>
                <Container withShadow={PageStore.panelHeader.withShadow}>
                    <PanelHeader separator={false}
                                 className="viewport centered"
                                 before={PageStore.mode === 'slim-menu' && <LeftPart/>}>
                        {/** Note: header width should be equal to regular, except next array: */}
                        <div className="viewport__wrapper" data-page-mode={PageStore.dataPageMode}>
                            <If is={PageStore.mode !== 'slim-menu'}>
                                <LeftPart/>
                            </If>

                            <ContentPart/>

                            <If is={PageStore.mode !== 'slim-menu'}>
                                <RightPart/>
                            </If>
                        </div>
                    </PanelHeader>
                </Container>
            </Responsive.Desktop>
        </If>
    );
});


export { DesktopPanelHeader };
