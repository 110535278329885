/**
 * UseWordPlacement
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { useCallback, useState } from 'react';

import { DragEndEvent } from '@dnd-kit/core';

import { ArrangeTaskAnswer } from '@/shared/types';

import { Skip } from '../types';

import { useDragAndDrop } from './useDragAndDrop';
import { useWordSelection } from './useWordSelection';


interface Props {
    initialAnswers?: Record<string, string>;
    onChange?: (answers: Record<string, string>) => void;
}


export const useWordPlacement = (props: Props = {}) => {

    const [ answers, setAnswers ] = useState<Record<string, string>>(props?.initialAnswers || {});

    const { selectedWordId, handleWordClick, clearSelection } = useWordSelection();
    const { activeId, handleDragStart, handleDragEnd: onDragEnd } = useDragAndDrop();

    const removeWord = useCallback((slotId: string) => {
        setAnswers((prev) => {
            const newAnswers = { ...prev };
            delete newAnswers[slotId];

            props.onChange?.(newAnswers);

            return newAnswers;
        });

        clearSelection();
    }, [ clearSelection ]);

    const placeWord = useCallback((
        wordId: string,
        slotId: string,
    ) => {
        if (!wordId || !slotId) {
            return;
        }

        setAnswers((prev) => {
            const newAnswers = { ...prev };

            Object.entries(newAnswers).forEach(([ slot, existingWordId ]) => {
                if (normalizeUuid(existingWordId) === normalizeUuid(wordId)) {
                    delete newAnswers[slot];
                }
            });

            newAnswers[slotId] = wordId;

            props.onChange?.(newAnswers);

            return newAnswers;
        });

        clearSelection();
    }, [ clearSelection ]);

    const handleDragEnd = useCallback((event: DragEndEvent) => {
        const { active, over } = event;

        if (!active || !over) {
            onDragEnd(event);

            return;
        }

        if (over.id === 'word-list') {
            Object.entries(answers).forEach(([ slot, wordId ]) => {
                if (normalizeUuid(wordId) === normalizeUuid(active.id as string)) {
                    removeWord(slot);
                }
            });
        } else if (active.id !== over.id) {
            placeWord(active.id as string, over.id as string);
        }

        onDragEnd(event);
    }, [ onDragEnd, placeWord, answers, removeWord ]);

    const handleSlotClick = useCallback((slotId: string) => {
        if (selectedWordId) {
            placeWord(selectedWordId, slotId);
        }
    }, [ selectedWordId, placeWord ]);

    const getAvailableWords = useCallback((allWords: Skip[]) => {
        const usedWords = Object.values(answers).map(normalizeUuid);

        return allWords.filter((word) => !usedWords.includes(normalizeUuid(word.uuid)));
    }, [ answers ]);

    const getWordById = (
        id: string,
        words: Skip[],
    ) => {
        const word = words.find((word) => word.uuid === id);

        return word?.text || '';
    };

    const getAllWords = (
        skips: Skip[],
        fakeSkips: Skip[],
    ): Skip[] => {
        const processedSkips = skips.map((word) => ({
            ...word,
            uuid: `skip_${word.uuid}`,
        }));

        const processedFakeSkips = fakeSkips.map((word) => ({
            ...word,
            uuid: `fake_${word.uuid}`,
        }));

        return [ ...processedSkips, ...processedFakeSkips ];
    };

    const normalizeUuid = (uuid: string | undefined) => {
        return uuid?.replace(/^(skip_|fake_)/, '') || '';
    };

    const transformAnswersToDto = (
        answers: Record<string, string>,
        skips: Skip[],
    ) => {
        const result: ArrangeTaskAnswer = {};

        for (const [ k, v ] of _.entries(answers)) {
            const uuid = normalizeUuid(v);
            const skip = _.find(skips, { uuid });

            result[k] = {
                uuid,
                value: skip?.text || '',
            };
        }

        return result;
    };

    const transformAnswersToDOM = (
        skips: Skip[],
        quizAnswer: ArrangeTaskAnswer | undefined,
    ) => {
        const result: Record<string, string> = {};

        for (const [ k, v ] of _.entries(quizAnswer)) {
            result[k] = _.find(skips, { uuid: v.uuid })
                ? `skip_${v.uuid}`
                : `fake_${v.uuid}`;
        }

        return result;
    };

    const transformQuestionToDOM = (
        questionSkips: Skip[],
    ) => {
        const result: Record<string, string> = {};

        for (const skip of questionSkips) {
            result[skip.uuid] = `skip_${skip.uuid}`;
        }

        return result;
    };

    return {
        answers,
        activeId,
        selectedWordId,
        handleDragStart,
        handleDragEnd,
        handleWordClick,
        handleSlotClick,
        removeWord,
        getAvailableWords,
        getAllWords,
        getWordById,
        normalizeUuid,
        transformAnswersToDto,
        transformAnswersToDOM,
        transformQuestionToDOM,
    };
};
