/**
 * UseProfileUpdate
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';
import moment from 'moment';

import { UserAuthStore } from '@/store/user/auth';
import { ProfileStore } from '@/store/user/profile';

import {
    MyUserSettingsQueryResult,
    ProfileRole,
    useProfileUpdateMutation,
    UserSexType,
    useSetUserDomainMutation,
} from '@/codegen/graphql';

import { GqlResult } from '@/types/graphql';


export const useProfileUpdate = () => {

    const [ updateProfile, { loading: updateProfileLoading } ] = useProfileUpdateMutation({
        onError: error => console.error(error),
        update(cache, { data }) {
            const { profileUpdate } = data || {};

            if (profileUpdate) {
                const profile = _.omit(profileUpdate, 'user');

                cache.modify({
                    id: `UserEntity:${UserAuthStore.user?.id}`,
                    fields: {
                        profile: () => profile,
                        domain: () => profileUpdate.user.domain || null,
                    },
                });

                ProfileStore.merge({
                    ...profile,
                    user: {
                        ...ProfileStore.user,
                        domain: profileUpdate.user.domain,
                    },
                });
            }
        },
    });

    const [ setUserDomain, { loading: setUserDomainLoading } ] = useSetUserDomainMutation({
        onError: error => console.error(error),
    });

    const getInitialValues = (
        user: GqlResult<MyUserSettingsQueryResult>['userGetMyInfo'],
    ) => {
        const {
            sex,
            role,
            bdate,
            contact,
            originalNames,
        } = user.profile || {};

        const birthDate = bdate
            ? moment(bdate).toObject()
            : undefined;

        return {
            firstName: originalNames?.firstName || '',
            lastName: originalNames?.lastName || '',
            sex: sex || UserSexType.Ufo,
            birthDay: birthDate && String(birthDate.date) || '',
            birthMonth: birthDate && String(birthDate.months + 1) || '',
            birthYear: birthDate && String(birthDate.years) || '',
            domain: user.domain || '',
            role: role || ProfileRole.Student,
            contact: {
                phone: contact?.phone || user?.phone || '',
            },
        } as const;
    };

    return {
        updateProfile,
        updateProfileLoading,
        setUserDomain,
        setUserDomainLoading,
        getInitialValues,
    };
};
