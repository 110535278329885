/**
 * ContentEditable
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ForwardedRef, forwardRef, HTMLProps, useEffect } from 'react';

import { Container } from './ContentEditable.styled';

import { handleContentEditablePaste } from './helper';


interface Props extends HTMLProps<HTMLDivElement> {
    onChange?: () => void;
    placeholder?: string;
}


const ContentEditable = forwardRef((props: Props, ref: ForwardedRef<HTMLDivElement>) => {

    const { onChange, placeholder } = props;

    const handlePaste = (e: React.ClipboardEvent) => {
        handleContentEditablePaste(e);

        onChange?.();
    };

    useEffect(() => {
        if (ref && 'current' in ref && !ref.current?.textContent?.trim()) {
            ref.current?.classList.add('empty');
        }
    }, [ ref ]);

    return (
        <Container suppressContentEditableWarning
                   ref={ref}
                   onPaste={handlePaste}
                   contentEditable="true"
                   data-placeholder={placeholder} {...props}/>
    );
});


export { ContentEditable };
