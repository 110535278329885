/**
 * UseChatUnread
 *
 * @Note: Wrap a usage case in observer
 *
 * @author: exode <hello@exode.ru>
 */

import { useEffect } from 'react';

import { UserAuthStore } from '@/store/user/auth';
import { SubscriptionStore } from '@/store/subscription/subscription';

import { ChatFolderType } from '@/types/chat';
import { useChatGetUnreadCountQuery } from '@/codegen/graphql';


export const useChatUnread = (folder: ChatFolderType) => {

    const {
        data: { chatGetUnreadCount: unreadCountChat } = {},
        loading: unreadCountChatLoading,
    } = useChatGetUnreadCountQuery({
        skip: !UserAuthStore.isLoggedIn,
        variables: folder === 'All' ? undefined : { types: [ folder ] },
    });

    useEffect(() => {
        if (UserAuthStore.isLoggedIn) {
            SubscriptionStore.subscribeToChatTypeUnreadCount(folder);
        }
    }, [ folder, UserAuthStore.isLoggedIn ]);

    return {
        unreadCountChat,
        unreadCountChatLoading,
    };
};
