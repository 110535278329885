/**
 * RightPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer } from '@/store/user/profile';
import { RouterStore } from '@/store/core/router';

import { useI18n } from '@/hooks/core';
import { Auth, Link, Platform, Unauth } from '@/cutils';

import { Avatar, Search } from '@exode.ru/vkui';
import { TextTooltip } from '@exode.ru/vkui/unstable';
import { Icon28UserCircleOutline } from '@vkontakte/icons';

import { HeaderItem } from '@/components/Desktop/Panel';
import { LanguageDropdown } from '@/components/Atoms/LanguageDropdown';
import { DesktopNavigationContextMenuDrawer } from '@/components/Desktop/Navigation';

import { SettingsDropdownPart } from './SettingsDropdownPart';


const RightPart = observer(() => {

    const loginDisabled = RouterStore.pageId === '/login';

    const { t } = useI18n('components.Desktop.Panel.Header');

    return (
        <div className="viewport__right-menu vkuiSplitCol--spaced h-full">
            <div className="h-full flex items-center justify-end">
                <Platform.Market>
                    <Link pushPage={{ id: '/friends' }}>
                        <Search after="" style={{ width: '100%' }}/>
                    </Link>
                </Platform.Market>

                <Auth>
                    <SettingsDropdownPart/>
                </Auth>

                <Unauth>
                    <LanguageDropdown component={HeaderItem} componentProps={{
                        className: '!rounded-none ml-1',
                    }}/>

                    <Link disabled={loginDisabled} pushPage={{ id: '/login' }}>
                        <div>
                            <TextTooltip hidden={!loginDisabled} placement="bottom" text={t('youAlreadyOnLoginPage')}>
                                <div>
                                    <HeaderItem disabled={loginDisabled} className={'bg-transparent ml-1'}>
                                        <Avatar size={35} shadow={false}>
                                            <Icon28UserCircleOutline className="text-accent"/>
                                        </Avatar>
                                    </HeaderItem>
                                </div>
                            </TextTooltip>
                        </div>
                    </Link>
                </Unauth>

                <DesktopNavigationContextMenuDrawer/>
            </div>
        </div>
    );
});


export { RightPart };
