/**
 * UseManageGroupMembers
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { ManageGroupsStudentsPageStore } from '@/pages/Manage/Groups/Students/store';

import { Notify } from '@/cutils';
import { useStore } from '@/pages/Core';

import {
    GroupMemberFindManyDocument,
    GroupMemberFindManyQuery,
    useGroupMemberCreateManyMutation,
} from '@/codegen/graphql';

import { Icon16Cancel } from '@vkontakte/icons';


export const useManageGroupMembers = () => {

    const { list, filter, sort, params } = useStore(ManageGroupsStudentsPageStore);

    const [ _createMembers, {
        error: createMembersError,
        loading: createMembersLoading,
    } ] = useGroupMemberCreateManyMutation({
        onError(error) {
            console.error(error);

            Notify.vkui({
                appearance: 'error',
                message: error.message,
                icon: <Icon16Cancel/>,
            });
        },
        update: (cache, { data }) => {
            const variables = {
                list: { ...list },
                filter: { ...filter },
                sort: { ...sort },
            };

            const cachedMembers = cache.readQuery<GroupMemberFindManyQuery>({
                variables,
                query: GroupMemberFindManyDocument,
            });

            if (!cachedMembers) {
                return console.warn('[Cache]: cachedMembers отсутствуют в кэше');
            }

            const { page, pages, count, items } = cachedMembers.groupMemberFindMany;

            if (params.courseId) {
                /** Refetch groups count in course card */
                cache.evict({
                    id: `CourseEntity:${params.courseId}`,
                    fieldName: 'product',
                });
            }

            if (data?.groupMemberCreateMany) {
                cache.writeQuery<GroupMemberFindManyQuery>({
                    query: GroupMemberFindManyDocument,
                    variables,
                    data: {
                        groupMemberFindMany: {
                            page,
                            pages,
                            count: count + 1,
                            items: [
                                ...data?.groupMemberCreateMany,
                                ...(items ?? []),
                            ],
                        },
                    },
                });
            }
        },
    });

    const createMembers = (
        groupId: number,
        userIds: number[],
        onCompleted?: () => void,
    ) => {
        return _createMembers({
            variables: { groupId, userIds },
            onCompleted: () => onCompleted?.(),
        });
    };

    return {
        createMembers,
        createMembersError,
        createMembersLoading,
    };
};
