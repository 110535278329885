/**
 * ContentEditable styled container
 *
 * @author: exode <hello@exode.ru>
 */

import { HTMLProps } from 'react';

import styled from 'styled-components';


export const Container = styled.div<HTMLProps<HTMLDivElement>>`
    padding: 12px;
    position: relative;
    border-radius: var(--vkui--size_border_radius--regular, 8px);
    background-color: var(--field_background, var(--vkui--color_field_background));
    border: var(--thin-border) solid var(--field_border, var(--vkui--color_field_border_alpha));

    &:hover {
        border-color: var(--icon_tertiary_alpha);
    }

    &:focus {
        border-color: var(--accent, var(--vkui--color_stroke_accent));
    }

    &.empty:before {
        position: absolute;
        pointer-events: none;
        color: var(--text_secondary);
        content: attr(data-placeholder);
    }
`;
