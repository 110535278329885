/**
 * ManageGroupInviteMembersModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Modal } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { Title } from '@exode.ru/vkui';

import { InviteToGroupView } from '@/pages/Manage/Groups/List/views/InviteToGroupView';


interface Props {
    id: string;
}


const ManageGroupInviteMembersModal = (props: Props) => {

    const { t } = useI18n('modals.Manage.Group');

    return (
        <Modal.Card id={props.id} className="modal:w-550" subheader={t('chooseUsersToInvite')} header={(
            <Title className="modal-title" level="2" weight="3">
                {t('studentsInvitation')}
            </Title>
        )}>
            <Modal.Body fullHeight isScrollable>
                <InviteToGroupView/>
            </Modal.Body>
        </Modal.Card>
    );
};


export { ManageGroupInviteMembersModal };
