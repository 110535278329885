/**
 * CheckboxOptionAtom
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Checkbox } from '@exode.ru/vkui';

import { SvgComponent } from '@/cutils';

import { MultipleWithAttachmentTaskQuestion } from '@/shared/types';

import { useTaskBuilderContext } from '@/components/Task/Builder';

import { MultipleCheckboxConfig } from '../types';


export interface CheckboxOptionAtomProps {
    variant: MultipleWithAttachmentTaskQuestion['variants'][number];
    checkbox: MultipleCheckboxConfig;
}


const CheckboxOptionAtom = (props: CheckboxOptionAtomProps) => {

    const { variant, checkbox } = props;

    const { show: { subMode } } = useTaskBuilderContext();

    const [ comment, fill ] = subMode === 'result'
        ? checkbox.getCheckboxCommentAndFill(variant)
        : [ null, null ];

    return (
        <div className={[
            'flex items-center gap-2',
            subMode === 'result' ? 'pointer-events-none' : '',
        ].join(' ')}>
            <Checkbox checked={checkbox.getIsChecked(variant)}
                      className="checkbox-origin w-full bg-hover-50 py-1"
                      onChange={(e) => checkbox.handleChange(variant, e.target.checked)}
                      onIcon={<SvgComponent element={checkbox.onIcon} svgProps={{ fill }}/>}
                      offIcon={<SvgComponent element={checkbox.offIcon} svgProps={{ fill }}/>}
                      description={[
                          comment,
                          variant.text?.split('//')?.[1],
                      ].filter((e) => e).join(' • ')}>
                <span className="fs-18">
                    {variant.text?.split('//')?.[0]}
                </span>
            </Checkbox>
        </div>
    );
};


export { CheckboxOptionAtom };
