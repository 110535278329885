/**
 * ManageUserEditModal
 *
 * @author: exode <hello@exode.ru>
 */

import { observer } from 'mobx-react';

import React, { useLayoutEffect } from 'react';

import { IS_MARKETPLACE } from '@/root/src/env';

import { ManageSchoolUsersPageStore } from '@/pages/Manage/School/Users/store';

import { Modal } from '@/cutils';
import { useLocation, useStableModalParams } from '@/router/index';

import { UserEditTab } from '@/pages/Manage/School/Users';

import {
    EditUserFormMarketplaceHeader,
    EditUserFormSchoolHeader,
    EditUserFormView,
} from '@/pages/Manage/School/Users/views/EditUserFormView';


interface Props {
    id: string;
}


const ManageUserEditModal = observer((props: Props) => {

    const [ { userId } ] = useStableModalParams();
    const { route: { params } } = useLocation<{ tab: UserEditTab }>();

    const { setUserEditActiveTab } = ManageSchoolUsersPageStore;

    useLayoutEffect(() => {
        params.tab && setUserEditActiveTab(params.tab);
    }, []);

    return (
        <Modal.Card id={props.id} className="modal:w-650 modal:no-padding" header={(
            IS_MARKETPLACE
                ? <EditUserFormMarketplaceHeader userId={+userId}/>
                : <EditUserFormSchoolHeader userId={+userId}/>
        )}>
            <Modal.Body fullHeight isScrollable className="p-3 pb-0 !mt-0">
                <EditUserFormView userId={+userId}/>
            </Modal.Body>
        </Modal.Card>
    );
});


export { ManageUserEditModal };
