/**
 * MessagesViewContext
 *
 * @author: exode <hello@exode.ru>
 */

import React, { createContext, ReactElement, useContext, useState } from 'react';


export interface MessagesViewContext {
    scrollToBottom: ((force?: boolean, smooth?: boolean) => void) | null;
    handleEditMessage: Function | null;
    createChatIsLoading?: boolean | null;
    setCreateChatIsLoading?: ((isLoading: boolean) => void) | null;
}

interface MessagesViewProviderProps {
    value: MessagesViewContext;
    children: ReactElement | ReactElement[];
}


/**
 * MessagesView context
 */
const MessagesViewContext = createContext<MessagesViewContext>({
    scrollToBottom: null,
    handleEditMessage: null,
    createChatIsLoading: null,
    setCreateChatIsLoading: null,
});

/**
 * MessagesView provider
 * @constructor
 */
const MessagesViewProvider = (props: MessagesViewProviderProps) => {

    const [ createChatIsLoading, setCreateChatIsLoading ] = useState(false);

    return (
        <MessagesViewContext.Provider value={{
            ...props.value,
            createChatIsLoading,
            setCreateChatIsLoading,
        }}>
            {props.children}
        </MessagesViewContext.Provider>
    );
};

/**
 * UseMessagesViewContext hook
 * @returns {MessagesViewContext}
 */
const useMessagesViewContext = (): MessagesViewContext => useContext(MessagesViewContext);


export { MessagesViewProvider, useMessagesViewContext };
