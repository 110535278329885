/**
 * User checkbox cell styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div<{ rounded?: boolean }>`
    .vkuiCell__content {
        padding-top: 4px;
        padding-bottom: 4px;
        border-radius: ${({ rounded }) => rounded ? '' : '0 !important'};
    }
`;
