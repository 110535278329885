/**
 * MessageMediaImageItem
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ForwardedRef, forwardRef, MouseEventHandler } from 'react';

import { If } from '@/cutils';
import { Skeleton } from '@mui/material';
import { UiService } from '@/services/Ui/Ui';
import { ChatMessageItem } from '@/types/chat';

import { ImageLoader } from '@/components/Atoms/ImageLoader';
import { ImageBlurOnLoad } from '@/components/Atoms/ImageBlurOnLoad/ImageBlurOnLoad';


interface Props {
    messageId: number;
    media: ChatMessageItem['medias'][number];
    maxHeight?: number;
    onClick?: MouseEventHandler<HTMLDivElement>;
}


const MessageMediaImageItem = forwardRef((props: Props, ref: ForwardedRef<HTMLDivElement>) => {

    const { onClick, media: { storage } } = props;

    const maxHeight = Math.min(300, storage?.meta.dimensions?.height || 300);
    const maxWidth = 'd:max-w-[425px] md:!max-w-[300px] max-w-full cursor-zoom-in';

    const { width, height } = UiService.calcAspectRatio(
        storage?.meta.dimensions || { width: 100, height: 100 },
        'height',
        maxHeight,
    );

    return (
        <div ref={ref} onClick={onClick}>
            <If is={!!storage?.location}>
                <ImageLoader src={storage?.location || ''} loaded={(
                    <ImageBlurOnLoad width={width}
                                     height={height}
                                     className={maxWidth}
                                     maxHeight={maxHeight}
                                     location={storage?.location}
                                     name={storage?.meta.originalName}/>
                )} placeholder={(
                    <ImageLoader src={storage?.imageSizes.small || ''} loaded={(
                        <ImageBlurOnLoad blur
                                         width={width}
                                         height={height}
                                         className={maxWidth}
                                         maxHeight={maxHeight}
                                         location={storage?.imageSizes.small}
                                         name={storage?.meta.originalName}/>
                    )} placeholder={(
                        <Skeleton width={width}
                                  height={height}
                                  variant="rectangular"
                                  className={[ 'vk-rounded shadow-lg', maxWidth ].join(' ')}/>
                    )}/>
                )}/>
            </If>

            <If is={!storage?.location}>
                <Skeleton width={width}
                          height={height}
                          variant="rectangular"
                          className={`vk-rounded shadow-lg ${maxWidth}`}/>
            </If>
        </div>
    );
});


export { MessageMediaImageItem };
