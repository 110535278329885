/**
 * EditUserFormView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { ManageSchoolUsersPageStore, observer } from '../../store';

import { IS_MARKETPLACE } from '@/root/src/env';

import { useI18n, useTabNavigation } from '@/hooks/core';

import { Tabs, TabsItem } from '@exode.ru/vkui';

import {
    Icon20ArrowRightSquareOutline,
    Icon20AudiobookOutline,
    Icon20EducationOutline,
    Icon20KeyOutline,
    Icon20TabAddOutline,
    Icon20UserOutline,
} from '@vkontakte/icons';

import { StudyTab } from './tabs/StudyTab';
import { ProfileTab } from './tabs/ProfileTab';
import { SessionsTab } from './tabs/SessionsTab';
import { CreateAccessTab } from './tabs/CreateAccessTab';
import { StudyProgressTab } from './tabs/StudyProgressTab';

import { UserEditTab } from '../../interfaces';


export interface EditUserFormViewProps {
    userId: number;
}


const EditUserFormView = observer((props: EditUserFormViewProps) => {

    const { userId } = props;

    const { t } = useI18n('pages.Manage.School.Users.views.EditUserFormView');

    const {
        userEditActiveTab,
        setUserEditActiveTab,
    } = ManageSchoolUsersPageStore;

    const { tab, navigation } = useTabNavigation(userEditActiveTab, [
        {
            type: UserEditTab.Study,
            before: <Icon20EducationOutline/>,
            title: t('study'),
            view: <StudyTab userId={userId}/>,
            subTabs: [
                {
                    type: UserEditTab.Study,
                    before: <Icon20ArrowRightSquareOutline style={{ transform: 'rotate(180deg)' }}/>,
                    title: t('backToAllStudy'),
                    view: <></>,
                    hidden: IS_MARKETPLACE,
                },
                {
                    type: UserEditTab.StudyProgress,
                    before: <Icon20AudiobookOutline/>,
                    title: t('studyProgress'),
                    view: <StudyProgressTab/>,
                    group: 'study-progress',
                },
                {
                    type: UserEditTab.CreateAccess,
                    before: <Icon20TabAddOutline/>,
                    title: t('productAccessEnrollment'),
                    view: <CreateAccessTab userId={+userId}/>,
                    group: 'create-access',
                    subTabs: [],
                },
            ],
        },
        {
            type: UserEditTab.Profile,
            before: <Icon20UserOutline/>,
            title: t('profile'),
            view: <ProfileTab userId={+userId}/>,
            subTabs: [],
        },
        {
            type: UserEditTab.Sessions,
            before: <Icon20KeyOutline/>,
            title: t('sessions'),
            view: <SessionsTab userId={userId}/>,
            subTabs: [],
        },
    ]);

    return (
        <div className="p-4 pt-0">
            <Tabs mode="accent">
                {navigation?.filter((e) => !e.hidden).map(({ type, title, ...rest }, index) => (
                    <TabsItem {...rest} key={index}
                              data-test={`user.${type}-tab`}
                              className="gap-1 py-2 first:ml-0"
                              selected={type === userEditActiveTab}
                              onClick={() => setUserEditActiveTab(type as UserEditTab)}>
                        {title}
                    </TabsItem>
                ))}
            </Tabs>

            <div className="pt-2 pb-3">
                {tab?.view}
            </div>
        </div>
    );
});


export { EditUserFormView };
