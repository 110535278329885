/**
 * Task types
 *
 * @author: exode <hello@exode.ru>
 */

import { nanoid } from 'nanoid';

import React, { ReactElement } from 'react';

import { useI18n } from '@/hooks/core/useI18n';
import { useTaskManage } from '@/hooks/apollo';

import { TaskAnswerType, TaskComplexity, TaskContentType, TaskVisibility } from '@/codegen/graphql';

import {
    Icon20CheckBoxOn,
    Icon20ChevronLeft2,
    Icon20ChevronLeftOutline,
    Icon20CircleSmallOutline,
    Icon20DocumentPlusOutline,
    Icon20LightbulbStarOutline,
    Icon20ListNumberOutline,
    Icon20LogoOldVk,
    Icon20RadioOn,
    Icon20TableHeaderOutline,
    Icon20WriteSquareOutline,
} from '@vkontakte/icons';

import {
    ArrangeTaskQuestion,
    BooleanTaskQuestion,
    DetailedTaskQuestion,
    FillSpacesTaskQuestion,
    MappingTaskQuestion,
    MultipleTaskQuestion,
    MultipleWithAttachmentTaskQuestion,
    ShortTaskQuestion,
    SingleTaskQuestion,
    SingleWithAttachmentTaskQuestion,
} from '@/shared/types';


export type TaskBuilderFormValues = ReturnType<ReturnType<typeof useTaskManage>['getInitialValues']>;
/**
 * Тип контента задания
 */
export const taskContentType = () => {

    const { t } = useI18n('types.task');

    return {
        [TaskContentType.Course]: t('course'),
        [TaskContentType.Tournament]: t('tournament'),
    } as const;
};

/**
 * Иконка сложности контента задания
 */
export const taskComplexityIcon: { [key in TaskComplexity]: ReactElement } = {
    [TaskComplexity.VeryLow]: <Icon20ChevronLeft2 className="text-green-400" style={{ transform: 'rotate(-90deg)' }}/>,
    [TaskComplexity.Low]: <Icon20ChevronLeftOutline className="text-green-600"
                                                    style={{ transform: 'rotate(-90deg)' }}/>,
    [TaskComplexity.Medium]: <Icon20CircleSmallOutline className="text-accent"/>,
    [TaskComplexity.High]: <Icon20ChevronLeftOutline className="text-red-400" style={{ transform: 'rotate(90deg)' }}/>,
    [TaskComplexity.VeryHigh]: <Icon20ChevronLeft2 className="text-red-600" style={{ transform: 'rotate(90deg)' }}/>,
} as const;

/**
 * Сложность контента задания
 */
export const taskComplexity: { [key in TaskComplexity]: string } = {
    [TaskComplexity.VeryHigh]: 'Очень высокая',
    [TaskComplexity.High]: 'Высокая',
    [TaskComplexity.Medium]: 'Средняя',
    [TaskComplexity.Low]: 'Низкая',
    [TaskComplexity.VeryLow]: 'Очень низкая',
} as const;

/**
 * Типы заданий
 */
export const taskAnswerType = () => {

    const { t } = useI18n('types.task');

    return {
        [TaskAnswerType.Arrange]: t('arrange'),
        [TaskAnswerType.Boolean]: t('boolean'),
        [TaskAnswerType.Detailed]: t('detailed'),
        [TaskAnswerType.FillSpaces]: t('fillSpaces'),
        [TaskAnswerType.Mapping]: t('mapping'),
        [TaskAnswerType.Multiple]: t('multiple'),
        [TaskAnswerType.MultipleWithAttachment]: t('multipleWithAttachment'),
        [TaskAnswerType.Short]: t('short'),
        [TaskAnswerType.Single]: t('single'),
        [TaskAnswerType.SingleWithAttachment]: t('singleWithAttachment'),
    } as const;
};

export const taskAnswerTypeDescription = () => {

    const { t } = useI18n('types.task');

    return {
        [TaskAnswerType.Arrange]: t('arrangeDescription'),
        [TaskAnswerType.Boolean]: t('booleanDescription'),
        [TaskAnswerType.Detailed]: t('detailedDescription'),
        [TaskAnswerType.FillSpaces]: t('fillSpacesDescription'),
        [TaskAnswerType.Mapping]: t('mappingDescription'),
        [TaskAnswerType.Multiple]: t('multipleDescription'),
        [TaskAnswerType.MultipleWithAttachment]: t('multipleWithAttachmentDescription'),
        [TaskAnswerType.Short]: t('shortDescription'),
        [TaskAnswerType.Single]: t('singleDescription'),
        [TaskAnswerType.SingleWithAttachment]: t('singleWithAttachmentDescription'),
    } as const;
};

/**
 * Иконка типа ответа задания
 */
export const taskAnswerTypeIcon: { [key in TaskAnswerType]: ReactElement } = {
    [TaskAnswerType.Arrange]: <Icon20ListNumberOutline/>,
    [TaskAnswerType.Boolean]: <Icon20LightbulbStarOutline/>,
    [TaskAnswerType.Detailed]: <Icon20DocumentPlusOutline/>,
    [TaskAnswerType.FillSpaces]: <Icon20LogoOldVk/>,
    [TaskAnswerType.Mapping]: <Icon20TableHeaderOutline/>,
    [TaskAnswerType.Multiple]: <Icon20CheckBoxOn/>,
    [TaskAnswerType.MultipleWithAttachment]: <Icon20CheckBoxOn/>,
    [TaskAnswerType.Short]: <Icon20WriteSquareOutline/>,
    [TaskAnswerType.Single]: <Icon20RadioOn/>,
    [TaskAnswerType.SingleWithAttachment]: <Icon20RadioOn/>,
};

/**
 * Видимость задания
 */
export const taskVisibility = () => {

    const { t } = useI18n('types.task');

    return {
        [TaskVisibility.All]: t('all'),
        [TaskVisibility.Personal]: t('personal'),
        [TaskVisibility.Organization]: t('organization'),
    } as const;
};

/**
 * Режим просмотра задания
 */
export enum TaskViewMode {
    Edit = 'Edit',
    Show = 'Show',
}

/**
 * Значения по умолчанию (для поля question)
 * @type {}
 */
export const taskInitialValue: () => {
    [TaskAnswerType.Arrange]: {
        title: string;
        question: ArrangeTaskQuestion;
    };
    [TaskAnswerType.Boolean]: {
        title: string;
        question: BooleanTaskQuestion;
    };
    [TaskAnswerType.Detailed]: {
        title: string;
        question: DetailedTaskQuestion;
    };
    [TaskAnswerType.FillSpaces]: {
        title: string;
        question: FillSpacesTaskQuestion;
    };
    [TaskAnswerType.Mapping]: {
        title: string;
        question: MappingTaskQuestion;
    };
    [TaskAnswerType.Multiple]: {
        title: string;
        question: MultipleTaskQuestion;
    };
    [TaskAnswerType.MultipleWithAttachment]: {
        title: string;
        question: MultipleWithAttachmentTaskQuestion;
    };
    [TaskAnswerType.Short]: {
        title: string;
        question: ShortTaskQuestion;
    };
    [TaskAnswerType.Single]: {
        title: string;
        question: SingleTaskQuestion;
    };
    [TaskAnswerType.SingleWithAttachment]: {
        title: string;
        question: SingleWithAttachmentTaskQuestion;
    };
} = () => {

    const { t } = useI18n('types.task');

    return {
        [TaskAnswerType.Arrange]: {
            title: t('enterQuestionTitle'),
            question: {
                textWithSkips: '',
                skips: [],
                fakeSkips: [],
            },
        },
        [TaskAnswerType.Boolean]: {
            title: t('enterQuestionTitle'),
            question: {
                variants: [
                    {
                        uuid: nanoid(),
                        text: t('initialBooleanTrue'),
                        correct: true,
                    },
                    {
                        uuid: nanoid(),
                        text: t('initialBooleanFalse'),
                        correct: false,
                    },
                ],
            },
        },
        [TaskAnswerType.Detailed]: {
            title: t('enterQuestionTitle'),
            question: {},
        },
        [TaskAnswerType.FillSpaces]: {
            title: t('enterQuestionTitle'),
            question: {
                textWithSkips: '',
                skips: [],
            },
        },
        [TaskAnswerType.Mapping]: {
            title: t('enterQuestionTitle'),
            question: {
                variants: [],
            },
        },
        [TaskAnswerType.Multiple]: {
            title: t('enterQuestionTitle'),
            question: {
                variants: [
                    {
                        uuid: nanoid(),
                        text: '',
                        correct: true,
                    },
                    {
                        uuid: nanoid(),
                        text: '',
                        correct: true,
                    },
                ],
            },
        },
        [TaskAnswerType.MultipleWithAttachment]: {
            title: t('enterQuestionTitle'),
            question: {
                variants: [
                    {
                        uuid: nanoid(),
                        text: '',
                        correct: true,
                        attachments: {},
                    },
                    {
                        uuid: nanoid(),
                        text: '',
                        correct: true,
                        attachments: {},
                    },
                ],
            },
        },
        [TaskAnswerType.Short]: {
            title: t('enterQuestionTitle'),
            question: {
                checking: {
                    caseSensitive: false,
                    correctAnyUserAnswer: false,
                },
                variants: [
                    {
                        uuid: nanoid(),
                        text: '',
                        correct: true,
                    },
                ],
            },
        },
        [TaskAnswerType.Single]: {
            title: t('enterQuestionTitle'),
            question: {
                variants: [
                    {
                        uuid: nanoid(),
                        text: '',
                        correct: true,
                        attachments: {},
                    },
                    {
                        uuid: nanoid(),
                        text: '',
                        correct: false,
                    },
                ],
            },
        },
        [TaskAnswerType.SingleWithAttachment]: {
            title: t('enterQuestionTitle'),
            question: {
                variants: [
                    {
                        uuid: nanoid(),
                        text: '',
                        correct: true,
                        attachments: {},
                    },
                    {
                        uuid: nanoid(),
                        text: '',
                        correct: false,
                        attachments: {},
                    },
                ],
            },
        },
    };
};
