/**
 * TagInlineText component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { CSSProperties, ReactElement, ReactNode } from 'react';

import { If } from '@/cutils';

import { gradient as stylesGradient } from '@/styles/modules/gradient';


interface Props {
    content: ReactNode | null | undefined;
    className?: string;
    style?: CSSProperties;
    gradient?: 'gray' | 'green' | 'red' | 'blue' | 'tertiary' | 'white' | 'secondary';
    icon?: ReactElement;
    dataTest?: string;
    dataValue?: string;
    maxHeight?: string;
    isPointer?: boolean;
    size?: 's' | 'm' | 'l';
    fontSize?: number;
    defaultMinWidth?: boolean;
    onClick?: () => void;
}


const TagInlineText = (props: Props) => {

    const {
        content,
        gradient,
        icon,
        dataTest,
        dataValue,
        style,
        onClick,
        size = 's',
        fontSize = 10,
        isPointer = true,
        maxHeight = 'max-h-2.5',
        defaultMinWidth = true,
    } = props;

    const className = [
        props.className,
        gradient === 'gray' && stylesGradient.gray500ToGray400,
        gradient === 'green' && stylesGradient.green500ToLime600,
        gradient === 'red' && stylesGradient.orange600ToRed500,
        gradient === 'blue' && stylesGradient.blue600ToPurple500Gradient,
        gradient === 'white' && 'from-white to-white',
        gradient === 'tertiary' && 'text-secondary font-medium px-0',
        gradient === 'secondary' && 'bg-button-secondary text-primary font-medium',
        ![ 'secondary', 'tertiary' ].includes(gradient || '') ? 'text-white' : '',
    ].filter(e => e).join(' ');

    return (
        <>
            <div onClick={onClick} data-test={dataTest} data-value={dataValue} style={style} className={[
                className,
                maxHeight,
                defaultMinWidth ? 'min-w-[29px]' : '',
                isPointer ? 'cursor-pointer' : '',
                `rounded-full fs-${fontSize} gap-1 leading-none`,
                'inline-flex justify-center items-center bg-gradient-to-br',
                size === 's'
                    ? 'p-1 py-0.5'
                    : (size === 'm'
                            ? 'py-1 px-1.5'
                            : (size === 'l' ? 'py-2.5 px-3' : '')
                    ),
            ].join(' ')}>
                <If is={!!icon}>
                    <div>{icon}</div>
                </If>

                <div className="w-fit text-ellipsis overflow-hidden">
                    {content}
                </div>
            </div>
        </>
    );
};


export { TagInlineText };
