/**
 * TopBarBlankPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If } from '@/cutils';
import { Caption, Text } from '@exode.ru/vkui';


interface Props {
    title: string;
    subtitle?: string;
}


const TopBarBlankPart = (props: Props) => {

    const { title, subtitle } = props;

    return (
        <div className="flex justify-center items-center py-2 px-2 thin-border-bottom h-[36px]">
            <div className="text-center">
                <Text weight="regular"
                      data-test="chat.topbar.name"
                      className="text-ellipsis overflow-x-hidden whitespace-nowrap m-auto">
                    {title}
                </Text>

                <If is={!!subtitle}>
                    <Caption level="2" weight="regular" className="text-subhead">
                        {subtitle}
                    </Caption>
                </If>
            </div>
        </div>
    );
};


export { TopBarBlankPart };
