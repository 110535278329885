/**
 * HeaderView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { useState } from 'react';

import { DebounceInput } from 'react-debounce-input';

import { useI18n } from '@/hooks/core';
import { SvgComponent } from '@/cutils';
import { DebouncedInput } from '@/types/html';
import { observer, Page } from '@/pages/Core';

import { Button, Search, Separator } from '@exode.ru/vkui';
import { Icon20BookSpreadOutline, Icon24CancelOutline, Icon24Filter } from '@vkontakte/icons';

import { GradientBannerImage } from '@/components/Atoms/GradientBanner';

import { FilterView } from './FilterView';
import { useManageHomeworkFilter } from '../filter';


const HeaderView = observer(() => {

    const { t } = useI18n('pages.Manage.Homework.views');

    const {
        urlFilter,
        setUrlFilter,
        removeUrlFilter,
    } = useManageHomeworkFilter();

    const [ showFilter, setShowFilter ] = useState(
        !_.isEmpty(
            _.omitBy(
                _.pick(urlFilter, [
                    'statuses',
                    'courseIds',
                    'launchIds',
                    'lessonsIds',
                    'curatorIds',
                ]),
                _.isEmpty,
            ),
        ),
    );

    return (
        <Page.MainHeader stacked
                         title={t('practiceManagement')}
                         subtitle={t('practiceHeaderSubtitle')}
                         wrapperClassName="px-8"
                         pageSectionProps={{ className: 'px-0' }}
                         pageRowProps={{ innerNoHorizontalPadding: 0 }}
                         children={showFilter && (
                             <>
                                 <Separator wide className="mt-3"/>
                                 <FilterView/>
                             </>
                         )}
                         icon={(
                             <GradientBannerImage className="-ml-12 banner__image absolute">
                                 <SvgComponent element={<Icon20BookSpreadOutline/>} svgProps={{
                                     width: 160,
                                     height: 160,
                                     className: 'absolute m-auto right-4 text-accent opacity-50',
                                     style: {
                                         marginRight: -60,
                                         transform: 'rotate(20deg)',
                                     },
                                 }}/>
                             </GradientBannerImage>
                         )}
                         actions={(
                             <div className="flex items-center gap-2">
                                 <Button mode="secondary"
                                         className="h-[32px] vk-rounded"
                                         before={showFilter ? <Icon24CancelOutline/> : <Icon24Filter/>}
                                         onClick={() => {
                                             if (showFilter) {
                                                 removeUrlFilter();
                                             }

                                             setShowFilter(!showFilter);
                                         }}>
                                     {showFilter ? t('resetFilters') : t('filters')}
                                 </Button>

                                 <DebounceInput name="search"
                                                minLength={1}
                                                debounceTimeout={500}
                                                value={urlFilter.search}
                                                className="px-0 w-[300px]"
                                                element={Search as DebouncedInput}
                                                onChange={({ target: { value } }) => setUrlFilter({ search: value })}/>
                             </div>
                         )}>
        </Page.MainHeader>
    );
});


export { HeaderView };
