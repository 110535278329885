/**
 * UseNotificationUnread
 *
 * @Note: Wrap a usage case in observer
 *
 * @author: exode <hello@exode.ru>
 */

import { UserAuthStore } from '@/store/user/auth';

import { NotificationStatus, useNotificationCountByStatusQuery } from '@/codegen/graphql';


export const useNotificationUnread = () => {

    const {
        loading: unreadCountNotificationLoading,
        data: { notificationCountByStatus: unreadCountNotification } = {},
    } = useNotificationCountByStatusQuery({
        skip: !UserAuthStore.isLoggedIn,
        variables: {
            notificationStatus: NotificationStatus.Unread,
        },
    });

    return {
        unreadCountNotification,
        unreadCountNotificationLoading,
    };
};
