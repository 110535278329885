/**
 * IconPicker
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { useState } from 'react';

import { If, SvgComponent } from '@/cutils';

import { Avatar } from '@exode.ru/vkui';
import { Dropdown } from '@exode.ru/vkui/dist/unstable';
import { Icon24SmileAddOutline } from '@vkontakte/icons';

import { dynamicIcons } from '@/components/Atoms/DynamicVkIcon';

import { gradient } from '@/styles/modules/gradient';


interface Props {
    defaultIcon?: string | null;
    onSelect: (icon: string) => void;
}


const IconPicker = (props: Props) => {

    const { onSelect, defaultIcon } = props;

    const [ pickerShown, setPickerShown ] = useState(false);

    const svgProps = { width: 48, height: 48, fill: 'var(--white)' };

    return (
        <Dropdown action="click" placement="bottom-start" shown={pickerShown} onShownChange={setPickerShown} content={(
            <div className="w-full h-[250px] overflow-auto whitespace-nowrap">
                <div className="grid grid-cols-5 p-2">
                    {_.map(Object.keys(dynamicIcons), (iconKey) => (
                        <div className="hover:bg-hover vk-rounded cursor-pointer p-2" onClick={() => {
                            onSelect(iconKey);
                            setPickerShown(false);
                        }}>
                            <SvgComponent svgProps={{ fill: 'var(--accent)' }}
                                          element={dynamicIcons[iconKey as keyof typeof dynamicIcons]}/>
                        </div>
                    ))}
                </div>
            </div>
        )}>
            <Avatar size={72} className={[
                'cursor-pointer',
                `bg-gradient-to-tl ${gradient.blue400ToBlue700Gradient}`,
            ].join(' ')}>
                <If is={!!defaultIcon}>
                    <SvgComponent svgProps={svgProps} element={dynamicIcons[defaultIcon as keyof typeof dynamicIcons]}/>
                </If>

                <If is={!defaultIcon}>
                    <SvgComponent svgProps={svgProps} element={<Icon24SmileAddOutline/>}/>
                </If>
            </Avatar>
        </Dropdown>
    );
};


export { IconPicker };
