/**
 * TaskSearch
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useState } from 'react';

import { DebounceInput } from 'react-debounce-input';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';

import { Graphql } from '@/cutils';
import { DebouncedInput } from '@/types/html';

import { Button, Search } from '@exode.ru/vkui';
import { TaskFindManyQuery } from '@/codegen/graphql';

import { TaskSimpleCell } from '@/components/Task/TaskSimpleCell';


interface Props {
    selectedUuids?: string[];
    onSelect?: (taskUuids: string[]) => void;
}


const TaskSearch = (props: Props) => {

    const { onSelect, selectedUuids = [] } = props;

    const { t } = useI18n('components.Task.Search');

    const [ search, setSearch ] = useState('');
    const [ selected, setSelected ] = useState(selectedUuids);

    return (
        <>
            <DebounceInput element={Search as DebouncedInput}
                           name="search"
                           value={search}
                           minLength={1}
                           debounceTimeout={500}
                           className="mb-2 px-0"
                           placeholder={t('searchByTasks')}
                           onChange={(e) => setSearch(e.target.value)}/>

            <div>
                <TaskFindManyQuery children={(result) => (
                    <>
                        <Graphql.Loading result={result}>
                            <Page.Spinner/>
                        </Graphql.Loading>

                        <Graphql.Success result={result}>
                            {({ taskFindMany: { items } }) => (
                                <div>
                                    {items?.map((task) => (
                                        <TaskSimpleCell key={task.id} task={task} onClick={() => {
                                            setSelected((prevState) => (
                                                prevState.includes(task.uuid)
                                                    ? prevState.filter(e => e !== task.uuid)
                                                    : [ ...prevState, task.uuid ]
                                            ));
                                        }}/>
                                    ))}
                                </div>
                            )}
                        </Graphql.Success>

                        <Graphql.Error result={result}/>
                    </>
                )} variables={{
                    list: { skip: 0, take: 20 },
                    filter: { search, manage: true },
                }}/>
            </div>

            <div className="flex h-fit mt-4">
                <Button size="l" className="h-fit" stretched onClick={() => onSelect?.(selected)}>
                    {t('select')}
                </Button>
            </div>
        </>
    );
};


export { TaskSearch };
