/**
 * TopBarPartPersonalCreateItem
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import toast from 'react-hot-toast';

import { APP_URL, IS_NATIVE } from '@/root/src/env';

import { ChatProfileFindOneQuery, UserException, UserSexType } from '@/codegen/graphql';

import { useI18n } from '@/hooks/core';
import { getGqlCause } from '@/api/graphql';
import { Graphql, If, Link, Redirect } from '@/cutils';

import { Caption, Text } from '@exode.ru/vkui';
import { Icon20UserSlashOutline } from '@vkontakte/icons';

import { User } from '@/components/Atoms/User';
import { LastOnlineText } from '@/components/Profile/Page';

import { TopBarSkeleton } from '../parts/TopBarSkeleton';


interface Props {
    personalUserId: number;
}


const TopBarPartPersonalCreateItem = (props: Props) => {

    const { personalUserId } = props;

    const { t } = useI18n('pages.Chat.Dialog');

    return (
        <ChatProfileFindOneQuery children={(result) => (
            <>
                <Graphql.Loading result={result}>
                    <TopBarSkeleton/>
                </Graphql.Loading>

                <Graphql.Success result={result}>
                    {({ profileFindOne: profile }) => (
                        <Link blank={!IS_NATIVE}
                              toOuter={!IS_NATIVE ? `${APP_URL}/@${personalUserId}` : undefined}
                              pushPage={{
                                  id: '/@:userId([0-9_A-Za-z]+)',
                                  params: { userId: `${personalUserId}` },
                              }}>
                            <div className="text-center">
                                <Text weight="regular">
                                    <User.Name profile={profile} className="justify-center"/>
                                </Text>

                                <Caption className="text-subhead" weight="regular" level="2">
                                    <LastOnlineText userId={personalUserId}
                                                    lastOnlineAt={profile?.user.lastOnlineAt}
                                                    sex={profile?.sex || UserSexType.Ufo}/>
                                </Caption>
                            </div>
                        </Link>
                    )}
                </Graphql.Success>

                <Graphql.Error result={result}>
                    {(error) => {
                        if (getGqlCause(error) === UserException.NotFound) {
                            toast(t('usersWereNotFound'), {
                                icon: <Icon20UserSlashOutline width={24} height={24} fill="var(--accent)"/>,
                                position: 'bottom-right',
                            });
                        }

                        return (
                            <>
                                <If is={getGqlCause(error) === UserException.NotFound}>
                                    <Redirect to="/chat" replace/>
                                </If>

                                <If is={getGqlCause(error) !== UserException.NotFound}>
                                    <></>
                                </If>
                            </>
                        );
                    }}
                </Graphql.Error>
            </>
        )} variables={{ userId: personalUserId }}/>
    );
};


export { TopBarPartPersonalCreateItem };
