/**
 * AudioContentElementShow
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer } from '@/store/core/config';

import { If } from '@/cutils';
import { AudioContent } from '@/shared/types';

import { ContentElementShowProps } from '../interfaces';


interface Props extends ContentElementShowProps<AudioContent> {

}


const AudioContentElementShow = observer((props: Props) => {

    const { contentElement: { content } } = props;

    return (
        <If is={!!content.location}>
            <audio controls className="w-full my-2">
                <source src={content.location}/>
            </audio>
        </If>
    );
});


export { AudioContentElementShow };
