/**
 * HeaderItemPart
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement, ReactNode } from 'react';

import { SvgComponent } from '@/cutils';

import { Button, ButtonProps } from '@exode.ru/vkui';


interface Props extends Omit<ButtonProps, 'title'> {
    title?: ReactNode;
    before?: ReactElement;
    active?: boolean;
    textClassName?: string;
}


const HeaderItemPart = (props: Props) => {

    const {
        title,
        before,
        active,
        className,
        textClassName,
        ...rest
    } = props;

    return (
        <Button size="l"
                mode="tertiary"
                disabled={active}
                className={[ className, 'rounded-none' ].join(' ')}
                before={before && <SvgComponent element={before} svgProps={{ fill: 'var(--accent)' }}/>}
                children={(
                    title
                        ? (
                            <span className={[
                                'text-primary',
                                textClassName,
                                active ? 'text-underline-marker h-after-4' : '',
                            ].join(' ')}>
                                {title}
                            </span>
                        )
                        : undefined
                )} {...rest}/>
    );
};


export { HeaderItemPart };
