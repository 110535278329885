/**
 * Use debounce update
 *
 * @author: exode <hello@exode.ru>
 */

import { useEffect, useRef } from 'react';

import { useDebounce } from 'use-debounce';


export const useDebounceUpdate = <V = Record<string, unknown> | string, >(
    value: V,
    callback: (v: V) => void,
    options: {
        disabled?: boolean;
        timeout?: number;
        onInstantChange?: () => void;
        onEmptyChanged?: () => void;
    } = {},
) => {
    const {
        disabled,
        onInstantChange,
        onEmptyChanged,
        timeout = 950,
    } = options;

    const isFirstRender = useRef(true);
    const prevDebouncedValueRef = useRef<string | null>(null);

    const [ debouncedValue ] = useDebounce(JSON.stringify(value), timeout);

    useEffect(() => {
        if (disabled) {
            return;
        }

        if (isFirstRender.current) {
            isFirstRender.current = false;
            prevDebouncedValueRef.current = debouncedValue;

            return;
        }

        if (debouncedValue !== prevDebouncedValueRef.current) {
            prevDebouncedValueRef.current = debouncedValue;
            callback(value);
        } else {
            onEmptyChanged?.();
        }
    }, [ disabled, debouncedValue ]);

    useEffect(() => {
        onInstantChange?.();
    }, [ value ]);
};
