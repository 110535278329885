/**
 * UseWordSelection
 *
 * @author: exode <hello@exode.ru>
 */

import { useCallback, useState } from 'react';


export const useWordSelection = () => {

    const [ selectedWordId, setSelectedWordId ] = useState<string | null>(null);

    const handleWordClick = useCallback((wordId: string) => {
        setSelectedWordId(prev => prev === wordId ? null : wordId);
    }, []);

    const clearSelection = useCallback(() => {
        setSelectedWordId(null);
    }, []);

    return {
        selectedWordId,
        handleWordClick,
        clearSelection,
    };
};
