/**
 * SystemMessageItem component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Text } from '@exode.ru/vkui';
import { ChatMessageItem } from '@/types/chat';

import { MessageReaderWrapperItem } from '../MessageReaderWrapperItem';

import { Container } from './SystemMessageItem.styled';


interface Props {
    message: ChatMessageItem;
}


const SystemMessageItem = (props: Props) => {

    const { message: { id, text } } = props;

    return (
        <MessageReaderWrapperItem message={props.message}>
            <Container key={id} data-id={text} className="flex items-center justify-center m-2">
                <Text className="thin-border text-secondary fs-14 px-3 py-0.5 rounded-2xl">
                    {text}
                </Text>
            </Container>
        </MessageReaderWrapperItem>
    );
};


export { SystemMessageItem };
