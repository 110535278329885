/**
 * EditHeaderPart
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { ReactElement } from 'react';

import { Formik } from 'formik';

import { useI18n } from '@/hooks/core';
import { useTaskManage } from '@/hooks/apollo';
import { AutoSaveForm, Field } from '@/cutils';
import { UpdateTaskInput } from '@/codegen/graphql';

import { FormItem, Input } from '@exode.ru/vkui';

import { TaskBuilderProps, useTaskBuilderContext } from '@/components/Task/Builder';


export interface EditHeaderPartProps {
    header: ReactElement;
    task: TaskBuilderProps['task'];
    updateTask: (values: UpdateTaskInput) => void;
}


const EditHeaderPart = (props: EditHeaderPartProps) => {

    const { task, header, updateTask } = props;

    const { t } = useI18n('components.Task.Builder.parts.create');

    const { edit } = useTaskBuilderContext();

    const { getInitialValues } = useTaskManage();

    return (
        <Formik initialValues={_.pick(getInitialValues(task), 'title')} onSubmit={(values) => updateTask(values)}>
            {({ values, handleSubmit, errors, touched, handleBlur, handleChange }) => (
                <>
                    {React.cloneElement(header, { task, title: values.title })}

                    <AutoSaveForm onChange={handleSubmit}
                                  onInstantChange={edit?.onInstantChange}
                                  className={[ !edit?.list?.expanded ? 'hidden' : '' ].join(' ')}>
                        <FormItem top={t('questionHeader*')}
                                  className="z-[3] pt-0 relative"
                                  status={Field.status(errors, touched, 'title')}
                                  bottom={Field.message(errors, touched, 'title', '')}>
                            <Input name="title"
                                   data-test="title"
                                   onBlur={handleBlur}
                                   value={values.title}
                                   placeholder={t('forExampleSelectCorrectAnswers')}
                                   onChange={Field.transform.ignoreSpaceOnChange(handleChange)}/>
                        </FormItem>
                    </AutoSaveForm>
                </>
            )}
        </Formik>
    );
};


export { EditHeaderPart };
