/**
 * FolderWrapper
 *
 * @author: exode <hello@exode.ru>
 */

import { ReactElement } from 'react';

import { Responsive } from '@/cutils';


interface Props {
    children: ReactElement | ReactElement[];
}


const FolderWrapper = (props: Props) => {
    return (
        <>
            <Responsive.DesktopOnly>
                <div className="vkuiHorizontalScroll__in-wrapper overflow-auto scrollbar-hidden">
                    {props.children}
                </div>
            </Responsive.DesktopOnly>

            <Responsive.Tablet>
                {props.children}
            </Responsive.Tablet>
        </>
    );
};


export { FolderWrapper };
