/**
 * ManageGroupsListPage store
 *
 * @author: exode <hello@exode.ru>
 */

import { makeObservable } from 'mobx';

import { observer, PageStore } from '@/pages/Core';


/** Перемещен в store для решения circular dependency */
export const defaultFilter = {
    search: '',
    productIds: [] as number[],
    courseIds: [] as number[],
};

export const dtoFilterGroupsListTransformer = (filter: typeof defaultFilter) => filter;

class ManageGroupsListPage extends PageStore {

    constructor() {
        super();
        makeObservable(this);
    }

    state = {
        list: { skip: 0, take: 20 },
        filter: dtoFilterGroupsListTransformer(defaultFilter),
        sort: {},
    };

}

const ManageGroupsListPageStore = new ManageGroupsListPage();


export { observer, ManageGroupsListPageStore };
