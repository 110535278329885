/**
 * Use editorJs
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { nanoid } from 'nanoid';

import { MutableRefObject, useCallback, useRef } from 'react';

import { FormikHelpers, FormikState, FormikValues } from 'formik';

import { EditorCore } from '@react-editor-js/core/src/editor-core';
import { SavedData } from '@editorjs/editorjs/types/data-formats/block-data';


export type EditorJSMapRef = { [key: number | string]: EditorCore }

interface Props {
    /** Name of field in formik for get and setFieldValue */
    fieldName: string;
    /** Instance of editorJS */
    editorCore: MutableRefObject<EditorCore | null> | MutableRefObject<EditorJSMapRef>;
    /** Key of instance {Provide only}  */
    editorMapKey?: number | string;
    /** Callback on change value */
    callback?: (data: Record<any, any>) => void;
    /** Formik values */
    values?: FormikState<any>['values'];
    /** Formik setFieldValue */
    setFieldValue?: FormikHelpers<FormikValues>['setFieldValue'];
}


const useEditorJs = () => {

    const timeoutRef = useRef<NodeJS.Timeout>();

    const editorCore = useRef<EditorCore | null>(null);

    const editorCoreMap = useRef<EditorJSMapRef>({});

    const handleInitialize = useCallback((instance) => {
        editorCore.current = instance;
    }, []);

    const handleInitializeMap = useCallback(
        (instance, index: number | string) => (
            editorCoreMap.current[index] = instance
        ),
        [],
    );

    const extractText = (blocks: SavedData[] | undefined) => {
        return blocks?.map((block: SavedData) => block?.data?.text?.trim()).join('') || '';
    };

    const handleEditorJsChange = useCallback(async (props: Props) => {

        const {
            editorCore,
            values,
            setFieldValue,
            fieldName,
            callback,
            editorMapKey,
        } = props;

        try {
            const savedData = !_.isNil(editorMapKey)
                ? await (editorCore.current as EditorJSMapRef)?.[editorMapKey]?.save()
                : await (editorCore.current as EditorCore)?.save();

            const throttleSubmit = _.throttle(() => callback?.(savedData), 1000);

            const ignoreToCompare = [ 'time' ];
            const formikValue = _.get(values, fieldName);

            const stateIsEqual = _.isEqual(
                _.omit(formikValue, ignoreToCompare),
                _.omit(savedData, ignoreToCompare),
            );

            if (!values || !stateIsEqual) {
                setFieldValue?.(fieldName, savedData);

                return throttleSubmit();
            }
        } catch (error) {
            console.error(error);
        }
    }, []);

    const timeoutHandleEditorJsChange = useCallback((props: Props & { timeout?: number }) => {
        const { timeout = 250, ...rest } = props;

        const timedOutCallback = setTimeout(() => handleEditorJsChange(rest), timeout);

        timeoutRef.current && clearTimeout(timeoutRef.current);
        timeoutRef.current = timedOutCallback;

        return timedOutCallback;
    }, []);

    const isValidOutputData = (data: any) => {
        return data && !_.isEmpty(data?.blocks);
    };

    const isNotEmpty = (content: Record<any, any> | undefined) => {
        return content?.blocks.length
            && (
                content?.blocks.some((e: any) => e?.data?.text?.trim())
                || content?.blocks.some((e: any) => e?.data?.content?.length)
                || content?.blocks.some((e: any) => e?.data?.file)
                || ![ 'paragraph', 'quote', 'header' ].includes(content?.blocks[0]?.type)
            );
    };

    const getEditorJsInitial = () => {
        return {
            version: '2.29.0-rc.1',
            time: new Date().getTime(),
            blocks: [
                {
                    id: nanoid(10),
                    type: 'paragraph',
                    data: { text: '' },
                },
            ],
        };
    };

    const parseDefaultValue = (value: any) => {
        return isValidOutputData(value) ? value : getEditorJsInitial();
    };

    const createEditorJsKey = (
        key: string,
        index: number | undefined,
    ) => (
        index ? `${key}:${index}` : key
    );

    return {
        isNotEmpty,
        editorCore,
        editorCoreMap,
        extractText,
        parseDefaultValue,
        createEditorJsKey,
        isValidOutputData,
        getEditorJsInitial,
        handleInitialize,
        handleInitializeMap,
        handleEditorJsChange,
        timeoutHandleEditorJsChange,
    };
};


export { useEditorJs };
