/**
 * AppSplitColumn
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { ConfigStore } from '@/store/core/config';
import { observer, PageStore } from '@/store/core/page';

import { If, Responsive } from '@/cutils';
import { EpicViewType } from '@/types/router';
import { Panel, SplitCol, View } from '@exode.ru/vkui';

import { PanelHeaderSpace, PanelHeaderTab } from '@/components/Panel/Header';
import { PermanentPageContentTop } from '@/components/App/PermanentRender';
import { DesktopNavigationContextMenu, DesktopNavigationMenu, DesktopSlimMenu } from '@/components/Desktop/Navigation';

import { EpicRoot } from '../root-views/EpicRoot';


interface AppContainProps {
    type: EpicViewType;
}


export const AppSplitColumn = observer((props: AppContainProps) => {

    const { type } = props;

    const menu = {
        slim: type === 'fullscreen' || PageStore.mode === 'slim-menu',
        wide: PageStore.mode !== 'slim-menu'
            && ![ 'iframe', 'fullscreen' ].includes(type),
    };

    const className = [
        'viewport__wrapper',
        `viewport--${type}`,
        type === 'iframe' ? 'viewport__wrapper--full' : '',
    ].join(' ');

    return (
        <div id="viewport-wrapper" className={className} data-page-mode={PageStore.mode}>
            <If is={menu.wide}>
                <DesktopNavigationMenu/>
            </If>

            <If is={menu.slim}>
                <DesktopSlimMenu/>
            </If>

            <SplitCol spaced={ConfigStore.isDesktop} className="viewport__content viewport__content--page">

                <If is={![ 'iframe', 'fullscreen' ].includes(type)}>
                    <PanelHeaderSpace offset={PageStore.mode === 'slim-menu' ? 16 : 18}/>
                </If>

                <Responsive.Mobile>
                    <PanelHeaderTab/>
                </Responsive.Mobile>

                <View activePanel="epic-root"
                      data-page-space={PageStore.bottomSafeSpace}
                      data-page-entire-screen={PageStore.entireScreen}>
                    <Panel id="epic-root">
                        <PermanentPageContentTop/>

                        <EpicRoot/>
                    </Panel>
                </View>
            </SplitCol>

            <DesktopNavigationContextMenu type={type}/>
        </div>
    );
});
