/**
 * DetailedShow
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { useFormikContext } from 'formik';

import { OutputData } from '@editorjs/editorjs';
import { useEditorJs, useI18n } from '@/hooks/core';

import { StorageSpace } from '@/codegen/graphql';
import { editorJsTools, i18nEditorJs, If, ReactEditorJs } from '@/cutils';
import { DetailedTaskAnswer, DetailedTaskQuestion, FileExtension } from '@/shared/types';

import { Icon24Attach, Icon28DeleteOutline } from '@vkontakte/icons';

import { FileRow } from '@/components/Atoms/FileRow';
import { FileUpload } from '@/components/Atoms/FileUpload';
import { BlockTitle } from '@/components/Content/BlockTitle';
import { ConfirmButton } from '@/components/Atoms/ConfirmButton';
import { EditorJsOutput } from '@/components/Atoms/EditorJsOutput';
import { ProgressButton } from '@/components/Atoms/ProgressButton';
import { EditorJsTextArea } from '@/components/Atoms/EditorJsTextArea';
import { TaskBuilderShowProps, useTaskBuilderContext } from '@/components/Task/Builder';


interface Props extends TaskBuilderShowProps<
    DetailedTaskQuestion,
    DetailedTaskAnswer
> {

}


const DetailedShow = (props: Props) => {

    const { task, answer: { detailed } = {} } = props;

    const { t } = useI18n('components.Task.Builder.show.types');

    const { show: { subMode } } = useTaskBuilderContext();
    const { values, setFieldValue, handleSubmit } = useFormikContext();

    const {
        editorCore,
        isNotEmpty,
        handleInitialize,
        isValidOutputData,
        timeoutHandleEditorJsChange,
    } = useEditorJs();

    return (
        <div>
            <If is={subMode === 'answer'}>
                <EditorJsTextArea>
                    <ReactEditorJs holder={task.uuid}
                                   i18n={i18nEditorJs()}
                                   key={`content.${task.uuid}`}
                                   placeholder={t('enterAnswer')}
                                   onInitialize={handleInitialize}
                                   tools={editorJsTools({ space: StorageSpace.User })}
                                   defaultValue={detailed?.content as unknown as OutputData}
                                   onChange={() => timeoutHandleEditorJsChange({
                                       values,
                                       editorCore,
                                       setFieldValue,
                                       fieldName: 'detailed.content',
                                       callback: () => handleSubmit(),
                                   })}/>
                </EditorJsTextArea>
            </If>

            <If is={[ 'preview', 'result' ].includes(subMode)}>
                <>
                    <If is={subMode === 'result'}>
                        <BlockTitle title={t('yourAnswer')}/>
                    </If>

                    <If is={(
                        isValidOutputData(detailed?.content)
                        && isNotEmpty(detailed?.content)
                    )}>
                        <EditorJsOutput data={detailed?.content}/>
                    </If>

                    <If is={(
                        !isValidOutputData(detailed?.content)
                        && !isNotEmpty(detailed?.content)
                        && _.isEmpty(detailed?.attachments)
                    )}>
                        <div className={[
                            'flex flex-1 p-5 mt-2 bg-hover-50 thin-border h-full',
                            'items-center vk-rounded justify-center text-secondary fs-13',
                        ].join(' ')}>
                            {subMode === 'result'
                                ? t('answerIsEmpty')
                                : t('userAnswerWillAppearHere')
                            }
                        </div>
                    </If>
                </>
            </If>

            <>
                {detailed?.attachments?.map(({ location, type }, index) => (
                    <FileRow key={index}
                             mimeType={type}
                             hasHover={false}
                             location={location}
                             className="bg-hover mt-5"
                             name={`${t('yourFile')} ${index + 1}`}
                             after={(
                                 <If is={subMode === 'answer'}>
                                     <ConfirmButton stopPropagation
                                                    className="rounded-full"
                                                    confirmIcon={<Icon28DeleteOutline fill="var(--destructive)"/>}
                                                    initialIcon={(
                                                        <Icon28DeleteOutline fill="var(--icon_secondary)"/>
                                                    )}
                                                    callback={() => {
                                                        setFieldValue(
                                                            'detailed.attachments',
                                                            detailed?.attachments?.filter((__, i) => index !== i),
                                                        );

                                                        handleSubmit();
                                                    }}/>
                                 </If>
                             )}/>
                ))}
            </>

            <If is={subMode === 'answer'}>
                <FileUpload id="file"
                            width="100%"
                            height="100px"
                            className="mb-3"
                            multiple={false}
                            controlIconSize={28}
                            space={StorageSpace.User}
                            accept={FileExtension.Docs}
                            onFileUpload={([ file ]) => {
                                setFieldValue(
                                    'detailed.attachments',
                                    [
                                        ...(detailed?.attachments || []),
                                        {
                                            type: file.fileType,
                                            location: file.location,
                                        },
                                    ],
                                );

                                handleSubmit();
                            }}>
                    <ProgressButton stretched
                                    size="l"
                                    mode="secondary"
                                    className="mt-5"
                                    before={<Icon24Attach/>}
                                    data-test="practice.add-audio">
                        {t('enterAttachments')}
                    </ProgressButton>
                </FileUpload>
            </If>
        </div>
    );
};


export { DetailedShow };
