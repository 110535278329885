/**
 * HomeWorkTagPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { TagInlineText } from '@/components/Atoms/TagInlineText';


interface Props {
    className?: string;
}


const HomeWorkTagPart = (props: Props) => {

    const { className } = props;

    const { t } = useI18n('components.Course.LessonCard');

    return (
        <TagInlineText gradient="green" className={[ 'min-w-[18px]', className ].join(' ')} content={t('homework')}/>
    );
};


export { HomeWorkTagPart };
