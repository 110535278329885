/**
 * UpdateSchoolInput
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import * as Yup from 'yup';

import { IsNotEmpty, IsOptional, IsString, Matches, MaxLength, MinLength } from 'class-validator';

import { regex } from '@/utils';

import { SchoolContactRole } from '@/codegen/graphql';
import { isMinMax, isNotEmpty } from '@/libs/class-validator/constants';


export class CreateSchoolInput {

    @IsString()
    @IsNotEmpty({ message: isNotEmpty('Название', 'пустым') })
    @MinLength(3, { message: isMinMax(3, 'Название', 'min', 'должно') })
    readonly name: string;

    @IsString()
    @IsNotEmpty({ message: isNotEmpty('Домен', 'пустым') })
    @MinLength(3, { message: isMinMax(3, 'Домен', 'min', 'должен') })
    readonly baseDomain: string;

}

export class UpdateSchoolInput {

    @IsOptional()
    @MinLength(3, { message: isMinMax(3, 'Имя', 'min') })
    readonly name: string;

    @IsOptional()
    @IsNotEmpty({ message: isNotEmpty('Базовый домен') })
    @Matches(regex.urlPath, { message: 'Неверный домен' })
    @MaxLength(20, { message: isMinMax(20, 'Домен', 'max', 'должно') })
    readonly baseDomain: string;

    @IsOptional()
    @Matches(regex.urlDomain, { message: 'Неверный домен' })
    readonly customDomain: string;

}

export class UpdateMenuItemInput {

    @IsNotEmpty({ message: isNotEmpty('Название', 'пустым') })
    @MaxLength(100, { message: isMinMax(100, 'Ссылка', 'max', 'должна') })
    readonly name: string;

    @IsNotEmpty({ message: isNotEmpty('Ссылка', 'пустой') })
    @MaxLength(250, { message: isMinMax(250, 'Ссылка', 'max', 'должна') })
    readonly link: string;

}

export class CreateDocumentItemInput {

    @IsNotEmpty({ message: isNotEmpty('Название', 'пустым') })
    readonly name: string;

    @IsNotEmpty({ message: isNotEmpty('Ссылка', 'пустой') })
    readonly location: string;

}

export class SchoolOrganizationUpdateInput {

    @IsNotEmpty({ message: isNotEmpty('Инн', 'пустым') })
    readonly inn: string;

    @IsNotEmpty({ message: isNotEmpty('Название', 'пустым') })
    readonly organizationName: string;

}

export const SchoolContactItemInputSchema = Yup.object().shape({
    contacts: Yup.array().of(
        Yup.object().shape({
            name: Yup.string(),
            phone: Yup.string(),
            email: Yup.string(),
            link: Yup.string(),
            role: Yup.string().oneOf(_.values(SchoolContactRole)).optional(),
        }),
    ),
});

export const SchoolAddressItemInputSchema = Yup.object().shape({
    addresses: Yup.array().of(
        Yup.object().shape({
            name: Yup.string(),
            address: Yup.string(),
            locationLink: Yup.string(),
        }),
    ),
});

export const SchoolFooterItemInputSchema = Yup.object().shape({
    footer: Yup.array().of(
        Yup.object().shape({
            title: Yup.string(),
            items: Yup.array().of(
                Yup.object().shape({
                    text: Yup.string(),
                    link: Yup.string(),
                }),
            ),
        }),
    ),
});

export const SchoolLogoIconInputSchema = Yup.object().shape({
    blockSettings: Yup.object().shape({
        logo: Yup.object().shape({
            text: Yup.string().nullable(),
        }),
    }),
});
