/**
 * MessagesView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import toast from 'react-hot-toast';
import { useHotkeys } from 'react-hotkeys-hook';

import React, { Fragment, MutableRefObject, useCallback, useEffect, useLayoutEffect, useState } from 'react';

import { GqlResult } from '@/types/graphql';
import { observer, useStore } from '@/pages/Core';
import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';
import { SubscriptionStore } from '@/store/subscription/subscription';

import { ObservableQuery } from '@apollo/client/core/ObservableQuery';

import {
    ChatMessageFindManyQuery,
    ChatMessageFindManyQueryResult,
    ChatMessageType,
    useChatMessageReadManyMutation,
} from '@/codegen/graphql';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { DocumentEvent } from '@/types/window';
import { ChatService } from '@/services/Chat/Chat';
import { ChatMessageItemNullable } from '@/types/chat';
import { InfiniteScroll, ScrollHelper } from '@/helpers/ui';

import { Spinner } from '@exode.ru/vkui';
import { Icon24ClockCircleFillGray } from '@vkontakte/icons';

import { WindowsCustomScrollbar } from '@/components/Atoms/Styled';

import { InputViewProps } from './InputView';
import { useMessagesViewContext } from '../contexts/MessagesViewContext';

import { MessageItem } from '../items/MessageItem';
import { SystemMessageItem } from '../items/SystemMessageItem';
import { ScrollToBottomItem } from '../items/ScrollToBottomItem';


interface Props {
    arePinned: boolean;
    fetchMore: ObservableQuery<ChatMessageFindManyQuery>['fetchMore'];
    inputRef: MutableRefObject<HTMLTextAreaElement | null>;
    chatMessagesRef: MutableRefObject<HTMLDivElement | null>;
    messages: ChatMessageItemNullable;
    cursor: GqlResult<ChatMessageFindManyQueryResult>['chatMessageFindMany']['cursor'];
    topBarData?: InputViewProps['topBarData'];
}


const MessagesView = observer((props: Props) => {

    const {
        fetchMore,
        inputRef,
        chatMessagesRef,
        messages,
        cursor,
        topBarData,
    } = props;

    const { t } = useI18n('pages.Chat.Dialog');

    const { scrollToBottom, handleEditMessage } = useMessagesViewContext();

    const { store, list, filter, sort, params: { chatId } } = useStore(ChatDialogsPageStore);

    const [ readManyMessages ] = useChatMessageReadManyMutation();

    const [ position, setPosition ] = useState({ pageX: 0, pageY: 0 });
    const [ contextId, setContextId ] = useState<number | null>(null);

    const readMessages = useCallback(_.throttle(() => {
        if (!store.state.unreadMessageIds.size) {
            return;
        }

        const snapshotOfMessages = [ ...store.state.unreadMessageIds ];

        return readManyMessages({
            variables: {
                chatId: +chatId,
                messageIds: snapshotOfMessages,
            },
            update: (cache, { data }) => {
                cache.modify({
                    id: `ChatEntity:${chatId}`,
                    fields: {
                        countUnread: (value) => Math.max(value - (data?.chatMessageReadMany.length ?? 0), 0),
                    },
                });
            },
            onCompleted: () => {
                const newMessages = _.difference(
                    [ ...store.state.unreadMessageIds ],
                    snapshotOfMessages,
                );

                store.setUnreadMessageIds(newMessages);
            },
        });
    }, 2000), [ chatId ]);

    const onTryEditLastMessage = () => {
        const firstEditableMessage = _.orderBy(messages, 'id', 'desc')
            .find(({ isEditable, isMine }) => isMine && isEditable);

        if (firstEditableMessage) {
            store.setSelectedMessages({ [chatId]: [ firstEditableMessage ] });
            handleEditMessage?.();
        } else {
            toast(t('messageNotEditable'), {
                id: `chat-message-edit:${chatId}`,
                icon: <Icon24ClockCircleFillGray className="ml-1"/>,
            });
        }
    };

    useHotkeys('up', (keyboardEvent) => {
        keyboardEvent.stopPropagation();
        keyboardEvent.preventDefault();

        onTryEditLastMessage();
    }, [ messages ]);

    useEffect(() => {
        if (_.isFinite(+chatId)) {
            SubscriptionStore.subscribeToChatIds(+chatId);
        }
    }, [ chatId ]);

    useEffect(() => {
        store.state.unreadMessageIds.size && readMessages();
    }, [ store.state.unreadMessageIds.size ]);

    useLayoutEffect(() => {
        scrollToBottom?.();
    }, []);

    const onBeforeAddMessageInChat = (e: any) => {
        if (+chatId !== e.detail.chatId) {
            return;
        }

        const scrollFromBottom = ScrollHelper.scrollFromBottom(chatMessagesRef);

        const onAfterAddMessageInChat = () => {
            if (scrollFromBottom < 20) {
                setImmediate(() => {
                    scrollToBottom?.();

                    /** Trigger scroll to recalculate showScrollToBottom */
                    chatMessagesRef.current?.dispatchEvent(new CustomEvent('scroll'));
                });
            }
        };

        document.addEventListener(
            DocumentEvent.ChatAfterNewMessageAddInCache,
            onAfterAddMessageInChat,
            { once: true },
        );
    };

    const onAfterDeleteMessageFromChat = () => {
        /** Trigger scroll to recalculate showScrollToBottom */
        chatMessagesRef.current?.dispatchEvent(new CustomEvent('scroll'));
    };

    useEffect(() => {
        document.addEventListener(
            DocumentEvent.ChatTryEditLastMessage,
            onTryEditLastMessage,
        );

        return () => {
            document.removeEventListener(
                DocumentEvent.ChatTryEditLastMessage,
                onTryEditLastMessage,
            );
        };
    }, [ messages ]);

    /** Scroll in bottom on the new message */
    useEffect(() => {
        document.addEventListener(
            DocumentEvent.ChatBeforeNewMessageAddInCache,
            onBeforeAddMessageInChat,
        );

        document.addEventListener(
            DocumentEvent.ChatAfterDeleteMessageInCache,
            onAfterDeleteMessageFromChat,
        );

        return () => {
            document.removeEventListener(
                DocumentEvent.ChatBeforeNewMessageAddInCache,
                onBeforeAddMessageInChat,
            );

            document.removeEventListener(
                DocumentEvent.ChatAfterDeleteMessageInCache,
                onAfterDeleteMessageFromChat,
            );
        };
    }, []);

    return (
        <div className="flex flex-1 relative">
            <WindowsCustomScrollbar ref={chatMessagesRef} className={[
                'overflow-auto overflow-x-hidden pb-3',
                'flex flex-col absolute top-0 bottom-0 left-2 right-2',
                ChatService.isChannelButNotAdmin(topBarData) ? 'm:mb-14' : '',
            ].join(' ')}>
                <InfiniteScroll isReverse
                                threshold={1500}
                                initialLoad={false}
                                useWindow={false}
                                hasMore={cursor.hasPrev}
                                className="mt-2 first:mt-auto first:pt-2"
                                getScrollParent={() => chatMessagesRef.current}
                                loader={<Spinner key={0} className="mb-2.5 h-[60px] loader"/>}
                                loadMore={async (__: number, beforeInsertMessages: Function) => {
                                    await fetchMore({
                                        variables: {
                                            chatId: +chatId,
                                            list: { ...list.messages },
                                            filter: { ...filter.messages },
                                            sort: { ...sort.messages },
                                            cursor: { before: cursor.before },
                                        },
                                        updateQuery: (previousQueryResult, { fetchMoreResult }) => {
                                            beforeInsertMessages();

                                            return {
                                                chatMessageFindMany: {
                                                    __typename: 'ListMessageChatOutput',
                                                    cursor: {
                                                        __typename: 'CursorOutput',
                                                        after: fetchMoreResult.chatMessageFindMany.cursor.after,
                                                        before: fetchMoreResult.chatMessageFindMany.cursor.before,
                                                        hasNext: fetchMoreResult.chatMessageFindMany.cursor.hasNext,
                                                        hasPrev: fetchMoreResult.chatMessageFindMany.cursor.hasPrev,
                                                    },
                                                    items: [
                                                        ...(fetchMoreResult?.chatMessageFindMany?.items ?? []),
                                                        ...(previousQueryResult?.chatMessageFindMany.items ?? []),
                                                    ],
                                                },
                                            };
                                        },
                                    });
                                }}>
                    {messages?.map((message, i) => (
                        <Fragment key={message.id}>
                            <If is={message.type === ChatMessageType.User}>
                                <MessageItem index={i}
                                             chatId={+chatId}
                                             message={message}
                                             inputRef={inputRef}
                                             messages={messages}
                                             position={position}
                                             setPosition={setPosition}
                                             contextId={contextId}
                                             setContextId={setContextId}/>
                            </If>

                            <If is={message.type === ChatMessageType.System}>
                                <SystemMessageItem message={message}/>
                            </If>
                        </Fragment>
                    ))}
                </InfiniteScroll>
            </WindowsCustomScrollbar>

            <ScrollToBottomItem chatId={+chatId}
                                chatMessagesRef={chatMessagesRef}
                                onClick={() => scrollToBottom?.(true)}
                                className={ChatService.isChannelButNotAdmin(topBarData) ? 'm:!bottom-[70px]' : ''}/>
        </div>
    );
});


export { MessagesView };
