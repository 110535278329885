/**
 * ChatFriendsHasNotPersonalChatView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Graphql } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { useLocation } from '@/router/index';
import { ChatCellItem } from '@/pages/Chat/Dialog/items/ChatCellItem';

import { LastOnlineText } from '@/components/Profile/Page';
import { ChatEntity, ChatType, FindManyFriendsHasNotPersonalChatQuery } from '@/codegen/graphql';

import {
    friendsHasNotPersonalChatFilterConfig,
    friendsHasNotPersonalChatListConfig,
} from '@/pages/Chat/Dialog/constants';

import { DialogsListViewProps } from './DialogsListView';


interface Props {
    onChatCellClick: DialogsListViewProps['onChatCellClick'];
    onSuccess?: (result: { count: number }) => void;
}


const ChatFriendsHasNotPersonalChatView = (props: Props) => {

    const { onChatCellClick, onSuccess } = props;

    const { t } = useI18n('pages.Chat.Dialog');

    const { route: { params: { personalUserId } } } = useLocation();

    return (
        <FindManyFriendsHasNotPersonalChatQuery children={(result) => (
            <>
                <Graphql.Success result={result}>
                    {({ friendshipFindMany: { items } }) => (
                        <>
                            {items?.map((user) => (
                                <ChatCellItem key={user.id}
                                              isRounded={false}
                                              lastActionText=""
                                              isActive={+personalUserId === user.id}
                                              onChatCellClick={() => onChatCellClick('user', { userId: user.id })}
                                              lastMessageText={(
                                                  <LastOnlineText userId={user.id}
                                                                  sex={user.profile.sex}
                                                                  lastOnlineAt={user.lastOnlineAt}/>
                                              )}
                                              chat={{
                                                  type: ChatType.Personal,
                                                  name: user.profile.fullName,
                                                  description: '',
                                                  icon: user.profile.avatar.medium,
                                                  countUnread: 0,
                                                  personalCompanionMember: { user },
                                                  lastMessage: {
                                                      createdAt: '-',
                                                      user: { profile: { fullName: t('startChatting') } },
                                                  },
                                              } as ChatEntity}/>
                            ))}
                        </>
                    )}
                </Graphql.Success>

                <Graphql.Error result={result}/>
            </>
        )} onCompleted={({ friendshipFindMany: { count } }) => onSuccess?.({ count })} variables={{
            list: friendsHasNotPersonalChatListConfig,
            filter: friendsHasNotPersonalChatFilterConfig,
        }}/>
    );
};


export { ChatFriendsHasNotPersonalChatView };
