/**
 * Chat dialog styled items
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const ChatCellContainer = styled.div`
    min-height: 75px;

    .vkuiRichCell__before {
        display: flex;
    }
`;

export const MessageItemContainer = styled.div`

`;

export const chatIconClass = 'opacity-70 hover:opacity-100 cursor-pointer';
