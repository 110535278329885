/**
 * PhotoProvider
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ComponentProps } from 'react';

import { PhotoProvider as ReactPhotoProvider, PhotoView } from 'react-photo-view';

import { Router } from '@/services/Utils/Router';


interface Props extends ComponentProps<typeof ReactPhotoProvider> {

}


const PhotoProvider = (props: Props) => {
    return (
        <ReactPhotoProvider maskOpacity={0.9} onVisibleChange={(visible) => {
            Router.updateParams({ photoViewer: `${visible}` });
        }} {...props}/>
    );
};


export { PhotoProvider, PhotoView };
