/**
 * UseWebhookManage
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { useStore } from '@/pages/Core';
import { ManageSchoolWebhooksPageStore } from '@/pages/Manage/School/Webhooks/store';

import { Notify } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { getGqlCause } from '@/api/graphql';

import { SchoolWebhookEndpointItem } from '@/types/school';

import {
    CreateEndpointWebhookInput,
    UpdateEndpointWebhookInput,
    useWebhookEndpointCreateMutation,
    useWebhookEndpointDeleteMutation,
    useWebhookEndpointUpdateMutation,
    WebhookEndpointFindManyDocument,
    WebhookEndpointFindManyQuery,
    WebhookException,
} from '@/codegen/graphql';


export const useWebhookManage = () => {

    const { t } = useI18n('hooks.apollo.school');

    const { list, filter, sort } = useStore(ManageSchoolWebhooksPageStore);

    const getVariables = () => ({
        list: { ...list },
        filter: { ...filter },
        sort: { ...sort },
    });

    const getInitialValues = (webhook?: SchoolWebhookEndpointItem) => ({
        url: webhook?.url || '',
        events: webhook?.events || [],
        note: webhook?.note || '',
        active: !_.isNil(webhook?.active)
            ? webhook?.active
            : true,
    });

    const [ _createWebhookEndpoint, {
        error: createWebhookEndpointError,
        loading: createWebhookEndpointLoading,
    } ] = useWebhookEndpointCreateMutation({
        update: (cache, { data }) => {
            const cachedWebhookEndpoints = cache.readQuery<WebhookEndpointFindManyQuery>({
                variables: getVariables(),
                query: WebhookEndpointFindManyDocument,
            });

            if (!cachedWebhookEndpoints) {
                return console.warn('[Cache]: cachedWebhookEndpoints отсутствуют в кэше');
            }

            const { items, count, page, pages } = cachedWebhookEndpoints.webhookEndpointFindMany || {};

            if (data?.webhookEndpointCreate) {
                cache.writeQuery<WebhookEndpointFindManyQuery>({
                    variables: getVariables(),
                    query: WebhookEndpointFindManyDocument,
                    data: {
                        webhookEndpointFindMany: {
                            page: page || 1,
                            pages: pages || 1,
                            count: count + 1,
                            items: [
                                data.webhookEndpointCreate,
                                ...(items ?? []),
                            ],
                        },
                    },
                });
            }
        },
        onError: (error) => {
            switch (getGqlCause(error)) {
                case WebhookException.LimitOfCreatedEndpointsExcited:
                    Notify.toast.error(t('webhooksLimitExceeded'));
            }
        },
    });

    const createWebhookEndpoint = (
        endpoint: CreateEndpointWebhookInput,
        onCompleted?: () => void,
    ) => {
        return _createWebhookEndpoint({
            variables: { endpoint },
            onCompleted: () => onCompleted?.(),
        });
    };

    const [ _updateWebhookEndpoint, {
        error: updateWebhookEndpointError,
        loading: updateWebhookEndpointLoading,
    } ] = useWebhookEndpointUpdateMutation();

    const updateWebhookEndpoint = (
        endpointId: number,
        endpoint: UpdateEndpointWebhookInput,
        onCompleted?: () => void,
    ) => {
        return _updateWebhookEndpoint({
            variables: { endpointId, endpoint },
            onCompleted: () => onCompleted?.(),
        });
    };

    const [ _deleteWebhookEndpoint, {
        error: deleteWebhookEndpointError,
        loading: deleteWebhookEndpointLoading,
    } ] = useWebhookEndpointDeleteMutation();

    const deleteWebhookEndpoint = (endpointId: number) => {
        return _deleteWebhookEndpoint({
            variables: { endpointId },
            update: (cache, { data }) => {
                const cachedWebhookEndpoints = cache.readQuery<WebhookEndpointFindManyQuery>({
                    variables: getVariables(),
                    query: WebhookEndpointFindManyDocument,
                });

                if (!cachedWebhookEndpoints) {
                    return console.warn('[Cache]: cachedWebhookEndpoints отсутствуют в кэше');
                }

                const { items, count, page, pages } = cachedWebhookEndpoints.webhookEndpointFindMany || {};

                if (data?.webhookEndpointDelete) {
                    cache.writeQuery<WebhookEndpointFindManyQuery>({
                        variables: getVariables(),
                        query: WebhookEndpointFindManyDocument,
                        data: {
                            webhookEndpointFindMany: {
                                page: page || 1,
                                pages: pages || 1,
                                count: count - 1 || 0,
                                items: items.filter(({ id }) => id !== endpointId),
                            },
                        },
                    });
                }
            },
        });
    };

    return {
        createWebhookEndpoint,
        createWebhookEndpointError,
        createWebhookEndpointLoading,
        updateWebhookEndpoint,
        updateWebhookEndpointError,
        updateWebhookEndpointLoading,
        deleteWebhookEndpoint,
        deleteWebhookEndpointError,
        deleteWebhookEndpointLoading,
        getInitialValues,
    };
};
