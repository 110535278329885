/**
 * PageModeSwitcher
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactNode } from 'react';

import { observer, PageStore as PageCoreStore, PageStore } from '@/store/core/page';

import { SegmentedControl } from '@exode.ru/vkui';
import { Icon20MenuOutline, Icon20SquareSplit4Outline } from '@vkontakte/icons';


interface Props {
    children?: ReactNode;
}


const PageModeSwitcher = observer((props: Props) => {

    const { mode } = PageStore;

    return (
        <div className="flex items-center justify-between w-full">
            {props.children}

            <SegmentedControl size="m"
                              value={PageCoreStore.mode}
                              className="thin-border"
                              data-test="settings-role"
                              onChange={(value) => {
                                  PageCoreStore.setPageMode(value as 'regular');
                              }}
                              options={[
                                  {
                                      value: 'regular',
                                      label: (
                                          <Icon20MenuOutline className="min-w-[40px]" fill={(
                                              mode === 'regular'
                                                  ? 'var(--button_primary_foreground)'
                                                  : 'var(--icon_primary)'
                                          )}/>
                                      ),
                                  },
                                  {
                                      value: 'slim-menu',
                                      label: (
                                          <Icon20SquareSplit4Outline className="min-w-[40px]" fill={(
                                              mode === 'slim-menu'
                                                  ? 'var(--button_primary_foreground)'
                                                  : 'var(--icon_primary)'
                                          )}/>
                                      ),
                                  },
                              ]}/>
        </div>
    );
});


export { PageModeSwitcher };
