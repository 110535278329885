/**
 * SkipsListAtom
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { FormItem } from '@exode.ru/vkui';
import { Icon16CancelCircle } from '@vkontakte/icons';

import { TagInlineText } from '@/components/Atoms/TagInlineText';

import { Skip } from '../types';
import { AddFakeSkipAtom } from './AddFakeSkipAtom';


interface Props {
    skips: Skip[];
    onRemoveSkip: (uuid: string) => void;
    onAddFakeWord: (text: string) => void;
}


const SkipsListAtom = (props: Props) => {

    const { skips, onRemoveSkip, onAddFakeWord } = props;

    const { t } = useI18n('components.Task.Builder.edit.arrange');

    return (
        <FormItem className="pt-0 mb-1" top={t('createdSkips')}>
            <div className="flex flex-wrap gap-2 mt-2 mb-7">
                {skips.map((skip) => (
                    <div key={skip.uuid} className={[
                        skip.isFake ? 'bg-destructive-20' : 'bg-positive-20',
                        'flex items-center justify-center font-medium py-1.5 pr-1.5 pl-2.5 vk-rounded word-break',
                    ].join(' ')}>
                        <span className="fs-13">
                            {skip.text}
                        </span>

                        <div onClick={() => onRemoveSkip(skip.uuid)} className={[
                            'cursor-pointer ml-1 opacity-50',
                            'hover:opacity-75 transition-opacity duration-150',
                        ].join(' ')}>
                            <Icon16CancelCircle/>
                        </div>
                    </div>
                ))}

                <If is={!skips.length}>
                    <TagInlineText size="l"
                                   fontSize={12}
                                   gradient="gray"
                                   isPointer={false}
                                   content={t('skipsNotCreated')}/>
                </If>
            </div>

            <AddFakeSkipAtom onAddFakeWord={onAddFakeWord}/>
        </FormItem>
    );
};


export { SkipsListAtom };
