/**
 * DiscountFormView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { useRef } from 'react';

import { observer } from '@/pages/Core';
import { PlatformStore } from '@/store/platform';

import { Form, Formik, FormikProps } from 'formik';

import { getGqlCause } from '@/api/graphql';
import { createValidator } from 'class-validator-formik';

import { useI18n } from '@/hooks/core';
import { ObjectUtil, regex } from '@/utils';
import { useLocation } from '@/router/index';
import { Router } from '@/services/Utils/Router';
import { useProductDiscount } from '@/hooks/apollo';
import { Field, Notify, numberOrNull } from '@/cutils';
import { DiscountItem, productCurrency } from '@/types/product';
import { CreateDiscountInput } from '@/libs/class-validator/course';

import { CreateDiscountInput as GqlCreateDiscountInput, DiscountException, DiscountType } from '@/codegen/graphql';

import { TextTooltip } from '@exode.ru/vkui/unstable';
import { Icon24AddSquareOutline, Icon24CheckSquareOutline } from '@vkontakte/icons';
import { Button, Checkbox, FormItem, FormLayoutGroup, Input, SegmentedControl, Textarea } from '@exode.ru/vkui';

import { FilterInput } from '@/components/Atoms/FilterInput';
import { StickyButton } from '@/components/Atoms/StickyButton';
import { InputSideInfo } from '@/components/Atoms/InputSideInfo';
import { CheckListTooltip } from '@/components/Atoms/CheckListTooltip';
import { DateInputInterval } from '@/components/Atoms/DateInputInterval';
import { FieldBottomLimiter } from '@/components/Atoms/FieldBottomLimiter';

import { useDiscountsFilter } from '../filter';


interface Props {
    discount?: DiscountItem;
    filter: ReturnType<typeof useDiscountsFilter>;
}


const DiscountFormView = observer((props: Props) => {

    const { discount, filter } = props;

    const { COMMON } = PlatformStore;

    const { t } = useI18n('pages.Manage.Product.Discount');

    const { route: { params: { productId } } } = useLocation();

    const formikRef = useRef<FormikProps<GqlCreateDiscountInput>>(null);

    const {
        createDiscount,
        createDiscountLoading,
        updateDiscount,
        updateDiscountLoading,
        getInitialValues,
        generateRandomCode,
    } = useProductDiscount({
        filter,
        onCompleted: () => {
            Router.replaceModal();

            Notify.toast.success(
                discount
                    ? t('promoCodeWasSuccessfullyUpdated')
                    : t('promoCodeWasSuccessfullyCreated'),
            );
        },
        onError: (error) => {
            if (getGqlCause(error) === DiscountException.CodeAlreadyExist) {
                formikRef.current?.setErrors({ code: error.message });
            }
        },
    });

    const onSubmit = (values: GqlCreateDiscountInput) => {
        return discount
            ? updateDiscount(discount.id, { ...values, productIds: [ +productId ] })
            : createDiscount({ ...values, productIds: [ +productId ] });
    };

    return (
        <Formik validateOnBlur
                validateOnMount
                validateOnChange
                enableReinitialize
                onSubmit={onSubmit}
                innerRef={formikRef}
                initialTouched={{ value: true }}
                initialValues={getInitialValues(discount)}
                validate={createValidator(CreateDiscountInput)}>
            {({
                  values,
                  isValid,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  initialValues,
                  setFieldValue,
              }) => (
                <Form className="p-3">
                    <FormItem className="p-0"
                              top={t('discountType')}
                              status={Field.status(errors, touched, 'type')}
                              bottom={Field.message(errors, touched, 'type')}>
                        <SegmentedControl size="l"
                                          name="type"
                                          value={values.type}
                                          className="thin-border"
                                          data-test="promocode.type"
                                          onChange={(value) => {
                                              setFieldValue('type', value);

                                              setFieldValue('value', '');
                                          }}
                                          options={[
                                              {
                                                  label: t('amount'),
                                                  value: DiscountType.Amount,
                                              },
                                              {
                                                  label: t('percent'),
                                                  value: DiscountType.Percent,
                                              },
                                          ]}/>
                    </FormItem>

                    <FormLayoutGroup className="p-0 mb-1" mode="horizontal">
                        <FormItem className="p-0"
                                  status={Field.status(errors, touched, 'value')}
                                  bottom={Field.message(errors, touched, 'value')}
                                  top={[
                                      values.type === DiscountType.Amount
                                          ? t('discountAmount')
                                          : t('discountPercent'),
                                      '*',
                                  ].join(' ')}>
                            <FilterInput name="value"
                                         onBlur={handleBlur}
                                         value={values.value || ''}
                                         data-test="promocode.value"
                                         regExp={regex.floatNumberOnInput}
                                         placeholder={(
                                             values.type === DiscountType.Amount
                                                 ? t('enterAmount')
                                                 : t('enterPercent')
                                         )}
                                         callback={(e) => setFieldValue(
                                             'value',
                                             numberOrNull(e.target.value),
                                         )}
                                         after={(
                                             <InputSideInfo side="after">
                                                 {(
                                                     values.type === DiscountType.Amount
                                                         ? COMMON && productCurrency[COMMON.organization.settings.currency]
                                                         : '%'
                                                 )}
                                             </InputSideInfo>
                                         )}/>
                        </FormItem>

                        <FormItem className="p-0" top={t('limitUsage')}>
                            <FilterInput name="maxApplies"
                                         onBlur={handleBlur}
                                         value={values.maxApplies || ''}
                                         data-test="promocode.max-applies"
                                         regExp={regex.floatNumberOnInput}
                                         placeholder={t('maxUsage')}
                                         callback={(e) => setFieldValue(
                                             'maxApplies',
                                             numberOrNull(e.target.value),
                                         )}/>
                        </FormItem>
                    </FormLayoutGroup>

                    <FormItem className="p-0"
                              top={t('codeOfPromoCodeException')}
                              status={Field.status(errors, touched, 'code')}
                              bottom={Field.message(errors, touched, 'code')}>
                        <Input name="code"
                               maxLength={12}
                               onBlur={handleBlur}
                               value={values.code}
                               data-test="promocode.code"
                               placeholder={t('enterCode')}
                               onChange={Field.transform.ignoreSpaceOnChange(handleChange)}
                               after={(
                                   <Button size="s"
                                           mode="secondary"
                                           className="mr-2"
                                           data-test="promocode.generateCode"
                                           onClick={() => setFieldValue('code', generateRandomCode())}>
                                       {t('generate')}
                                   </Button>
                               )}/>
                    </FormItem>

                    <DateInputInterval className="pb-0 px-0"
                                       key={[ values.activeFrom, values.activeTo ].join('.')}
                                       handleChange={(field, value) => setFieldValue(field, value || null)}
                                       from={{
                                           name: 'activeFrom',
                                           label: t('validityDate'),
                                           value: values.activeFrom,
                                           error: errors.activeFrom,
                                           dataTest: 'price.activeFrom',
                                           dateInputProps: {
                                               enableTime: true,
                                               alwaysShowTime: true,
                                               minMaxInclusivity: '[]',
                                           },
                                       }}
                                       to={{
                                           name: 'activeTo',
                                           label: t('expirationDate'),
                                           value: values.activeTo,
                                           error: errors.activeTo,
                                           dataTest: 'price.activeTo',
                                           dateInputProps: {
                                               enableTime: true,
                                               alwaysShowTime: true,
                                               minMaxInclusivity: '[]',
                                               minDate: values.activeFrom,
                                           },
                                       }}/>

                    <FormItem className="p-0 px-4">
                        <Checkbox name="active"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  data-test="promocode.active"
                                  checked={values.active || false}
                                  description={(
                                      <span className="text-primary">
                                          {(
                                              !values.active
                                                  ? t('nowPromoCodeIsDeactivated')
                                                  : t('nowPromoCodeIsActivated')
                                          )}
                                      </span>
                                  )}>
                            {t('promoCodeActivation')}
                        </Checkbox>
                    </FormItem>

                    <FormItem className="my-4 p-0"
                              top={t('serviceNote')}
                              status={Field.status(errors, touched, 'note')}
                              bottom={(
                                  <FieldBottomLimiter maxLength={255}
                                                      value={values.note}
                                                      message={Field.message(errors, touched, 'note')}/>
                              )}>
                        <Textarea rows={3}
                                  name="note"
                                  maxLength={255}
                                  value={values.note || ''}
                                  data-test="promocode.note"
                                  placeholder={t('noteExample')}
                                  onChange={Field.transform.ignoreSpaceOnChange(handleChange)}/>
                    </FormItem>

                    <StickyButton className="px-0 pb-3">
                        <TextTooltip placement="left" hidden={_.isEmpty(errors)} text={(
                            <CheckListTooltip list={_.values(errors).map((text) => ({
                                text: `${text}`,
                                mode: 'error',
                            }))}/>
                        )}>
                            <Button stretched
                                    size="l"
                                    type="submit"
                                    mode="commerce"
                                    data-test="promocode.create"
                                    loading={createDiscountLoading || updateDiscountLoading}
                                    before={discount ? <Icon24CheckSquareOutline/> : <Icon24AddSquareOutline/>}
                                    disabled={(
                                        !isValid
                                        || createDiscountLoading
                                        || updateDiscountLoading
                                        || ObjectUtil.isEqual(initialValues, values)
                                    )}>
                                {discount ? t('save') : t('create')}
                            </Button>
                        </TextTooltip>
                    </StickyButton>
                </Form>
            )}
        </Formik>
    );
});


export { DiscountFormView };
