/**
 * ManageSchoolUsersPage
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PageStore } from '@/store/core/page';

import { useI18n } from '@/hooks/core';
import { useLocation } from '@/router/index';
import { observer, Page } from '@/pages/Core';
import { manageContextMenu } from '@/types/manage';

import { SchoolUserFindManyDocument } from '@/codegen/graphql';

import { ContextMenuItems } from '@/components/Atoms/ContextMenuItems';
import { SchoolContextInfo } from '@/components/School/ContextInfo';
import { DesktopHeaderMenu } from '@/components/Desktop/Navigation';

import { HeaderView } from './views/HeaderView';
import { UsersListView } from './views/UsersListView';
import { UsersTableView } from './views/UsersTableView';


const ManageSchoolUsersPage = observer(() => {

    const { mode } = PageStore;

    const { route: { params } } = useLocation();

    const { t } = useI18n('pages.Manage.School.Users');

    const { users: { items } } = manageContextMenu(params);

    return (
        <Page.Wrapper mode="slim-menu">
            <Page.Head>
                <Page.Header refetchDocs={[
                    SchoolUserFindManyDocument,
                ]} title={(
                    mode === 'slim-menu'
                        ? (
                            <DesktopHeaderMenu after={null}
                                               items={items}
                                               responsiveMode="isDesktop"
                                               mobileTitle={t('allUsers')}/>
                        )
                        : t('allUsers')
                )}/>
            </Page.Head>

            <Page.Content>
                <HeaderView/>

                <Page.OnMode onModes={[ 'regular' ]}>
                    <UsersListView/>
                </Page.OnMode>

                <Page.OnMode onModes={[ 'slim-menu' ]}>
                    <UsersTableView/>
                </Page.OnMode>
            </Page.Content>

            <Page.Context contextIsScrollable>
                <Page.ContextWrapper>
                    <ContextMenuItems statusComponent={<SchoolContextInfo/>}
                                      tabs={manageContextMenu(params).users.items}/>
                </Page.ContextWrapper>
            </Page.Context>
        </Page.Wrapper>
    );
});


export { ManageSchoolUsersPage };
