/**
 * UserActionsDropdown
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useState } from 'react';

import { ManageSchoolUsersPageStore } from '@/pages/Manage/School/Users/store';

import { useSchoolUser } from '@/hooks/apollo';
import { If, Notify, Rbac } from '@/cutils';
import { useCopy, useI18n } from '@/hooks/core';

import { GqlResult } from '@/types/graphql';
import { Permission, SchoolUserFindOneQueryResult } from '@/codegen/graphql';

import { UserEditTab } from '@/pages/Manage/School/Users';

import { Dropdown } from '@exode.ru/vkui/dist/unstable';
import { Button, SimpleCell, Spinner } from '@exode.ru/vkui';

import {
    Icon20Cancel,
    Icon20MenuOutline,
    Icon28BoxHeartOutline,
    Icon28CancelShieldOutline,
    Icon28CheckShieldOutline,
    Icon28PincodeLockOutline,
} from '@vkontakte/icons';

import { ConfirmButton } from '@/components/Atoms/ConfirmButton';


interface Props {
    user: GqlResult<SchoolUserFindOneQueryResult>['schoolUserFindOne'];
}


const UserActionsDropdown = (props: Props) => {

    const { user } = props;

    const [ showDropdown, setShowDropdown ] = useState(false);

    const { setUserEditActiveTab } = ManageSchoolUsersPageStore;

    const { t } = useI18n('pages.Manage.School.Users.views.EditUserFormView');

    const { copy } = useCopy(t('passwordCopied'));

    const {
        schoolUserManageUpdate,
        schoolUserManageUpdateLoading,
        getLastUserPlainPassword,
        getLastUserPlainPasswordLoading,
    } = useSchoolUser();

    const isLoading = [
        schoolUserManageUpdateLoading,
    ].some((e) => e);

    return (
        <Dropdown action="click"
                  shown={showDropdown}
                  className="overflow-hidden"
                  onShownChange={setShowDropdown}
                  content={(
                      <>
                          <Rbac.Permission permissions={[ Permission.SchoolManageUsers ]}>
                              <SimpleCell className="!rounded-none" subtitle={t('keepItSafety')} before={(
                                  !getLastUserPlainPasswordLoading
                                      ? <Icon28PincodeLockOutline/>
                                      : <Spinner className="mx-0 w-fit mr-3 min-w-[32px]"/>
                              )} onClick={async () => {
                                  const password = await getLastUserPlainPassword(user.id);

                                  password
                                      ? copy(password)
                                      : Notify.toast.error(t('passwordNotAvailable'));

                                  setShowDropdown(false);
                              }}>
                                  {t('copyPassword')}
                              </SimpleCell>
                          </Rbac.Permission>

                          <SimpleCell className="!rounded-none"
                                      subtitle={t('addAccessManually')}
                                      before={<Icon28BoxHeartOutline/>}
                                      onClick={() => {
                                          setShowDropdown(false);

                                          setUserEditActiveTab(UserEditTab.CreateAccess);
                                      }}>
                              {t('addAccesses')}
                          </SimpleCell>

                          <If is={user.banned}>
                              <SimpleCell className="!rounded-none"
                                          subtitle={t('recoverUser')}
                                          before={<Icon28CheckShieldOutline fill="var(--dynamic_green)"/>}
                                          onClick={() => {
                                              setShowDropdown(false);
                                              schoolUserManageUpdate(user.id, { banned: false });
                                          }}>
                                  {t('unBlock')}
                              </SimpleCell>
                          </If>

                          <If is={!user.banned}>
                              <ConfirmButton dataTest="user.block-comfirm"
                                             confirmProps={{ children: t('confirmBlockUser') }}
                                             callback={() => {
                                                 setShowDropdown(false);
                                                 schoolUserManageUpdate(user.id, { banned: true });
                                             }}>
                                  <SimpleCell data-test="user.block"
                                              subtitle={t('exitAllSessionsAndBlock')}
                                              className="!rounded-none text-destructive"
                                              before={<Icon28CancelShieldOutline fill="var(--destructive)"/>}>
                                      {t('block')}
                                  </SimpleCell>
                              </ConfirmButton>
                          </If>
                      </>
                  )}>
            <Button stretched
                    size="m"
                    mode="secondary"
                    loading={isLoading}
                    data-test="user.actions"
                    before={showDropdown ? <Icon20Cancel/> : <Icon20MenuOutline/>}>
                {t('actions')}
            </Button>
        </Dropdown>
    );
};


export { UserActionsDropdown };
