/**
 * StickyObserver util
 *
 * @author: exode <hello@exode.ru>
 */

import React, { cloneElement, ReactElement, useEffect, useRef, useState } from 'react';


interface Props {
    children: ReactElement;
    stackedProps: Partial<any> & React.Attributes;
    sides?: ('top' | 'bottom')[];
    disabled?: boolean;
    unStackedProps?: Partial<any> & React.Attributes;
}


const StickyObserver = (props: Props) => {

    const {
        children,
        stackedProps,
        disabled = false,
        unStackedProps = {},
        sides = [ 'top', 'bottom' ],
    } = props;

    const [ stacked, setStacked ] = useState(false);
    const [ side, setSide ] = useState<'top' | 'bottom' | null>(null);

    const ref = useRef<HTMLElement>();

    useEffect(() => {
        if (!ref?.current || disabled) {
            return;
        }

        const observer = new IntersectionObserver(
            ([ e ]) => {
                const isStacked = !e.isIntersecting;
                const side = e.boundingClientRect.top < 0 ? 'top' : 'bottom';

                if (sides.includes(side) || !isStacked) {
                    setStacked(isStacked);
                }

                if (isStacked) {
                    setSide(side);
                }
            },
            { threshold: [ 1 ] },
        );

        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ ref, disabled ]);

    return cloneElement(children, {
        ref,
        getRootRef: ref,
        ...(stacked ? { ...stackedProps, side, ['data-side']: side } : unStackedProps),
    });
};


export { StickyObserver };
