/**
 * BlockHeaderPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { IconButton } from '@mui/material';

import { useI18n } from '@/hooks/core';

import { If } from '@/cutils';
import { Header } from '@exode.ru/vkui';
import { Icon24ChevronDown, Icon24ChevronUp, Icon24DeleteOutline, Icon24Dismiss } from '@vkontakte/icons';

import { ConfirmButton } from '@/components/Atoms/ConfirmButton';

import { ContentElementEditProps } from '../../interfaces';


interface Props {
    list: ContentElementEditProps['list'];
    loading: ContentElementEditProps['loading'];
    onDelete: ContentElementEditProps['onDelete'];
    className?: string;
}


const BlockHeaderPart = (props: Props) => {

    const { list, loading, onDelete, className } = props;

    const { t } = useI18n('components.ContentElement.part.edit');

    return (
        <Header mode="primary" className={[ 'px-0 mb-0', className ].join(' ')} aside={onDelete && (
            <ConfirmButton callback={onDelete}
                           text={t('deleteBlock?')}
                           className="rounded-full"
                           loading={loading?.delete}
                           initialIcon={<Icon24Dismiss fill="var(--accent)"/>}
                           confirmIcon={<Icon24DeleteOutline fill="var(--destructive)"/>}/>
        )}>
            {!!list && (
                <>
                    <span className="mr-4">{t('blockNo', { no: list.index + 1 })}</span>

                    <If is={list.index > 0}>
                        <IconButton size="small" className="ml-1" onClick={() => list?.onOrderChange?.('up')}>
                            <Icon24ChevronUp fill="var(--accent)"/>
                        </IconButton>
                    </If>

                    <If is={(list.index > 0 || !!list.totalCount) && list?.index < (list.totalCount || 0) - 1}>
                        <IconButton size="small" onClick={() => list?.onOrderChange?.('down')}>
                            <Icon24ChevronDown fill="var(--accent)"/>
                        </IconButton>
                    </If>
                </>
            )}
        </Header>
    );
};


export { BlockHeaderPart };
