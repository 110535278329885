/**
 * ScrollToBottomItem
 *
 * @author: exode <hello@exode.ru>
 */

import React, { RefObject, useEffect, useState } from 'react';

import { ChatDialogsPageStore } from '../store';

import { If } from '@/cutils';
import { ScrollHelper } from '@/helpers/ui';
import { apolloClient } from '@/api/graphql';

import { Avatar, Counter } from '@exode.ru/vkui';
import { Icon28ChevronDownOutline } from '@vkontakte/icons';

import { ChatFindOneTopBarDocument, ChatFindOneTopBarQuery } from '@/codegen/graphql';


interface Props {
    chatId: number;
    onClick: () => void;
    chatMessagesRef: RefObject<HTMLDivElement>;
    className?: string;
}


const ScrollToBottomItem = (props: Props) => {

    const { chatId, onClick, chatMessagesRef, className } = props;

    const cachedChat = apolloClient.cache.readQuery<ChatFindOneTopBarQuery>({
        variables: { chatId },
        query: ChatFindOneTopBarDocument,
    });

    const countUnread = cachedChat?.chatFindOne.countUnread || 0;

    const [ showScrollToBottom, setShowScrollToBottom ] = useState(false);

    const handleScroll = () => {
        setShowScrollToBottom(ScrollHelper.scrollFromBottom(chatMessagesRef) >= 20);

        if (chatMessagesRef.current) {
            ChatDialogsPageStore.merge({
                chatScrolls: {
                    ...ChatDialogsPageStore.chatScrolls,
                    [+chatId]: chatMessagesRef.current?.scrollTop,
                },
            });
        }
    };

    useEffect(() => {
        chatMessagesRef.current?.addEventListener('scroll', handleScroll);

        return () => {
            chatMessagesRef.current?.removeEventListener('scroll', handleScroll);
        };
    }, [ chatMessagesRef.current ]);

    useEffect(() => {
        const scrollTop = ChatDialogsPageStore.chatScrolls[chatId];

        if (chatMessagesRef?.current && scrollTop !== undefined) {
            chatMessagesRef.current.scrollTop = scrollTop;
        }
    }, [ chatId ]);

    return (
        <If is={showScrollToBottom}>
            <Avatar size={40} onClick={onClick} className={[
                className,
                'absolute thin-border bg-content',
                'z-50 cursor-pointer right-[20px] bottom-[25px]',
            ].join(' ')} badge={(
                <If is={countUnread > 0}>
                    <Counter size="s" mode="primary">
                        {countUnread}
                    </Counter>
                </If>
            )}>
                <Icon28ChevronDownOutline fill="var(--accent)"/>
            </Avatar>
        </If>
    );
};


export { ScrollToBottomItem };
