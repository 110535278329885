/**
 * Translate
 *
 * @author: exode <hello@exode.ru>
 */

import * as _ from 'lodash';

import { isJSON } from 'class-validator';

// @ts-ignore @aslan
import { TranslateJson } from '@/shared/types';

import { ObjectUtil } from './Object';


class Translate {

    /**
     * Get translated
     * @param { | undefined | null} data
     * @param {string} key
     * @returns {any}
     */
    static getTranslated(
        data: TranslateJson | undefined | null,
        key: string,
    ) {
        if (isJSON(data)) {
            const { repackValue } = ObjectUtil.packTools();

            data = repackValue(data);
        }

        return _.isString(data)
            ? data as string
            : (!_.isNil(data?.[key as keyof TranslateJson])
                    ? data[key as keyof TranslateJson]
                    : (data?.['Default' as keyof TranslateJson] || data?.[_.keys(data)[0] as keyof TranslateJson] || '')
            );
    }

}


export { Translate };
