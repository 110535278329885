/**
 * ScheduledTournamentView
 *
 * @author: exode <hello@exode.ru>
 */

import React, { HTMLAttributes, ReactElement } from 'react';

import { observer, Page, useStore } from '@/pages/Core';
import { TournamentTabStore } from '../store';

import { ScheduledTournamentsQuery, ScheduledTournamentsQueryResult } from '@/codegen/graphql';

import { Counter, Header, List } from '@exode.ru/vkui';

import { Graphql, If } from '@/cutils';
import { GqlResult } from '@/types/graphql';
import { usePagination } from '@/hooks/core';

import { TournamentsPlaceholderView } from '@/pages/Tournament/History/views/TournamentsPlaceholderView';

import { Pagination } from '@/components/Atoms/Pagination';
import { TournamentCard } from '@/components/Tournament/Card';
import { TournamentCardSkeleton } from '@/components/Atoms/Skeleton/TournamentCard';

import { gradient } from '@/styles/modules/gradient';


interface WrapperProps extends HTMLAttributes<HTMLDivElement> {
    children: ReactElement | ReactElement[];
    count?: number;
    header?: string;
}

interface LoadingProps {
    header?: string;
}

interface SuccessProps {
    tournaments: GqlResult<ScheduledTournamentsQueryResult>['tournamentFindMany'];
    header?: string;
}


const Wrapper = (props: WrapperProps) => {

    const { children, count, header = 'Запланированные турниры', ...rest } = props;

    return (
        <Page.Row {...rest}>
            <Header mode="primary" aside={(
                <Counter size="s" mode="primary" className={[
                    'px-1 py-0.5 bg-gradient-to-r text-white',
                    gradient.blue600ToPurple500Gradient,
                ].join(' ')}>
                    {count} найдено
                </Counter>
            )}>
                <span className="text-underline-marker h-after-4">
                    {header}
                </span>
            </Header>

            <div className="pb-2">
                {children}
            </div>
        </Page.Row>
    );
};

const Loading = ({ header }: LoadingProps) => {
    return (
        <Wrapper header={header} count={0}>
            <List className="d:py-2 m:py-1 px-3">
                {Array(6).fill(0).map((_, i) => <TournamentCardSkeleton key={i}/>)}
            </List>
        </Wrapper>
    );
};

const Success = (props: SuccessProps) => {

    const { tournaments: { items, count }, header } = props;

    return (
        <Wrapper header={header} count={count}>
            <If is={!count}>
                <TournamentsPlaceholderView/>
            </If>

            <If is={!!count}>
                <List className="d:py-2 m:py-1 px-3">
                    {items?.map((tournament) => (
                        <div key={tournament.id} className="mb-3 last:mb-0">
                            <TournamentCard {...tournament} />
                        </div>
                    ))}
                </List>
            </If>
        </Wrapper>
    );
};


const ScheduledTournamentView = observer(() => {

    const { store, filter, sort } = useStore(TournamentTabStore);

    const { page, list, onPageChange } = usePagination({
        store,
        listField: 'scheduled',
        path: '/tournaments/:page([0-9]+)',
    });

    return (
        <ScheduledTournamentsQuery children={(result) => (
            <>
                <Graphql.Loading result={result}>
                    <Loading/>
                </Graphql.Loading>

                <Graphql.Success result={result}>
                    {({ tournamentFindMany: tournaments }) => (
                        <>
                            <Success tournaments={tournaments}/>

                            <Pagination boundaryCount={1}
                                        currentPage={page}
                                        totalPages={tournaments.pages}
                                        onChange={(value) => onPageChange(value, result.refetch)}/>
                        </>
                    )}
                </Graphql.Success>

                <Graphql.Error result={result}/>
            </>
        )} variables={{
            list: { ...list },
            filter: { ...filter },
            sort: { ...sort },
        }}/>
    );
});


export { Success, Loading, Wrapper, ScheduledTournamentView };
