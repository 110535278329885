/**
 * Filter useSchoolManageLegalFilter
 *
 * @author: exode <hello@exode.ru>
 */

import { useUrlFilters } from '@/hooks/core';

import { ManageSchoolLegalPageStore } from './store';

import { SellerOrganizationDocumentType } from '@/codegen/graphql';


export const defaultFilter = {
    types: [] as SellerOrganizationDocumentType[],
};

export const dtoFilterTransformer = (filter: typeof defaultFilter) => filter;

export const useSchoolManageLegalFilter = () => useUrlFilters(
    { ...defaultFilter },
    [],
    dtoFilterTransformer,
    {
        store: ManageSchoolLegalPageStore,
    },
);
