/**
 * SeparatorWithText
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactNode } from 'react';


interface Props {
    children?: ReactNode;
    className?: string;
}


const SeparatorWithText = (props: Props) => {

    const { children, className } = props;

    return (
        <div className={[ className, 'relative flex items-center' ].join(' ')}>
            <div className="flex-grow h-[1px] thin-border"/>

            <span className="flex-shrink-0 px-4 text-sm text-secondary">
                {children}
            </span>

            <div className="flex-grow h-[1px] thin-border"/>
        </div>
    );
};


export { SeparatorWithText };
