/**
 * ScrollSaver util
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect } from 'react';

import { ScrollHelper } from '@/helpers/ui';
import { DocumentEvent } from '@/types/window';
import { observer, PageStore as PageCoreStore } from '@/store/core/page';


interface Props {
    scrollKey: string;
    smooth?: boolean;
    scrollDeps?: string[];
    element?: Window | HTMLDivElement | null;
}


const ScrollSaver = observer((props: Props) => {

    const {
        element,
        scrollKey,
        scrollDeps,
        smooth = false,
    } = props;

    /**
     * Перед уходом со страницы (unmount) запоминаем скролл
     */
    const onRouteUpdate = (e: any) => {
        const { params } = e?.detail?.prev || {};

        if (params && (params.modal || params.popup)) {
            return;
        }

        PageCoreStore.setScroll(
            scrollKey,
            element
                ? (element as HTMLElement).scrollTop
                : undefined,
        );
    };

    /**
     * После изменения route на той же странице восстанавливает скролл
     * (н-р, закрытия модального окна)
     */
    const onRouteLoadScroll = (e: any) => {
        const { params } = e?.detail?.prev || {};

        if (params && (!params.modal && !params.popup)) {
            return;
        }

        ScrollHelper.to(
            PageCoreStore.scrolls[scrollKey] || 0,
            smooth,
            element,
        );
    };

    useEffect(() => {
        const to = PageCoreStore.scrolls[scrollKey] || 0;

        ScrollHelper.to(to, smooth, element);

        document.addEventListener(DocumentEvent.RouterBeforeUpdate, onRouteUpdate);
        document.addEventListener(DocumentEvent.RouterAfterUpdate, onRouteLoadScroll);

        return () => {
            document.removeEventListener(DocumentEvent.RouterBeforeUpdate, onRouteUpdate);
            document.removeEventListener(DocumentEvent.RouterAfterUpdate, onRouteLoadScroll);
        };
    }, [ ...(scrollDeps || []) ]);

    return <></>;
});


export { ScrollSaver };
