/**
 * ContentElement
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect } from 'react';

import { SubscriptionStore } from '@/store/subscription/subscription';

import { ContentElementType } from '@/codegen/graphql';
import { useContentElementInteract } from '@/hooks/apollo';

import { ContentElementEditProps, ContentElementShowProps } from './interfaces';

import { AudioContentElementEdit } from './edit/AudioContentElementEdit';
import { ChecklistContentElementEdit } from './edit/ChecklistContentElementEdit';
import { VideoContentElementEdit } from './edit/VideoContentElementEdit';
import { EditorJsBlockContentElementEdit } from './edit/EditorJsBlockContentElementEdit';

import { AudioContentElementShow } from './show/AudioContentElementShow';
import { ChecklistContentElementShow } from './show/ChecklistContentElementShow';
import { VideoContentElementShow } from './show/VideoContentElementShow';
import { EditorJsBlockContentElementShow } from './show/EditorJsBlockContentElementShow';


interface EditProps extends ContentElementEditProps {

}

interface ShowProps extends ContentElementShowProps {

}

interface Props {
    mode: 'edit' | 'show';
}


const ContentElement = (props: (EditProps | ShowProps) & Props) => {

    const { contentElement: { uuid } } = props;

    const components = {
        edit: {
            [ContentElementType.ActionContextBanner]: EditorJsBlockContentElementEdit,
            [ContentElementType.AdContextBanner]: EditorJsBlockContentElementEdit,
            [ContentElementType.Audio]: AudioContentElementEdit,
            [ContentElementType.ChatBotContent]: EditorJsBlockContentElementEdit,
            [ContentElementType.Checklist]: ChecklistContentElementEdit,
            [ContentElementType.Survey]: ChecklistContentElementEdit,
            [ContentElementType.Text]: EditorJsBlockContentElementEdit,
            [ContentElementType.EditorJsBlock]: EditorJsBlockContentElementEdit,
            [ContentElementType.NotionPage]: EditorJsBlockContentElementEdit,
            [ContentElementType.Video]: VideoContentElementEdit,
        },
        show: {
            [ContentElementType.ActionContextBanner]: EditorJsBlockContentElementShow,
            [ContentElementType.AdContextBanner]: EditorJsBlockContentElementShow,
            [ContentElementType.Audio]: AudioContentElementShow,
            [ContentElementType.ChatBotContent]: EditorJsBlockContentElementShow,
            [ContentElementType.Checklist]: ChecklistContentElementShow,
            [ContentElementType.Survey]: ChecklistContentElementShow,
            [ContentElementType.Text]: EditorJsBlockContentElementShow,
            [ContentElementType.EditorJsBlock]: EditorJsBlockContentElementShow,
            [ContentElementType.NotionPage]: EditorJsBlockContentElementShow,
            [ContentElementType.Video]: VideoContentElementShow,
        },
    };

    const ContentElementComponent = components[props.mode][props.contentElement.type];

    const {
        interact,
        interactError,
        interactLoading,
    } = useContentElementInteract();

    useEffect(() => {
        SubscriptionStore.subscribeToContentElementUuid(uuid);
    }, [ uuid ]);

    return (
        <ContentElementComponent {...(props as EditProps & ShowProps)} interaction={{
            error: interactError,
            loading: interactLoading,
            interact: (data) => interact(uuid, data),
        }}/>
    );
};


export { ContentElement };
