/**
 * TaskBuilder component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import {
    SaveAnswerTaskInput,
    TaskCommonFragmentFragment,
    TaskResultFragmentFragment,
    TaskUserAnswer,
} from '@/codegen/graphql';

import { If } from '@/cutils';
import { TaskViewMode } from '@/types/task';

import { Separator } from '@exode.ru/vkui';

import { ContentElementItem } from '@/components/ContentElement';

import { CommonHeaderPart } from './common/CommonHeaderPart';
import { TaskBuilderProvider } from './contexts/TaskBuilderContext';

import { EditMode } from './edit/EditMode';
import { ShowMode } from './show/ShowMode';
import { Container } from './TaskBuilder.styled';


export interface TaskBuilderProps {
    /** Common */
    mode: TaskViewMode;
    task: TaskCommonFragmentFragment;
    className?: string;

    /** Edit */
    edit?: {
        setMode?: (mode: TaskViewMode) => void;
        onDelete?: Function;
        onUpdate?: (task: TaskCommonFragmentFragment) => void;
        getExistBlocks?: (taskId: number) => ContentElementItem[] | undefined;
        onInstantChange?: () => void;
        list?: {
            index: number;
            expanded?: boolean;
            onExpandToggle?: (taskId: number | undefined) => void;
            orderChangeModes?: ('up' | 'down')[];
            onOrderChange?: (direction: 'up' | 'down') => void;
        };
        loading?: {
            order?: boolean;
            create?: boolean;
            update?: boolean;
            delete?: boolean;
        };
    };

    /** Show */
    show?: {
        /**
         * Sub-mode:
         * • answer = default show - wait user answer
         * • preview = show inside edit mode
         * • result = show result after answer
         */
        subMode: 'answer' | 'preview' | 'result';
        saveMode?: 'autosave' | 'button';
        onAnswer?: (answer: SaveAnswerTaskInput) => void;
        onInstantChange?: () => void;

        answer?: {
            userAnswer?: TaskUserAnswer;
            result?: TaskResultFragmentFragment;
        };

        list?: {
            index?: number;
            current?: boolean;
        };

        loading?: {
            answer?: boolean;
        };

        error?: {
            answer?: boolean;
        };
    };
}


const TaskBuilder = (props: TaskBuilderProps) => {

    const {
        mode,
        task,
        className,
        edit = {},
        show = { subMode: 'answer' },
    } = props;

    return (
        <TaskBuilderProvider value={{ mode, edit, show }}>
            <Container date-mode={mode}
                       data-submode={show.subMode}
                       data-test={`task-builder.${task?.id}`}
                       className={[ 'group', className ].join(' ')}>
                <If is={mode === TaskViewMode.Edit}>
                    <EditMode {...props} header={<CommonHeaderPart task={task}/>}/>
                </If>

                <If is={mode === TaskViewMode.Show}>
                    <ShowMode {...props} header={(
                        <If is={show.subMode === 'preview'}>
                            <div className="flex flex-col flex-1 pt-1.5 px-2 pb-2">
                                <CommonHeaderPart stickyDisabled task={task}/>
                            </div>

                            <If is={!!edit?.list?.expanded}>
                                <Separator wide className="mb-2.5"/>
                            </If>
                        </If>
                    )}/>
                </If>
            </Container>
        </TaskBuilderProvider>
    );
};


export { TaskBuilder };
