/**
 * UseTextSelection
 *
 * @author: exode <hello@exode.ru>
 */

import { useEffect, useState } from 'react';

import { elementSelectors, SelectedSkip, TooltipPosition } from '../types';


export const useTextSelection = () => {

    const [ showTooltip, setShowTooltip ] = useState(false);
    const [ selectedWord, setSelectedWord ] = useState<string | null>(null);
    const [ selectedRange, setSelectedRange ] = useState<Range | null>(null);
    const [ selectedSkip, setSelectedSkip ] = useState<SelectedSkip | null>(null);
    const [ tooltipMode, setTooltipMode ] = useState<'create' | 'remove'>('create');
    const [ tooltipPosition, setTooltipPosition ] = useState<TooltipPosition>({ x: 0, y: 0 });

    const handleTextSelection = (
        range: Range,
        text: string,
        rect: DOMRect,
    ) => {
        const container = range.commonAncestorContainer;

        const skipElement = container.nodeType === Node.TEXT_NODE
            ? container.parentElement?.closest(elementSelectors.skipWord)
            : (container as Element).closest(elementSelectors.skipWord);

        if (skipElement instanceof HTMLElement) {
            const skipText = skipElement.dataset.skipText;
            const skipUuid = skipElement.dataset.skipUuid;

            if (skipText && skipUuid) {
                setSelectedSkip({ text: skipText, uuid: skipUuid });

                setTooltipPosition({
                    x: skipElement.offsetLeft + skipElement.clientWidth / 2,
                    y: skipElement.offsetTop - 35,
                });

                setTooltipMode('remove');
                setShowTooltip(true);
            }
            return;
        }

        if (text.trim()) {
            setSelectedWord(text);
            setSelectedRange(range);

            setTooltipPosition({
                x: rect.left,
                y: rect.top - 45,
            });

            setTooltipMode('create');
            setShowTooltip(true);
        } else {
            clearSelection();
        }
    };

    const handleSkipClick = (skipElement: HTMLElement) => {
        const skipText = skipElement.dataset.skipText;
        const skipUuid = skipElement.dataset.skipUuid;

        if (skipText && skipUuid) {
            setSelectedSkip({ text: skipText, uuid: skipUuid });

            setTooltipPosition({
                x: skipElement.offsetLeft,
                y: skipElement.offsetTop - 50,
            });

            setTooltipMode('remove');
            setShowTooltip(true);
        }
    };

    const clearSelection = () => {
        setSelectedWord(null);
        setSelectedSkip(null);
        setSelectedRange(null);
        setShowTooltip(false);

        window.getSelection()?.removeAllRanges();
    };

    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            const target = e.target as HTMLElement;

            const isSkipWordClick = target.closest(elementSelectors.skipWord);
            const isTooltipClick = target.closest(elementSelectors.tooltipButton);

            if (!isTooltipClick && !isSkipWordClick) {
                clearSelection();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return {
        selectedWord,
        selectedSkip,
        selectedRange,
        tooltipPosition,
        showTooltip,
        tooltipMode,
        handleTextSelection,
        handleSkipClick,
        clearSelection,
    };
};
