/**
 * DiscountListView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { Page } from '@/pages/Core';

import { DiscountManageFindManyQuery } from '@/codegen/graphql';

import { Graphql, If, Link } from '@/cutils';
import { useI18n, usePagination } from '@/hooks/core';

import { Counter, Header } from '@exode.ru/vkui';

import { Pagination } from '@/components/Atoms/Pagination';

import { DiscountRowItem } from '../items/DiscountRowItem';
import { EmptyDiscountListView } from './EmptyDiscountListView';

import { useDiscountsFilter } from '../filter';


interface Props {
    productId: number;
    filter: ReturnType<typeof useDiscountsFilter>;
    pagination: ReturnType<typeof usePagination>;
}


const DiscountListView = (props: Props) => {

    const { productId, pagination, filter: { dtoFilter } } = props;

    const { t } = useI18n('pages.Manage.Product.Discount');

    const { page, list, onPageChange } = pagination;

    return (
        <DiscountManageFindManyQuery children={(result) => (
            <>
                <Graphql.Loading result={result}>
                    <Page.Spinner/>
                </Graphql.Loading>

                <Graphql.Success result={result}>
                    {({ discountManageFindMany: { items, pages, count } }) => (
                        <>
                            <Page.Row>
                                <Header mode="secondary" aside={(
                                    <Counter size="m" mode="primary">
                                        {count}
                                    </Counter>
                                )}>
                                    {t('discountsList')}
                                </Header>

                                {items?.map((discount) => (
                                    <Link key={discount.id} pushModal={{
                                        id: 'manage-product-discount',
                                        params: {
                                            discountId: `${discount?.id}`,
                                            productId: `${productId}`,
                                        },
                                    }}>
                                        <div>
                                            <DiscountRowItem discount={discount}/>
                                        </div>
                                    </Link>
                                ))}

                                <If is={_.isEmpty(items)}>
                                    <EmptyDiscountListView/>
                                </If>
                            </Page.Row>

                            <Pagination sticky
                                        boundaryCount={1}
                                        currentPage={page}
                                        totalPages={pages}
                                        onChange={(value) => onPageChange(value, result.refetch)}/>
                        </>
                    )}
                </Graphql.Success>

                <Graphql.Error result={result}/>
            </>
        )} variables={{
            list: { ...list },
            filter: dtoFilter,
        }}/>
    );
};


export { DiscountListView };
