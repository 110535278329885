/**
 * UseDraggableStyles
 *
 * @author: exode <hello@exode.ru>
 */

import { Transform } from '@dnd-kit/utilities';


export const useDraggableStyles = (
    transform: Transform | null,
    isDragging: boolean,
) => {
    if (!transform) {
        return undefined;
    }

    return {
        zIndex: isDragging ? 1000000 : undefined,
        transition: 'none',
        touchAction: 'none',
        transform: `translate3d(${Math.round(transform.x)}px, ${Math.round(transform.y)}px, 0)`,
    };
};
