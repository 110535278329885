/**
 * Filter useManageHomeworkFilter
 *
 * @author: exode <hello@exode.ru>
 */

import { useUrlFilters } from '@/hooks/core';

import { defaultFilter, dtoFilterHomeworksTransformer, ManageHomeworkPageStore } from './store';


export const useManageHomeworkFilter = () => useUrlFilters(
    defaultFilter,
    [],
    dtoFilterHomeworksTransformer,
    {
        store: ManageHomeworkPageStore,
    },
);
