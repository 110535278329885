/**
 * MultipleShow
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { useFormikContext } from 'formik';

import { TaskAnswerType } from '@/codegen/graphql';

import { useI18n } from '@/hooks/core';
import { If, Notify, useAutoSaveFormContext } from '@/cutils';
import { MultipleWithAttachmentTaskAnswer, MultipleWithAttachmentTaskQuestion, StorageFileType } from '@/shared/types';

import { MultipleCheckboxConfig, VariantItem } from './types';

import {
    Icon24CheckBoxOff,
    Icon24CheckBoxOn,
    Icon24CheckCircleOn,
    Icon24Lock,
    Icon24RadioOff,
} from '@vkontakte/icons';

import { TaskBuilderShowProps, useTaskBuilderContext } from '@/components/Task/Builder';


import { CheckboxOptionAtom, VariantAttachmentAtom } from './atoms';


export interface MultipleShowProps extends TaskBuilderShowProps<
    MultipleWithAttachmentTaskQuestion,
    MultipleWithAttachmentTaskAnswer
> {

}


const MultipleShow = (props: MultipleShowProps) => {

    const { task, answer: { quiz } = {} } = props;

    const { t } = useI18n('components.Task.Builder.parts.answer');

    const { setFieldValue } = useFormikContext();
    const { show: { subMode } } = useTaskBuilderContext();
    const { handleAutoSaveChange } = useAutoSaveFormContext();

    const groupByType = _.groupBy(task.question.variants, 'attachments.main.type');

    const isRadioMode = [
        TaskAnswerType.Boolean,
        TaskAnswerType.Single,
        TaskAnswerType.SingleWithAttachment,
    ].includes(task.answerType);

    const withAttachment = [
        TaskAnswerType.SingleWithAttachment,
        TaskAnswerType.MultipleWithAttachment,
    ].includes(task.answerType);

    const checkbox: MultipleCheckboxConfig = {
        type: isRadioMode ? 'radio' : 'checkbox',
        onIcon: isRadioMode
            ? <Icon24CheckCircleOn width={28} height={28}/>
            : <Icon24CheckBoxOn width={28} height={28}/>,
        offIcon: isRadioMode
            ? <Icon24RadioOff width={28} height={28}/>
            : <Icon24CheckBoxOff width={28} height={28}/>,
        getAnswerIsCorrect: (variant: VariantItem) => {
            return checkbox.getIsChecked(variant)
                ? variant.correct ? 'correct-checked' : 'incorrect-checked'
                : variant.correct ? 'correct-unchecked' : 'incorrect-unchecked';
        },
        getCheckboxCommentAndFill: (variant: VariantItem) => {
            const answerResult = checkbox.getAnswerIsCorrect(variant);

            switch (answerResult) {
                case 'correct-checked':
                    return [ t('correct-checked'), 'var(--dynamic_green)' ];

                case 'correct-unchecked':
                    return [ t('correct-unchecked'), 'var(--icon_secondary)' ];

                case 'incorrect-checked':
                    return [ t('incorrect-checked'), 'var(--destructive)' ];

                case 'incorrect-unchecked':
                default:
                    return [ t('incorrect-unchecked'), null ];
            }
        },
        getIsChecked: (variant: VariantItem) => {
            return subMode === 'preview'
                ? !!_.find(task.question.variants, { uuid: variant.uuid })?.correct
                : !!_.find(quiz?.selected, { uuid: variant.uuid });
        },
        handleChange: (
            variant: VariantItem,
            checked: boolean,
        ) => {
            if (subMode === 'result') {
                return;
            }

            if (subMode === 'preview') {
                return Notify.toast(
                    t('previewModeReadonly'),
                    {
                        id: 'MultipleShow:previewMode',
                        icon: <Icon24Lock fill="var(--dynamic_green)"/>,
                    },
                );
            }

            if ([
                TaskAnswerType.Single,
                TaskAnswerType.Boolean,
                TaskAnswerType.SingleWithAttachment,
            ].includes(task.answerType)) {
                setFieldValue('quiz', {
                    selected: [ {
                        uuid: variant.uuid,
                        variantText: variant.text,
                    } ],
                });
            } else {
                const selected = !checked
                    ? [ ...(quiz?.selected?.filter(({ uuid }) => uuid !== variant?.uuid)) || [] ]
                    : [
                        ...(quiz?.selected || []),
                        {
                            uuid: variant.uuid,
                            variantText: variant.text,
                        },
                    ];

                setFieldValue('quiz', { selected });
            }

            setImmediate(() => handleAutoSaveChange());
        },
    };

    return (
        <>
            <If is={!withAttachment}>
                <div className="flex flex-1 flex-col justify-center gap-2">
                    {task.question.variants.map((variant, index) => (
                        <CheckboxOptionAtom key={index} variant={variant} checkbox={checkbox}/>
                    ))}
                </div>
            </If>

            <If is={withAttachment}>
                <div className="flex flex-col gap-5">
                    {_.map(_.entries(groupByType), ([ type, variants ], index) => (
                        <div key={index} className={[
                            type !== StorageFileType.Audio ? 'grid grid-cols-2 gap-5' : '',
                            type === StorageFileType.Audio ? 'flex flex-col gap-2' : '',
                        ].join(' ')}>
                            {variants.map((variant) => (
                                <VariantAttachmentAtom key={variant.uuid} variant={variant} checkbox={checkbox}/>
                            ))}
                        </div>
                    ))}
                </div>
            </If>
        </>
    );
};


export { MultipleShow };
