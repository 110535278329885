/**
 * Filter useSchoolManageDisciplinesFilter
 *
 * @author: exode <hello@exode.ru>
 */

import { useUrlFilters } from '@/hooks/core';

import { ManageSchoolDisciplinesPageStore } from './store';

import { ContentCategorySpace } from '@/codegen/graphql';


export const defaultFilter = {
    search: '',
    spaces: [] as ContentCategorySpace[],
    slugs: [] as string[],
    visible: null as unknown as boolean,
};

export const dtoFilterTransformer = (filter: typeof defaultFilter) => filter;

export const useSchoolManageDisciplinesFilter = () => useUrlFilters(
    { ...defaultFilter },
    [],
    dtoFilterTransformer,
    {
        store: ManageSchoolDisciplinesPageStore,
    },
);
