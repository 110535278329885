/**
 * ContentBlocksListPart
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { MutableRefObject, useState } from 'react';

import { useI18n } from '@/hooks/core';
import { ScrollHelper } from '@/helpers/ui';

import { Notify } from '@/cutils';

import { Button } from '@exode.ru/vkui';

import { ContentElement } from '@/components/ContentElement';

import { ContentLayoutBlockManageProps } from '../ContentLayoutBlockManage';


interface Props {
    blocks: ContentLayoutBlockManageProps['blocks'];
    blockRefs: MutableRefObject<HTMLDivElement[]>;
    actions: ContentLayoutBlockManageProps['actions'];
    options: ContentLayoutBlockManageProps['options'];
    placement: ContentLayoutBlockManageProps['placement'];
    setBlockEditing: ContentLayoutBlockManageProps['setBlockEditing'];
    className?: string;
}


const ContentBlocksListPart = (props: Props) => {

    const {
        blocks,
        actions,
        options,
        className,
        blockRefs,
        placement,
        setBlockEditing,
    } = props;

    const {
        updateBlock,
        updateBlockLoading,
        deleteBlock,
        deleteBlockLoading,
        recoverBlock,
        updateBlockOrder,
        updateBlockOrderLoading,
        updateBlockOrderInCache,
    } = actions;

    const { t } = useI18n('components.Content.ContentLayoutBlockManage');

    const [ iterationIds, setIterationIds ] = useState<Record<number, boolean>>({});

    /** Clear ref on block deletion */
    const removeBlockRef = (index: number) => {
        const refs = [ ...blockRefs.current ];

        refs.splice(index, 1);

        blockRefs.current = refs;
    };

    /** Set is loading */
    const setIsLoading = (
        contentElementId: number,
        loading: boolean,
    ) => (
        setIterationIds((prevState) => ({
            ...prevState,
            [contentElementId]: loading,
        }))
    );

    /** Handle block delete */
    const handleBlockDelete = async (
        contentElementId: number,
        index: number,
    ) => {
        const deletedBlock = _.find(blocks, { id: contentElementId });

        setIsLoading(contentElementId, true);

        await deleteBlock(contentElementId);

        removeBlockRef(index);

        setIsLoading(contentElementId, false);

        Notify.vkui({
            closeOnClick: false,
            uniqKey: 'content-layout-block:recover',
            message: t('blockSuccessfullyDeleted'),
            appearance: 'primary',
            vkuiOptions: {
                layout: 'horizontal',
                action: (
                    <Button mode="secondary" className="!bg-white !text-black">
                        {t('restore')}
                    </Button>
                ),
                onActionClick: async () => {
                    /** Scroll to recovered block */
                    setTimeout(() => {
                        if (placement === 'page') {
                            ScrollHelper.to(
                                (blockRefs.current?.at(index)?.offsetTop || 0) + 2,
                                true,
                            );
                        }
                    }, 100);

                    return recoverBlock(contentElementId, deletedBlock);
                },
            },
        });
    };

    /** Handle order change */
    const handleOrderChange = async (
        index: number,
        direction: 'up' | 'down',
    ) => {
        const newIndex = direction === 'up' ? index - 1 : index + 1;

        const newOrder = [
            { ...blocks[index], order: newIndex },
            { ...blocks[newIndex], order: index },
        ];

        updateBlockOrderInCache(newOrder);

        setTimeout(() => {
            if (placement === 'page') {
                ScrollHelper.to(
                    (blockRefs.current?.at(newIndex)?.offsetTop || 0) + 2,
                    true,
                );
            }
        }, 100);

        await updateBlockOrder({
            items: _.map(newOrder, (o) => _.pick(o, [ 'id', 'order' ])),
        });
    };

    return (
        <div data-component="ContentBlocksListPart">
            {blocks.map((block, index) => (
                <div key={block.id}
                     className={className}
                     ref={(ref) => blockRefs && ref && (blockRefs.current[index] = ref)}>
                    <ContentElement mode="edit"
                                    options={options}
                                    contentElement={block}
                                    onEdit={() => setBlockEditing()}
                                    onDelete={() => handleBlockDelete(block.id, index)}
                                    update={(values) => updateBlock(block.id, values)}
                                    loading={{
                                        update: updateBlockLoading,
                                        order: updateBlockOrderLoading,
                                        delete: deleteBlockLoading && iterationIds[block.id],
                                    }}
                                    list={{
                                        index,
                                        totalCount: blocks.length,
                                        onOrderChange: (direction) => handleOrderChange(
                                            index,
                                            direction,
                                        ),
                                    }}/>
                </div>
            ))}
        </div>
    );
};


export { ContentBlocksListPart };
