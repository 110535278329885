/**
 * ManageGroupPage store
 *
 * @author: exode <hello@exode.ru>
 */

import { makeObservable } from 'mobx';

import { observer, PageStore } from '@/pages/Core';


/** Перемещен в store для решения circular dependency */
export const defaultFilter = {
    groupIds: [] as number[],
};

export const dtoFilterGroupsStudentsTransformer = (filter: typeof defaultFilter) => filter;

class ManageGroupsStudentsPage extends PageStore {

    constructor() {
        super();
        makeObservable(this);
    }

    state = {
        list: { skip: 0, take: 20 },
        filter: dtoFilterGroupsStudentsTransformer(defaultFilter),
        sort: {},
    };

}

const ManageGroupsStudentsPageStore = new ManageGroupsStudentsPage();


export { observer, ManageGroupsStudentsPageStore };
