/**
 * Filter useSchoolManageUsersFilter
 *
 * @author: exode <hello@exode.ru>
 */

import { useUrlFilters } from '@/hooks/core';

import { FilterProfileInput, Permission } from '@/codegen/graphql';

import { ManageSchoolUsersPageStore } from '@/pages/Manage/School/Users/store';


export const defaultFilter = {
    userIds: [] as number[],
    active: null as unknown as boolean,
    search: '',
    verified: null as unknown as boolean,
    banned: null as unknown as boolean,
    permissions: [] as Permission[],
    profile: {} as FilterProfileInput,
};

export const dtoFilterTransformer = (filter: typeof defaultFilter) => filter;

export const useSchoolManageUsersFilter = () => useUrlFilters(
    { ...defaultFilter },
    [],
    dtoFilterTransformer,
    {
        store: ManageSchoolUsersPageStore,
    },
);
