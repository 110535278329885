/**
 * ButtonGroupWrapperPart
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactNode } from 'react';

import { useI18n } from '@/hooks/core';

import { SeparatorWithText } from '@/components/Atoms/SeparatorWithText';


interface Props {
    children: ReactNode;
}


const ButtonGroupWrapperPart = (props: Props) => {

    const { t } = useI18n('components.Content.ContentLayoutBlockManage');

    return (
        <>
            <SeparatorWithText className="mt-1 mb-3">
                {t('addNewBlock')}
            </SeparatorWithText>

            {props.children}
        </>
    );
};


export { ButtonGroupWrapperPart };
