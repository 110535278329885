/**
 * CreateAccessTab
 *
 * @author: exode <hello@exode.ru>
 */

import * as Yup from 'yup';

import React, { useState } from 'react';

import { Form, Formik } from 'formik';

import { ManageSchoolUsersPageStore } from '@/pages/Manage/School/Users/store';

import { Notify } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { useSchoolUser } from '@/hooks/apollo';
import { enrollmentsYupFragment } from '@/libs/class-validator';

import { Button } from '@exode.ru/vkui';
import { Icon24EducationOutline } from '@vkontakte/icons';

import { StickyButton } from '@/components/Atoms/StickyButton';

import { UserEditTab } from '@/pages/Manage/School/Users';
import { AccessListItem } from '@/pages/Manage/School/Users/items/AccessListItem';


interface Props {
    userId: number;
}


const CreateAccessTab = (props: Props) => {

    const { userId } = props;

    const { t } = useI18n('pages.Manage.School.Users.views.EditUserFormView');

    const { setUserEditActiveTab } = ManageSchoolUsersPageStore;

    const { userAccessCreateMany, userAccessCreateManyLoading } = useSchoolUser();

    const [ accessToggles, setAccessToggles ] = useState<Record<number, boolean>>({ 0: true });

    const initialValues = {
        enrollments: {
            accesses: [ {
                productId: null as unknown as number,
                launchId: null as unknown as number,
                priceId: null as unknown as number,
            } ],
        },
    };

    return (
        <Formik validateOnBlur
                validateOnMount
                validateOnChange
                initialValues={initialValues}
                validationSchema={Yup.object().shape({ enrollments: enrollmentsYupFragment })}
                onSubmit={(values) => userAccessCreateMany(
                    userId,
                    values.enrollments,
                    () => {
                        setUserEditActiveTab(UserEditTab.Study);

                        Notify.toast.success(t('accessesGranted'));
                    },
                )}>
            {({ values, isValid, handleSubmit }) => (
                <Form onSubmit={handleSubmit} className="flex flex-col justify-between h-full">
                    <div className="flex flex-col flex-1">
                        <AccessListItem preventDeleteLast
                                        accessToggles={accessToggles}
                                        setAccessToggles={setAccessToggles}/>
                    </div>

                    <StickyButton className="px-0 pb-7">
                        <Button size="l"
                                stretched
                                type="submit"
                                mode="commerce"
                                onClick={() => handleSubmit()}
                                before={<Icon24EducationOutline/>}
                                loading={userAccessCreateManyLoading}
                                data-test="manage.user-add-access.button"
                                disabled={!isValid || userAccessCreateManyLoading}>
                            {t('grantAccesses')} ({values.enrollments.accesses.length})
                        </Button>
                    </StickyButton>
                </Form>
            )}
        </Formik>
    );
};


export { CreateAccessTab };
