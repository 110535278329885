/**
 * LeftPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, PageStore } from '@/store/core/page';

import { PageSpace } from '@/shared/types';
import { If, Link, Platform, Responsive } from '@/cutils';

import { DesktopNavigationMenuDrawer } from '@/components/Desktop/Navigation';

import { HeaderLogoPart } from './HeaderLogoPart';


const LeftPart = observer(() => {
    return (
        <div className="viewport__left-menu vkuiSplitCol--spaced h-full">
            <div className="flex items-center h-full">
                <If is={PageStore.mode !== 'slim-menu'}>
                    <Platform.If platforms={[
                        PageSpace.SchoolPlatform,
                        PageSpace.MarketplacePlatform,
                    ]}>
                        <DesktopNavigationMenuDrawer/>
                    </Platform.If>
                </If>

                <Responsive.DesktopOnly force={PageStore.mode === 'slim-menu'}>
                    <Link pushPage={{ id: '/' }}>
                        <HeaderLogoPart/>
                    </Link>
                </Responsive.DesktopOnly>
            </div>
        </div>
    );
});


export { LeftPart };
