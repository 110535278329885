/**
 * PersonalUserView
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useLayoutEffect, useState } from 'react';

import { Page } from '@/pages/Core';

import { ChatFindOnePersonalQueryResult } from '@/codegen/graphql';

import { If } from '@/cutils';
import { Time } from '@/utils';
import { GqlResult } from '@/types/graphql';
import { Router } from '@/services/Utils/Router';

import { ChatMessagesView } from './ChatMessagesView';


interface Props {
    personalUserId: number;
    chat?: GqlResult<ChatFindOnePersonalQueryResult>['chatFindOnePersonal'] | null;
}


const PersonalUserView = ({ chat, personalUserId }: Props) => {

    const [ loading, setLoading ] = useState(true);

    useLayoutEffect(() => {
        if (chat && chat.id) {
            Time.timer(0)
                .then(() => Router.replacePage('/chat', { chatId: `${chat?.id}` }));
        } else {
            setLoading(false);
        }
    }, [ chat ]);

    return (
        <>
            <If is={loading}>
                <div className="flex flex-col w-full relative">
                    <div className="flex flex-1 relative">
                        <Page.Spinner/>
                    </div>
                </div>
            </If>

            <If is={!loading}>
                <ChatMessagesView personalUserId={personalUserId}/>
            </If>
        </>

    );
};


export { PersonalUserView };
