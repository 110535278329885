/**
 * ProductAccessListView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { ManageSchoolUsersPageStore } from '../../store';

import { Router } from '@/services/Utils/Router';
import { CourseService } from '@/services/Course/Course';

import { ProductType } from '@/codegen/graphql';
import { ProductAccessProgressItem } from '@/types/product';

import { CourseCard, CourseList } from '@/components/Course/CourseCard';

import { UserEditTab } from '../../interfaces';
import { CourseCardHeaderItem } from './items/CourseCardHeaderItem';


export interface ProductAccessListViewProps {
    accesses: ProductAccessProgressItem[];
}


const ProductAccessListView = (props: ProductAccessListViewProps) => {

    const { accesses } = props;

    const { setUserEditActiveTab } = ManageSchoolUsersPageStore;

    return (
        <CourseList>
            {accesses?.map((access) => {
                const { product, participant } = access;

                switch (product.type) {
                    case ProductType.Course:
                        return product.course && (
                            <CourseCard mode="progress"
                                        key={access.id}
                                        multiLine={false}
                                        clickMode="currentLesson"
                                        colorful={access.isActive}
                                        header={<CourseCardHeaderItem access={access}/>}
                                        vkCardClassName={!access.isActive ? 'bg-hover' : ''}
                                        onClick={() => {
                                            Router.updateParams({ accessId: `${access.id}` });
                                            setUserEditActiveTab(UserEditTab.StudyProgress);
                                        }}
                                        course={{
                                            ...product.course,
                                            lessons: CourseService.combineLessonsWithProgress(product, participant),
                                            currentLesson: CourseService.getCurrentLessonByProgress(product, participant),
                                        }}/>
                        );
                }
            })}
        </CourseList>
    );
};


export { ProductAccessListView };
