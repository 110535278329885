/**
 * SubNavigationSelect styled container
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { ReactNode, useEffect, useState } from 'react';

import { useLocation } from '@/router/index';
import { useUrlFilters } from '@/hooks/core';

import { Icon24Cancel, Icon24Filter } from '@vkontakte/icons';
import { CustomSelect, CustomSelectOption, CustomSelectProps, SubnavigationButtonProps } from '@exode.ru/vkui';

import { NavigationButton, SelectWrapper } from './SubNavigationSelect.styled';


interface Props {
    value: any;
    name: string;
    initialTitle: string;
    onChange: (name: string, value: any) => void;
    options: Array<{
        value: string | number;
        label: string;
        icon?: ReactNode;
    }>;
    filterIcon?: ReactNode;
    placeholder?: string;
    customSelectProps?: CustomSelectProps;
    navigationButtonProps?: SubnavigationButtonProps;
}


const SubNavigationSelect = (props: Props) => {

    const {
        name,
        value,
        options,
        onChange,
        initialTitle,
        customSelectProps,
        navigationButtonProps,
        placeholder = initialTitle,
        filterIcon = (
            <Icon24Filter fill="var(--icon_secondary)"/>
        ),
    } = props;

    const [ open, setOpen ] = useState(false);
    const [ selectedValue, setSelectedValue ] = useState(value);

    const { route: { params } } = useLocation();
    const { removeUrlFilter } = useUrlFilters({});

    const setSelectedOption = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;

        setSelectedValue(value);
        onChange(name, value);
    };

    useEffect(() => {
        setSelectedValue(value);
    }, [ value ]);

    return (
        <SelectWrapper hasValue={!!value}>
            <NavigationButton expandable
                              open={open}
                              className={open ? 'thin-border-border' : 'thin-border-border--transparent'}
                              before={(
                                  params[name]
                                      ? <Icon24Cancel fill="var(--accent)" onClick={(e) => {
                                          e.stopPropagation();
                                          removeUrlFilter([ name as never ]);
                                      }}/>
                                      : filterIcon
                              )} {...navigationButtonProps}>
                {_.find(options, { value: selectedValue })?.label || initialTitle}
            </NavigationButton>

            <CustomSelect name={name}
                          value={value || '-'}
                          options={options}
                          placeholder={placeholder}
                          onOpen={() => setOpen(true)}
                          onClose={() => setOpen(false)}
                          onChange={setSelectedOption}
                          renderOption={({ option, ...restProps }) => (
                              <CustomSelectOption {...restProps} key={option.value} before={option.icon}/>
                          )} {...customSelectProps}/>
        </SelectWrapper>
    );
};


export { SubNavigationSelect };
