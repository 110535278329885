/**
 * PracticeAttemptStatusItem
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If, SvgComponent } from '@/cutils';

import { practiceAttemptStatus } from '@/types/practice';


import { CourseLessonPracticeAttemptStatus } from '@/codegen/graphql';


interface Props {
    status: CourseLessonPracticeAttemptStatus | undefined;
    size?: number;
    className?: string;
}


const PracticeAttemptStatusItem = (props: Props) => {

    const { status, className, size = 20 } = props;

    const practiceAttemptStatuses = practiceAttemptStatus();

    return (
        <If is={!!status}>
            <div className={[
                className,
                'rounded-full',
                status && practiceAttemptStatuses[status].bgColor,
            ].join(' ')}>
                {status && (
                    <SvgComponent svgProps={{ width: size, height: size }}
                                  element={practiceAttemptStatuses[status].icon}/>
                )}
            </div>
        </If>
    );
};


export { PracticeAttemptStatusItem };
