/**
 * UseManageGroup
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { ManageGroupsListPageStore } from '@/pages/Manage/Groups/List/store';
import { SaveStoreDeepKeys, SaveStoreKeys, Saving, SavingStore } from '@/store/core/saving';

import { Notify } from '@/cutils';

import { useStore } from '@/pages/Core';
import { GroupItem } from '@/types/group';
import { GqlResult } from '@/types/graphql';

import {
    CreateGroupInput,
    GroupAccessStartPointType,
    GroupFindManyDocument,
    GroupFindManyQuery,
    GroupManageCreateMutationResult,
    GroupScheduleOpenUnit,
    GroupScheduleType,
    UpdateGroupInput,
    useGroupManageCreateMutation,
    useGroupManageUpdateMutation,
} from '@/codegen/graphql';

import { Icon16Cancel } from '@vkontakte/icons';


interface Props {
    withSavingStore?: boolean;
}


export const useManageGroup = (props: Props = {}) => {

    const { list, filter, sort, params } = useStore(ManageGroupsListPageStore);

    const setGroupEditing = (editing = true) => {
        SavingStore.setEditing(
            SaveStoreKeys.Group,
            SaveStoreDeepKeys.GroupSettings,
            editing,
        );
    };

    const getInitialValues = (group: GroupItem | null): UpdateGroupInput => {

        const {
            communication,
            accessLimitation,
            contentLimitation,
            scheduleLimitation,
        } = group || {};

        return {
            name: group?.name || '',
            maxMembers: group?.maxMembers || undefined,
            communication: {
                channelIsActive: !_.isNil(communication?.channelIsActive)
                    ? communication?.channelIsActive :
                    false,
                groupChatIsActive: !_.isNil(communication?.groupChatIsActive)
                    ? communication?.groupChatIsActive
                    : false,
                dialogueWithEmployeesBlocked: !_.isNil(communication?.dialogueWithEmployeesBlocked)
                    ? communication?.dialogueWithEmployeesBlocked
                    : false,
            },
            accessLimitation: {
                active: !_.isNil(accessLimitation?.active)
                    ? accessLimitation?.active
                    : false,
                durationInMinutes: accessLimitation?.durationInMinutes || undefined,
                startPointType: accessLimitation?.startPointType || GroupAccessStartPointType.CommonDate,
                commonDateStartAt: accessLimitation?.commonDateStartAt || null,
            },
            scheduleLimitation: {
                active: !_.isNil(scheduleLimitation?.active)
                    ? scheduleLimitation?.active
                    : false,
                type: scheduleLimitation?.type || GroupScheduleType.ByWeekdays,
                openUnit: scheduleLimitation?.openUnit || GroupScheduleOpenUnit.Lesson,
                byWeekdays: {
                    selected: scheduleLimitation?.byWeekdays?.selected || [],
                    startFrom: scheduleLimitation?.byWeekdays?.startFrom || undefined,
                },
                onceForInterval: {
                    durationInMinutes: scheduleLimitation?.onceForInterval?.durationInMinutes || 0,
                    startFrom: scheduleLimitation?.onceForInterval?.startFrom || undefined,
                },
            },
            contentLimitation: {
                disableSendHomework: !_.isNil(contentLimitation?.disableSendHomework)
                    ? contentLimitation?.disableSendHomework
                    : undefined,
                hideEarlyAccessLessons: !_.isNil(contentLimitation?.hideEarlyAccessLessons)
                    ? contentLimitation?.hideEarlyAccessLessons
                    : undefined,
                lessons: contentLimitation?.lessons || {},
            },
        } as const;
    };

    /** Create a group */
    const [ _createGroup, {
        error: createGroupError,
        loading: createGroupLoading,
    } ] = useGroupManageCreateMutation({
        onError(error) {
            console.error(error);

            Notify.vkui({
                appearance: 'error',
                message: error.message,
                icon: <Icon16Cancel/>,
            });
        },
        update: (cache, { data }) => {
            const variables = {
                list: { ...list },
                filter: { ...filter },
                sort: { ...sort },
            };

            const cachedGroups = cache.readQuery<GroupFindManyQuery>({
                variables,
                query: GroupFindManyDocument,
            });

            if (!cachedGroups) {
                return console.warn('[Cache]: cachedGroups отсутствуют в кэше');
            }

            const { page, pages, count, items } = cachedGroups.groupFindMany;

            if (params.courseId) {
                /** Refetch groups count in course card */
                cache.evict({
                    id: `CourseEntity:${params.courseId}`,
                    fieldName: 'product',
                });
            }

            if (data?.groupManageCreate) {
                cache.writeQuery<GroupFindManyQuery>({
                    query: GroupFindManyDocument,
                    variables,
                    data: {
                        groupFindMany: {
                            page,
                            pages,
                            count: count + 1,
                            items: [
                                data?.groupManageCreate,
                                ...(items ?? []),
                            ],
                        },
                    },
                });
            }
        },
    });

    const createGroup = (
        group: CreateGroupInput,
        onCompleted?: (data: GqlResult<GroupManageCreateMutationResult>['groupManageCreate']) => void,
    ) => {
        return _createGroup({
            variables: { group },
            onCompleted: (data) => {
                onCompleted?.(data.groupManageCreate);
            },
        });
    };

    /** Update group */
    const [ _updateGroup, {
        error: updateGroupError,
        loading: updateGroupLoading,
    } ] = useGroupManageUpdateMutation();

    const updateGroup = (
        groupId: number,
        group: UpdateGroupInput,
        onCompleted?: () => void,
    ) => {
        return _updateGroup({
            variables: { groupId, group },
            onCompleted: () => onCompleted?.(),
        });
    };

    Saving.listenSetSaving(
        SaveStoreKeys.Group,
        SaveStoreDeepKeys.GroupSettings,
        [ updateGroupLoading ],
        [ updateGroupError ],
        { disabled: !props?.withSavingStore },
    );

    return {
        createGroup,
        createGroupError,
        createGroupLoading,
        updateGroup,
        updateGroupError,
        updateGroupLoading,
        setGroupEditing,
        getInitialValues,
    };
};
