/**
 * MessageMediaWidgetItem
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useStore } from '@/pages/Core';
import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';

import { Graphql } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { ChatMessageItem } from '@/types/chat';

import { Caption } from '@exode.ru/vkui';
import { Skeleton } from '@mui/material';

import { CourseLessonPracticeAttemptWidgetQuery, MessageMediaWidgetType } from '@/codegen/graphql';

import { PracticeAttemptStatusItem } from '@/pages/Manage/Homework';

import { TaskResultWidget } from '@/components/Widgets/Task/TaskResultWidget';


interface Props {
    chatId: number;
    message: ChatMessageItem;
    widgetId: number;
    widget: ChatMessageItem['medias'][number]['widget'];
}


const MessageMediaWidgetItem = (props: Props) => {

    const { chatId, message, widget } = props;

    const { t } = useI18n('pages.Chat.Dialog.WidgetItem');

    const { store, input } = useStore(ChatDialogsPageStore);

    const { courseLessonPracticeAttemptUuid } = widget?.data || {};

    switch (widget?.type) {
        case MessageMediaWidgetType.CourseLessonPracticeAttempt:
            return courseLessonPracticeAttemptUuid ? (
                <CourseLessonPracticeAttemptWidgetQuery children={(result) => (
                    <>
                        <Graphql.Loading result={result}>
                            <Skeleton height={64}
                                      width="100%"
                                      variant="rectangular"
                                      className="vk-rounded thin-border"/>
                        </Graphql.Loading>

                        <Graphql.Success result={result}>
                            {({ courseLessonPracticeAttemptFindOneByUuid: attempt }) => {
                                const {
                                    status,
                                    answers,
                                    practice,
                                    pointsAmount,
                                    attemptIndex,
                                    maxPointsAmount,
                                } = attempt;

                                return (
                                    <TaskResultWidget answers={answers}
                                                      points={pointsAmount}
                                                      maxPoints={maxPointsAmount}
                                                      title={practice?.lesson.name}
                                                      subtitle={`${t('attempt')}: ${attemptIndex}`}
                                                      icon={(
                                                          <PracticeAttemptStatusItem status={status}
                                                                                     className="p-1.5 mr-3"/>
                                                      )}
                                                      handleReply={() => {
                                                          store.setInput('replyMessage', {
                                                              ...input.replyMessage,
                                                              [chatId]: message,
                                                          });
                                                      }}/>
                                );
                            }}
                        </Graphql.Success>

                        <Graphql.Error disableRefetch result={result}>
                            <div className={[
                                'flex items-center justify-center vk-rounded',
                                'bg-hover thin-border w-full h-[64px]',
                            ].join(' ')}>
                                <Caption className="text-secondary">
                                    {t('failedToLoadWidget')}
                                </Caption>
                            </div>
                        </Graphql.Error>
                    </>
                )} variables={{ attemptUuid: courseLessonPracticeAttemptUuid }}/>
            ) : <></>;

        default:
            return <></>;
    }
};


export { MessageMediaWidgetItem };
