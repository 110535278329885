/**
 * UseLessonPracticeManage
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { GqlResult } from '@/types/graphql';
import { apolloClient } from '@/api/graphql';

import { Notify } from '@/components/Utils';

import { Icon16Cancel } from '@vkontakte/icons';

import {
    CourseLessonPracticeFindOneDocument,
    CourseLessonPracticeFindOneQuery,
    CourseLessonPracticeFindOneQueryResult,
    CreateLessonPracticeCourseInput,
    CreateTaskInput,
    TaskCommonFragmentFragment,
    UpdateLessonPracticeTaskOrderCourseInput,
    useCourseLessonPracticeManageCreateMutation,
    useCourseLessonPracticeManageUpdateMutation,
    useCourseLessonPracticeManageUpdateTaskOrderMutation,
    useCourseLessonPracticeTaskManageCreateMutation,
    useCourseLessonPracticeTaskManageDeleteMutation,
} from '@/codegen/graphql';


export const useLessonPracticeManage = (
    courseId: number,
    lessonId: number,
) => {

    /** Управление практикой: */

    const getInitialValues = (
        practice?: GqlResult<CourseLessonPracticeFindOneQueryResult>['courseLessonPracticeFindOne'],
    ) => ({
        description: practice?.description || '',
        deadlineInDays: practice?.deadlineInDays || 0,
    });

    const [ _createPractice, {
        loading: createPracticeLoading,
        error: createPracticeError,
    } ] = useCourseLessonPracticeManageCreateMutation({
        onError: (error) => console.error(error),
    });

    const createPractice = (practice: CreateLessonPracticeCourseInput) => {
        return _createPractice({
            variables: {
                lessonId,
                practice,
            },
            refetchQueries: [
                {
                    query: CourseLessonPracticeFindOneDocument,
                    variables: { courseId, lessonId },
                },
            ],
        });
    };

    const [ _updatePractice, {
        loading: updatePracticeLoading,
        error: updatePracticeError,
    } ] = useCourseLessonPracticeManageUpdateMutation({
        onError: (error) => console.error(error),
    });

    const updatePractice = (
        lessonPracticeId: number,
        practice: CreateLessonPracticeCourseInput,
    ) => {
        return _updatePractice({
            variables: {
                practice,
                lessonPracticeId,
            },
        });
    };

    const [ _updatePracticeTaskOrder, {
        loading: updatePracticeTaskOrderLoading,
        error: updatePracticeTaskOrderError,
    } ] = useCourseLessonPracticeManageUpdateTaskOrderMutation({
        onError: (error) => console.error(error),
    });

    /** Создание практики */
    const [ _createPracticeTask, {
        data: createPracticeTaskData,
        loading: createPracticeTaskLoading,
    } ] = useCourseLessonPracticeTaskManageCreateMutation();

    /** Управление заданиями к практике: */

    const createPracticeTask = (
        courseId: number,
        lessonId: number,
        lessonPracticeId: number,
        task: CreateTaskInput,
        onCompleted?: (data: TaskCommonFragmentFragment) => void,
    ) => {
        return _createPracticeTask({
            variables: { lessonPracticeId, task },
            onCompleted: (data) => onCompleted?.(
                data.courseLessonPracticeTaskManageCreate.task,
            ),
            onError(error) {
                console.error(error);

                Notify.vkui({
                    appearance: 'error',
                    message: error.message,
                    icon: <Icon16Cancel/>,
                });
            },
            update: (cache, { data }) => {
                const variables = { courseId, lessonId };

                const practice = cache.readQuery<CourseLessonPracticeFindOneQuery>({
                    query: CourseLessonPracticeFindOneDocument,
                    variables,
                });

                if (!practice?.courseLessonPracticeFindOne) {
                    return console.warn('[Cache]: practice отсутствуют в кэше');
                }

                if (data?.courseLessonPracticeTaskManageCreate) {
                    cache.writeQuery<CourseLessonPracticeFindOneQuery>({
                        query: CourseLessonPracticeFindOneDocument,
                        variables,
                        data: {
                            courseLessonPracticeFindOne: {
                                ...practice.courseLessonPracticeFindOne,
                                tasks: [
                                    ...(practice?.courseLessonPracticeFindOne?.tasks.map((item) => item) ?? []),
                                    data.courseLessonPracticeTaskManageCreate,
                                ],
                            },
                        },
                    });
                }
            },
        });
    };

    const [ _deletePracticeTask, {
        loading: deletePracticeTaskLoading,
    } ] = useCourseLessonPracticeTaskManageDeleteMutation();

    const deletePracticeTask = (
        courseId: number,
        lessonId: number,
        practiceTaskId: number,
    ) => {
        return _deletePracticeTask({
            variables: { practiceTaskId },
            update: (cache, { data }) => {
                const variables = { courseId, lessonId };

                const practice = cache.readQuery<CourseLessonPracticeFindOneQuery>({
                    query: CourseLessonPracticeFindOneDocument,
                    variables,
                });

                if (!practice?.courseLessonPracticeFindOne) {
                    return console.warn('[Cache]: practice отсутствуют в кэше');
                }

                if (data?.courseLessonPracticeTaskManageDelete) {
                    cache.writeQuery<CourseLessonPracticeFindOneQuery>({
                        query: CourseLessonPracticeFindOneDocument,
                        variables,
                        data: {
                            courseLessonPracticeFindOne: {
                                ...practice.courseLessonPracticeFindOne,
                                tasks: practice.courseLessonPracticeFindOne?.tasks
                                    ?.filter(({ id }) => id !== practiceTaskId) || [],
                            },
                        },
                    });
                }
            },
        });
    };

    const getExistPracticeTasks = () => {
        const { cache } = apolloClient;

        const { courseLessonPracticeFindOne } = cache.readQuery<CourseLessonPracticeFindOneQuery>({
            variables: { courseId, lessonId },
            query: CourseLessonPracticeFindOneDocument,
        }) || {};

        return courseLessonPracticeFindOne?.tasks;
    };

    const updatePracticeTaskOrder = (
        lessonPracticeId: number,
        items: UpdateLessonPracticeTaskOrderCourseInput['items'],
    ) => {
        const { cache } = apolloClient;

        const existTasks = getExistPracticeTasks();

        if (existTasks) {
            cache.modify({
                id: `CourseLessonPracticeEntity:${lessonPracticeId}`,
                fields: {
                    tasks: () => _.orderBy(existTasks.map((practiceTask) => {
                        const orderPracticeTask = _.find(
                            items,
                            { uuid: practiceTask.task.uuid },
                        );

                        return orderPracticeTask
                            ? { ...practiceTask, order: orderPracticeTask.order }
                            : practiceTask;
                    }), 'order'),
                },
            });
        }

        return _updatePracticeTaskOrder({
            variables: {
                lessonId,
                lessonPracticeId,
                taskOrder: { items },
            },
        });
    };

    return {
        getInitialValues,
        createPractice,
        createPracticeError,
        createPracticeLoading,
        updatePractice,
        updatePracticeError,
        updatePracticeLoading,
        createPracticeTask,
        createPracticeTaskLoading,
        createPracticeTaskData,
        deletePracticeTask,
        deletePracticeTaskLoading,
        updatePracticeTaskOrder,
        updatePracticeTaskOrderError,
        updatePracticeTaskOrderLoading,
    };
};
