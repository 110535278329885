/**
 * ChatFolderItem
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer } from '@/pages/Core';

import { If } from '@/cutils';
import { useChatUnread } from '@/hooks/apollo';
import { chatFolderName, ChatFolderType } from '@/types/chat';

import { Counter, TabsItem } from '@exode.ru/vkui';

import { useChatListFilter } from '../filter';


interface Props {
    folder: ChatFolderType;
    onSelectedClick?: (folder: ChatFolderType) => void;
}


const ChatFolderItem = observer((props: Props) => {

    const { folder, onSelectedClick } = props;

    const chatFolderNames = chatFolderName();

    const { unreadCountChat } = useChatUnread(folder);

    const { urlFilter, setUrlFilter } = useChatListFilter();

    const isSelected = urlFilter.folder === folder
        || (folder === 'All' && !urlFilter.folder);

    const selectChatFolder = (folder: ChatFolderType) => () => {
        if (urlFilter.folder === folder) {
            onSelectedClick?.(folder);
        }

        setUrlFilter({ folder }, { mode: 'merge' });
    };

    return (
        <TabsItem selected={isSelected} onClick={selectChatFolder(folder)} className={[
            'flex items-center justify-center last:mr-[6px] select-none',
            !!unreadCountChat ? '' : 'pr-px',
        ].join(' ')} status={(
            <If is={!!unreadCountChat}>
                <Counter mode="primary" size="s">
                    {unreadCountChat}
                </Counter>
            </If>
        )}>
            {chatFolderNames[folder]}
        </TabsItem>
    );
});


export { ChatFolderItem };
