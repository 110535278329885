/**
 * ManageSchoolWebhooksPage store
 *
 * @author: exode <hello@exode.ru>
 */

import { makeObservable } from 'mobx';

import { observer, PageStore } from '@/pages/Core';

import { defaultFilter, dtoFilterTransformer } from './filter';


class ManageSchoolWebhooksPage extends PageStore {

    constructor() {
        super();
        makeObservable(this);
    }

    state = {
        list: { skip: 0, take: 15 },
        filter: dtoFilterTransformer(defaultFilter),
        sort: {},
    };

}

const ManageSchoolWebhooksPageStore = new ManageSchoolWebhooksPage();


export { observer, ManageSchoolWebhooksPageStore };
