/**
 * EpicRoot
 *
 * @author: exode <hello@exode.ru>
 */

import React, { Suspense } from 'react';

import { PageStore } from '@/store/core/page';
import { observer, RouterStore } from '@/store/core/router';

import { Routes } from '@/router/routes';

import { RoutePathType } from '@/router/paths';
import { RouterItemType } from '@/types/router';

import { Middleware } from '@/cutils';

import { Epic, Panel, View } from '@exode.ru/vkui';

import { NavigationTabBarItems } from '@/components/Navigation/Tab';
import { AppPageFallback } from '@/components/App/PageFallback';


const EpicRoot = observer(() => {
    return (
        <Epic activeStory={RouterStore.viewId} tabbar={<NavigationTabBarItems/>}>

            {/** App routes and their views */}

            {
                Object.keys(Routes).map((key, index) => {

                    const Route = Routes[key as RoutePathType] as RouterItemType;
                    const viewId = Route.viewId ? Route.viewId : '' as RoutePathType;

                    const Page = Route.auth
                        ? <Middleware.Auth id={viewId}
                                           children={Route.view}
                                           permissions={Route.auth}
                                           onGuardForbidden={Route.onGuardForbidden}/>
                        : (Route.unauth
                                ? <Middleware.Unauth children={Route.view} onGuardForbidden={Route.onGuardForbidden}/>
                                : Route.view
                        );

                    return (
                        <View data-epic-root
                              key={index}
                              id={viewId}
                              activePanel={viewId}
                              view-type={RouterStore.forceTabbar !== 'hide' ? Route.type : 'page'}>
                            <Panel id={viewId} className={[
                                PageStore.footer
                                && RouterStore.type !== 'iframe'
                                && PageStore.mode !== 'slim-menu'
                                    ? 'd:pb-9'
                                    : '',
                            ].join(' ')}>
                                <Suspense fallback={<AppPageFallback/>}>
                                    {Page}
                                </Suspense>
                            </Panel>
                        </View>
                    );
                })
            }
        </Epic>
    );
});


export { EpicRoot };
