/**
 * Use tab navigation
 *
 * @author: exode <hello@exode.ru>
 */

import { ReactNode } from 'react';


export interface TabItemItem {
    type: string;
    title: string;
    view: ReactNode;
    before?: ReactNode;
    hidden?: boolean;
    subTabs?: (TabItemItem & { group?: string; })[];
}


export const useTabNavigation = (
    activeTabType: string,
    tabs: TabItemItem[],
) => {
    const root = tabs.find(tab => tab.type === activeTabType);

    if (root) {
        return {
            tab: root,
            parent: undefined,
            navigation: tabs,
        };
    }

    for (const tab of tabs) {
        if (tab.subTabs) {
            const childTab = tab.subTabs.find(tab => tab.type === activeTabType);

            if (childTab) {
                return {
                    tab: childTab,
                    parent: tab,
                    navigation: tab?.subTabs?.filter((e) => (
                        !e.group
                        || !childTab?.group
                        || e.group === childTab.group
                    )),
                };
            }
        }
    }

    return {
        tab: undefined,
        parent: undefined,
        navigation: [],
    };
};
