/**
 * SystemMessageItem styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div`
  & > span {
    border: var(--thin-border) solid var(--text_secondary);
  }
`;
