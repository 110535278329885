/**
 * Use fixed dimensions
 *
 * @author: exode <hello@exode.ru>
 */

import { useEffect, useRef, useState } from 'react';


interface Dimensions {
    width?: number;
    height?: number;
}

interface UseFixedDimensionsOptions {
    fixWidth?: boolean;
    fixHeight?: boolean;
}


export const useFixedDimensions = (
    options: UseFixedDimensionsOptions = { fixHeight: true },
    deps: Array<any> = [],
) => {
    const ref = useRef<HTMLDivElement>(null);

    const [ dimensions, setDimensions ] = useState<Dimensions>({});

    const style: React.CSSProperties = {};

    if (dimensions.width !== undefined) {
        style.minWidth = `${dimensions.width}px`;
    }

    if (dimensions.height !== undefined) {
        style.minHeight = `${dimensions.height}px`;
    }

    useEffect(() => {
        if (deps.length) {
            setDimensions({});
        }
    }, deps);

    useEffect(() => {
        const element = ref.current;

        if (!element) {
            return;
        }

        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                const { width, height } = entry.contentRect;

                setDimensions((prev) => ({
                    width: options.fixWidth
                        ? (prev.width === undefined ? width : Math.max(prev.width, width))
                        : undefined,
                    height: options.fixHeight
                        ? (prev.height === undefined ? height : Math.max(prev.height, height))
                        : undefined,
                }));
            }
        });

        resizeObserver.observe(element);

        return () => {
            resizeObserver.disconnect();
        };
    }, [ options.fixWidth, options.fixHeight, ...deps ]);

    return {
        ref,
        style: Object.keys(style).length > 0 ? style : undefined,
    };
};
