/**
 * Helper
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';


export const handleContentEditablePaste = (e: ClipboardEvent | React.ClipboardEvent) => {
    e.preventDefault();

    const text = e.clipboardData?.getData('text/plain');
    document.execCommand('insertText', false, text);
};
