/**
 * UseDragAndDrop
 *
 * @author: exode <hello@exode.ru>
 */

import { useCallback, useState } from 'react';

import { DragEndEvent, DragStartEvent } from '@dnd-kit/core';


export const useDragAndDrop = () => {

    const [ activeId, setActiveId ] = useState<string | null>(null);

    const handleDragStart = useCallback((event: DragStartEvent) => {
        setActiveId(event.active.id as string);
    }, []);

    const handleDragEnd = useCallback((event: DragEndEvent) => {
        setActiveId(null);

        return event;
    }, []);

    return {
        activeId,
        handleDragStart,
        handleDragEnd,
    };
};
