/**
 * SubNavigationSelect styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { SubnavigationButton } from '@exode.ru/vkui';


export const SelectWrapper = styled.div<{ hasValue: boolean }>`
    width: fit-content;
    position: relative;

    :global(.vkuiCustomSelect) {
        width: 100%;
    }

    .Select {
        opacity: 0;
        width: 100%;
        position: absolute;
        bottom: 0;
        z-index: 5;
    }

    :global(.vkuiSelect--empty .vkuiSelect__title) {
        color: var(--text_primary);
    }

    :global(.vkuiFormField__after) {
        display: none;
    }

    .vkuiSubnavigationButton__before {
        cursor: pointer;
        z-index: ${({ hasValue }) => hasValue ? '100' : '0'}
    }

    .Tappable__hoverShadow {
        background-color: transparent !important;
    }
`;

export const NavigationButton = styled(SubnavigationButton)`
    border-bottom-right-radius: ${({ open }) => open ? '0' : 'var(--vkui--size_border_radius--regular)'} !important;
    border-bottom-left-radius: ${({ open }) => open ? '0' : 'var(--vkui--size_border_radius--regular)'} !important;
`;
