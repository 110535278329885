/**
 * DetailedEdit
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { DetailedTaskQuestion } from '@/shared/types';

import { TaskBuilderEditProps } from '@/components/Task/Builder';


interface Props extends TaskBuilderEditProps<DetailedTaskQuestion> {

}


const DetailedEdit = (props: Props) => {

    const {} = props;

    return (
        <>
        </>
    );
};


export { DetailedEdit };
