/**
 * NavigationTabBarItems component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { IS_VK } from '@/root/src/env';

import { RouterStore } from '@/store/core/router';
import { ConfigStore, observer } from '@/store/core/config';

import { PageSpace } from '@/shared/types';
import { Auth, If, Platform, Responsive } from '@/cutils';
import { useI18n, usePlatformDigitalIsOnSale } from '@/hooks/core';

import { Tabbar, TabbarItem } from '@exode.ru/vkui';
import { Icon24HomeOutline, Icon24MessageOutline, Icon24SquareStackUpOutline } from '@vkontakte/icons';

import { NavigationTabBarItem } from '@/components/Navigation/Tab';
import { AvailableNewAppVersion, DownloadAppBanner } from '@/components/Promotion/MobileBanners';

import { MenuPart } from './parts/MenuPart';
import { ChatIndicatorPart } from './parts/ChatIndicatorPart';


const NavigationTabBarItems = observer(() => {

    const { t } = useI18n('components.Navigation.Tab.BarItems');

    const showTabbar = !ConfigStore.isDesktop
        && [ 'tab', 'iframe' ].includes(RouterStore.type)
        && RouterStore.forceTabbar !== 'hide';

    const { digitalIsOnSale } = usePlatformDigitalIsOnSale();

    return (
        <Platform.If platforms={[
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ]}>
            <Platform.Market>
                <Responsive.Mobile>
                    <AvailableNewAppVersion showTabbar={showTabbar}/>
                    <DownloadAppBanner showTabbar={showTabbar}/>
                </Responsive.Mobile>
            </Platform.Market>

            <If is={showTabbar}>
                <Tabbar>
                    <If is={!IS_VK && digitalIsOnSale}>
                        <Auth>
                            <NavigationTabBarItem tab="education" page={{ id: '/education' }}>
                                <TabbarItem text={t('education')}>
                                    <Icon24HomeOutline/>
                                </TabbarItem>
                            </NavigationTabBarItem>
                        </Auth>

                        <NavigationTabBarItem tab="catalog" page={{ id: '/catalog' }}>
                            <TabbarItem text={t('catalog')}>
                                <Icon24SquareStackUpOutline/>
                            </TabbarItem>
                        </NavigationTabBarItem>
                    </If>

                    <Auth>
                        <NavigationTabBarItem tab="chat" page={{ id: '/chat' }}>
                            <TabbarItem text={t('messenger')} indicator={<ChatIndicatorPart/>}>
                                <Icon24MessageOutline/>
                            </TabbarItem>
                        </NavigationTabBarItem>
                    </Auth>

                    <MenuPart/>
                </Tabbar>
            </If>
        </Platform.If>
    );
});


export { NavigationTabBarItems };
