/**
 * RecoverDraft component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If } from '@/cutils';
import { useFormikDraft, useI18n } from '@/hooks/core';

import { Button, Header, Separator } from '@exode.ru/vkui';

import { InnerHtml } from '@/components/Atoms/InnerHtml';


interface Props {
    showDraft: boolean;
    setValues: any;
    handleRestore: ReturnType<typeof useFormikDraft>['handleRestore'];
    handleDeleteDraft: () => void;
    className?: string;
    withSeparator?: boolean;
}


const RecoverDraft = (props: Props) => {

    const {
        showDraft,
        handleRestore,
        setValues,
        handleDeleteDraft,
        className,
        withSeparator = true,
    } = props;

    const { t } = useI18n('components.Atoms.RecoverDraft');

    return (
        <If is={showDraft}>
            <div className={[ className, 'thin-border-b' ].join(' ')}>
                <div className="flex flex-1 items-center justify-between">
                    <Header multiline mode="primary" className="no-margin-top p-0 mt-2" subtitle={(
                        <div onClick={handleDeleteDraft} className="cursor-pointer">
                            <span className="m:hidden">
                                <InnerHtml content={t('youAlsoCanDeleteDraft')}/>
                            </span>

                            <span className="d:hidden">
                                <InnerHtml content={t('orDeleteDraft')}/>
                            </span>
                        </div>
                    )}>
                        {t('recoverDraft?')}
                    </Header>

                    <div>
                        <Button appearance="positive" onClick={() => handleRestore(setValues)}>
                            {t('recover')}
                        </Button>
                    </div>
                </div>

                <If is={withSeparator}>
                    <Separator wide className="pt-2"/>
                </If>
            </div>
        </If>
    );
};


export { RecoverDraft };
