/**
 * UseEditor
 *
 * @author: exode <hello@exode.ru>
 */

import { RefObject } from 'react';

import { useSkipWord } from './useSkipWord';
import { elementSelectors } from '../types';


export const useEditor = (editorRef: RefObject<HTMLDivElement>) => {

    const { createSkipWordElement } = useSkipWord(editorRef);

    const addSkipSpan = (
        text: string,
        uuid: string,
        listeners: Parameters<typeof createSkipWordElement>[2],
    ) => {
        if (!editorRef.current) {
            return;
        }

        return createSkipWordElement(
            text,
            uuid,
            listeners,
        );
    };

    const updateSkipSpan = (
        uuid: string,
        newText: string,
    ) => {
        if (!editorRef.current) {
            return;
        }

        const span = editorRef.current.querySelector(`[data-skip-uuid="${uuid}"]`) as HTMLElement;

        if (span) {
            span.textContent = newText;
            span.dataset.skipText = newText;
        }
    };

    const removeSkipSpan = (uuid: string) => {
        if (!editorRef.current) {
            return;
        }

        const span = editorRef.current.querySelector(`[data-skip-uuid="${uuid}"]`);

        if (span) {
            const text = span.textContent || '';
            const textNode = document.createTextNode(text);

            span.replaceWith(textNode);
            editorRef.current.normalize();
        }
    };

    const clearSkipSpans = () => {
        if (!editorRef.current) {
            return;
        }

        const spans = editorRef.current.querySelectorAll(elementSelectors.skipWord);

        spans.forEach((span) => {
            const text = span.textContent || '';
            const textNode = document.createTextNode(text);
            span.replaceWith(textNode);
        });

        editorRef.current.normalize();
    };

    return {
        addSkipSpan,
        updateSkipSpan,
        removeSkipSpan,
        clearSkipSpans,
    };
};
