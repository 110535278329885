/**
 * Accordion styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { AccordionProps } from './Accordion';


export const Container = styled.div<AccordionProps>`
    .manage-drawer > .MuiPaper-root {
        right: 0 !important;
    }

    .MuiPaper-root {
        right: unset;
        box-shadow: none !important;
        color: var(--text_primary) !important;
        background-color: var(--background_content) !important;
        border-radius: ${({ borderRadius = 'var(--vkui--size_border_radius_paper--regular)' }) => borderRadius} !important;

        .MuiAccordionSummary-content {
            margin: ${({ verticalSpacing }) => `${verticalSpacing || 14}px 0`};
        }

        [ class $= "-MuiButtonBase-root-MuiAccordionSummary-root" ] {
            min-height: initial !important;
            border-radius: ${({ borderRadius = 'var(--vkui--size_border_radius_paper--regular)' }) => borderRadius} !important;
            padding: ${({ headerSpacing }) => headerSpacing ? headerSpacing : '0 1rem'};
        }

        .Mui-expanded {
            min-height: initial !important;
        }
    }

    .MuiAccordionDetails-root {
        padding-left: ${({ horizontalPadding }) => `${horizontalPadding} !important`};
        padding-right: ${({ horizontalPadding }) => `${horizontalPadding} !important`};
        padding-top: ${({ verticalPadding }) => `${verticalPadding} !important`};
        padding-bottom: ${({ verticalPadding }) => `${verticalPadding} !important`};
    }

    .vkuiTappable {
        border-radius: ${({ borderRadius = 'var(--vkui--size_border_radius_paper--regular)' }) => borderRadius} !important;
    }

    .RichCell__content-after {
        margin: auto;
    }
`;
