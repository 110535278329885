/**
 * UserAvatar styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { UserAvatarProps } from '@/components/Atoms/UserAvatar/UserAvatar';


export const Container = styled.div<Pick<UserAvatarProps, 'badgeBg' | 'className' | 'badgeColor'>>`
    .vkuiAvatar__badge-online {
        color: ${({ badgeColor }) => badgeColor || ''};
        background-color: ${({ badgeBg }) => badgeBg || ''};
    }
`;
