/**
 * ShortShow
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { useFormikContext } from 'formik';

import { useI18n } from '@/hooks/core';
import { Field, If } from '@/cutils';
import { ShortTaskAnswer, ShortTaskQuestion } from '@/shared/types';

import { Input, MiniInfoCell } from '@exode.ru/vkui';
import { Icon20CancelCircleFillRed, Icon20CheckAlt, Icon20CheckCircleFillGreen } from '@vkontakte/icons';

import { TaskBuilderShowProps, useTaskBuilderContext } from '@/components/Task/Builder';


interface Props extends TaskBuilderShowProps<
    ShortTaskQuestion,
    ShortTaskAnswer
> {

}


const ShortShow = (props: Props) => {

    const { task, answer: { quiz } = {} } = props;

    const { handleChange } = useFormikContext();
    const { show: { subMode } } = useTaskBuilderContext();

    const { t } = useI18n('components.Task.Builder.parts.answer');

    const answerIsCorrect = task.question.variants
        ?.some((e) => e.text === quiz?.answer);

    return (
        <div>
            <Input name="quiz.answer"
                   placeholder={t('enterAnswer')}
                   onChange={Field.transform.ignoreSpaceOnChange(handleChange)}
                   className={[
                       'mb-1 fs-content',
                       [ 'preview', 'result' ].includes(subMode)
                           ? 'pointer-events-none'
                           : '',
                   ].join(' ')}
                   status={(
                       subMode === 'result'
                           ? answerIsCorrect ? 'valid' : 'error'
                           : 'default'
                   )}
                   value={(
                       subMode === 'preview'
                           ? _.map(task.question.variants, 'text')?.join(' / ')
                           : quiz?.answer || ''
                   )}
                   before={(
                       subMode === 'result'
                           ? (answerIsCorrect
                               ? <Icon20CheckCircleFillGreen/>
                               : <Icon20CancelCircleFillRed/>)
                           : undefined
                   )}/>

            <If is={subMode === 'result'}>
                <div className="mt-2">
                    {task.question.variants?.map((e) => (
                        <MiniInfoCell key={e.uuid}
                                      textWrap="full"
                                      className="pl-0"
                                      textLevel="secondary"
                                      before={<Icon20CheckAlt fill="var(--dynamic_green)"/>}>
                            {e.text}
                        </MiniInfoCell>
                    ))}
                </div>
            </If>
        </div>
    );
};


export { ShortShow };
