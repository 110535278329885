/**
 * UserRowItem
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If, Link } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { SchoolUserFindManyItem } from '@/types/user';

import { Icon24MessageOutline } from '@vkontakte/icons';
import { Button, ButtonGroup, Cell, IconButton } from '@exode.ru/vkui';

import { User } from '@/components/Atoms/User';
import { UserAvatar } from '@/components/Atoms/UserAvatar';
import { TagInlineText } from '@/components/Atoms/TagInlineText';
import { LastOnlineText } from '@/components/Profile/Page';

import { UserEditTab } from '@/pages/Manage/School/Users';


interface Props {
    user: SchoolUserFindManyItem;
}


const UserRowItem = (props: Props) => {

    const { user } = props;

    const { t } = useI18n('pages.Manage.School.Users.views');

    return (
        <Link pushModal={{
            id: 'manage-user-edit',
            params: { userId: `${user.id}` },
        }}>
            <Cell className="vk-rounded"
                  subtitle={<LastOnlineText userId={user.id} lastOnlineAt={user.lastOnlineAt}/>}
                  before={(
                      <UserAvatar size={48}
                                  userId={user.id}
                                  className="cursor-pointer mr-4"
                                  name={user.profile?.fullName || ''}
                                  src={user.profile?.avatar.medium || ''}/>
                  )}
                  after={(
                      <div className="flex items-center gap-1">
                          <ButtonGroup gap="none">
                              <If is={!!(user.email || user.phone)}>
                                  <Link onClick={(e) => e.stopPropagation()} pushModal={{
                                      id: 'manage-user-edit',
                                      params: {
                                          userId: `${user.id}`,
                                          tab: UserEditTab.Profile,
                                      },
                                  }}>
                                      <Button size="s"
                                              mode="tertiary"
                                              appearance="overlay"
                                              data-test="user-row.phone"
                                              className="text-secondary">
                                          <span className="text-secondary">
                                              {user.phone || user.email}
                                          </span>
                                      </Button>
                                  </Link>
                              </If>
                          </ButtonGroup>

                          <Link onClick={(e) => e.stopPropagation()} pushPage={{
                              id: '/chat',
                              params: { personalUserId: `${user.id}` },
                          }}>
                              <IconButton className="mr-2">
                                  <Icon24MessageOutline/>
                              </IconButton>
                          </Link>
                      </div>
                  )}>
                <div className="flex items-center gap-1">
                    <User.Name profile={user.profile}/>

                    <If is={!!user.productAccessesCount}>
                        <TagInlineText gradient="secondary"
                                       content={t('productAccesses', { count: user.productAccessesCount || 0 })}/>
                    </If>

                    <If is={user.banned}>
                        <TagInlineText gradient="red" content={t('banned')}/>
                    </If>

                    <If is={!user.activated}>
                        <TagInlineText gradient="gray" content={t('notActivated')}/>
                    </If>
                </div>
            </Cell>
        </Link>
    );
};


export { UserRowItem };
