/**
 * VariantsListPart
 *
 * @author: exode <hello@exode.ru>
 */

import moment from 'moment';

import { nanoid } from 'nanoid';

import React, { useState } from 'react';

import { ArrayHelpers, FastField, FastFieldProps, useFormikContext } from 'formik';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { StorageFileType, TaskAnswerType } from '@/codegen/graphql';
import { MultipleWithAttachmentTaskQuestion } from '@/shared/types';

import { TextTooltip } from '@exode.ru/vkui/dist/unstable';
import { Button, Checkbox, FormItem, Input, List } from '@exode.ru/vkui';

import {
    Icon20AddCircleFillBlue,
    Icon20AddCircleFillGreen,
    Icon24DeleteOutline,
    Icon24RadioOff,
    Icon24RadioOn,
} from '@vkontakte/icons';

import { ConfirmButton } from '@/components/Atoms/ConfirmButton';
import { TaskBuilderProps } from '@/components/Task/Builder';

import { VariantMainAttachmentAtom, VariantUploadAtom } from './atoms';


interface Props {
    task: TaskBuilderProps['task'];
    helpers: ArrayHelpers;
    variants: MultipleWithAttachmentTaskQuestion['variants'];
    onInstantChange?: () => void;
    fieldPrefix?: string;
}


const VariantsListPart = (props: Props) => {

    const {
        task,
        variants,
        onInstantChange,
        helpers: { push, remove },
        fieldPrefix = 'question.variants',
    } = props;

    const [ addedAt, setAddedAt ] = useState<Date | null>(null);

    const { handleSubmit, setFieldValue } = useFormikContext();

    const { t } = useI18n('components.Task.Builder.edit.parts');

    const allVariantsIsCorrect = task.answerType === TaskAnswerType.Short;

    const withAttachment = [
        TaskAnswerType.SingleWithAttachment,
        TaskAnswerType.MultipleWithAttachment,
    ].includes(task.answerType);

    const isRadioMode = [
        TaskAnswerType.Boolean,
        TaskAnswerType.Single,
        TaskAnswerType.SingleWithAttachment,
    ].includes(task.answerType);

    const checkbox = {
        disabled: allVariantsIsCorrect,
        onIcon: isRadioMode ? <Icon24RadioOn/> : undefined,
        offIcon: isRadioMode ? <Icon24RadioOff/> : undefined,
        handleChange: (index: number) => {
            const getField = (i: number) => `${fieldPrefix}[${i}].correct`;

            if ([
                TaskAnswerType.Single,
                TaskAnswerType.Boolean,
                TaskAnswerType.SingleWithAttachment,
            ].includes(task.answerType)) {
                variants.forEach((__, i) => {
                    if (i !== index) {
                        setFieldValue(getField(i), false);
                    }
                });
            }
        },
    };

    return (
        <>
            <List className="flex flex-col gap-3.5 pb-4">
                {variants?.map((variant, index) => (
                    <div key={variant.uuid} className="flex items-center gap-3 first:mt-2 min-h-[40px] w-full">
                        <FastField name={`${fieldPrefix}[${index}].correct`}>
                            {({ field }: FastFieldProps) => (
                                <TextTooltip placement="left"
                                             className="max-w-[180px]"
                                             appearance={variant.correct ? 'accent' : undefined}
                                             text={(
                                                 allVariantsIsCorrect
                                                     ? t('allVariantsIsCorrect')
                                                     : t('correctAnswer')
                                             )}>
                                    <Checkbox {...field} checked={variant.correct}
                                              onIcon={checkbox.onIcon}
                                              offIcon={checkbox.offIcon}
                                              disabled={checkbox.disabled}
                                              onChange={(e) => {
                                                  /** @Note: Order matters */
                                                  checkbox.handleChange(index);

                                                  field.onChange(e);

                                                  /** Boolean cannot be unselected */
                                                  if (!e.target.checked
                                                      && task.answerType === TaskAnswerType.Boolean
                                                  ) {
                                                      setFieldValue(field.name, true);
                                                  }
                                              }}/>
                                </TextTooltip>
                            )}
                        </FastField>

                        <If is={withAttachment}>
                            <VariantMainAttachmentAtom variant={variant}/>

                            <VariantUploadAtom variant={variant}
                                               fieldName={`${fieldPrefix}[${index}].attachments.main`}/>
                        </If>

                        <FormItem className={[
                            'p-0 flex-1',
                            /** Hide text input for audio */
                            variant.attachments?.main?.type === StorageFileType.Audio ? 'hidden' : '',
                        ].join(' ')}>
                            <div className="flex items-center gap-2">
                                <FastField name={`${fieldPrefix}[${index}].text`}>
                                    {({ field }: FastFieldProps) => (
                                        <Input {...field} className="w-full" value={variant.text} placeholder={(
                                            allVariantsIsCorrect
                                                ? t('correctVariant')
                                                : (withAttachment ? t('enterDescription') : t('enterText'))
                                        )} autoFocus={[
                                            !variant.text,
                                            index === variants.length - 1,
                                            addedAt && moment(addedAt).diff(moment(), 's') < 1,
                                        ].every((e) => e)}/>
                                    )}
                                </FastField>
                            </div>
                        </FormItem>

                        <If is={[
                            variants?.length > 1,
                            task.answerType !== TaskAnswerType.Boolean,
                        ].every((e) => e)}>
                            <div className="bg-input rounded-full thin-border">
                                <ConfirmButton className="opacity-75 hover:opacity-100" initialIcon={(
                                    <Icon24DeleteOutline fill="var(--icon_secondary)"/>
                                )} callback={() => {
                                    remove(index);
                                    onInstantChange?.();
                                    handleSubmit();
                                }}/>
                            </div>
                        </If>
                    </div>
                ))}
            </List>

            <If is={[
                task.answerType !== TaskAnswerType.Boolean,
            ].every((e) => e)}>
                <div className="flex items-center justify-center pb-4">
                    <Button size="l" mode="tertiary" data-test="task.add-variant" before={(
                        allVariantsIsCorrect
                            ? <Icon20AddCircleFillGreen/>
                            : <Icon20AddCircleFillBlue/>
                    )} onClick={() => {
                        push({
                            uuid: nanoid(),
                            text: '',
                            correct: allVariantsIsCorrect,
                        });

                        setAddedAt(new Date());

                        onInstantChange?.();
                        handleSubmit();
                    }}>
                        {allVariantsIsCorrect ? t('addCorrectOption') : t('addOption')}
                    </Button>
                </div>
            </If>
        </>
    );
};


export { VariantsListPart };
