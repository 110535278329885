/**
 * ChatMembersView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useLocation } from '@/router/index';

import { observer, Page } from '@/pages/Core';
import { ConfigStore } from '@/store/core/config';
import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';

import { useI18n, usePagination } from '@/hooks/core';
import { ChatMemberFindManyQuery } from '@/codegen/graphql';

import { Graphql, If, Link, Responsive } from '@/cutils';
import { Counter, Header, List } from '@exode.ru/vkui';

import { User } from '@/components/Atoms/User';
import { Pagination } from '@/components/Atoms/Pagination';
import { CustomScrollbar } from '@/components/Atoms/Styled';


const ChatMembersView = observer(() => {

    const { t } = useI18n('pages.Chat.Dialog');

    const { route: { params: { chatId } } } = useLocation();

    const { page, onPageChange, sort, filter, list } = usePagination({
        listField: 'members',
        filterField: 'members',
        sortField: 'members',
        mode: ConfigStore.isDesktop ? 'modal' : 'page',
        store: ChatDialogsPageStore,
    });

    return (
        <>
            <If is={!!+chatId}>
                <ChatMemberFindManyQuery children={(result) => (
                    <>
                        <Graphql.Loading result={result}>
                            <Page.Spinner/>
                        </Graphql.Loading>

                        <Graphql.Success result={result}>
                            {({ chatMemberFindMany: { items, count, pages } }) => (
                                <>
                                    <Responsive.Mobile>
                                        <Page.Head>
                                            <Page.Header backTo="/chat" title={t('chatParticipants')}/>
                                        </Page.Head>
                                    </Responsive.Mobile>

                                    <Header mode="primary" aside={<Counter size="m" mode="primary">{count}</Counter>}>
                                        {t('totalParticipants')}
                                    </Header>

                                    <CustomScrollbar className="min-h-[300px] d:overflow-auto">
                                        <List>
                                            {items?.map(({ user }) => (
                                                <Link key={user.id} pushPage={{
                                                    id: '/@:userId([0-9_A-Za-z]+)',
                                                    params: { userId: `${user.id}` },
                                                }}>
                                                    <User.Cell mode="simple"
                                                               userId={user.id}
                                                               dataTest="chat.member"
                                                               profile={user.profile}
                                                               lastOnlineAt={user.lastOnlineAt}/>
                                                </Link>
                                            ))}
                                        </List>
                                    </CustomScrollbar>

                                    <Pagination sticky
                                                boundaryCount={1}
                                                currentPage={page}
                                                totalPages={pages}
                                                onChange={(value) => onPageChange(value, result.refetch)}/>
                                </>
                            )}
                        </Graphql.Success>

                        <Graphql.Error result={result}/>
                    </>
                )} variables={{
                    chatId: +chatId,
                    list: { ...list },
                    filter: { ...filter.members },
                    sort: { ...sort.members },
                }}/>
            </If>
        </>
    );
});


export { ChatMembersView, ChatMembersView as default };
