/**
 * FileRow component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { IS_NATIVE } from '@/root/src/env';

import { FileUtil } from '@/utils';
import { ChatService } from '@/services/Chat/Chat';
import { If, useRedirect } from '@/cutils';
import { StorageFileType } from '@/shared/types';

import { Icon40DocumentCircle } from '@vkontakte/icons';
import { Avatar, Separator, SimpleCell, SimpleCellProps, Text } from '@exode.ru/vkui';

import { Container } from './FileRow.styled';


export interface FileRowProps extends SimpleCellProps {
    name: string;
    location: string;
    mimeType: string;
    size?: number;
    createdAt?: Date;
    dataTest?: string;
    separator?: boolean;
}


const FileRow = (props: FileRowProps) => {

    const {
        name,
        size,
        onClick,
        location,
        mimeType,
        dataTest,
        createdAt,
        className,
        separator = false,
        ...rest
    } = props;

    const { redirect } = useRedirect();

    const fileType = FileUtil.getFileTypeByMimeType(mimeType);

    /** Image icon for preview of the file */
    const avatarUrl = fileType === StorageFileType.Image ? location : '';

    /** Will not open in new tab */
    const inAppViewFileTypes = [
        StorageFileType.Image,
    ];

    return (
        <>
            <Container className="w-full">
                <SimpleCell data-test={dataTest}
                            className={[ '!pt-0 !pb-0 !pl-2 !pr-1', className ].join(' ')}
                            onClick={(e) => {
                                onClick?.(e);

                                if (!inAppViewFileTypes.includes(fileType)) {
                                    if (!IS_NATIVE && mimeType.includes(('application/pdf'))) {
                                        /** Next-release: add protection for PDF */
                                        return redirect(`${location}#toolbar=0`, true, true);
                                    }

                                    redirect(location, true, true);
                                }
                            }}
                            subtitle={(
                                <Text className="text-subhead fs-12 mt-[-4px]">
                                    {[
                                        size && FileUtil.getFileSize(size),
                                        createdAt && ChatService.parseChatDate(createdAt),
                                    ].filter(e => e).join(' • ')}
                                </Text>
                            )}
                            before={(
                                <Avatar mode="app" size={32} src={avatarUrl} className={avatarUrl && 'thin-border'}>
                                    <If is={!avatarUrl}>
                                        <Icon40DocumentCircle width={30} height={30} fill="var(--accent)"/>
                                    </If>
                                </Avatar>
                            )} {...rest}>
                    <Text weight="3" className="text-accent line-clamp-1 fs-13">
                        {name}
                    </Text>
                </SimpleCell>
            </Container>

            <If is={separator}>
                <Separator className="ml-[38px]"/>
            </If>
        </>
    );
};


export { FileRow };
