/**
 * Helper
 *
 * @author: exode <hello@exode.ru>
 */

import { RoutePathType } from '@/router/paths';
import { ContextMenuTab } from '@/types/manage';


/**
 * Определение активности context menu
 * @param {RoutePathType} pageId
 * @param {Record<any, any>} params
 * @param {ContextMenuTab} item
 */
export const itemIsActive = (
    pageId: RoutePathType,
    params: Record<any, any>,
    item: ContextMenuTab,
) => {
    const { path, extraPaths, exactParams, parentPaths } = item;

    const isCurrentPage = pageId === path.id;

    const activeConditions = [
        !exactParams && isCurrentPage,
        !exactParams && extraPaths?.some((p) => p === pageId),
        !exactParams && parentPaths?.some((p) => pageId.includes(p)),
        exactParams && isCurrentPage && params[exactParams.key] === exactParams.value,
    ];

    return activeConditions?.some((e) => e);
};
