/**
 * FileOptionAtom
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';
import { If, useRedirect } from '@/cutils';

import { Button, Caption } from '@exode.ru/vkui';

import { useTaskBuilderContext } from '@/components/Task/Builder';

import { CheckboxOptionAtomProps } from './CheckboxOptionAtom';


interface Props extends CheckboxOptionAtomProps {

}


const FileOptionAtom = (props: Props) => {

    const { variant, checkbox } = props;

    const { show: { subMode } } = useTaskBuilderContext();

    const { redirect } = useRedirect();
    const { location } = variant.attachments?.main || {};

    const { t } = useI18n('components.TaskBuilder.show.atoms');

    const isChecked = checkbox.getIsChecked(variant);

    const [ comment, fill ] = subMode === 'result'
        ? checkbox.getCheckboxCommentAndFill(variant)
        : [ null, null ];

    return (
        <div onClick={() => checkbox.handleChange(variant, !isChecked)} className={[
            'relative vk-rounded overflow-hidden border-[3px]',
            subMode !== 'result' ? 'cursor-pointer' : '',
            (isChecked || fill) ? 'p-2 border-solid border-[var(--accent)]' : '',
        ].join(' ')}>
            <div className="vk-rounded">
                <div className={[
                    'absolute',
                    (isChecked || fill) ? 'top-[18px] left-[18px]' : 'top-[10px] left-[10px]',
                ].join(' ')}>
                    <div style={{ color: fill ? `${fill} !important` : undefined }} className={[
                        'text-accent bg-white',
                        checkbox.type === 'checkbox' ? 'rounded-[6px]' : 'rounded-full',
                    ].join(' ')}>
                        {isChecked ? checkbox.onIcon : checkbox.offIcon}
                    </div>
                </div>

                <div className="flex flex-col items-center justify-center bg-hover aspect-[1/1] vk-rounded">
                    <Button className="mt-2" mode="outline" onClick={() => redirect(location, true)}>
                        {t('open')}
                    </Button>
                </div>

                <If is={!!variant.text || !!comment}>
                    <Caption className="text-center text-subhead pt-2">
                        {[ comment, variant.text ].filter((e) => e).join(' • ')}
                    </Caption>
                </If>
            </div>
        </div>
    );
};


export { FileOptionAtom };
