/**
 * CourseCard component
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { MouseEvent, ReactNode } from 'react';

import { PageParams } from 'router.tsx';

import { observer, PreferenceStore } from '@/store/preference/preference';

import { CourseManageFragmentFragment } from '@/codegen/graphql';

import { RoutePathType } from '@/router/paths';

import { Avatar, ButtonGroup, Card, Text } from '@exode.ru/vkui';
import { Icon28GiftOutline, Icon48PictureOutline } from '@vkontakte/icons';

import { useLocation } from '@/router/index';
import { CourseFindManyItem } from '@/types/course';

import { CourseService } from '@/services/Course/Course';
import { Router as RouterService } from '@/services/Utils/Router';

import { If, useRedirect } from '@/cutils';

import { TagsItem } from './parts/TagsItem';
import { PriceItem } from './parts/PriceItem';
import { BgIconPart } from './parts/BgIconPart';
import { ProgressItem } from './parts/ProgressItem';
import { CartFooterItem } from './parts/CartFooterItem';
import { CommonFooterItem } from './parts/CommonFooterItem';
import { ManageFooterItem } from './parts/ManageFooterItem';

import { getCourseCardColors } from './helper';
import { Container } from './CourseCard.styled';


export interface CourseCardProps {
    course: CourseFindManyItem;
    mode?: 'default' | 'cart' | 'progress' | 'manage';
    onClick?: (e: MouseEvent) => void;
    clickMode?: 'default' | 'currentLesson';
    className?: string;
    vkCardClassName?: string;
    header?: ReactNode;
    pickRouteParams?: string[];
    colorful?: boolean;
    multiLine?: boolean;
}


const CourseCard = observer((props: CourseCardProps) => {

    const { redirect } = useRedirect();

    const {
        course,
        colorful,
        className,
        vkCardClassName,
        multiLine = true,
        mode = 'default',
        pickRouteParams = [],
        clickMode = 'default',
        header = <TagsItem course={props.course}/>,
        course: {
            id,
            name,
            image,
            lessons,
            product,
            currentLesson,
            preferenceSettings,
        },
        onClick = (event: MouseEvent) => {
            const path: RoutePathType = mode === 'manage'
                ? '/manage/course/:courseId([0-9]+)/navigator'
                : (() => {
                    switch (clickMode) {
                        case 'currentLesson':
                            return !currentLesson && _.isEmpty(lessons.filter((e) => e.withContent))
                                ? '/courses/:page([0-9]+)/:courseId([0-9]+)/study/empty'
                                : '/courses/:page([0-9]+)/:courseId([0-9]+)/study/:lessonId([0-9]+)';

                        default:
                            return '/courses/:page([0-9]+)/:courseId([0-9]+)';
                    }
                })();

            const params: PageParams = _.omitBy({
                ..._.pick(routeParams, pickRouteParams),
                page: '1',
                courseId: `${id}`,
                lessonId: clickMode === 'currentLesson'
                    ? `${currentLesson?.id || lessons.find((e) => e.withContent)?.id}`
                    : '',
            }, _.isEmpty);

            if (mode !== 'manage'
                && clickMode === 'default'
                && preferenceSettings?.cardCustomLink
            ) {
                return redirect(preferenceSettings.cardCustomLink);
            }

            RouterService.pushPage(path, params, false, event);
        },
    } = props;

    const { route: { params: routeParams } } = useLocation();

    const { totalCount } = CourseService.getLessonsAnalytics(lessons);

    return (
        <Container {...props} onClick={onClick} progressCount={totalCount} className={[
            className,
            'vk-rounded cursor-pointer relative overflow-hidden',
        ].join(' ')}>
            <Card mode="outline" data-test={`course.card.${id}`} data-test-static="course.card" className={[
                'h-full',
                vkCardClassName,
                !colorful ? '' : 'before:content-none',
            ].join(' ')} style={{
                background: colorful
                    ? getCourseCardColors(course, PreferenceStore.isDark)
                    : '',
            }}>
                <div className={[
                    'flex vk-rounded flex-col h-full',
                    !colorful ? 'thin-border' : '',
                ].join(' ')}>
                    <div className="section-image">
                        {header}

                        <If is={!!image.main || !colorful}>
                            <Avatar mode="app" shadow={false} src={image.main || ''} className={[
                                'course-image self-start',
                                'vk-rounded is-compact m:self-start',
                                image.main ? 'cursor-pointer bg-transparent' : '',
                            ].join(' ')} children={(
                                image.main
                                    ? undefined
                                    : (
                                        <Icon48PictureOutline className={colorful ? 'opacity-70' : ''}
                                                              fill={colorful ? 'var(--text_primary)' : undefined}/>
                                    )
                            )}/>
                        </If>
                    </div>

                    <div className={[
                        'flex justify-between flex-col py-5 m:py-4 w-full h-full',
                    ].join(' ')}>
                        <div className={[
                            'section-text cursor-pointer mb-0.5 px-5 m:px-4 is-compact',
                        ].join(' ')}>
                            <div className={[
                                'flex justify-between mt-0.5 flex-col pt-8',
                            ].join(' ')}>
                                <Text weight="medium"
                                      data-test="course.name"
                                      style={{ lineHeight: '120%' }}
                                      className={[
                                          'fs-20',
                                          multiLine ? 'line-clamp-2' : 'line-clamp-1',
                                      ].join(' ')}>
                                    {name}
                                </Text>
                            </div>
                        </div>

                        <If is={mode === 'progress'}>
                            <div className="px-5 m:px-4">
                                <ProgressItem multiLine={multiLine} course={props.course}/>
                            </div>
                        </If>

                        <If is={[ 'default', 'cart', 'manage' ].includes(mode)}>
                            <div className={[
                                multiLine ? 'mt-8' : '',
                                'flex items-center justify-between px-5 m:px-4 flex-col',
                            ].join(' ')}>
                                <ButtonGroup gap="m" className={[
                                    'items-center w-full justify-between',
                                ].join(' ')}>
                                    <If is={![ 'cart', 'manage' ].includes(mode)}>
                                        <CommonFooterItem mode={mode} course={props.course}/>

                                        <PriceItem product={product}/>
                                    </If>

                                    <If is={mode === 'cart'}>
                                        <CartFooterItem course={props.course}/>
                                    </If>

                                    <If is={mode === 'manage'}>
                                        <ManageFooterItem course={props.course as CourseManageFragmentFragment}/>
                                    </If>
                                </ButtonGroup>
                            </div>
                        </If>
                    </div>
                </div>
            </Card>

            <If is={mode === 'default' && product.isFree && !!colorful}>
                <BgIconPart icon={(
                    <Icon28GiftOutline width={250} height={250} style={{
                        opacity: 0.4,
                        marginRight: -30,
                        transform: 'rotate(20deg)',
                    }}/>
                )}/>
            </If>
        </Container>
    );
});


export { CourseCard };
