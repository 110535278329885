/**
 * Rest api
 *
 * @author: exode <hello@exode.ru>
 */

import axios, { AxiosRequestConfig } from 'axios';

import { Url } from '@/utils';

import { restApiUrl } from '@/api/constants';
import { basicRequestHeaders } from '@/api/headers';


class RestApi {

    /**
     * Заголовки авторизации в запросе
     * @returns Authorization: string, 'Content-Type': string
     * @constructor
     */
    private static get headers() {
        return basicRequestHeaders();
    }

    /**
     * Уведомление об ошибки запроса
     * @constructor
     * @param error
     */
    private static notifyFailedError(error: any) {
        if (error?.response?.status) {
            console.warn(`💩 Rest api call is not success:`, error?.response);
        } else {
            console.warn(`📶 No internet connection:`, error);
        }
    }

    /**
     * Обработка ошибки запроса
     * @param error
     * @returns {{exception: any, payload: any, success: boolean, unsent: boolean}}
     * @private
     */
    private static parseError(error: any) {
        this.notifyFailedError(error);

        const status = error?.response?.status;

        return {
            success: false,
            payload: {} as any,
            exception: status && error?.response?.data,
            unsent: !status,
        };
    }

    /**
     * Post запрос к Api
     * @param url
     * @param data
     * @param headers
     * @param config
     * @returns mixed
     */
    static async post(
        url: string,
        data = {},
        headers = {},
        config?: AxiosRequestConfig,
    ) {
        headers = { ...this.headers, ...headers };

        try {
            const result = await axios({
                ...config,
                url: restApiUrl + url,
                method: 'POST',
                headers,
                data,
            });

            return result.data;

        } catch (error: any) {
            return this.parseError(error);
        }
    }

    /**
     * Get запрос к Api
     * @param {string} url
     * @param {{}} headers
     * @param {{}} data
     * @returns {Promise<any>}
     */
    static async get(
        url: string,
        headers = {},
        data = {},
    ) {
        headers = { ...this.headers, ...headers };

        try {
            const query = '?' + Url.objectToQuery(data);
            const result = await axios.get(restApiUrl + url + query, { headers });

            return result.data;

        } catch (error: any) {
            return this.parseError(error);
        }
    }

}


export { RestApi };
