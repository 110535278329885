/**
 * UseSkips
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { useCallback, useState } from 'react';

import { v4 } from 'uuid';

import { ArrangeTaskQuestion } from '@/shared/types';

import { elementSelectors, Skip, SkipPosition } from '../types';


interface Props {
    initialSkips?: Skip[];
}


export const useSkips = (props: Props = {}) => {

    const [ skips, setSkips ] = useState<Skip[]>(props.initialSkips || []);

    const clearSkips = () => {
        setSkips([]);
    };

    const addSkip = (partial: Omit<Skip, 'uuid'>): Skip => {
        const uuid = v4();
        const marker = partial.isFake ? uuid : `|_[skip:${uuid}]_|`;

        const skip = { ...partial, uuid: marker };

        setSkips([ ...skips, skip ]);

        return skip;
    };

    const updateSkip = (
        uuid: string,
        newText: string,
    ) => {
        setSkips((prevSkips) => prevSkips.map((skip) => (
            skip.uuid === uuid ? { ...skip, text: newText } : skip
        )));
    };

    const getSkipsOrder = (editorElement: HTMLElement): SkipPosition[] => {
        return Array.from(editorElement.querySelectorAll(elementSelectors.skipWord))
            .map((element, index) => ({
                position: index,
                uuid: element.getAttribute('data-skip-uuid') || '',
            }));
    };

    const sortSkipsByPosition = (skips: Skip[]): Skip[] => {
        return [ ...skips ].sort((a, b) => {
            if (a.position === undefined || b.position === undefined) {
                return 0;
            }

            return a.position - b.position;
        });
    };

    const updateSkipsOrder = useCallback((positions: SkipPosition[]) => {
        setSkips((prevSkips) => {
            const updatedSkips = prevSkips.map(skip => {
                const position = positions.find(({ uuid }) => uuid === skip.uuid)?.position;

                return { ...skip, position: position ?? skip.position };
            });

            return sortSkipsByPosition(updatedSkips);
        });
    }, []);

    const removeSkip = (uuid: string) => {
        setSkips((prevSkips) => {
            return prevSkips.filter(skip => skip.uuid !== uuid)
                .map((skip, index) => ({
                    ...skip,
                    position: skip.isFake ? undefined : index,
                }));
        });
    };

    const transformSkipTextToDto = (html: string | undefined): string => {
        const node = new DOMParser().parseFromString(html || '', 'text/html');

        node.querySelectorAll('[data-skip-uuid]').forEach((el) => {
            const skipUuid = el.getAttribute('data-skip-uuid');

            if (skipUuid) {
                el.replaceWith(skipUuid);
            }
        });

        const divs = Array.from(node.querySelectorAll('div')).reverse();

        divs.forEach((divEl) => {
            const textContent = divEl.textContent || '';
            const textNode = node.createTextNode('\n' + textContent.trim());

            divEl.replaceWith(textNode);
        });

        return node.body.innerText.trim()
            /** Add new lines */
            .replace(/\n+/g, '\n')
            /** Remove added no-break spaces */
            .replace(/\u00A0/g, '');
    };

    const transformSkipsToDto = (
        skips: Skip[],
        filter: (s: Skip) => boolean | undefined = () => true,
    ) => {
        return skips
            .filter(filter)
            .map((e) => _.pick(e, [ 'uuid', 'text', 'position' ]));
    };

    const transformSkipsToState = (
        question: ArrangeTaskQuestion,
    ) => {
        return [
            ...(question.skips?.map((e) => ({ ...e, isFake: false })) || []),
            ...(question.fakeSkips?.map((e) => ({ ...e, isFake: true })) || []),
        ];
    };

    return {
        skips,
        addSkip,
        updateSkip,
        removeSkip,
        clearSkips,
        getSkipsOrder,
        updateSkipsOrder,
        transformSkipsToDto,
        sortSkipsByPosition,
        transformSkipsToState,
        transformSkipTextToDto,
    };
};
