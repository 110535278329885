/**
 * AudioRecorder styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div`
    width: 100%;
    display: flex;
    min-height: 55px;
    align-items: center;
    justify-content: center;

    .audio-recorder {
        display: flex;
        box-shadow: none;
        border: var(--thin-border) solid var(--input_border);

        &.recording {
            width: 100%;
            padding: 4px;
            border-radius: 14px;
        }

        &:not(.recording) {
            width: 55px;

            .audio-recorder-mic {
                width: 30px;
                height: 30px;
            }
        }

        &-visualizer {
            display: flex;
            align-content: center;
            justify-content: center;
        }
    }
`;
