/**
 * ManageHomeworkPage store
 *
 * @author: exode <hello@exode.ru>
 */

import { makeObservable } from 'mobx';

import { observer, PageStore } from '@/pages/Core';

import { CourseLessonPracticeAttemptStatus } from '@/codegen/graphql';


/** Перемещен в store для решения circular dependency */
export const defaultFilter = {
    search: '',
    userIds: [] as number[],
    launchIds: [] as number[],
    courseIds: [] as number[],
    lessonIds: [] as number[],
    statuses: [] as CourseLessonPracticeAttemptStatus[],
    curatorIds: [] as number[],
};

export const dtoFilterHomeworksTransformer = (filter: typeof defaultFilter) => filter;


class ManageHomeworkPage extends PageStore {

    constructor() {
        super();
        makeObservable(this);
    }

    state = {
        list: { take: 25 },
        filter: dtoFilterHomeworksTransformer(defaultFilter),
        sort: {},
    };

}

const ManageHomeworkPageStore = new ManageHomeworkPage();


export { observer, ManageHomeworkPageStore };
