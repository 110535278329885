/**
 * VariantUploadAtom
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { FastField, FastFieldProps, useFormikContext } from 'formik';

import { StorageSpace } from '@/codegen/graphql';
import { MultipleWithAttachmentTaskQuestion } from '@/shared/types';

import { FileUpload } from '@/components/Atoms/FileUpload';

import { VariantUploadButtonAtom } from './VariantUploadButtonAtom';


interface Props {
    fieldName: string;
    variant: MultipleWithAttachmentTaskQuestion['variants'][number];
}


const VariantUploadAtom = (props: Props) => {

    const { variant, fieldName } = props;

    const { setFieldValue, handleSubmit } = useFormikContext();

    return (
        <FastField name={fieldName}>
            {({ field }: FastFieldProps) => (
                <FileUpload width="100%"
                            height="100%"
                            multiple={false}
                            space={StorageSpace.Task}
                            defaultClassName="vk-rounded"
                            id={`task-builder:${field.name}.${variant.uuid}`}
                            className="w-[40px] h-[40px] bg-hover thin-border vk-rounded"
                            onFileUpload={([ file ]) => {
                                setFieldValue(
                                    field.name,
                                    file.location
                                        ? { type: file.fileType, location: file.location }
                                        : null,
                                );

                                handleSubmit();
                            }}>
                    <VariantUploadButtonAtom variant={variant}/>
                </FileUpload>
            )}
        </FastField>
    );
};


export { VariantUploadAtom };
