/**
 * AudioContentElementEdit
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { useState } from 'react';

import { Formik } from 'formik';

import { AudioContent, FileExtension } from '@/shared/types';

import { AutoSaveForm, If } from '@/cutils';
import { useI18n, useOnceUpdate } from '@/hooks/core';

import { ContentElementType, StorageSpace } from '@/codegen/graphql';

import { SegmentedControl } from '@exode.ru/vkui';
import { Icon24Attach, Icon28DeleteOutline } from '@vkontakte/icons';

import { FileUpload } from '@/components/Atoms/FileUpload';
import { ConfirmButton } from '@/components/Atoms/ConfirmButton';
import { AudioRecorder } from '@/components/Atoms/AudioRecorder';
import { ProgressButton } from '@/components/Atoms/ProgressButton';
import { getContentElementContextValue } from '@/components/ContentElement';

import { BlockHeaderPart, BlockWrapperPart } from '../parts/edit';

import { ContentElementEditProps } from '../interfaces';


interface Props extends ContentElementEditProps<AudioContent> {

}


const AudioContentElementEdit = (props: Props) => {

    const {
        list,
        loading,
        onEdit,
        update,
        onDelete,
        options,
        contentElement,
    } = props;

    const { t } = useI18n('components.ContentElement.edit');

    const [ audioMode, setAudioMode ] = useState<'upload' | 'record'>('upload');

    const {
        edit: {
            blockHeaderProps = {},
            blockWrapperProps = {},
            BlockWrapper = BlockWrapperPart,
        } = {},
    } = getContentElementContextValue(ContentElementType.Audio);

    const {
        initialValues,
        handleUpdateSubmit,
    } = useOnceUpdate(
        _.pick(contentElement, [ 'content' ]),
        async (values) => update?.(values),
    );

    return (
        <Formik initialValues={initialValues} onSubmit={handleUpdateSubmit}>
            {({ values, handleSubmit, setFieldValue }) => (
                <AutoSaveForm onChange={() => handleSubmit()} onInstantChange={() => onEdit?.()}>
                    <BlockWrapper index={list?.index} {...blockWrapperProps}>
                        <BlockHeaderPart list={list} loading={loading} onDelete={onDelete} {...blockHeaderProps}/>

                        <If is={!values.content.location}>
                            <SegmentedControl size="l"
                                              value={audioMode}
                                              className="thin-border"
                                              onChange={(value) => setAudioMode(value as 'upload' | 'record')}
                                              options={[
                                                  {
                                                      value: 'upload',
                                                      label: t('upload'),
                                                      className: 'ignore-instant-change',
                                                  },
                                                  {
                                                      value: 'record',
                                                      label: t('record'),
                                                      className: 'ignore-instant-change',
                                                  },
                                              ]}/>

                            <If is={audioMode === 'upload'}>
                                <FileUpload id="audio"
                                            width="100%"
                                            height="100px"
                                            className="mb-3"
                                            multiple={false}
                                            controlIconSize={28}
                                            space={options.space}
                                            accept={FileExtension.Audio}
                                            onFileUpload={([ file ]) => {
                                                const audio: AudioContent = {
                                                    location: `${file.location}`,
                                                    meta: file.meta,
                                                };

                                                setFieldValue('content', audio);

                                                handleSubmit();
                                            }}>
                                    <ProgressButton stretched
                                                    size="l"
                                                    mode="secondary"
                                                    className="mt-5"
                                                    before={<Icon24Attach/>}
                                                    data-test="practice.add-audio">
                                        {t('addingAudio')}
                                    </ProgressButton>
                                </FileUpload>
                            </If>

                            <If is={audioMode === 'record'}>
                                <div className="flex items-center justify-center mt-6 mb-2">
                                    <AudioRecorder space={StorageSpace.Course}
                                                   uploadId={`AudioRecorder:${list?.index}`}
                                                   onUploaded={(file) => {
                                                       const audio: AudioContent = {
                                                           location: file.location || '',
                                                           duration: file.meta?.duration || 0,
                                                           meta: _.pick(file.meta, [
                                                               'size',
                                                               'encoding',
                                                               'mimeType',
                                                               'originalName',
                                                           ]),
                                                       };

                                                       setFieldValue('content', audio);
                                                       handleSubmit();
                                                   }}/>
                                </div>
                            </If>
                        </If>

                        <If is={!!values.content.location}>
                            <div className="flex items-center gap-2 my-5">
                                <audio controls className="w-full">
                                    <source src={values.content.location}/>
                                </audio>

                                <ConfirmButton className="rounded-full"
                                               confirmIcon={<Icon28DeleteOutline fill="var(--destructive)"/>}
                                               initialIcon={<Icon28DeleteOutline fill="var(--icon_secondary)"/>}
                                               callback={() => {
                                                   setFieldValue('content', {});
                                                   handleSubmit();
                                               }}/>
                            </div>
                        </If>
                    </BlockWrapper>
                </AutoSaveForm>
            )}
        </Formik>
    );
};


export { AudioContentElementEdit };
