/**
 * Types
 *
 * @author: exode <hello@exode.ru>
 */

export interface Skip {
    uuid: string;
    text: string;
    isFake?: boolean;
    position?: number;
}

export interface SkipPosition {
    uuid: string;
    position: number;
}

export interface TooltipPosition {
    x: number;
    y: number;
}

export interface SelectedSkip {
    text: string;
    uuid: string;
}

export const elementSelectors = {
    skipWord: '.skip-word',
    tooltipButton: '.tooltip-button',
};
