/**
 * UsersSelectView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { DebounceInput } from 'react-debounce-input';

import { useI18n } from '@/hooks/core';
import { observer } from '@/pages/Core';
import { Graphql, If } from '@/cutils';
import { ScrollHelper } from '@/helpers/ui';

import { DebouncedInput } from '@/types/html';
import { SchoolUserFindManyItem } from '@/types/user';

import { SchoolUserFindManyQuery } from '@/codegen/graphql';

import { FormItem, Input, List, Placeholder } from '@exode.ru/vkui';
import { Icon24SearchOutline, Icon56UsersOutline } from '@vkontakte/icons';

import { UserListSkeleton } from '@/components/Atoms/Skeleton/UserList';
import { UserCheckboxCell } from '@/components/Atoms/UserCheckboxCell';
import { UserSelectedChips } from '@/components/Atoms/UserSelectedChips';


interface Props {
    before?: ReactNode;
    onChange?: (profiles: SchoolUserFindManyItem['profile'][]) => void;
}


const UsersSelectView = observer((props: Props) => {

    const { before, onChange } = props;

    const { t } = useI18n('pages.Manage.School.Users.views.UsersSelectView');

    const [ search, setSearch ] = useState('');
    const [ memberProfiles, setMemberProfiles ] = useState<SchoolUserFindManyItem['profile'][]>([]);

    const membersListRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        onChange?.(memberProfiles);
    }, [ memberProfiles ]);

    return (
        <div className="flex flex-col flex-1">
            <div className="d:sticky d:top-0 bg-modal-content z-[1]">
                <>{before}</>

                <FormItem className="px-0 pt-0" top={t('selectedUsers', { selectedCount: memberProfiles.length })}>
                    <UserSelectedChips placement="page"
                                       ref={membersListRef}
                                       memberProfiles={memberProfiles}
                                       setMemberProfiles={setMemberProfiles}
                                       className="p-1.5 bg-input vk-rounded thin-border"/>
                </FormItem>

                <FormItem className="p-0">
                    <DebounceInput minLength={1}
                                   maxLength={15}
                                   value={search || ''}
                                   debounceTimeout={500}
                                   placeholder={t('typeUserName')}
                                   before={<Icon24SearchOutline/>}
                                   element={Input as DebouncedInput}
                                   onChange={({ target: { value } }) => setSearch(value)}/>
                </FormItem>
            </div>

            <SchoolUserFindManyQuery children={(result) => (
                <>
                    <Graphql.Loading result={result}>
                        <UserListSkeleton baseAvatarSize={42}
                                          itemClassName="px-0"
                                          baseSkeletonWidth={220}
                                          listClassName="mt-1.5 gap-1.5"/>
                    </Graphql.Loading>

                    <Graphql.Success result={result}>
                        {({ schoolUserFindMany: { items } }) => (
                            <>
                                <List className="flex flex-col pt-1">
                                    {items?.map((user) => {
                                        const isMember = !!_.find(memberProfiles, { id: user.profile.id });

                                        const onChange = () => {
                                            setMemberProfiles(
                                                (prevState) => isMember
                                                    ? prevState.filter(({ id }) => id !== user.profile.id)
                                                    : [ ...prevState, user.profile ],
                                            );

                                            setImmediate(() => ScrollHelper.scrollToBottom(membersListRef, true));
                                        };

                                        return (
                                            <UserCheckboxCell key={user.id}
                                                              user={user}
                                                              onChange={onChange}
                                                              checked={isMember}
                                                              profile={user.profile}/>
                                        );
                                    })}
                                </List>

                                <If is={_.isEmpty(items)}>
                                    <div className="flex items-center h-full">
                                        <Placeholder className="m-auto"
                                                     header={t('usersNotFound')}
                                                     children={t('tryChangeFilter')}
                                                     icon={<Icon56UsersOutline fill="var(--accent)"/>}/>
                                    </div>
                                </If>
                            </>
                        )}
                    </Graphql.Success>

                    <Graphql.Error result={result}/>
                </>
            )} variables={{
                list: { take: 50 },
                filter: {
                    search,
                    profile: { search: search },
                },
            }}/>
        </div>
    );
});


export { UsersSelectView };
